import Constant from '@/const';
import axios from 'axios';

const getTuslah = async ({ keyword }) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/service/tuslah?search=${keyword}`),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getTuslah;
