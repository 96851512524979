<template>
  <v-card flat outlined :style="addColor ? 'background-color: #f0f9ff;' : ''">
    <div v-for="(item, index) in form.drugs" :key="`drug-${index}`">
      <v-form
        ref="formDrugs"
        class="form-container"
        v-show="page === index"
        lazy-validation
      >
        <label class="required">Nama Obat</label>
        <v-combobox
          ref="drugName"
          placeholder="Masukkan Nama Obat"
          item-value="id_stock"
          append-icon="mdi-chevron-down"
          dense
          single-line
          return-object
          :readonly="isSaved || ['creation', 'hand-over'].includes(formType)"
          :filter="comboboxFilter"
          @change="
            updateDosage(item.detail?.strength || '', index);
            updateMixDrugQuantity({
              dosage: item.detail?.dosage_needed,
              index,
            });
          "
          :loading="loading.drugs"
          v-model="item.detail"
          :search-input.sync="search[index]"
          :items="resource.drugs"
          :rules="[
            v =>
              v?.quantity !== 0 || 'Obat habis. Silahkan pilih obat lainnya ',
            v =>
              (v?.quantity >= (Number(item.amount_requested) || 0) &&
                v?.quantity !== 0) ||
              'Stok tidak cukup',
          ]"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>
                <v-layout>
                  <v-flex d-flex justify-start>
                    <span class="text-left">
                      {{ `${data.item.text}` }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-layout>
                  <span class="mr-4">
                    Exp:
                    {{ data.item.expiryDateFormatted }}
                  </span>
                  <span class="mr-4">
                    No Batch: {{ data.item.batchNumber }}
                  </span>
                  <span class="mr-4">{{
                    `Sisa Stok : ${data.item.stock} ${data.item.packaging}`
                  }}</span>
                  <span>{{ currency(data.item.price || 0) }}</span>
                </v-layout>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-combobox>
        <label class="required">Dosis</label>
        <v-text-field
          v-model.number="item.dosage_needed"
          :suffix="item.detail?.strength_unit || ''"
          :readonly="isSaved"
          @keyup="
            updateMixDrugQuantity({
              dosage: item.dosage_needed,
              index,
            })
          "
          hide-details
          dense
          single-line
        />
        <label class="required">Jumlah Digunakan</label>
        <label>{{
          item.detail === '' || item.detail === undefined
            ? ''
            : (item.amount_requested.toFixed(2) || 0) +
              ' ' +
              (item.detail?.preparation || '')
        }}</label>
        <label class="required">Jumlah Ditagihkan</label>
        <label>{{
          item.detail === '' || item.detail === undefined
            ? ''
            : (item.amount_given || 0) + ' ' + (item.detail?.preparation || '')
        }}</label>
      </v-form>
    </div>
    <div class="action-container">
      <div class="flex-item d-flex justify-start">
        <v-btn
          @click="addDrug()"
          small
          outlined
          class="mr-2 primary--text"
          :disabled="isSaved || ['creation', 'hand-over'].includes(formType)"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
        <v-btn
          :disabled="
            form.drugs.length === 1 ||
              isSaved ||
              ['creation', 'hand-over'].includes(formType)
          "
          @click="deleteDrug()"
          small
          outlined
          class="mr-2 red--text"
          ><v-icon small>mdi-delete</v-icon></v-btn
        >
      </div>
      <div class="flex-item">
        <v-btn
          @click="previous()"
          small
          outlined
          class="primary--text"
          :color="
            (notAvailablePage[0] >= page + 1 &&
              notAvailablePage[notAvailablePage.length - 1] >= page + 1) ||
            notAvailablePage.length === 0 ||
            page + 1 === 1
              ? 'primary'
              : 'error'
          "
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <span class="font-weight-bold mx-2">{{
          `${page + 1} / ${form.drugs.length}`
        }}</span>
        <v-btn
          @click="next()"
          small
          outlined
          class="primary--text"
          :color="
            (notAvailablePage[0] <= page + 1 &&
              notAvailablePage[notAvailablePage.length - 1] <= page + 1) ||
            notAvailablePage.length === 0 ||
            page + 1 === form.drugs.length
              ? 'primary'
              : 'error'
          "
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import { getDrugsStock } from '@/fetchApi/Stock';
import formatMixin from '@/mixin/formatMixin';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

const _ = require('lodash');
const moment = require('moment-timezone');
export default {
  name: 'MixIngredientsForm',
  mixins: [formatMixin],
  props: {
    addColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: [],
      loading: {
        drugs: false,
      },
      resource: {
        drugs: [],
      },
      notAvailablePage: [],
    };
  },
  watch: {
    search(value) {
      this.searchDrug(this, value[this.page]);
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getDrugReview', 'getPage']),
    page: {
      get() {
        return this.getPage;
      },
    },
    form: {
      get() {
        return this.getForm.mix;
      },
    },
    isSaved: {
      get() {
        return this.getDrugReview.isSaved;
      },
    },
    formType: {
      get() {
        return this.getDrugReview.formType;
      },
    },
    isEdit: {
      get() {
        return this.getForm.isEdit;
      },
    },
  },
  mounted() {
    this.getDrugs();
    this.findNotAvailableIndices();
    this.isEdit && this.$refs.formDrugs[this.page].validate();
  },
  methods: {
    ...mapMutations(['updateMixDrugQuantity', 'setPage']),
    comboboxFilter(_, __, itemText) {
      return itemText;
    },
    searchDrug: _.debounce((v, i) => v.getDrugs(i), 600),
    async getDrugs(keyword = '') {
      try {
        this.loading.drugs = true;
        const params = {
          page: 1,
          itemCount: 25,
          category: 'Obat',
          name: keyword || '',
        };
        const response = await getDrugsStock(params);
        const { data } = response.data;
        this.resource.drugs = data.map(arr => {
          return {
            id: arr.id_drugs,
            batchNumber: arr.batch_number,
            id_stock: arr._id,
            name: arr.drugs.detail.name,
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs.detail.strength_unit,
            strength: arr.drugs.detail.strength,
            dosage_needed: arr.drugs.detail.strength,
            preparation: arr.drugs.detail.preparation,
            packaging: arr.package,
            price: arr.price,
            pricePerItem: arr.price,
            expiry: arr.expiry_date,
            expiryDateFormatted: moment(arr.expiry_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY',
            ),
            stock: arr.quantity,
            quantity: arr.quantity,
          };
        });
      } catch {
        this.resource.drugs = [];
      } finally {
        this.loading.drugs = false;
      }
    },
    updateDosage(dosage, index) {
      this.form.drugs[index].strength = dosage || '';
      this.form.drugs[index].dosage_needed = dosage || '';
      this.findNotAvailableIndices();
    },
    addDrug() {
      this.form.drugs.push({
        detail: '',
        amount_requested: '',
        amount_given: '',
        strength: '',
      });
      this.setPage(this.form.drugs.length - 1);
    },
    deleteDrug() {
      this.form.drugs.splice(this.page, 1);
      if (this.page === 0) {
        this.setPage(0);
      } else {
        this.setPage(this.page - 1);
      }
    },
    findNotAvailableIndices() {
      const setArr = new Set();
      const isNotAvailable = this.form.drugs.filter(
        item =>
          item?.detail?.available_stock === false ||
          item?.detail?.stock <= 0 ||
          item?.detail?.stock <= (item.amount_given || 0),
      );
      for (let i = 0; i < isNotAvailable.length; i++) {
        const indexOfIsNotAvailable =
          this.form.drugs.indexOf(isNotAvailable[i]) + 1;
        setArr.add(indexOfIsNotAvailable);
      }
      this.notAvailablePage = Array.from(setArr).sort((a, b) => a - b);
    },
    next() {
      this.findNotAvailableIndices();
      if (this.page === this.form.drugs.length - 1) return;
      this.setPage(this.page + 1);
      if (this.$refs.formDrugs[this.page].validate()) {
        return;
      }
    },
    previous() {
      this.findNotAvailableIndices();
      if (!this.page) return;
      this.setPage(this.page - 1);
      if (this.$refs.formDrugs[this.page].validate()) {
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  padding: 0.5vw;
  width: 98%;
  // background-color: #f0f9ff;

  .form-container {
    display: grid;
    grid-template-columns: 40% 55%;
    margin-bottom: 1vw;
    gap: 0.5vw;
    color: #101010;
    text-align: start;
    align-items: center;

    label {
      font-size: 0.72vw;
    }
  }

  .action-container {
    display: flex;

    .flex-item {
      font-size: 0.8vw;
      flex: 1 1 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
.required::after {
  content: '*';
  color: #eb4747;
}
</style>
