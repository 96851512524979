<template>
  <div class="monthly-illness-report mx-4">
    <div style="margin-bottom: 10px;">
      <div class="header">
        <v-col cols="5" class="text-left">
          <h2>Laporan Data Kesakitan Bulanan</h2>
          <h5 class="caption grey--text float-left">
            Laporan Kesakitan
          </h5>
        </v-col>
        <!-- <div class="caption grey--text float-left">
            Terakhir diperbarui pada: 10 November 2022
          </div> -->
        <div style="display: flex; justify-content: space-between; gap: 10px;">
          <div class="d-flex align-center justify-center" mt-3>
            <div class="action-container" @click="backHome">
              <v-icon class="">mdi-home</v-icon>
            </div>
          </div>
          <div class="d-flex align-center justify-center" mt-3>
            <div class="action-container" @click="refresh()">
              <v-icon class="" :class="{ loadingrefresh: refreshLoad }"
                >mdi-refresh</v-icon
              >
            </div>
          </div>
        </div>
      </div>

      <v-row>
        <v-tabs
          class="mx-6 mt-6"
          v-model="tabs"
          height="50px"
          slider-color="primary"
        >
          <v-tab class="text-capitalize font-weight-bold">
            Jumlah Kunjungan Pasien
          </v-tab>
          <v-tab class="text-capitalize font-weight-bold">
            Jumlah Kasus
          </v-tab>
          <v-tab class="text-capitalize font-weight-bold">
            Grafik
          </v-tab>
        </v-tabs>
      </v-row>
    </div>
    <v-main>
      <v-container fluid>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <detail-table-pain-data
              :isFetching="isFetching"
              :isByCase="false"
              url="/report/sickness/total-visit"
              ref="byVisit"
              @exportReport="downloadSicknessReport(false, $event)"
            ></detail-table-pain-data
          ></v-tab-item>
          <v-tab-item>
            <detail-table-pain-data
              :isFetching="isFetching"
              url="/report/sickness"
              ref="byCase"
              isByCase
              @exportReport="downloadSicknessReport(true, $event)"
            ></detail-table-pain-data
          ></v-tab-item>
          <v-tab-item>
            <pain-data-chart ref="graph"></pain-data-chart>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-main>
    <v-snackbar :timeout="4000" v-model="snackbar.show" :color="snackbar.color">
      <div class="snackbar-content">
        {{ snackbar.message }}
        <v-btn
          text
          fab
          dense
          depressed
          small
          @click.native="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';
// import Swal from 'sweetalert2';
import jwtMixin from '@/mixin/jwtMixin';

import DetailTablePainData from './MonthlyPainData/DetailTablePainData.vue';
import PainDataChart from './MonthlyPainData/PainDataChart.vue';
import moment from 'moment';
const axios = require('axios');
// const _ = require('lodash');

export default {
  name: 'MonthlyReport',
  mixins: [jwtMixin, alertMixin],
  components: {
    PainDataChart,
    DetailTablePainData,
  },
  data() {
    return {
      tabs: null,
      menu: false,
      menu2: false,
      refreshLoad: false,
      snackbar: {
        color: '',
        message: '',
        show: false,
      },
      isFetching: false,
      input: {
        dateStart: '',
        dateEnd: '',
        disease: '',
      },
      resource: {
        disease: [],
      },
      isLoading: false,
      dateStart: '',
      dateEnd: '',
      isDiagnoseLoading: false,
    };
  },

  methods: {
    async downloadSicknessReport(isByCase = false, event) {
      let sDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      let eDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD');
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/report/sickness/sickness_report?start_date=${
              event === undefined ? sDate : event.startDate
            }&end_date=${event === undefined ? eDate : event.endDate}&type=${
              isByCase ? 'total-case' : 'total-visit'
            }`,
          ),
        );
        if (res.status === 200) {
          this.snackbar.color = '#2B9C4D';
          this.snackbar.message = 'Data berhasil di export!';
          this.snackbar.show = true;
          // this.showFillSuccess('Export Berhasil');
          const { url } = res.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          this.snackbar.color = '#D32F2F';
          this.snackbar.message = 'Export data gagal, silahkan coba lagi!';
          this.snackbar.show = true;
        }
      }
    },
    refresh() {
      this.refreshLoad = true;
      switch (this.tabs) {
        case 0:
          this.$refs.byVisit.refresh();
          setTimeout(() => {
            this.refreshLoad = false;
          }, 200);

          break;
        case 1:
          this.$refs.byCase.refresh();
          setTimeout(() => {
            this.refreshLoad = false;
          }, 200);
          break;
        case 2:
          this.$refs.graph.refresh();
          setTimeout(() => {
            this.refreshLoad = false;
          }, 200);
          break;
      }
    },
    backHome() {
      this.$router.push('/reporting/laporan-pelayanan/');
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
}

.graph-table {
  height: 50vh;
}

table thead {
  background: #ededed;
}

table th {
  font-weight: 500;
  font-size: 12px;
  padding: 0.5rem;
}

table .subheader {
  background: #f5f5f5;
  font-size: 9px;
}

table tbody td {
  border-right: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-container {
  &:hover {
    background: #dadada;
    border-radius: 100%;
  }
  cursor: pointer;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingrefresh {
  display: inline-block;
  animation: spin 0.2s linear infinite;
}

.report-container {
  width: 100%;
  margin-top: 10px;
}
.grid-display {
  display: grid;
  // grid-gap: 16px;
  // padding: 16px;

  &.report-header {
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    // grid-auto-flow: column;
    grid-auto-columns: minmax(1fr);
    span {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: #404040;
    }
    .report-head {
      background: #deeef9;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .report-sub-head {
      background: #f0f9ff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-align: center;

      span {
        font-size: 9px;
        color: #404040;
      }
    }
  }
  &.report-items {
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    grid-auto-flow: column;
    grid-auto-columns: minmax(1fr);
    grid-gap: 1px;
    span {
      padding: 0.5rem;
      font-size: 0.85714285714rem;
      line-height: 1.28571428571rem;
      font-weight: 400;
      color: #000000;
      white-space: pre-wrap;
      overflow-wrap: break-word !important;
      white-space: normal !important;
      text-align: left;
      box-sizing: border-box;
    }
  }
  .rowSpan23 {
    grid-row-end: 3;
    grid-row-start: 2;
  }
  .rowSpan12 {
    grid-row-end: 2;
    grid-row-start: 1;
  }
  .rowSpan13 {
    grid-row-end: 3;
    grid-row-start: 1;
  }
  .colSpan13 {
    grid-column-end: 6;
    grid-column-start: 4;
  }
  .colSpan35 {
    grid-column-end: 8;
    grid-column-start: 6;
  }
  .colSpan57 {
    grid-column-end: 10;
    grid-column-start: 8;
  }
  .colSpan714 {
    grid-column-end: 12;
    grid-column-start: 10;
  }
  .colSpan816 {
    grid-column-end: 14;
    grid-column-start: 12;
  }
  .colSpan999 {
    grid-column-end: 16;
    grid-column-start: 14;
  }

  .colSpan1000 {
    grid-column-end: 18;
    grid-column-start: 16;
  }

  .colSpan1010 {
    grid-column-end: 20;
    grid-column-start: 18;
  }

  .colSpan1020 {
    grid-column-end: 22;
    grid-column-start: 20;
  }

  .colSpan1030 {
    grid-column-end: 24;
    grid-column-start: 22;
  }

  .colSpan1040 {
    grid-column-end: 26;
    grid-column-start: 24;
  }
}
.table-container {
  position: relative;
  height: 45vh;
  .border-container {
    z-index: 1;
    position: absolute;
    display: grid;
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    left: 0fr;
    top: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    .border {
      width: 1px;
      background: #e0e0e0;
    }
  }
}

.input-graph {
  width: 80%;
  margin: auto;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 80%;

  .graph-img {
    height: 100px;
  }
}

.snackbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
