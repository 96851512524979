import Constant from '@/const';
import axios from 'axios';

const getDrugMarginById = async id => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/drugs/margin/${id}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getDrugMarginById;
