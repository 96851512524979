<template>
  <v-card class="pa-5 main-wrapper">
    <header-form
      title="Setting Margin dan Harga"
      :chip="`Global + ${isGlobalSpecific ? 'Spesifik' : 'Kategori'} Setting`"
      :description="
        isGlobalSpecific
          ? 'Atur harga dan margin secara global untuk semua item, atau sesuaikan langsung untuk item tertentu'
          : 'Atur harga dan margin secara global untuk item, atau sesuaikan langsung berdasarkan kategori.'
      "
      :confirmationButtonIcon="
        isGlobalSpecific ? 'mdi-check' : 'mdi-arrow-right'
      "
      :confirmationButtonText="isGlobalSpecific ? confirmBtnText : 'Lanjut'"
      :isUsingBackButton="!getIsEdit"
      :isUsingCancelButton="getIsEdit"
      :isEnableConfirmButton="getIsEnableConfirmationBtn"
      @back="back"
      @confirm="next"
      @cancel="closeForm"
    />
    <div class="input-container">
      <div>
        <div class="d-flex justify-space-between">
          <span class="grey-text-light">HARGA DEFAULT</span>
          <span class="grey-text-light" style="color: #9e9e9e"
            >Base Harga: <strong style="color: black">HNA</strong></span
          >
        </div>
        <input-price-card
          ref="card1"
          @on-change-input="type => onUserType(type, 0)"
          :index="0"
          isDefault
        />
      </div>
      <div class="text-start">
        <span class="grey-light-text">
          MULTIPLE HARGA (OPSIONAL)
        </span>
        <input-price-card
          ref="card2"
          :index="1"
          @on-change-input="type => onUserType(type, 1)"
        />
      </div>
      <input-price-card
        ref="card3"
        :index="2"
        @on-change-input="type => onUserType(type, 2)"
      />
    </div>
    <v-text-field
      placeholder="Cari barang"
      v-if="isGlobalSpecific"
      outlined
      dense
      single-line
      v-model="search"
      append-icon="mdi-magnify"
    />
    <div class="text-start">
      <span class="grey-light-text">
        PREVIEW PERHITUNGAN
      </span>
      <div :class="`${isGlobalSpecific ? 'specific ' : ''}grid-display`">
        <span
          v-for="(item, index) in displayedHeaderTable.filter(i => i)"
          :key="'table-header-' + index"
          :class="item.class"
          >{{ item.text }}</span
        >
      </div>
      <v-progress-linear
        indeterminate
        v-if="getLoadingState.previewTable"
      ></v-progress-linear>
      <div
        class="table-container"
        id="scroll-target"
        v-scroll:#scroll-target="onScroll"
      >
        <div
          v-for="(item, index) in table.data"
          :key="'row-' + index"
          :class="`${isGlobalSpecific ? 'specific ' : ''}grid-display row`"
        >
          <span class="text-start">{{ item.name }}</span>
          <v-tooltip v-if="isGlobalSpecific" bottom>
            <template #activator="{ on: tooltip }">
              <span
                v-on="{ ...tooltip }"
                class="text-start chip"
                :class="{ specific: item.setting === 'Spesifik' }"
                >{{ item.setting }}</span
              >
            </template>
            <span>{{
              item.setting === 'Spesifik'
                ? 'Harga produk ini telah diatur secara spesifik'
                : 'Harga produk ini mengikuti aturan global yang diatur saat ini'
            }}</span>
          </v-tooltip>
          <span class="text-start">{{ item.category }}</span>
          <span class="text-end">{{ currency(item.hna) }}</span>
          <span class="text-end">{{ currency(item.hpp) }}</span>
          <span class="text-start">{{ item.margin }}%</span>
          <span class="text-start">{{ item.ppn }}%</span>
          <span class="text-end">{{ currency(item.price1) }}</span>
          <span class="text-end">{{ currency(item.price2) }}</span>
          <span class="text-end">{{ currency(item.price3) }}</span>
          <v-btn
            color="primary"
            v-if="isGlobalSpecific"
            style="letter-spacing: 0.001px; border-width: 2px; font-size: .75vw;"
            outlined
            dense
            depressed
            single-line
            @click="openDialog(item)"
            class="text-capitalize pa-0"
            >Atur Harga</v-btn
          >
        </div>
      </div>
    </div>
    <v-snackbar
      style="position: absolute"
      :timeout="4000"
      v-model="getSnackbar.show"
      :color="getSnackbar.color"
    >
      <div class="d-flex justify-space-between">
        <span class="mr-auto">{{ getSnackbar.text }}</span>
        <v-icon
          class="ml-5"
          dark
          small
          @click="() => (getSnackbar.show = false)"
        >
          mdi-close
        </v-icon>
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import HeaderForm from '../HeaderForm.vue';
import InputPriceCard from '../GlobalSetting/InputPriceCard.vue';
import { createNamespacedHelpers } from 'vuex';
import Swal from 'sweetalert2';

import formatMixin from '@/mixin/formatMixin';

const { mapMutations, mapGetters, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);
const _ = require('lodash');
export default {
  components: { HeaderForm, InputPriceCard },
  mixins: [formatMixin],
  name: 'GlobalCategorySettingDialog',
  data() {
    return {
      search: '',
      table: {
        data: [],
        pagination: { page: 1 },
      },
    };
  },
  mounted() {
    this.getDataPreview();
  },
  beforeDestroy() {
    this.clearPriceState();
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  computed: {
    ...mapGetters([
      'getSpecificDrugResult',
      'getIsEnableConfirmationBtn',
      'getSnackbar',
      'getDataFromServer',
      'getIsEdit',
      'getPriceSetting',
      'getPreviewData',
      'getSelectedSetting',
      'getLoadingState',
    ]),
    confirmBtnText: {
      get() {
        return this.getIsEdit ? 'Simpan Perubahan' : 'Simpan';
      },
    },
    hasFormChanged: {
      get() {
        return (
          this.getPriceSetting[0].margin !== '' ||
          this.getPriceSetting[0].ppn !== '' ||
          this.getPriceSetting[0].description !== '' ||
          this.getPriceSetting[1].isActive ||
          this.getPriceSetting[2].isActive
        );
      },
    },
    displayedHeaderTable: {
      get() {
        return this.tableHeader.concat(
          ...this.getPriceSetting.map((item, index) => ({
            text: this.isGlobalSpecific
              ? `Harga ${index + 1}`
              : item.description || `Harga ${index + 1}`,
            class: 'text-end',
          })),
          this.isGlobalSpecific ? { text: '', value: 'text-start' } : null,
        );
      },
    },
    tableHeader: {
      get() {
        return this.isGlobalSpecific
          ? [
              {
                text: 'Nama Barang',
                class: 'text-start',
              },
              {
                text: 'Pengaturan Harga',
                class: 'text-start',
              },
              {
                text: 'Golongan',
                class: 'text-start',
              },
              {
                text: 'HNA',
                class: 'text-end',
              },
              {
                text: 'HPP',
                class: 'text-end',
              },
              {
                text: 'Margin',
                class: 'text-start',
              },
              {
                text: 'PPN',
                class: 'text-start',
              },
            ]
          : [
              {
                text: 'Nama Barang',
                class: 'text-start',
              },
              {
                text: 'Golongan',
                class: 'text-start',
              },
              {
                text: 'HNA',
                class: 'text-end',
              },
              {
                text: 'HPP',
                class: 'text-end',
              },
              {
                text: 'Margin',
                class: 'text-start',
              },
              {
                text: 'PPN',
                class: 'text-start',
              },
            ];
      },
    },
    isGlobalSpecific: {
      get() {
        return this.getSelectedSetting === 'global-specific-setting';
      },
    },
  },
  methods: {
    ...mapMutations([
      'setSelectedForm',
      'clearTemporaryStore',
      'setDialog',
      'clearPriceState',
      'setPriceSettingDirectly',
      'setTemporaryStore',
      'setSpecificDrug',
      'setSpecificDrugResult',
      'clearPriceState',
      'setSelectedForm',
      'clearPriceState',
      'setPriceSettingDirectly',
    ]),
    closeForm() {
      this.setDialog(false);
      this.setSelectedForm(null);
      this.clearPriceState();
    },
    openDialog(drug) {
      this.setTemporaryStore(this.getPriceSetting);
      this.clearPriceState();
      this.setSpecificDrug(drug);
      this.setSelectedForm(5);
    },
    onScroll() {
      if (!this.isGlobalSpecific) return;
      const el = document.getElementById('scroll-target');
      if (el) {
        if (el.scrollTop + el.clientHeight + 3 >= el.scrollHeight) {
          this.table.pagination.page += 1;
          this.getDataPreview(true); // 'true' value stands for isScrollEvent parameter (see function declaration)
        }
      }
    },
    searchData: _.debounce(v => {
      v.getDataPreview();
    }, 600),
    ...mapActions([
      'resolveGetPreviewCalculation',
      'resolvePostAddDrugMargin',
      'resolvePutAddDrugMargin',
    ]),
    async getDataPreview(isScrollEvent) {
      const params = {
        page: isScrollEvent ? this.table.pagination.page : 1,
        itemCount: this.isGlobalSpecific ? 12 : 10,
        search: this.search || '',
      };
      const { mappedData } = await this.resolveGetPreviewCalculation(params);
      if (isScrollEvent) {
        this.table.data = this.table.data.concat(
          ...mappedData.map(item => {
            const specificDrug = this.getSpecificDrugResult.find(
              i => i.id === item.id,
            );
            if (specificDrug) {
              return {
                ...item,
                setting: 'Spesifik',
                price1: specificDrug.margins?.[0]?.sellingPrice,
                price2: specificDrug.margins?.[1]?.sellingPrice || 0,
                price3: specificDrug.margins?.[2]?.sellingPrice || 0,
                margin: specificDrug.margins?.[0]?.margin,
                ppn: specificDrug.margins?.[0]?.ppn,
                allMarginsData: specificDrug?.margins,
              };
            }
            return {
              ...item,
              setting: 'Global',
            };
          }),
        );
      } else {
        this.table.pagination.page = 1;
        this.table.data = this.getPreviewData.map(item => {
          const specificDrug = this.getSpecificDrugResult.find(
            i => i.id === item.id,
          );
          if (specificDrug) {
            return {
              ...item,
              setting: 'Spesifik',
              price1: specificDrug.margins?.[0]?.sellingPrice,
              price2: specificDrug.margins?.[1]?.sellingPrice || 0,
              price3: specificDrug.margins?.[2]?.sellingPrice || 0,
              margin: specificDrug.margins?.[0]?.margin,
              ppn: specificDrug.margins?.[0]?.ppn,
              allMarginsData: specificDrug.margins,
            };
          }
          return {
            ...item,
            setting: 'Global',
          };
        });
      }
    },
    onUserType(type, idx) {
      const calculatePrice = (hna, index) => {
        if (!this.getPriceSetting[index]?.isActive) return 0;
        const basePrice = hna;
        const marginAmount =
          (basePrice * +this.getPriceSetting[index].margin) / 100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin * +this.getPriceSetting[index].ppn) / 100;
        return priceAfterMargin + taxAmount;
      };

      switch (type) {
        case 'margin':
        case 'ppn':
          this.table.data = this.table.data.map(item => {
            if (item.setting === 'Spesifik') return item;
            return {
              ...item,
              [type]: this.getPriceSetting[0][type],
              [`price${idx + 1}`]: calculatePrice(item.hna, idx),
            };
          });
          break;
        case 'description':
          this.table.header = this.table.header.map(item => {
            return {
              ...item,
            };
          });
      }
    },
    back() {
      if (this.hasFormChanged) {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Konfirmasi Tindakan</p></div>`,
          iconHtml:
            '<span sstyle="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Anda belum menyelesaikan pengisian form. Perubahan yang</p><p>belum disimpan akan hilang. Apakah Anda yakin ingin</p><p>membatalkan perubahan?</p></div>',
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'button-confirm',
            cancelButton: 'button-error-blue',
          },
          confirmButtonText: 'Tetap Mengisi Form',
          cancelButtonText: 'Batalkan Perubahan',
        }).then(result => {
          if (result.isConfirmed) {
            return;
          }
          this.clearPriceState();
          this.setSelectedForm(0);
          this.clearTemporaryStore();
        });
      } else {
        this.setSelectedForm(0);
        this.clearTemporaryStore();
      }
    },
    next() {
      if (!this.getIsEnableConfirmationBtn) return;
      if (
        !this.$refs.card1.$refs.form.validate() ||
        !this.$refs.card2.$refs.form.validate() ||
        !this.$refs.card3.$refs.form.validate()
      ) {
        return;
      } else {
        if (this.isGlobalSpecific) {
          this.$emit('finish-setting', {
            origin: 4,
            snackbarText: 'Setting Margin dan Harga Farmasi berhasil dibuat!',
            confirmationFunction: async () => {
              if (this.getIsEdit) {
                await this.resolvePutAddDrugMargin({
                  type: 'global_specific',
                  additionalData: [
                    {
                      margins: this.getPriceSetting.map(item => ({
                        margin: +item.margin,
                        ppn: +item.ppn,
                        isActive: !!item.isActive,
                        description: item.description,
                      })),
                    },
                  ].concat(
                    this.getSpecificDrugResult.map(item => ({
                      id_drug: item.id,
                      margins: item.margins.map(i => ({
                        margin: +i.margin,
                        ppn: +i.ppn,
                        isActive: !!i.isActive,
                        description: i.description,
                      })),
                    })),
                  ),
                });
              } else {
                await this.resolvePostAddDrugMargin({
                  type: 'global_specific',
                  additionalData: [
                    {
                      margins: this.getPriceSetting.map(item => ({
                        margin: +item.margin,
                        ppn: +item.ppn,
                        isActive: !!item.isActive,
                        description: item.description,
                      })),
                    },
                  ].concat(
                    this.getSpecificDrugResult.map(item => ({
                      id_drug: item.id,
                      margins: item.margins.map(i => ({
                        margin: +i.margin,
                        ppn: +i.ppn,
                        isActive: !!i.isActive,
                        description: i.description,
                      })),
                    })),
                  ),
                });
              }
            },
          });
          return;
        }
        this.setTemporaryStore(this.getPriceSetting);
        // reset price input
        this.setPriceSettingDirectly([
          {
            isDefault: true,
            margin: '',
            ppn: '',
            description: '',
            isActive: true,
          },
          {
            isDefault: false,
            isActive: false,
            margin: '',
            ppn: '',
            description: '',
          },
          {
            isDefault: false,
            isActive: false,
            margin: '',
            ppn: '',
            description: '',
          },
        ]);
        this.setSelectedForm(3);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  position: relative;
  overflow: auto;
  height: 90vh;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  .input-container {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 2vw;
    align-items: end;
  }

  .grid-display {
    display: grid;
    grid-template-columns: 28% 6% repeat(2, 7%) 5% 8% repeat(3, 10%);
    column-gap: 1%;
    padding: 0.5vw 0;
    border-bottom: 2px solid #e0e0e0;
    color: #9e9e9e;
    font-size: 0.85vw;

    &.specific {
      grid-template-columns: 24% 10% 4% repeat(2, 7%) 5% 4% repeat(3, 7%) 4.5vw;
      align-items: center;
    }

    &.row {
      border-bottom: 1px solid #e0e0e0;
      color: #404040;
      padding: 0.5vw 0.8vw;
      font-size: 0.8vw;

      .chip {
        background-color: #f0f8fe;
        width: fit-content;
        padding: 0.2vw 0.6vw;
        color: #1976d2;
        border-radius: 5px;

        &.specific {
          background-color: #e7f8ec;
          color: #2b9c4d;
        }
      }
    }
  }

  .table-container {
    height: 45vh;
    padding-top: 1.1vw;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #757575;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
}
</style>
