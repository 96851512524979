import { getPreviewMargin } from '@/fetchApi/MasterData/Drugs';
import {
  postAddDrugMargin,
  getDrugMargin,
  putUpdateDrugMargin,
  getCategoryMarginList,
  getCategoryMarginStatus,
} from '@/fetchApi/Setting/MarginPrice';

const state = {
  dialog: false, //TODO: change to false when API is ready
  selectedForm: null, //TODO: change to null when API is ready
  inputState: 'add',
  selectedSetting: '', // TODO: change to '' when API is ready
  isEdit: false,
  temporaryStore: [],
  previewData: [],
  dataFromServer: {},
  loading: {
    previewTable: false,
    dashboard: false,
    saving: false,
  },
  priceSetting: [
    {
      isDefault: true,
      margin: '',
      ppn: '',
      description: '',
      isActive: true,
    },
    {
      isDefault: false,
      isActive: false,
      margin: '',
      ppn: '',
      description: '',
    },
    {
      isDefault: false,
      isActive: false,
      margin: '',
      ppn: '',
      description: '',
    },
  ],
  globalSetting: {},
  categorySetting: {
    selectedCategory: 'medicine',
  },
  specificSetting: {
    drug: null,
    result: [],
    isEditFromDashboard: false,
  },
  snackbar: {
    text: '',
    show: false,
    color: '',
  },
};

const getters = {
  getDialog: state => state.dialog,
  getSnackbar: state => state.snackbar,
  getSelectedForm: state => state.selectedForm,
  getPreviewData: state => state.previewData,
  getIsEdit: state => state.isEdit,
  getDataFromServer: state => state.dataFromServer,
  getIsEditFromDashboard: state => state.specificSetting.isEditFromDashboard,
  getSpecificDrugResult: state => state.specificSetting.result,
  getSelectedSetting: state => state.selectedSetting,
  getPriceSetting: state => state.priceSetting,
  getInputState: state => state.inputState,
  getSelectedCategory: state => state.categorySetting.selectedCategory,
  getLoadingState: state => state.loading,
  getSpecificDrug: state => state.specificSetting.drug,
  getTemporaryStore: state => state.temporaryStore,
  getPriceSettingRules: state => {
    return {
      noEmptyRule: v => !!v || v === '0' || 'Wajib diisi!',
      max: v => +v <= 500 || 'Maksimal 500%',
      noIdenticalMargin: [
        v => {
          if (
            (state.priceSetting[1].isActive &&
              +v === +state.priceSetting[1].margin) ||
            (state.priceSetting[2].isActive &&
              +v === +state.priceSetting[2].margin)
          ) {
            return 'Margin antar harga tidak boleh sama!';
          }
        },
        v => {
          if (
            +v === +state.priceSetting[0].margin ||
            (state.priceSetting[2].isActive &&
              +v === +state.priceSetting[2].margin)
          ) {
            return 'Margin antar harga tidak boleh sama!';
          }
        },
        v => {
          if (
            +v === +state.priceSetting[0].margin ||
            (state.priceSetting[1].isActive &&
              +v === +state.priceSetting[1].margin)
          ) {
            return 'Margin antar harga tidak boleh sama!';
          }
        },
      ],
    };
  },
  getIsEnableConfirmationBtn: state => {
    if (!state.priceSetting[1].isActive && !state.priceSetting[2].isActive) {
      return (
        state.priceSetting[0].margin !== '' &&
        state.priceSetting[0].ppn !== '' &&
        state.priceSetting[0].description !== ''
      );
    } else if (
      state.priceSetting[1].isActive &&
      state.priceSetting[2].isActive
    ) {
      return (
        state.priceSetting[0].margin !== '' &&
        state.priceSetting[0].margin <= 500 &&
        state.priceSetting[0].ppn !== '' &&
        +state.priceSetting[0].ppn <= 500 &&
        state.priceSetting[0].description !== '' &&
        state.priceSetting[1].margin !== '' &&
        state.priceSetting[1].margin <= 500 &&
        state.priceSetting[1].ppn !== '' &&
        +state.priceSetting[1].ppn <= 500 &&
        state.priceSetting[1].description !== '' &&
        state.priceSetting[2].margin !== '' &&
        state.priceSetting[2].margin <= 500 &&
        state.priceSetting[2].ppn !== '' &&
        +state.priceSetting[2].ppn <= 500 &&
        state.priceSetting[2].description !== '' &&
        !state.priceSetting.filter(
          (obj, index, self) =>
            obj.margin !== '' &&
            self.findIndex(o => +o.margin === +obj.margin) !== index,
        ).length
      );
    } else if (
      state.priceSetting[1].isActive &&
      !state.priceSetting[2].isActive
    ) {
      return (
        state.priceSetting[0].margin !== '' &&
        state.priceSetting[0].margin <= 500 &&
        state.priceSetting[0].ppn !== '' &&
        state.priceSetting[0].ppn <= 500 &&
        state.priceSetting[0].description !== '' &&
        state.priceSetting[1].margin !== '' &&
        state.priceSetting[1].margin <= 500 &&
        state.priceSetting[1].ppn !== '' &&
        state.priceSetting[1].ppn <= 500 &&
        state.priceSetting[1].description !== '' &&
        !state.priceSetting.filter(
          (obj, index, self) =>
            index !== 2 &&
            obj.margin !== '' &&
            self.findIndex(o => +o.margin === +obj.margin) !== index,
        ).length
      );
    } else {
      return (
        state.priceSetting[0].margin !== '' &&
        state.priceSetting[0].ppn <= 500 &&
        state.priceSetting[0].margin <= 500 &&
        state.priceSetting[0].ppn !== '' &&
        state.priceSetting[0].description !== '' &&
        state.priceSetting[2].margin !== '' &&
        state.priceSetting[2].ppn <= 500 &&
        state.priceSetting[2].margin <= 500 &&
        state.priceSetting[2].ppn !== '' &&
        state.priceSetting[2].description !== '' &&
        !state.priceSetting.filter(
          (obj, index, self) =>
            index !== 1 &&
            obj.margin !== '' &&
            self.findIndex(o => +o.margin === +obj.margin) !== index,
        ).length
      );
    }
  },
};

const mutations = {
  setSnackbar(state, value) {
    state.snackbar = value;
  },
  setDialog(state, value) {
    state.dialog = value;
  },
  setLoading(state, { key, value }) {
    state.loading[key] = value;
  },
  setSelectedForm(state, value) {
    state.selectedForm = value;
  },
  setSelectedSetting(state, value) {
    state.selectedSetting = value;
  },
  setIsEdit(state, value) {
    state.isEdit = value;
  },
  setDataFromServer(state, { value, key }) {
    state.dataFromServer[key] = value;
  },
  setPriceSetting(state, { index, value, key }) {
    state.priceSetting[index][key] = value;
  },
  setPriceSettingDirectly(state, value) {
    state.priceSetting = [...value];
  },
  setSpecificDrug(state, value) {
    state.specificSetting.drug = value;
  },
  setInputState(state, value) {
    state.inputState = value;
  },
  setSelectedCategory(state, value) {
    state.categorySetting.selectedCategory = value;
  },
  setIsEditFromDashboard(state, value) {
    state.specificSetting.isEditFromDashboard = value;
  },
  setTemporaryStore(state, value) {
    state.temporaryStore = value;
  },
  setSpecificDrugResult(state, { value, status }) {
    if (status === 'add') {
      state.specificSetting.result.push(value);
    } else if (status === 'update') {
      state.specificSetting.result = state.specificSetting.result.map(item => {
        return value.id === item.id ? { ...value } : item;
      });
    } else {
      state.specificSetting.result = [...value];
    }
  },
  setPreviewData(state, value) {
    state.previewData = value;
  },
  resetPriceSetting(state) {
    state.priceSetting = [
      {
        isDefault: true,
        margin: '',
        ppn: '',
        description: '',
        isActive: true,
      },
      {
        isDefault: false,
        isActive: false,
        margin: '',
        ppn: '',
        description: '',
      },
      {
        isDefault: false,
        isActive: false,
        margin: '',
        ppn: '',
        description: '',
      },
    ];
  },
  clearTemporaryStore(state) {
    state.temporaryStore = [];
  },
  clearSelectedSetting(state) {
    state.selectedSetting = '';
  },
  clearPriceState(state) {
    state.priceSetting = [
      {
        isDefault: true,
        margin: '',
        ppn: '',
        description: '',
        isActive: true,
      },
      {
        isDefault: false,
        isActive: false,
        margin: '',
        ppn: '',
        description: '',
      },
      {
        isDefault: false,
        isActive: false,
        margin: '',
        ppn: '',
        description: '',
      },
    ];
  },
};

const actions = {
  async resolveGetPreviewCalculation({ commit, state }, additionalParams) {
    try {
      commit('setLoading', { key: 'previewTable', value: true });
      const params = {
        page: additionalParams?.page || 1,
        itemCount: additionalParams?.itemCount || 10,
        ...additionalParams,
      };

      const calculatePrice = (hna, index) => {
        if (!state.priceSetting[index]?.isActive) return 0;
        const basePrice = hna;
        const marginAmount =
          (basePrice * state.priceSetting[index].margin) / 100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin * state.priceSetting[index].ppn) / 100;
        return priceAfterMargin + taxAmount;
      };

      const response = await getPreviewMargin(params);
      const mappedData = response.data.data.map(item => {
        return {
          id: item.id,
          name: item.displayName,
          category: item.group || '-',
          hna: item.hna,
          hpp: item.hpp,
          margin: state.priceSetting[0].margin || 0,
          ppn: state.priceSetting[0].ppn || 0,
          price1: calculatePrice(item.hna, 0),
          price2: calculatePrice(item.hna, 1),
          price3: calculatePrice(item.hna, 2),
        };
      });
      commit('setPreviewData', mappedData);

      const { length: dataLength } = response.data;

      return { dataLength, mappedData };
    } catch {
      commit('setPreviewData', []);
    } finally {
      commit('setLoading', { key: 'previewTable', value: false });
    }
  },
  async resolvePostAddDrugMargin({ commit, state }, { type, additionalData }) {
    try {
      commit('setLoading', { key: 'saving', value: true });
      const payload = {
        type,
        data:
          type === 'category' || type === 'global' || type === 'global_specific'
            ? additionalData
            : additionalData.concat({
                margins: state.temporaryStore
                  .filter(i => i.isActive)
                  .map(item => {
                    return {
                      ppn: +item.ppn,
                      margin: +item.margin,
                      description: item.description,
                      isActive: !!item.isActive,
                    };
                  }),
              }),
      };
      await postAddDrugMargin(payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setLoading', { key: 'saving', value: false });
    }
  },
  async resolvePutAddDrugMargin({ commit }, { type, additionalData }) {
    try {
      commit('setLoading', { key: 'saving', value: true });
      const payload = {
        type,
        data: additionalData,
      };
      await putUpdateDrugMargin(payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setLoading', { key: 'saving', value: false });
    }
  },
  async resolveGetDrugMargin({ commit }) {
    try {
      commit('setLoading', { key: 'dashboard', value: true });
      const translateSettingType = type => {
        switch (type) {
          case 'global':
            return 'global-setting';
          case 'category':
            return 'category-setting';
          case 'global_category':
            return 'global-category-setting';
          case 'global_specific':
            return 'global-specific-setting';
        }
      };

      const response = await getDrugMargin();
      commit(
        'setSelectedSetting',
        translateSettingType(response.data?.data?.type || ''),
      );
      commit('setDataFromServer', {
        key: 'nondrugs',
        value: response.data.data.data
          .map(item => ({
            ...item,
            category: item.category || 'Global Setting',
          }))
          .filter(item => item.category !== 'Obat'),
      });
    } catch (error) {
      const err = error;
      throw err;
    } finally {
      commit('setLoading', { key: 'dashboard', value: false });
    }
  },
  async resolveGetCategoryMarginList({ commit }) {
    try {
      const response = await getCategoryMarginList();
      commit('setDataFromServer', {
        key: 'drugs',
        value: response.data.data,
      });
    } catch {
      commit('setDataFromServer', {
        key: 'drugs',
        value: [],
      });
    }
  },
  async resolveGetCategoryMarginStatus() {
    try {
      const response = await getCategoryMarginStatus();
      return response.data.data.map(item => ({
        isSet: item.isSet,
        name: item.name,
      }));
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
