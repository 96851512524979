<template>
  <v-card elevation="0" class="px-4 pb-10 pt-5" :class="{ active: isActive }">
    <v-form ref="form">
      <template v-if="isAdmin">
        <p class="grey-light-text" v-if="index === 0">HARGA DEFAULT</p>
        <v-checkbox
          v-else
          :label="'Harga ' + (index + 1)"
          v-model="isActive"
          class="mb-4 pa-0"
          hide-details
          single-line
          dense
          :value="true"
        />
      </template>
      <template v-else>
        <div class="d-flex justify-space-between mb-6">
          <h4>
            <strong>{{
              index ? `Harga ${index + 1}` : 'Harga Default'
            }}</strong>
          </h4>
          <v-card
            elevation="0"
            rounded="lg"
            :color="isActive ? '#e7f8ec' : '#ffebee'"
            :class="`status-card ${isActive ? 'active' : ''}`"
          >
            <span>{{ isActive ? 'Aktif' : 'Tidak Aktif' }}</span>
            <v-icon
              class="ml-1"
              small
              :color="isActive ? '#2b9c4d' : '#d32f2f'"
              >{{ isActive ? 'mdi-check' : 'mdi-close' }}</v-icon
            >
          </v-card>
        </div>
      </template>
      <p
        class="grey-dark-text mb-0 font-weight-bold"
        :class="{ 'disable-text': !isAdmin }"
      >
        Base Harga
      </p>
      <div class="d-flex align-baseline">
        <p class="grey-dark-text mr-3" :class="{ 'disable-text': !isAdmin }">
          HNA
        </p>
        <v-text-field single-line dense hide-details :value="hna" disabled />
      </div>
      <div class="d-flex">
        <div class="mr-10">
          <p
            :class="{
              required: isActive && isAdmin,
              'disable-text': !isAdmin,
            }"
          >
            Margin
          </p>
          <v-text-field
            :disabled="!isActive || !isAdmin"
            :rules="
              rulesByCondition
                ? [
                    rulesByCondition.noEmptyRule,
                    rulesByCondition.max,
                    rulesByCondition.noIdenticalMargin[index],
                  ].concat(...additionalRules)
                : []
            "
            v-model="margin"
            placeholder="-"
            single-line
            type="number"
            dense
            suffix="%"
          />
        </div>
        <div>
          <p
            :class="{
              required: isActive && isAdmin,
              'disable-text': !isAdmin,
            }"
          >
            PPN
          </p>
          <v-text-field
            type="number"
            v-model="ppn"
            :disabled="!isActive || !isAdmin"
            :rules="
              rulesByCondition
                ? [rulesByCondition.noEmptyRule, rulesByCondition.max].concat(
                    ...additionalRules,
                  )
                : []
            "
            placeholder="-"
            single-line
            dense
            suffix="%"
          />
        </div>
      </div>
      <p
        class="grey-dark-text font-weight-bold"
        :class="{ 'disable-text': !isAdmin }"
      >
        Harga Jual
      </p>
      <v-text-field
        single-line
        dense
        hide-details
        :value="sellingPrice"
        class="mb-5"
        disabled
      />
      <p
        class="grey-dark-text font-weight-bold"
        :class="{ required: isActive && isAdmin, 'disable-text': !isAdmin }"
      >
        Deskripsi Harga {{ index === 0 ? 'Default' : index + 1 }}
      </p>
      <v-text-field
        single-line
        dense
        v-model="description"
        :rules="rulesByCondition ? [rulesByCondition.noEmptyRule] : []"
        :disabled="!isActive || !isAdmin"
        placeholder="contoh: Harga Retail"
      />
    </v-form>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import formatMixin from '@/mixin/formatMixin';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

export default {
  name: 'SpecificPriceInputCard',
  mixins: [formatMixin],
  props: {
    index: {
      type: Number,
      default: 0,
      required: true,
    },
    additionalRules: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.mapData();
  },
  computed: {
    ...mapGetters([
      'getPriceSetting',
      'getPriceSettingRules',
      'getSpecificDrug',
    ]),
    isAdmin: {
      get() {
        return this.$store.getters.userLoggedIn.role
          .map(role => role.toLowerCase())
          .some(role => ['administrator', 'kepala unit'].includes(role));
      },
    },
    rulesByCondition: {
      get() {
        if (this.index === 0) {
          return this.getPriceSettingRules;
        } else if (this.getPriceSetting[this.index].isActive) {
          return this.getPriceSettingRules;
        } else {
          return null;
        }
      },
    },
    sellingPrice: {
      get() {
        if (!this.getPriceSetting[this.index]?.isActive) return 0;
        const basePrice = +this.getSpecificDrug.hna;
        const marginAmount =
          (basePrice * +this.getPriceSetting[this.index].margin) / 100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin * this.getPriceSetting[this.index].ppn) / 100;
        return this.currency(priceAfterMargin + taxAmount);
      },
    },
    hna: {
      get() {
        return this.currency(this.getSpecificDrug.hna);
      },
    },
    isActive: {
      get() {
        return this.getPriceSetting[this.index].isActive;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'isActive', value });
      },
    },
    margin: {
      get() {
        return this.getPriceSetting[this.index].margin;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'margin', value });
      },
    },
    ppn: {
      get() {
        return this.getPriceSetting[this.index].ppn;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'ppn', value });
      },
    },
    description: {
      get() {
        return this.getPriceSetting[this.index].description;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'description', value });
      },
    },
  },
  watch: {
    margin() {
      this.$refs.form.validate();
    },
    ppn() {
      this.$refs.form.validate();
    },
  },
  methods: {
    ...mapMutations(['setPriceSetting']),
    mapData() {
      if (this.getSpecificDrug?.setting === 'Global') return;
      if (this.getSpecificDrug.allMarginsData[this.index].margin) {
        this.setPriceSetting({
          index: this.index,
          key: 'margin',
          value: this.getSpecificDrug.allMarginsData[this.index].margin,
        });
      }
      if (this.getSpecificDrug.allMarginsData[this.index].ppn) {
        this.setPriceSetting({
          index: this.index,
          key: 'ppn',
          value: this.getSpecificDrug.allMarginsData[this.index].ppn,
        });
      }
      if (this.getSpecificDrug.allMarginsData[this.index].description) {
        this.setPriceSetting({
          index: this.index,
          key: 'description',
          value: this.getSpecificDrug.allMarginsData[this.index].description,
        });
      }
      if (this.getSpecificDrug.allMarginsData[this.index].isActive) {
        this.setPriceSetting({
          index: this.index,
          key: 'isActive',
          value: this.getSpecificDrug.allMarginsData[this.index].isActive,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: #fafafa;
  &.active {
    background-color: #f0f8fe;
  }

  .grey-light-text {
    color: #9e9e9e;
    font-size: 0.8vw;
  }

  .grey-dark-text {
    font-size: 0.85vw;
    color: #616161;

    &.disable-text {
      color: #9e9e9e;
    }
  }
  .disable-text {
    color: #9e9e9e;
  }

  .status-card {
    padding: 0.2vw 0.5vw;
    display: flex;
    align-items: center;

    span {
      color: #d32f2f;
      font-size: 0.85vw;
    }

    &.active {
      span {
        color: #2b9c4d;
      }
    }
  }
}
</style>
