<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>
        <span v-if="state === 'add'">Input</span>
        <span v-if="state === 'edit'">Ubah</span>
        <span v-if="state === 'detail'">Detail</span>
        Barang Farmasi
      </h3>
      <v-btn absolute right @click="onClose()" small icon>
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="d-flex justify-center">
      <v-container class="scrollbar">
        <v-row no-gutters>
          <v-form ref="form" class="col-12">
            <v-col md="11" offset-md="1" class="ml-0">
              <v-row no-gutters>
                <v-col cols="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        for="name"
                        :class="state !== 'detail' ? 'required' : ''"
                        >Nama Barang</label
                      >
                    </div>

                    <v-text-field
                      :readonly="state === 'detail'"
                      v-model="form.name"
                      id="name"
                      class="detail-input__input-text"
                      required
                      :rules="rules.drugName"
                      placeholder="Nama Barang"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="barcode">Barcode</label>
                    </div>
                    <v-text-field
                      :readonly="state === 'detail'"
                      v-model="form.barcode"
                      id="barcode"
                      class="detail-input__input-text"
                      placeholder="Barcode"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="category"
                        >Kategori</label
                      >
                    </div>
                    <v-autocomplete
                      :readonly="state === 'detail'"
                      class="detail-input__input-text"
                      v-model="form.category"
                      dense
                      :items="items.categoryByMenu.data"
                      :loading="items.categoryByMenu.isLoading"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                      :rules="rules.category"
                      @input="getMargin"
                      placeholder="Pilih Kategori"
                    />
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="generik"
                      >
                        Generik
                      </label>
                    </div>
                    <v-radio-group
                      style="width: 73%;"
                      :readonly="state === 'detail'"
                      id="isGeneric"
                      v-model="form.isGeneric"
                      row
                      :rules="rules.generic"
                      @change="getMargin"
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="golongan"
                        >Golongan</label
                      >
                    </div>
                    <v-autocomplete
                      :readonly="state === 'detail'"
                      placeholder="Pilih golongan"
                      class="detail-input__input-text"
                      v-model="form.group"
                      dense
                      :rules="rules.group"
                      :items="items.group.data"
                      :loading="items.group.isLoading"
                      @input="getMargin"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="drug-code">Kode Obat</label>
                    </div>
                    <div class="kfa-container">
                      <div
                        :class="
                          `manual-input ${state === 'detail' ? 'full' : ''}`
                        "
                        v-if="kfaSwitch.drug"
                      >
                        <v-text-field
                          :readonly="state === 'detail'"
                          dense
                          single-line
                          class="ml-3"
                          :rules="
                            form.inputManual.drug_kfa.name ? rules.kfaRule : []
                          "
                          v-model="form.inputManual.drug_kfa.code"
                          validate-on-blur
                          type="number"
                          placeholder="00000000"
                        />
                        <v-text-field
                          :readonly="state === 'detail'"
                          dense
                          single-line
                          v-model="form.inputManual.drug_kfa.name"
                          placeholder="Nama obat"
                        />
                      </div>
                      <v-combobox
                        v-else
                        :search-input.sync="searchDrugsKfa"
                        :readonly="state === 'detail'"
                        class="detail-input__input-text"
                        prepend-inner-icon="mdi-magnify"
                        v-model="form.drug_kfa"
                        return-object
                        dense
                        item-text="displayName"
                        :items="items.kfaDrugs.data"
                        :loading="items.kfaDrugs.isLoading"
                        :append-icon="null"
                        placeholder="Cari dengan kode atau nama obat"
                        :clearable="state !== 'detail'"
                      />
                      <div class="align-center pl-1" v-if="state !== 'detail'">
                        <v-switch
                          class="kfa-switch"
                          :readonly="state === 'detail'"
                          @change="onChangeSwitch('drug', $event)"
                          :value="kfaSwitch.drug"
                          inset
                          small
                          flat
                          light
                          dense
                          single-line
                          hide-details
                        ></v-switch>
                        <span>Input manual</span>
                      </div>
                    </div>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="drug-code">Kode Produk</label>
                    </div>
                    <div class="kfa-container">
                      <div
                        :class="
                          `manual-input ${state === 'detail' ? 'full' : ''}`
                        "
                        v-if="kfaSwitch.product"
                      >
                        <v-text-field
                          :readonly="state === 'detail'"
                          dense
                          single-line
                          validate-on-blur
                          class="ml-3"
                          v-model="form.inputManual.product_kfa.code"
                          :rules="
                            form.inputManual.product_kfa.name
                              ? rules.kfaRule
                              : []
                          "
                          placeholder="00000000"
                          type="number"
                        />
                        <v-text-field
                          :readonly="state === 'detail'"
                          dense
                          single-line
                          v-model="form.inputManual.product_kfa.name"
                          placeholder="Nama produk"
                        />
                      </div>
                      <v-combobox
                        v-else
                        :search-input.sync="searchProductCode"
                        :clearable="state !== 'detail'"
                        :readonly="state === 'detail'"
                        class="detail-input__input-text"
                        v-model="form.product_kfa"
                        return-object
                        hide-details
                        single-line
                        item-text="displayName"
                        dense
                        :items="items.kfaProducts.data"
                        :loading="items.kfaProducts.isLoading"
                        append-icon="mdi-chevron-down"
                        placeholder="Pilih kode produk"
                      />
                      <div class="align-center pl-1" v-if="state !== 'detail'">
                        <v-switch
                          class="kfa-switch"
                          :value="kfaSwitch.product"
                          @change="onChangeSwitch('product', $event)"
                          :readonly="state === 'detail'"
                          inset
                          small
                          flat
                          light
                          dense
                          single-line
                          hide-details
                        ></v-switch>
                        <span>Input manual</span>
                      </div>
                    </div>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="rak">Rak</label>
                    </div>
                    <v-text-field
                      :readonly="state === 'detail'"
                      v-model="form.shelf"
                      id="rak"
                      class="detail-input__input-text"
                      placeholder="Rak"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="sediaan"
                        >Sediaan</label
                      >
                    </div>
                    <v-autocomplete
                      :readonly="state === 'detail'"
                      class="detail-input__input-text"
                      v-model="form.preparation"
                      dense
                      :items="items.preparation.data"
                      :loading="items.preparation.isLoading"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                      :rules="rules.preparation"
                      placeholder="Pilih Sediaan"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="pabrik"
                        >Pabrik</label
                      >
                    </div>
                    <v-menu
                      :disabled="state === 'detail'"
                      v-model="menuOpen"
                      close-on-content-click
                      tile
                      nudge-bottom="30"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :readonly="state === 'detail'"
                          v-model="form.factory"
                          :rules="form.factory ? [] : rules.factory"
                          @blur="factoryAvailbilty(form.factory)"
                          :loading="items.factory.isLoading"
                          v-on="on"
                          :append-icon="
                            state === 'detail' ? '' : 'mdi-chevron-downs'
                          "
                          @keyup="menuOpen = true"
                          class="detail-input__input-text"
                          placeholder="Pilih Pabrik"
                          validate-on-blur
                          dense
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-list
                          v-for="(group, index) in [
                            {
                              groupTitle: 'Tambah Baru',
                              items: items.factory.data,
                            },
                          ]"
                          :key="index"
                        >
                          <template>
                            <div class="text-start px-4 pb-1">
                              <span
                                style="cursor: pointer;"
                                class="primary--text subtitle-2"
                                @click="openDialog"
                                >{{ group.groupTitle }}</span
                              >
                            </div>
                            <v-divider v-if="state !== 'edit'"></v-divider>
                            <div
                              :style="
                                `overflow-y: scroll; overflow-x: hidden; max-height: ${
                                  resizeValue > 115 ? '20vh' : '30vh'
                                };`
                              "
                            >
                              <v-list-item-group>
                                <span
                                  class="caption px-4"
                                  v-if="
                                    !group.items.length &&
                                      !items.factory.isLoading
                                  "
                                  >Tidak ditemukan data pabrikan untuk
                                  {{ form.factory }}</span
                                >
                                <span
                                  class="caption px-4"
                                  v-if="items.factory.isLoading"
                                  >Mencari {{ form.factory }} ...</span
                                >
                                <v-list-item
                                  v-for="(item, itemIndex) in group.items"
                                  :key="itemIndex"
                                  @click="handleFactoryItemClick(item)"
                                >
                                  <v-list-item-content>
                                    <span class="body-2">{{ item }}</span>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </div>
                          </template>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="pabrik">Minimal Stok</label>
                    </div>
                    <v-text-field
                      :readonly="state === 'detail'"
                      class="detail-input__input-text"
                      v-model.number="form.min_stock"
                      dense
                      type="number"
                      placeholder="0"
                    ></v-text-field>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="farmakoterapi">Farmako Terapi</label>
                    </div>
                    <v-select
                      :readonly="state === 'detail'"
                      class="detail-input__input-text"
                      v-model="form.pharmacotherapy"
                      dense
                      :items="items.pharmacotherapy.data"
                      :loading="items.pharmacotherapy.isLoading"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                      placeholder="Pilih farmako terapi"
                    ></v-select>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="indikasi">
                        Indikasi
                      </label>
                    </div>
                    <v-textarea
                      :readonly="state === 'detail'"
                      v-model="form.indication"
                      id="indikasi"
                      class="detail-input__input-text align-start"
                      height="100"
                      outlined
                      placeholder="Masukkan Indikasi"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="efeksamping">
                        Efek Samping
                      </label>
                    </div>
                    <v-textarea
                      :readonly="state === 'detail'"
                      v-model="form.side_effects"
                      id="efeksamping"
                      class="detail-input__input-text align-start"
                      height="100"
                      outlined
                      placeholder="Masukkan efek samping"
                    />
                  </div>

                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="katastropik">
                        Katastropik
                      </label>
                    </div>
                    <v-radio-group
                      :readonly="state === 'detail'"
                      id="isCatastrophic"
                      v-model="form.isCatastrophic"
                      :mandatory="false"
                      row
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="aktif"
                        >Aktif</label
                      >
                    </div>
                    <v-radio-group
                      :readonly="state === 'detail'"
                      id="isActive"
                      v-model="form.isActive"
                      :mandatory="false"
                      row
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col cols="1"> </v-col>
                <v-col cols="5">
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="bahanbaku">
                        High Alert Medicine
                      </label>
                    </div>
                    <v-radio-group
                      :readonly="state === 'detail'"
                      id="isHighAlert"
                      v-model="form.isHighAlert"
                      :mandatory="false"
                      row
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="ven">VEN</label>
                    </div>
                    <v-radio-group
                      :readonly="state === 'detail'"
                      id="isVEN"
                      v-model="form.isVEN"
                      :mandatory="false"
                      row
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="kekuatan"
                        >Kekuatan</label
                      >
                    </div>

                    <v-text-field
                      :readonly="state === 'detail'"
                      v-model.number="form.strength"
                      id="kekuatan"
                      class="detail-input__input-text"
                      required
                      type="number"
                      :rules="rules.strength"
                      placeholder="Kekuatan"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="kekuatanunit"
                        >Kekuatan Unit</label
                      >
                    </div>
                    <v-autocomplete
                      :readonly="state === 'detail'"
                      class="detail-input__input-text"
                      v-model="form.strength_unit"
                      :items="items.packagesByMenu.data"
                      :loading="items.packagesByMenu.isLoading"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                      placeholder="Pilih kekuatan unit"
                      dense
                      :rules="rules.strength_unit"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        :class="state !== 'detail' ? 'required' : ''"
                        for="hna"
                        >HNA</label
                      >
                    </div>
                    <v-currency-field
                      v-model="form.hna"
                      :readonly="state === 'detail'"
                      prefix="Rp."
                      id="hna"
                      class="detail-input__input-text"
                      :rules="rules.hna"
                      validate-on-blur
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="hpp">HPP</label>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            style="margin-left: 8px;"
                          >
                            <v-icon small color="gray">mdi-information</v-icon>
                          </div>
                        </template>
                        <p style="max-width: 40rem; text-align: start;">
                          HPP dihitung dari (HNA - Diskon) + PPN Pembelian yang
                          dikenakan oleh supplier. Tidak termasuk ongkir atau
                          biaya tambahan lainnya.
                        </p>
                      </v-tooltip>
                    </div>
                    <v-currency-field
                      v-model="form.hpp"
                      :readonly="state === 'detail'"
                      id="hpp"
                      prefix="Rp."
                      class="detail-input__input-text"
                      :rules="rules.hpp"
                      validate-on-blur
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="sellingPrice">Harga Jual</label>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            style="margin-left: 8px;"
                          >
                            <v-icon small color="gray">mdi-information</v-icon>
                          </div>
                        </template>
                        <span
                          >Harga Jual akan muncul jika sudah mengisi Kategori,
                          Jenis Obat, dan Golongan</span
                        >
                      </v-tooltip>
                    </div>
                    <v-currency-field
                      v-model="sellingPrice"
                      readonly
                      id="sellingPrice"
                      prefix="Rp."
                      class="detail-input__input-text"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="carapakai">Cara Pakai</label>
                    </div>
                    <v-autocomplete
                      :readonly="state === 'detail'"
                      class="detail-input__input-text"
                      v-model="form.roa"
                      :items="items.roa.data"
                      :loading="items.roa.isLoading"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                      dense
                      placeholder="Pilih cara pakai"
                    ></v-autocomplete>
                  </div>

                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="kontraindikasi">
                        Kontra Indikasi
                      </label>
                    </div>
                    <v-textarea
                      :readonly="state === 'detail'"
                      v-model="form.c_indication"
                      id="kontraindikasi"
                      class="detail-input__input-text align-start"
                      height="100"
                      outlined
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="bahanbaku">
                        Bahan Baku
                      </label>
                    </div>
                    <v-textarea
                      :readonly="state === 'detail'"
                      v-model="form.ingredients"
                      id="bahanbaku"
                      class="detail-input__input-text align-start"
                      height="100"
                      outlined
                    />
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="bahanbaku">
                        Formulari
                      </label>
                    </div>
                    <v-radio-group
                      :readonly="state === 'detail'"
                      id="isFormulary"
                      v-model="form.isFormulary"
                      :mandatory="false"
                      row
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="bahanbaku">
                        Fornas
                      </label>
                    </div>
                    <v-radio-group
                      :readonly="state === 'detail'"
                      id="isFornas"
                      v-model="form.isFornas"
                      :mandatory="false"
                      row
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="detail-input__label" for="bahanbaku">
                        Obat Keras
                      </label>
                    </div>
                    <v-radio-group
                      :readonly="state === 'detail'"
                      id="isPotent"
                      v-model="form.isPotent"
                      :mandatory="false"
                      row
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="11" offset-md="1">
              <v-row no-gutters>
                <label class="grey--text font-weight-medium"
                  >SATUAN YANG DIGUNAKAN</label
                >
              </v-row>
              <!-- <v-col cols="5"> -->
              <v-row class="mt-3" no-gutters>
                <v-col cols="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        for="name"
                        :class="state !== 'detail' ? 'required' : ''"
                        >Satuan Terkecil</label
                      >
                    </div>
                    <v-select
                      :readonly="state === 'detail'"
                      v-model="smallestPackage"
                      class="detail-input__input-text"
                      required
                      :rules="rules.package"
                      :items="items.packagesByMenu.data"
                      :loading="items.packagesByMenu.isLoading"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                      dense
                    />
                  </div>
                  <div v-if="state !== 'detail'" class="d-flex justify-end">
                    <v-btn
                      @click="addPackage()"
                      color="primary"
                      text
                      class="text-none"
                      rounded
                      :disabled="
                        drugs.packaging.length > 0 || smallestPackage == ''
                      "
                      ><v-icon center>mdi-plus</v-icon>
                      Tambah Kemasan
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <template v-for="(item, index) in drugs.packaging">
                    <template>
                      <v-row class="mb-1" :key="index">
                        <v-col class="py-1" cols="4">
                          <v-select
                            :readonly="state === 'detail'"
                            v-model="drugs.packaging[index].package"
                            class="p-1 mt-0"
                            dense
                            required
                            :items="items.packagesByMenu.data"
                            :loading="items.packagesByMenu.isLoading"
                            :append-icon="
                              state === 'detail' ? '' : 'mdi-chevron-down'
                            "
                            placeholder="Kemasan"
                            hide-details
                          />
                        </v-col>
                        <v-col class="py-1" cols="2">
                          <v-text-field
                            :readonly="state === 'detail'"
                            v-model.number="drugs.packaging[index].quantity"
                            required
                            class="p-1 mt-0"
                            dense
                            type="number"
                            placeholder="Jumlah"
                            hide-details
                          >
                          </v-text-field>
                        </v-col>
                        <v-col class="py-1" cols="4">
                          <v-select
                            :readonly="state === 'detail'"
                            placeholder="Satuan"
                            v-model="drugs.packaging[index].package_unit"
                            required
                            class="p-1 mt-0"
                            dense
                            :items="items.packagesByMenu.data"
                            :loading="items.packagesByMenu.isLoading"
                            :append-icon="
                              state === 'detail' ? '' : 'mdi-chevron-down'
                            "
                            hide-details
                          />
                        </v-col>
                        <v-col cols="2" class="pa-0">
                          <v-btn
                            v-if="state !== 'detail'"
                            :disabled="
                              drugs.packaging[index].package_unit == '' ||
                                drugs.packaging[index].quantity == 0 ||
                                drugs.packaging[index].package == ''
                            "
                            icon
                          >
                            <v-icon @click="addPackage()"> mdi-plus </v-icon>
                          </v-btn>
                          <v-btn icon v-if="state !== 'detail'">
                            <v-icon @click="removePackage(index)">
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        width="100"
        text
        class="text-none"
        @click="onClose()"
      >
        Kembali
      </v-btn>
      <v-btn
        v-if="state !== 'detail'"
        color="primary"
        width="100"
        class="text-none"
        depressed
        @click="actionDrugs()"
      >
        Simpan
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <add-factory-dialog v-if="dialog" @close-dialog="closeAddFactory" />
    </v-dialog>
    <v-snackbar timeout="4000" absolute color="success" v-model="snackbar">
      <span class="mr-15">Data pabrik berhasil ditambahkan</span>
      <v-btn fab text depressed small @click.native="snackbar = false"
        ><v-icon small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import { scrollToEmptyInput } from '@/helper/';
import { createNamespacedHelpers } from 'vuex';
import AddFactoryDialog from './AddFactoryDialog.vue';
const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  'MasterDrugs',
);

const _ = require('lodash');
export default {
  props: {
    state: {
      type: String,
      default: 'add',
    },
  },
  components: {
    AddFactoryDialog,
  },
  data() {
    return {
      menuOpen: false,
      dialog: false,
      snackbar: false,
      searchDrugsKfa: '',
      searchProductCode: '',
      drugs: {
        packaging: [],
      },
      smallestPackage: '',
      rules: {
        kfaRule: this.state === 'detail' ? [] : [v => !!v || 'Wajib diisi'],
        drugName:
          this.state === 'detail' ? [] : [v => !!v || 'Masukkan nama barang'],
        category: this.state === 'detail' ? [] : [v => !!v || 'Pilih kategori'],
        preparation:
          this.state === 'detail' ? [] : [v => !!v || 'Pilih sediaan'],
        factory:
          this.state === 'detail'
            ? []
            : [() => !!this.form.factory || 'Pilih pabrik'],
        strength:
          this.state === 'detail'
            ? []
            : [v => !!v || v === 0 || 'Masukkan kekuatan'],
        strength_unit:
          this.state === 'detail' ? [] : [v => !!v || 'Masukkan kekuatan unit'],
        hpp: this.state === 'detail' ? [] : [v => !!v || 'Masukkan HPP'],
        hna: this.state === 'detail' ? [] : [v => !!v || 'Masukkan HNA'],
        group: this.state === 'detail' ? [] : [v => !!v || 'Masukkan Golongan'],
        package:
          this.state === 'detail'
            ? []
            : [v => !!v || 'Masukkan Satuan Terkecil'],
        generic:
          this.state === 'detail' ? [] : [v => v !== null || 'Pilih Generik'],
      },
    };
  },
  beforeDestroy() {
    this.clearForm();
    this.drugs.packaging = [];
  },
  mounted() {
    if (this.state === 'detail' || this.state === 'edit') {
      this.handleGetDrugsById();
    }

    if (this.state !== 'detail') {
      this.$nextTick(() => {
        this.resolveGetRoa();
        this.resolveGetGroup();
        this.resolveGetFactory();
        this.resolveGetPreparation();
        this.resolveGetCategoryByMenu();
        this.resolveGetPackagesByMenu();
        this.resolveGetPharmacotherapy();
      });
    }
  },
  watch: {
    'form.factory'() {
      this.searchFactory(this);
    },
    searchDrugsKfa() {
      this.searchDrugsKfaData(this);
    },
    searchProductCode() {
      this.searchProductCodeData(this);
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getForm', 'getKfaSwitch', 'getSellingPrice']),
    kfaSwitch: {
      get() {
        return this.getKfaSwitch;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
    resizeValue() {
      return ((window.outerWidth - 10) / window.innerWidth) * 100;
    },
    sellingPrice: {
      get() {
        return this.getSellingPrice;
      },
    },
  },
  methods: {
    ...mapMutations(['clearForm', 'setKfaSwitch']),
    ...mapActions([
      'resolveGetRoa',
      'resolvePutDrugs',
      'resolveGetGroup',
      'resolvePostDrugs',
      'resolveGetKfaData',
      'resolveGetFactory',
      'resolveGetDrugsById',
      'resolveGetPreparation',
      'resolveGetDrugsKfaData',
      'resolveGetCategoryByMenu',
      'resolveGetPackagesByMenu',
      'resolveGetPharmacotherapy',
      'resolveMarginData',
    ]),
    onChangeSwitch(type, value) {
      this.setKfaSwitch({ type, value });
    },
    searchFactory: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.resolveGetFactory(v.form.factory);
    }, 600),
    searchDrugsKfaData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.resolveGetDrugsKfaData(v.searchDrugsKfa);
    }, 600),
    searchProductCodeData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.resolveGetKfaData(v.searchProductCode);
    }, 600),
    onClose() {
      this.$emit('close-dialog');
    },
    openDialog() {
      this.dialog = true;
    },
    closeAddFactory(type) {
      if (type) {
        this.snackbar = true;
        this.menuOpen = true;
      }
      this.dialog = false;
    },
    handleFactoryItemClick(item) {
      this.form.factory = item;
    },
    handleGetDrugsById() {
      this.resolveGetDrugsById().then(item => {
        this.drugs.packaging = item.packaging
          .filter(v => v.isDefault != true)
          .map(a => {
            return {
              package: a.package,
              quantity: a.quantity,
              package_unit: a.package_unit,
              isDefault: a.isDefault,
            };
          });
        if (item.packaging.find(v => v.isDefault == true))
          this.smallestPackage = item.packaging.find(
            v => v.isDefault === true,
          ).package;
      });
    },
    factoryAvailbilty(input) {
      if (!this.items.factory.data.length) {
        this.clearTextField('form.factory');
        return;
      }

      if (!this.items.factory.data.find(v => v === input)) {
        this.clearTextField('form.factory');
        return;
      }
    },
    clearTextField(model) {
      const pathParts = model.split('.');
      let target = this;

      for (let i = 0; i < pathParts.length - 1; i++) {
        target = target[pathParts[i]];
      }

      const finalProp = pathParts[pathParts.length - 1];
      target[finalProp] = '';
    },
    addPackage() {
      this.drugs.packaging.push({
        package: '',
        quantity: '',
        package_unit: '',
      });
    },
    removePackage(index) {
      this.drugs.packaging.splice(index, 1);
    },
    getMargin() {
      this.resolveMarginData(this);
    },
    async actionDrugs() {
      if (await this.$refs.form.validate()) {
        let payload = Object.assign({}, this.drugs);
        payload.packaging = payload.packaging.filter(
          v => v.package != '' || v.package_unit != '' || v.quantity != 0,
        );

        // ADD smallest package to drugs payload
        payload.packaging.unshift({
          package: this.smallestPackage,
          quantity: 1,
          package_unit: this.smallestPackage,
          isDefault: true,
        });

        switch (this.state) {
          case 'add':
            this.resolvePostDrugs(payload.packaging).then(status => {
              if (status === 200) {
                Swal.fire(
                  'Berhasil',
                  'Data barang berhasil ditambahkan',
                  'success',
                );
              } else {
                Swal.fire('Gagal', 'Data barang gagal ditambahkan', 'error');
              }
            });
            break;
          case 'edit':
            this.resolvePutDrugs(payload.packaging).then(status => {
              if (status === 200) {
                Swal.fire(
                  'Berhasil',
                  'Data barang berhasil diperbaharui',
                  'success',
                );
              } else {
                Swal.fire('Gagal', 'Data barang gagal diperbaharui', 'error');
              }
            });
            break;
        }
      } else {
        scrollToEmptyInput();
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  height: 70vh;
  overflow: auto;
  width: 90vw;
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  text-align: left;

  .kfa-container {
    display: flex;
    align-content: flex-start;
    width: 75%;

    span {
      font-size: 0.65vw;
      color: black;
    }

    .kfa-switch {
      transform: scale(0.8);
      margin: 0;
    }

    .manual-input {
      display: grid;
      width: 75%;
      grid-template-columns: 1fr 2fr;
      column-gap: 0.8vw;

      &.full {
        width: 100%;
      }
    }
  }

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
    margin-right: 6px;
    font-size: 0.85vw;
  }

  &__input-text {
    margin-left: 10px;
    width: 50%;
  }
}

.padding {
  padding-left: 10px;
}

.margin-top {
  margin-top: 20px;
}

.radio {
  margin: 0;
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
