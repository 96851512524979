import { render, staticRenderFns } from "./MixIngredientsForm.vue?vue&type=template&id=44a3e623&scoped=true"
import script from "./MixIngredientsForm.vue?vue&type=script&lang=js"
export * from "./MixIngredientsForm.vue?vue&type=script&lang=js"
import style0 from "./MixIngredientsForm.vue?vue&type=style&index=0&id=44a3e623&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a3e623",
  null
  
)

export default component.exports