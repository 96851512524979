import Constant from '@/const';
import axios from 'axios';

const postSkipPatient = async payload => {
  try {
    const { id_counter, ...restPayload } = payload;

    if (id_counter === undefined || id_counter === null) {
      throw new Error('id_counter is required but is undefined or null.');
    }

    const response = await axios.post(
      Constant.apiUrl.concat(`/pharmacy-queue/skip/${id_counter}`),
      restPayload,
    );

    return response;
  } catch (error) {
    const variableError = error;
    throw variableError;
  }
};

export default postSkipPatient;
