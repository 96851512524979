<template>
  <v-container class="px-0 py-2" fluid>
    <div class="mt-5 mb-4 d-flex justify-space-between pr-8">
      <div class="d-flex text-start align-center">
        <h1>Margin dan Harga</h1>
        <span class="chip mx-3">{{ metaData.title }}</span>
        <span class="grey-light-text" style="font-size: .8vw;"
          >{{ metaData.description }}
        </span>
      </div>
      <v-btn
        v-if="isAdmin"
        color="primary"
        class="btn-edit text-capitalize"
        outlined
        depressed
        dense
        @click="openEditSetting"
      >
        Ubah Pengaturan <v-icon class="ml-2" small>mdi-pencil</v-icon>
      </v-btn>
    </div>

    <v-tabs v-model="tab" color="primary" slider-color="primary">
      <v-tab
        v-for="(item, index) in tabList"
        :key="'tab-' + index"
        class="tab-text text-capitalize"
      >
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <div style="position: relative;" v-on="{ ...tooltip }">
              <span>{{ item?.name }}</span>
              <v-icon
                :color="item?.isSet ? '#2b9c4d' : ''"
                style="position: absolute; bottom: 7px;"
                small
                >mdi-check-circle</v-icon
              >
            </div>
          </template>
          <span>{{
            item?.isSet
              ? `Harga kategori  "${item?.name}" telah diatur manual`
              : `Harga kategori "${item?.name}" mengikuti ${
                  getSelectedSetting === 'global-category-setting'
                    ? 'setting global'
                    : 'default sistem; Margin dan PPN 0%'
                }`
          }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>
    <div class="main-grid-display pt-6">
      <div class="text-right">
        <template v-if="isDrug">
          <p class="ma-0 pa-0 text-left grey-light-text">
            KATEGORI OBAT
          </p>
          <div class="card-container">
            <ul class="px-4">
              <li
                v-for="(item, index) in drugCategoryList"
                :key="'option-' + index"
                @click="onClickList(index)"
                :class="
                  `list-item grey-dark-text${item.isActive ? ' active' : ''}`
                "
              >
                {{ index ? 'Obat ' + item.name : item.name }}
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="card-container light mb-4">
            <span class="grey-light-text">Kategori </span>
            <span>
              <strong>{{ tabList?.[tab]?.name }}</strong></span
            >
          </div>
          <display-price-card
            v-bind="{ ...displayPrice?.[0] }"
            :index="0"
            is-vertical
          />
          <display-price-card
            v-bind="{ ...displayPrice?.[1] }"
            :index="1"
            is-vertical
          />
          <display-price-card
            v-bind="{ ...displayPrice?.[2] }"
            :index="2"
            is-vertical
          />
        </template>
        <v-btn
          color="primary"
          class="text-capitalize"
          style="letter-spacing: 0.001px;"
          v-if="
            isGlobalCategory &&
              isHasSetting &&
              tabList[tab].name !== 'Global Setting'
          "
          @click="resetToGlobalSetting"
          text
          dense
          depressed
          >Reset ke Global Setting
          <v-icon class="ml-1">mdi-refresh</v-icon></v-btn
        >
      </div>
      <div>
        <template v-if="activeCategory?.name !== 'Obat Belum Diatur' && isDrug">
          <div class="meta-description">
            <span class="grey-light-text">Base Harga:</span>
            <span><strong>HNA</strong></span>
            <span class="grey-light-text">Jenis Obat</span>
            <span
              ><strong>{{
                activeCategory.isGeneric === null
                  ? '-'
                  : activeCategory.isGeneric
                  ? 'Generik'
                  : 'Non Generik'
              }}</strong></span
            >
            <span class="grey-light-text">Golongan Obat</span>
            <span
              ><strong>{{ activeCategory.groupName || '-' }}</strong></span
            >
          </div>
          <div class="display-price-container mb-8">
            <display-price-card :index="0" v-bind="{ ...displayPrice?.[0] }" />
            <display-price-card :index="1" v-bind="{ ...displayPrice?.[1] }" />
            <display-price-card :index="2" v-bind="{ ...displayPrice?.[2] }" />
          </div>
        </template>
        <div class="d-flex justify-space-between">
          <span class="grey-light-text">
            PREVIEW PERHITUNGAN
          </span>
          <span
            v-if="isGlobalCategory && !isDrug && !tabList?.[tab]?.isSet"
            class="grey-dark-text"
          >
            Harga kategori ini mengikuti harga pada Global Setting
          </span>
        </div>
        <div class="grid-table header">
          <span
            v-for="(item, index) in tableHeader"
            :key="'header-' + index"
            :class="item.class"
            >{{ item.text }}</span
          >
        </div>
        <v-progress-linear
          v-if="getLoadingState.previewTable"
          indeterminate
        ></v-progress-linear>
        <div
          :class="
            `table-container pt-4 mb-2${
              activeCategory?.name !== 'Obat Belum Diatur' && isDrug
                ? ''
                : ' full'
            }`
          "
        >
          <div
            v-for="(item, index) in table.data"
            :key="'row' + index"
            class="grid-table row"
          >
            <span>{{ item?.name }}</span>
            <span class="text-end">{{
              currency(item.price1 || item.hna)
            }}</span>
            <span class="text-end">{{ currency(item.price2) }}</span>
            <span class="text-end">{{ currency(item.price3) }}</span>
          </div>
        </div>
        <footer class="d-flex justify-start">
          <v-pagination
            light
            @input="getDataPreview"
            v-if="table.data.length"
            v-model="pagination.page"
            :length="pagination.pageLength"
            :total-visible="pagination.limit"
          ></v-pagination>
        </footer>
      </div>
    </div>
  </v-container>
</template>

<script>
import DisplayPriceCard from '../DisplayPriceCard.vue';
import { createNamespacedHelpers } from 'vuex';
import formatMixin from '@/mixin/formatMixin';

import { deleteDrugMarginById } from '@/fetchApi/Setting/MarginPrice';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

export default {
  name: 'CategorySettingDashboard',
  mixins: [formatMixin],
  components: { DisplayPriceCard },
  data() {
    return {
      tab: 0,
      selectedDrugCategory: null,
      tabList: [],
      drugCategoryList: [],
      pagination: {
        page: 1,
        limit: 10,
        pageLength: 10,
      },
      table: {
        data: [],
      },
    };
  },
  watch: {
    tab() {
      this.pagination.page = 1;
      this.getDataPreview();
    },
  },
  computed: {
    ...mapGetters([
      'getPreviewData',
      'getLoadingState',
      'getDataFromServer',
      'getSelectedSetting',
      'getIsEdit',
    ]),
    tableHeader: {
      get() {
        return [{ text: 'Nama Barang', class: '' }].concat(
          this.displayPrice?.length
            ? [...Array(3)].map((_, index) => ({
                text:
                  this.displayPrice?.[index]?.description ||
                  `Harga ${index + 1}`,
                class: 'text-end',
              }))
            : [
                { text: 'Harga 1', class: 'text-end' },
                { text: 'Harga 2', class: 'text-end' },
                { text: 'Harga 3', class: 'text-end' },
              ],
        );
      },
    },
    isGlobalCategory: {
      get() {
        return this.getSelectedSetting === 'global-category-setting';
      },
    },
    metaData: {
      get() {
        if (this.getSelectedSetting === 'global-category-setting') {
          return {
            title: 'Global + Kategori Setting',
            description:
              'Harga dan margin disesuaikan secara global untuk semua item, atau berdasarkan kategori barang',
          };
        }
        return {
          title: 'Kategori Setting',
          description:
            'Harga dan margin diatur secara langsung berdasarkan kategori.',
        };
      },
    },
    activeCategory: {
      get() {
        return this.drugCategoryList.find(item => item.isActive);
      },
    },
    isAdmin: {
      get() {
        return this.$store.getters.userLoggedIn.role
          .map(role => role.toLowerCase())
          .some(role => ['administrator', 'kepala unit'].includes(role));
      },
    },
    displayPrice: {
      get() {
        if (this.isDrug) {
          if (this.activeCategory.name === 'Obat Belum Diatur') {
            return this.getDataFromServer.nondrugs.find(
              item => item.category === 'Global Setting',
            )?.margins;
          }
          return this.getDataFromServer.drugs.find(
            item => item.name === this.activeCategory.name,
          )?.margins;
        }
        if (
          !this.tabList[this.tab]?.isSet &&
          this.getSelectedSetting === 'global-category-setting'
        ) {
          return this.getDataFromServer.nondrugs.find(
            item => item.category === 'Global Setting',
          )?.margins;
        }
        return this.getDataFromServer.nondrugs.find(
          item => this.tabList[this.tab].name === item.category,
        )?.margins;
      },
    },
    isDrug: {
      get() {
        return this.tabList[this.tab]?.name === 'Obat';
      },
    },
    isHasSetting: {
      get() {
        const activeNonDrugCategory = this.tabList[this.tab]?.name;
        return (
          this.isDrug ||
          this.getDataFromServer.nondrugs.find(
            item => item.category === activeNonDrugCategory,
          )?.id_margin
        );
      },
    },
  },
  mounted() {
    this.getTabList();
    this.getDrugCategoryList();
  },
  methods: {
    ...mapMutations(['setSelectedForm', 'setDialog', 'setIsEdit']),
    ...mapActions([
      'resolveGetPreviewCalculation',
      'resolveGetCategoryMarginStatus',
      'resolveGetCategoryMarginList',
      'resolveGetDrugMargin',
    ]),
    openEditSetting() {
      this.setSelectedForm(3);
      this.setIsEdit(true);
      this.setDialog(true);
    },
    async resetToGlobalSetting() {
      let id_margin;
      if (this.isDrug) {
        id_margin = this.activeCategory.id_margin;
      } else {
        const activeNonDrugCategory = this.tabList[this.tab].name;
        id_margin = this.getDataFromServer.nondrugs.find(
          item => item.category === activeNonDrugCategory,
        )?.id_margin;
      }

      await deleteDrugMarginById(id_margin);
      await this.getDrugCategoryList();
      await this.resolveGetDrugMargin();
    },
    async getDrugCategoryList() {
      try {
        await this.resolveGetCategoryMarginList();
        this.drugCategoryList = this.getDataFromServer.drugs
          .filter(item => {
            return this.isGlobalCategory
              ? item.name !== 'Obat Belum Diatur'
              : item;
          })
          .map((item, index) => {
            return {
              isActive: index === 0,
              id_margin: item.id_margin,
              name: item.name,
              isGeneric: item.rule?.isGeneric,
              groupName: item.rule?.groupName,
            };
          });
      } catch {
        this.drugCategoryList = [];
      }
    },
    async getTabList() {
      try {
        this.tabList = await this.resolveGetCategoryMarginStatus();
        this.getDataPreview();
      } catch {
        this.tabList = [];
      }
    },
    async getDataPreview() {
      try {
        const itemCount = 12;
        const activeDrugListIndex = this.drugCategoryList
          .filter(item => item.name !== 'Obat Belum Diatur')
          .findIndex(item => item.isActive);
        const activeCategoryTab = this.tabList[this.tab];
        let id_margin;
        if (this.isDrug) {
          id_margin =
            this.getDataFromServer.drugs.filter(item => item.id_margin)?.[
              activeDrugListIndex
            ]?.id_margin || 'null';
        } else {
          id_margin = this.getDataFromServer.nondrugs.find(
            item => item.category === activeCategoryTab.name,
          )?.id_margin;
        }
        const params = {
          page: this.pagination.page,
          itemCount,
          id_margin,
          category: activeCategoryTab.name,
        };
        const { dataLength } = await this.resolveGetPreviewCalculation(params);
        this.pagination.pageLength = Math.ceil(dataLength / itemCount);

        const calculatePrice = (hna, index) => {
          if (!this.displayPrice?.[index]?.isActive) return 0;
          const basePrice = hna;
          const marginAmount =
            (basePrice * this.displayPrice[index].margin) / 100;
          const priceAfterMargin = basePrice + marginAmount;
          const taxAmount =
            (priceAfterMargin * this.displayPrice[index].ppn) / 100;
          return priceAfterMargin + taxAmount;
        };

        this.table.data = this.getPreviewData.map(item => {
          return {
            name: item.name,
            hna: item.hna,
            price1: calculatePrice(item.hna, 0),
            price2: calculatePrice(item.hna, 1),
            price3: calculatePrice(item.hna, 2),
          };
        });
      } catch {
        this.table.data = [];
      }
    },
    onClickList(index) {
      this.drugCategoryList = this.drugCategoryList.map((item, idx) => {
        return {
          ...item,
          isActive: idx === index,
        };
      });
      this.pagination.page = 1;
      this.getDataPreview();
    },
  },
};
</script>

<style scoped lang="scss">
.chip {
  background-color: #f0f8fe;
  color: #1976d2;
  padding: 0.5rem 0.7rem;
  font-size: 0.9vw;
}

.card-container {
  text-align: start;
  height: fit-content;
  background-color: #f0f8fe;
  border-radius: 8px;
  padding: 1rem 0px;

  &.light {
    background-color: #fafafa;
    padding: 0.8vw 1vw;
    span {
      font-size: 0.8vw;
    }
  }

  .list-item {
    padding: 0.6vw 0.8vw;
    font-size: 1vw;
    border-radius: 8px;
    margin-bottom: 0.4vw;
    display: flex;
    align-items: center;
    font-size: 0.85vw;

    &.active {
      background-color: #fff;
      color: #1976d2;
    }

    &.active:hover {
      background-color: #fff;
    }
  }

  .list-item:hover {
    background-color: #e3f1fc;
    cursor: pointer;
  }
}

h1 {
  font-size: 1.4vw;
}

.btn-edit {
  border-width: 2px;
  letter-spacing: 0.001px;
  padding: 1.1rem 0.5rem !important;
  font-size: 0.8vw;
}

.tab-text {
  color: #616161;
  letter-spacing: 0.01px;
}

.main-grid-display {
  display: grid;
  grid-template-columns: 20% 75%;
  column-gap: 2%;
}

.meta-description {
  background-color: #fafafa;
  margin-bottom: 1vw;
  padding: 0.8vw 1vw;
  border-radius: 9px;
  font-size: 0.8vw;

  span:nth-child(even) {
    margin-right: 2vw;
  }
  span:nth-child(odd) {
    margin-right: 0.4vw;
  }
}

.display-price-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: 100%;
  column-gap: 1vw;
  align-items: end;
}

.table-container {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;

  &.full {
    height: 53vh;
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}

.grid-table {
  display: grid;
  grid-template-columns: auto repeat(3, 10%);
  column-gap: 5%;
  padding: 0.5vw 0;
  border-bottom: 2px solid #e0e0e0;

  &.header {
    color: #9e9e9e;
    font-weight: 500;
    font-size: 0.85vw;
  }
  &.row {
    padding: 0.5vw 0.8vw;
    border-bottom: 1px solid #e0e0e0;
    color: #404040;
    font-size: 0.85vw;
  }
}

.grey-dark-text {
  color: #616161;
}

.grey-light-text {
  color: #9e9e9e;
}
</style>
