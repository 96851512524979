import Constant from '@/const';
const axios = require('axios');

const deleteDrugMarginById = async id_margin => {
  try {
    await axios.delete(
      Constant.apiUrl.concat(`/setting/drug-margin/${id_margin}`),
    );
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deleteDrugMarginById;
