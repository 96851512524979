<template>
  <v-form ref="form">
    <div class="grid">
      <label class="required">Nama Obat</label>
      <v-text-field
        :rules="rule"
        v-model="form.name"
        :readonly="isSaved"
        dense
        single-line
      ></v-text-field>
    </div>
    <mix-ingredients-form ref="mixIngredients" />
    <div class="grid">
      <label class="required">Jumlah Diminta</label>
      <span class="my-4">{{
        (form?.amount_requested || 0) +
          ' ' +
          (form?.drugs[0]?.detail?.packaging || '')
      }}</span>
      <label class="required">Jumlah Diberikan</label>
      <div class="d-flex justify-space-between">
        <v-text-field
          :rules="rule"
          v-model="form.quantity"
          dense
          :readonly="isSaved"
          class="mr-5"
          @keyup="
            updateMixDrugQuantity({
              dosage: getForm.drugs[page].dosage_needed,
              index: undefined,
            })
          "
        />
        <v-autocomplete
          v-model="form.packaging"
          :items="resource.racik_amount"
          @change="onChangeRacikAmount()"
          :rules="rule"
          :readonly="isSaved"
          dense
          append-icon="mdi-chevron-down"
        ></v-autocomplete>
      </div>
      <label class="required">Aturan Pakai</label>
      <div class="grid-usage">
        <v-combobox
          v-model="form.usage.day"
          :items="resource.usageOne"
          :rules="rule"
          :readonly="isSaved"
          append-icon="mdi-chevron-down"
          dense
          auto-select-first
        />
        <v-icon small color="black">mdi-close</v-icon>
        <v-combobox
          append-icon="mdi-chevron-down"
          :rules="rule"
          v-model="form.usage.usage"
          :items="resource.usageTwo"
          dense
          :readonly="isSaved"
          auto-select-first
        />
        <v-combobox
          dense
          auto-select-first
          clearable
          append-icon="mdi-chevron-down"
          :items="resource.packaging"
          :readonly="isSaved"
          :rules="rule"
          v-model="form.usage.package"
        />
      </div>
      <label>Aturan Umum</label>
      <v-autocomplete
        append-icon="mdi-chevron-down"
        placeholder="Pilih Aturan Umum"
        v-model="form.general_rule"
        :readonly="isSaved"
        :items="resource.general_rules"
        dense
      />
      <label>Rute</label>
      <v-autocomplete
        append-icon="mdi-chevron-down"
        placeholder="Pilih Rute"
        :items="resource.route"
        v-model="form.route"
        :readonly="isSaved"
        dense
        auto-select-first
      ></v-autocomplete>
      <label>Aturan Lainnya</label>
      <v-text-field
        single-line
        dense
        :readonly="isSaved"
        v-model="form.description"
      />
      <div class="mt-2" v-if="patientData.assurance.toLowerCase() === 'bpjs'">
        <label>Tagihan Tambahan </label>
        <v-tooltip color="#404040" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              style="cursor: pointer"
              color="primary"
              >mdi-information-outline</v-icon
            >
          </template>
          <div class="add-on-info">
            Mengaktifkan ini berarti item yang anda input masuk sebagai tagihan
            tambahan di luar BPJS
          </div>
        </v-tooltip>
      </div>

      <v-switch
        v-if="patientData.assurance.toLowerCase() === 'bpjs'"
        class="mt-1"
        inset
        hide-details="false"
        v-model="form.isAddOn"
      />
    </div>
  </v-form>
</template>

<script>
import MixIngredientsForm from './MixIngredientsForm.vue';
import { createNamespacedHelpers } from 'vuex';
import resources from '@/helper/pharmacyResources.js';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);
export default {
  name: 'MixForm',
  components: {
    MixIngredientsForm,
  },
  data() {
    return {
      resource: {
        drugs: [],
        usageOne: resources.usageOne,
        usageTwo: resources.usageTwo,
        packaging: resources.packaging.racik,
        racik_amount: resources.packaging.racikAmount,
        general_rules: resources.general_rules,
        route: resources.route,
        schedule: resources.schedule,
      },
      rule: [v => !!v || 'Wajib diisi'],
    };
  },
  watch: {
    'form.quantity': {
      handler() {
        this.$nextTick(() => {
          for (
            let i = 0;
            i < this.$refs?.mixIngredients?.$refs?.drugName.length;
            i++
          ) {
            this.$refs?.mixIngredients?.$refs?.drugName[i].validate();
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getDrugReview', 'getPatientData', 'getPage']),
    page: {
      get() {
        return this.getPage;
      },
    },
    form: {
      get() {
        return this.getForm.mix;
      },
    },
    isSaved: {
      get() {
        return this.getDrugReview.isSaved;
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
  },
  methods: {
    ...mapMutations(['updateMixDrugQuantity']),
    onChangeRacikAmount() {
      switch (this.form.packaging) {
        case 'Bungkus':
          this.form.usage.package = 'Bungkus';
          break;
        case 'Kapsul':
          this.form.usage.package = 'Kapsul';
          break;
        case 'Salep':
          this.form.usage.package = 'Oleskan';
          break;
        case 'Sirup':
          this.form.usage.package = 'Sendok Teh';
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 0.8vw;
  align-items: center;
  text-align: start;
  width: 95%;
  color: #101010;

  #{&}-usage {
    display: inherit;
    grid-template-columns: 1fr 0.3vw repeat(2, 1fr);
    column-gap: 0.8vw;
  }

  label {
    font-size: 0.72vw;
  }
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.required::after {
  content: '*';
  color: #eb4747;
}
.add-on-info {
  text-align: left;
  max-width: 200px; /* Adjust width to control line wrapping */
  white-space: normal; /* Allow text to wrap */
}
</style>
