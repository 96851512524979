<template>
  <v-card class="text-start py-5 px-7">
    <div class="d-flex justify-space-between">
      <h2 class="mb-4">Atur per item</h2>
      <v-btn v-if="!isAdmin" fab text @click="closeForm"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <p>
      Produk terpilih: <strong>{{ getSpecificDrug.name }}</strong>
    </p>
    <div class="grid">
      <specific-price-input-card
        :additionalRules="[additionalRule.noIdenticalMargin]"
        :index="0"
      />
      <specific-price-input-card
        :additionalRules="[additionalRule.noIdenticalMargin]"
        :index="1"
      />
      <specific-price-input-card
        :additionalRules="[additionalRule.noIdenticalMargin]"
        :index="2"
      />
    </div>
    <footer v-if="isAdmin" class="d-flex justify-end align-baseline pr-10">
      <v-btn
        depressed
        outlined
        @click="closeForm"
        color="primary"
        class="text-capitalize mr-4"
        style="letter-spacing: 0.001px;"
      >
        Batal
      </v-btn>
      <v-btn
        color="primary"
        depressed
        class="text-capitalize"
        @click="save"
        :disabled="!isEnableSaveBtn"
        style="letter-spacing: 0.001px;"
        >{{ isEditing ? 'Simpan Perubahan' : 'Simpan' }}</v-btn
      >
    </footer>
  </v-card>
</template>

<script>
import SpecificPriceInputCard from './SpecificPriceInputCard.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);
export default {
  components: { SpecificPriceInputCard },
  name: 'SpecificForm',
  computed: {
    ...mapGetters([
      'getIsEnableConfirmationBtn',
      'getSpecificDrug',
      'getTemporaryStore',
      'getIsEditFromDashboard',
      'getIsEdit',
      'getPriceSetting',
      'getDataFromServer',
    ]),
    isAdmin: {
      get() {
        return this.$store.getters.userLoggedIn.role
          .map(role => role.toLowerCase())
          .some(role => ['administrator', 'kepala unit'].includes(role));
      },
    },
    isEditing: {
      get() {
        return this.getSpecificDrug.setting === 'Spesifik';
      },
    },
    isEnableSaveBtn: {
      get() {
        return (
          this.getIsEnableConfirmationBtn &&
          typeof this.additionalRule.noIdenticalMargin() !== 'string'
        );
      },
    },
    additionalRule: {
      get() {
        return {
          noIdenticalMargin: () => {
            if (
              this.getPriceSetting?.[0]?.margin === '' ||
              this.getPriceSetting?.[0]?.ppn === ''
            )
              return true;
            if (this.getIsEdit || this.getIsEditFromDashboard) {
              const globalSetting = this.getDataFromServer.nondrugs.find(
                i => !i.id_drug,
              );
              return (
                +this.getPriceSetting?.[0]?.margin !==
                  +globalSetting?.margins?.[0]?.margin ||
                +this.getPriceSetting?.[0]?.ppn !==
                  +globalSetting?.margins?.[0]?.ppn ||
                'Nilai tidak boleh sama dengan global setting'
              );
            } else {
              return (
                +this.getPriceSetting?.[0]?.margin !==
                  +this.getTemporaryStore?.[0]?.margin ||
                +this.getPriceSetting?.[0]?.ppn !==
                  +this.getTemporaryStore?.[0]?.ppn ||
                'Nilai tidak boleh sama dengan global setting'
              );
            }
          },
        };
      },
    },
  },
  methods: {
    ...mapMutations([
      'setSpecificDrugResult',
      'setSelectedForm',
      'setSnackbar',
      'setIsEditFromDashboard',
      'setDialog',
      'setPriceSettingDirectly',
      'setTemporaryStore',
    ]),
    ...mapActions(['resolvePutAddDrugMargin', 'clearPriceState']),
    save() {
      if (this.getIsEditFromDashboard) {
        this.$emit('finish-setting', {
          origin: 5,
          snackbarText: 'Setting Margin dan Harga Farmasi berhasil diubah',
          confirmationFunction: async () => {
            await this.resolvePutAddDrugMargin({
              type: 'global_specific',
              additionalData: this.getDataFromServer.nondrugs
                .map(item => {
                  return item.id_drug === this.getSpecificDrug.id
                    ? {
                        id_drug: item.id_drug,
                        margins: this.getPriceSetting.map(i => {
                          return {
                            margin: +i.margin,
                            ppn: +i.ppn,
                            isActive: !!i.isActive,
                            description: i.description,
                          };
                        }),
                      }
                    : {
                        id_drug: item.id_drug || undefined,
                        margins: item.margins,
                      };
                })
                .concat(
                  this.getSpecificDrug.setting === 'Global' && {
                    id_drug: this.getSpecificDrug.id,
                    margins: this.getPriceSetting.map(i => {
                      return {
                        margin: +i.margin,
                        ppn: +i.ppn,
                        isActive: !!i.isActive,
                        description: i.description,
                      };
                    }),
                  },
                )
                .filter(v => v),
            });
          },
        });
        this.clearPriceState();
        return;
      }
      this.setSpecificDrugResult({
        value: {
          margins: this.getPriceSetting.map((item, index) => {
            return {
              ...item,
              sellingPrice: this.calculatePrice(
                this.getSpecificDrug.hna,
                index,
              ),
            };
          }),
          name: this.getSpecificDrug.name,
          id: this.getSpecificDrug.id,
        },
        status: this.isEditing ? 'update' : 'add',
      });
      this.closeForm();
      this.setSnackbar({
        show: true,
        text: `${this.getSpecificDrug.name} berhasil diperbarui`,
        color: 'success',
      });
    },
    calculatePrice(hna, index) {
      if (!this.getPriceSetting[index]?.isActive) return 0;
      const basePrice = hna;
      const marginAmount =
        (basePrice * +this.getPriceSetting[index].margin) / 100;
      const priceAfterMargin = basePrice + marginAmount;
      const taxAmount =
        (priceAfterMargin * +this.getPriceSetting[index].ppn) / 100;
      return priceAfterMargin + taxAmount;
    },
    closeForm() {
      if (this.getIsEditFromDashboard) {
        this.setDialog(false);
        this.setIsEditFromDashboard(false);
        this.setSelectedForm(null);
        this.clearPriceState();
        return;
      }
      this.setPriceSettingDirectly(this.getTemporaryStore);
      this.setTemporaryStore(null);
      this.setSelectedForm(4);
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.2vw;
}
p {
  font-size: 1.1vw;
  color: #404040;
}
.grid {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
  column-gap: 1vw;
  margin-bottom: 2vw;
}
</style>
