import {
  postEarlyAssessmentInpatient,
  getEarlyAssessmentInpatient,
  getTtvRegistration,
} from '@/fetchApi/Inpatient/Assessment';
import {
  mapToPayload,
  mapDataByType,
  mapDataAutofillAnamnesa,
  mapDataAutofillObjective,
} from './MapHelper/mapAssessmentData';
import { generateBadge } from './InpatientAssessmentUtils';

const _ = require('lodash');

const state = {
  tab: 0,
  tabsResource: [
    {
      type: 'initial_assessment',
      key: 'initialAssessment',
      text: 'Pengkajian Awal',
    },
    {
      type: 'anamnesis',
      key: 'anamnesis',
      text: 'Anamnesa',
    },
    {
      type: 'objective',
      key: 'objective',
      text: 'Data Objektif',
    },
    {
      type: 'physical',
      key: 'physical',
      text: 'Pemeriksaan Fisik',
    },
    {
      type: 'pain_assessment',
      key: 'painAssessment',
      text: 'Assesmen Nyeri',
    },
  ],
  loading: {
    save: false,
  },
  showSuccessMessage: {
    text: null,
    show: false,
  },
  id: {
    emr: '',
    registration: '',
    inpatient: '',
  },
  initialAssessment: {
    staff: null,
    isSaved: null,
    date: '',
    time: '',
    arrived: {
      date: {
        raw: '',
        formatted: '',
      },
      time: '',
    },
    assessmentStart: {
      date: {
        raw: '',
        formatted: '',
      },
      time: '',
      obtainedFrom: '',
      relationWithPatient: '',
    },
    entryMethod: 'Jalan Tanpa Bantuan',
    entryFrom: 'IGD Klinik',
  },
  anamnesis: {
    currentHealthHistory: '',
    pastHealthHistory: '',
    familyDiseaseHistory: {
      check: false,
      name: [],
    },
    allergy: [''],
    psychologyStatus: {
      check: false,
      name: [],
    },
    treatmentHistory: '',
  },
  objective: {
    sistole: '',
    diastole: '',
    heartRate: '',
    weight: '',
    height: '',
    bellyCircumference: '',
    rr: '',
    saturation: '',
    temperature: '',
    awareness: '',
  },
  physical: {
    head: '',
    back: '',
    eye: '',
    stomach: '',
    ear: '',
    genitalia: '',
    nose: '',
    anus: '',
    mouth: '',
    upperArm: '',
    neck: '',
    forearm: '',
    hair: '',
    fingers: '',
    lips: '',
    handNail: '',
    teeth: '',
    handJoints: '',
    tongue: '',
    upperLimbs: '',
    palate: '',
    lowerLimbs: '',
    throat: '',
    toes: '',
    tonsil: '',
    toeNails: '',
    breast: '',
    legJoints: '',
    breasts: '',
  },
  painAssessment: {
    pain: 0,
    painFactor: null,
    painLike: null,
    painRadiation: false,
    severity: 0,
    painFrequency: 0,
    painDuration: 0,
    description: null,
  },
  initialState: {
    initialAssessment: {
      isSaved: null,
      staff: null,
      date: '',
      time: '',
      arrived: {
        date: {
          raw: '',
          formatted: '',
        },
        time: '',
      },
      assessmentStart: {
        date: {
          raw: '',
          formatted: '',
        },
        time: '',
        obtainedFrom: '',
        relationWithPatient: '',
      },
      entryMethod: 'Jalan Tanpa Bantuan',
      entryFrom: 'IGD Klinik',
    },
    anamnesis: {
      currentHealthHistory: '',
      pastHealthHistory: '',
      familyDiseaseHistory: {
        check: false,
        name: [],
      },
      allergy: [''],
      psychologyStatus: {
        check: false,
        name: [],
      },
      treatmentHistory: '',
    },
    objective: {
      sistole: '',
      diastole: '',
      heartRate: '',
      weight: '',
      height: '',
      bellyCircumference: '',
      rr: '',
      saturation: '',
      temperature: '',
      awareness: '',
    },
    physical: {
      head: '',
      back: '',
      eye: '',
      stomach: '',
      ear: '',
      genitalia: '',
      nose: '',
      anus: '',
      mouth: '',
      upperArm: '',
      neck: '',
      forearm: '',
      hair: '',
      fingers: '',
      lips: '',
      handNail: '',
      teeth: '',
      handJoints: '',
      tongue: '',
      upperLimbs: '',
      palate: '',
      lowerLimbs: '',
      throat: '',
      toes: '',
      tonsil: '',
      toeNails: '',
      breast: '',
      legJoints: '',
      breasts: '',
    },
    painAssessment: {
      pain: 0,
      painFactor: null,
      painLike: null,
      painRadiation: false,
      severity: 0,
      painFrequency: 0,
      painDuration: 0,
      description: null,
    },
  },
};

const getters = {
  getLoading: state => state.loading,
  getInitialAssessment: state => state.initialAssessment,
  getAnamnesis: state => state.anamnesis,
  getObjective: state => state.objective,
  getPhysical: state => state.physical,
  getPainAssessment: state => state.painAssessment,
  getShowSuccessMessage: state => state.showSuccessMessage,
  getInitialState: state => state.initialState,
  getTab: state => state.tab,
  getTabsResource: state => state.tabsResource,
  getIsInitialAssessmentModified: state => {
    const current = state.initialAssessment;

    return !_.isEqual(current, state.initialState.initialAssessment);
  },
  getIsAnamnesisModified: state => {
    const current = state.anamnesis;

    return !_.isEqual(current, state.initialState.anamnesis);
  },
  getIsObjectiveModified: state => {
    const current = state.objective;

    return !_.isEqual(current, state.initialState.objective);
  },
  getIsPhysicalModified: state => {
    const current = state.physical;

    return !_.isEqual(current, state.initialState.physical);
  },
  getIsPainAssessmentModified: state => {
    const current = state.painAssessment;

    return !_.isEqual(current, state.initialState.painAssessment);
  },
};

const mutations = {
  clearAllFormState(state) {
    state.tab = 0;
    state.tabsResource = [
      {
        type: 'initial_assessment',
        key: 'initialAssessment',
        text: 'Pengkajian Awal',
      },
      {
        type: 'anamnesis',
        key: 'anamnesis',
        text: 'Anamnesa',
      },
      {
        type: 'objective',
        key: 'objective',
        text: 'Data Objektif',
      },
      {
        type: 'physical',
        key: 'physical',
        text: 'Pemeriksaan Fisik',
      },
      {
        type: 'pain_assessment',
        key: 'painAssessment',
        text: 'Assesmen Nyeri',
      },
    ];
    state.initialAssessment = {
      staff: null,
      isSaved: null,
      date: '',
      time: '',
      arrived: {
        date: {
          raw: '',
          formatted: '',
        },
        time: '',
      },
      assessmentStart: {
        date: {
          raw: '',
          formatted: '',
        },
        time: '',
        obtainedFrom: '',
        relationWithPatient: '',
      },
      entryMethod: 'Jalan Tanpa Bantuan',
      entryFrom: 'IGD Klinik',
    };
    state.anamnesis = {
      currentHealthHistory: '',
      pastHealthHistory: '',
      familyDiseaseHistory: {
        check: false,
        name: [],
      },
      allergy: [''],
      psychologyStatus: {
        check: false,
        name: [],
      },
      treatmentHistory: '',
    };
    state.objective = {
      sistole: '',
      diastole: '',
      heartRate: '',
      weight: '',
      height: '',
      bellyCircumference: '',
      rr: '',
      saturation: '',
      temperature: '',
      awareness: '',
    };
    state.physical = {
      head: '',
      back: '',
      eye: '',
      stomach: '',
      ear: '',
      genitalia: '',
      nose: '',
      anus: '',
      mouth: '',
      upperArm: '',
      neck: '',
      forearm: '',
      hair: '',
      fingers: '',
      lips: '',
      handNail: '',
      teeth: '',
      handJoints: '',
      tongue: '',
      upperLimbs: '',
      palate: '',
      lowerLimbs: '',
      throat: '',
      toes: '',
      tonsil: '',
      toeNails: '',
      breast: '',
      legJoints: '',
      breasts: '',
    };
    state.painAssessment = {
      pain: 0,
      painFactor: null,
      painLike: null,
      painRadiation: false,
      severity: 0,
      painFrequency: 0,
      painDuration: 0,
      description: null,
    };

    state.initialState = {
      initialAssessment: {
        isSaved: null,
        staff: null,
        date: '',
        time: '',
        arrived: {
          date: {
            raw: '',
            formatted: '',
          },
          time: '',
        },
        assessmentStart: {
          date: {
            raw: '',
            formatted: '',
          },
          time: '',
          obtainedFrom: '',
          relationWithPatient: '',
        },
        entryMethod: 'Jalan Tanpa Bantuan',
        entryFrom: 'IGD Klinik',
      },
      anamnesis: {
        currentHealthHistory: '',
        pastHealthHistory: '',
        familyDiseaseHistory: {
          check: false,
          name: [],
        },
        allergy: [''],
        psychologyStatus: {
          check: false,
          name: [],
        },
        treatmentHistory: '',
      },
      objective: {
        sistole: '',
        diastole: '',
        heartRate: '',
        weight: '',
        height: '',
        bellyCircumference: '',
        rr: '',
        saturation: '',
        temperature: '',
        awareness: '',
      },
      physical: {
        head: '',
        back: '',
        eye: '',
        stomach: '',
        ear: '',
        genitalia: '',
        nose: '',
        anus: '',
        mouth: '',
        upperArm: '',
        neck: '',
        forearm: '',
        hair: '',
        fingers: '',
        lips: '',
        handNail: '',
        teeth: '',
        handJoints: '',
        tongue: '',
        upperLimbs: '',
        palate: '',
        lowerLimbs: '',
        throat: '',
        toes: '',
        tonsil: '',
        toeNails: '',
        breast: '',
        legJoints: '',
        breasts: '',
      },
      painAssessment: {
        pain: 0,
        painFactor: null,
        painLike: null,
        painRadiation: false,
        severity: 0,
        painFrequency: 0,
        painDuration: 0,
        description: null,
      },
    };
  },
  setId(state, payload) {
    state.id = payload;
  },
  setTab(state, value) {
    state.tab = value;
  },
  setShowSuccessMessage(state, payload) {
    state.showSuccessMessage = payload;
  },
  setForm(state, { key, payload }) {
    state[key] = {
      ...payload,
    };
  },
  setIsSavedStateByType(state, { key, value }) {
    state[key].isSaved = value;
    state.initialState[key].isSaved = value;
  },
  setInitialState(state, { key, payload }) {
    state.initialState[key] = payload;
  },
  setTabsResource(state, { index, payload }) {
    state.tabsResource.splice(index, 1, {
      ...state.tabsResource[index],
      ...payload,
    });
  },
  setAutofillAnamnesa(state, { payload }) {
    state.anamnesis.currentHealthHistory = payload.currentHealthHistory;
  },
  setAutofillObjective(state, payload) {
    state.objective = payload;
  },
  onActionAllergy(state, { type, index }) {
    if (type === 'add') {
      state.anamnesis.allergy.push('');
    }
    if (type === 'delete') {
      state.anamnesis.allergy.splice(index, 1);
    }
  },
};

const actions = {
  async resolveGetEarlyAssessmentInpatient(
    { state, commit, rootGetters, dispatch },
    { tab, validate },
  ) {
    const patientRegisteredAtInpatient = {
      date: rootGetters['MedicalResumeStore/getForm'].date.in,
      time: rootGetters['MedicalResumeStore/getForm'].time.in,
    };
    const selectedTab = state.tabsResource[tab];
    try {
      const response = await getEarlyAssessmentInpatient(
        state.id.inpatient,
        selectedTab.type,
      );
      commit('setForm', {
        key: selectedTab.key,
        payload: mapDataByType(response.data.data, selectedTab.type),
      });
      commit('setInitialState', {
        key: selectedTab.key,
        payload: mapDataByType(response.data.data, selectedTab.type),
      });
    } catch {
      commit('setIsSavedStateByType', { key: selectedTab.key, value: false });
      if (
        selectedTab.type === 'anamnesis' ||
        selectedTab.type === 'objective'
      ) {
        dispatch('resolveGetTtvRegistration', { id: state.id.inpatient });
      }
    } finally {
      if (state[selectedTab.key].isSaved) {
        commit('setTabsResource', {
          index: tab,
          payload: {
            icon: generateBadge(state, tab, patientRegisteredAtInpatient),
          },
        });
        validate(tab);
      }
    }
  },
  async resolvePostEarlyAssessmentInpatient({ state, commit }, tab) {
    const selectedTab = state.tabsResource[tab];
    try {
      state.loading.save = true;
      const payload = mapToPayload(state, selectedTab.type);
      await postEarlyAssessmentInpatient(state.id.inpatient, payload);
      state.anamnesis.isSaved = true;
      state.objective.isSaved = true;
      commit('setShowSuccessMessage', {
        text: selectedTab.text,
        show: true,
      });
      setTimeout(() => {
        commit('setShowSuccessMessage', {
          text: '',
          show: false,
        });
      }, 3000);
    } catch (error) {
      commit('setShowSuccessMessage', {
        text: selectedTab.text,
        show: true,
        isFail: true,
      });
      setTimeout(() => {
        commit('setShowSuccessMessage', {
          text: '',
          show: false,
        });
      }, 3000);
    } finally {
      state.loading.save = false;
    }
  },
  async resolveGetTtvRegistration({ commit }, payload) {
    try {
      const response = await getTtvRegistration(payload.id);

      commit('setAutofillAnamnesa', {
        payload: mapDataAutofillAnamnesa(response.data.data),
      });
      commit(
        'setAutofillObjective',
        mapDataAutofillObjective(response.data.data),
      );
    } catch (error) {
      return error;
    }
  },
  formValidation({ state, commit, rootGetters }, { destination, origin }) {
    const patientRegisteredAtInpatient = {
      date: rootGetters['MedicalResumeStore/getForm'].date.in,
      time: rootGetters['MedicalResumeStore/getForm'].time.in,
    };

    if (destination > origin) {
      for (let i = origin; i < destination; i++) {
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateBadge(state, i, patientRegisteredAtInpatient),
          },
        });
      }
    } else {
      for (let i = origin; i > destination; i--) {
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateBadge(state, i, patientRegisteredAtInpatient),
          },
        });
      }
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
