<template>
  <v-card class="main-card">
    <!-- <v-card class="px-5"> -->
    <v-card-title class="pa-0">
      <v-row class="d-flex align-center justify-space-between pa-5">
        <div class="d-flex flex-row ml-10 align-center title-head">
          <label>No RM</label>
          <span>{{ patientData.rmNumber }}</span>
          <label>Nama</label>
          <span
            >{{ patientData.meta.name }} ({{
              patientData.meta.gender === 'Perempuan' ? 'P' : 'L'
            }})
          </span>
          <label>Tanggal Lahir</label>
          <span
            >{{ patientData.meta.birthDate }} ({{ patientData.meta.age }}
            tahun)
          </span>
        </div>
        <div class="d-flex flex-row">
          <h4>Tindakan & BHP</h4>
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="row-scroll mt-5 pa-0">
      <row class="d-flex flex-row  form-layout">
        <v-col class="">
          <!-- <v-col class="grid-input"> -->
          <v-form ref="action" class="grid-input">
            <label class="form-body__title required">Tindakan</label>
            <v-autocomplete
              placeholder="Pilih Tindakan"
              class="pt-0 "
              dense
              :items="service"
              item-text="name"
              :rules="rules.action"
              item-value="_id"
              :loading="loading"
              return-object
              v-model="inputService.serviceName"
            ></v-autocomplete>

            <label class="form-body__title required">Jumlah</label>
            <v-text-field
              class="pt-0 mt-0 margin-error"
              :rules="rules.actionAmount"
              v-model.number="inputService.amount"
              type="number"
            ></v-text-field>
            <label class="form-body__title mt-1">Total Biaya Tindakan</label>
            <p
              class="text-left black--text mt-1"
              style="font-size: 14px"
              v-if="inputService.serviceName === ''"
            >
              <br />
            </p>
            <p
              class="text-left black--text mt-1"
              style="font-size: 14px"
              v-else
            >
              Rp
              {{
                formatNumber(
                  (inputService.total =
                    inputService.amount * inputService.serviceName.total),
                )
              }}
            </p>

            <div class="form-body__title operator">
              <label class="mt-2 required">Operator Utama</label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="" v-bind="attrs" v-on="on">
                    <v-icon small style="color: #1976D2;"
                      >mdi-information-outline</v-icon
                    >
                  </div>
                </template>
                <div
                  style="text-align: left;"
                  v-html="
                    'Jasa medis akan dibagi rata jika terdapat<br> role yang sama baik antara operator<br> utama dan sekunder maupun antara<br> beberapa role sekunder'
                  "
                ></div>
              </v-tooltip>
            </div>
            <v-autocomplete
              placeholder="Pilih Nama Operator"
              :rules="rules.operator"
              class="pt-0 mt-2"
              dense
              auto-select-first
              :items="primaryStaff"
              item-text="name"
              :loading="loading"
              :value="inputService.operator"
              return-object
              @change="onPrimaryOperatorChange"
            >
            </v-autocomplete>

            <label class="form-body__title mt-2">Operator Sekunder</label>
            <div>
              <div
                class="operator"
                v-for="(item, index) in operatorList"
                :key="index"
              >
                <v-autocomplete
                  placeholder="Pilih Nama Operator"
                  class="pt-0 mt-2"
                  dense
                  auto-select-first
                  :items="staff"
                  item-text="name"
                  :loading="loading"
                  v-model="item.operator"
                  return-object
                  @change="updateSelectedList"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                      v-bind="attrs"
                      v-on="item.disabled ? {} : on"
                      :class="{ 'disabled-option': item.disabled }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <div>
                  <v-icon
                    style="margin-right: 15px; cursor: pointer"
                    @click="addOperator"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-plus</v-icon
                  >
                  <v-icon
                    @click="removeOperator(index)"
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                    >mdi-delete</v-icon
                  >
                </div>
              </div>
            </div>

            <div v-if="isBpjs" class="d-flex flex-start mt-2">
              <label class="form-body__title mr-2">Tagihan Tambahan</label>
              <v-tooltip color="#404040" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                    color="primary"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <div class="add-on-info">
                  Mengaktifkan ini berarti item yang anda input masuk sebagai
                  tagihan tambahan di luar BPJS
                </div>
              </v-tooltip>
            </div>
            <v-switch
              class="mt-2 "
              inset
              hide-details="false"
              v-model="inputService.isAddOn"
              v-if="isBpjs"
            >
            </v-switch>
            <div></div>
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                outlined
                elevation="0"
                class="text-none body-2 font-weight-medium pa-2 mr-3"
                @click="resetServiceForm()"
                v-if="actionEdit"
                >Batal</v-btn
              >
              <v-btn
                color="primary"
                elevation="0"
                class="text-none body-2 font-weight-medium pa-2"
                @click="addService()"
                >{{ actionEdit ? 'Simpan perubahan' : 'Tambahkan' }}</v-btn
              >
            </div>
          </v-form>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="">
          <!-- <v-col class="grid-input"> -->
          <v-form ref="consumableform" class="grid-input">
            <label class="form-body__title required mb-0">BHP/Alkes</label>
            <v-autocomplete
              class="pt-0 mt-0"
              dense
              :items="bhp"
              :rules="rules.consumable"
              item-text="name"
              :loading="loading"
              v-model="inputConsumable.consumableName"
              return-object
              placeholder="Pilih BHP atau alkes"
            ></v-autocomplete>
            <label class="form-body__title required">Jumlah</label>
            <v-text-field
              class="pt-0 mt-0 margin-error"
              :rules="rules.consumableAmount"
              v-model.number="inputConsumable.amount"
              type="number"
            ></v-text-field>
            <label class="form-body__title mb-4">Total Biaya BHP</label>
            <div>
              <p
                class="text-left black--text ma-0"
                v-if="inputConsumable.consumableName === ''"
              >
                <br />
              </p>
              <p class="text-left black--text ma-0" v-else>
                Rp
                {{
                  formatNumber(
                    (inputConsumable.total =
                      inputConsumable.amount *
                      inputConsumable.consumableName.hpp),
                  )
                }}
              </p>
            </div>
            <div v-if="isBpjs" class="d-flex flex-start mt-2">
              <label class="form-body__title mr-2">Tagihan Tambahan</label>
              <v-tooltip color="#404040" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                    color="primary"
                    class=""
                    >mdi-information-outline</v-icon
                  >
                </template>
                <div class="add-on-info">
                  Mengaktifkan ini berarti item yang anda input masuk sebagai
                  tagihan tambahan di luar BPJS
                </div>
              </v-tooltip>
            </div>
            <v-switch
              v-if="isBpjs"
              v-model="inputConsumable.isAddOn"
              class="ma-0 pa-0 mt-2"
              inset
              hide-details="false"
            >
            </v-switch>
            <div></div>
            <div class="d-flex justify-end mb-10">
              <v-btn
                color="primary"
                outlined
                elevation="0"
                class="text-none body-2 font-weight-medium pa-2 mr-3"
                @click="resetBhpForm()"
                v-if="bhpEdit"
                >Batal</v-btn
              >
              <v-btn
                color="primary"
                elevation="0"
                class="text-none body-2 font-weight-medium pa-2"
                @click="addBHP()"
                >{{ bhpEdit ? 'Simpan perubahan' : 'Tambahkan' }}</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </row>
      <!-- <v-layout justify-center pt-5> -->
      <div class="px-10 mt-5">
        <!-- fixed-header -->
        <v-data-table
          :headers="consumable.headers"
          :items="consumable.item"
          :items-per-page="consumable.item.length"
          style="width: 100%"
          :loading="loading"
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }">
            <tr class="d-flex align-center">
              <td>
                {{ item.name }}
              </td>
              <div v-if="item.isAddOn" class="d-flex align-center ml-3">
                <v-tooltip color="#404040" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <td v-bind="attrs" v-on="on" class="pt-0 ml-1">
                      <div class="addon-chip d-flex flex-col align-center">
                        <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                          >mdi-cash</v-icon
                        >
                        Tambahan
                      </div>
                    </td>
                  </template>
                  <span class="text-left">Tagihan tambahan di luar BPJS</span>
                </v-tooltip>
              </div>
            </tr>
          </template>
          <template v-slot:[`item.displayDate`]="{ item }">
            <tr class="d-flex align-center">
              <td>{{ item.displayDate }}, {{ item.displayTime }}</td>
            </tr>
          </template>
          <template v-slot:[`item.total_price`]="{ item }">
            <tr class="d-flex align-center">
              <td>{{ currency(item.total_price) }}</td>
            </tr>
          </template>
          <template v-slot:[`item.operatorList`]="{ item }">
            <tr class="d-flex align-center">
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class=""
                      v-bind="attrs"
                      v-on="on"
                      style="text-wrap: nowrap; max-width: 250px; overflow: hidden; text-overflow: ellipsis;"
                    >
                      {{
                        item.operatorList
                          ? item.operatorList
                              .map(item => item.operator.name)
                              .join(', ')
                          : '-'
                      }}
                    </div>
                  </template>
                  <div>
                    {{
                      item.operatorList
                        ? item.operatorList
                            .map(item => item.operator.name)
                            .join(', ')
                        : '-'
                    }}
                  </div>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.action`]="{ item, index }">
            <tr class="d-flex align-center">
              <td>
                <v-menu
                  v-if="item.isNew"
                  left
                  :offset-x="true"
                  :close-on-content-click="true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>

                  <v-list style="padding: 0px; min-width: 10.5rem;">
                    <v-list-item
                      @click="editItem(item, index)"
                      class="dropdown"
                    >
                      <v-list-item-title>
                        <span class="dropdown-item">
                          Ubah
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)" class="dropdown">
                      <v-list-item-title>
                        <span class="dropdown-item">
                          Hapus
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <div class="price-box d-flex mr-4 align-center">
        <span class="mr-2">Total Biaya</span>
        <span class="price-text">
          {{ currency(totalCost) }}
        </span>
      </div>
      <v-btn class="text-none" color="primary" @click="addData()">Simpan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');
const moment = require('moment-timezone');
const _ = require('lodash');

export default {
  name: 'BHPandAction',
  mixins: [jwtMixin, alertMixin, formatMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    id_registration: {
      type: String,
    },
  },
  data() {
    return {
      staff: [],
      primaryStaff: [],
      service: [],
      loading: false,
      bhp: [],
      serviceCost: '',
      total: 0,
      totalService: 0,
      inputService: {
        serviceName: '',
        amount: 1,
        total: '',
        operator: '',
        isAddOn: false,
      },
      operatorList: [
        {
          operator: '',
        },
      ],
      inputConsumable: {
        consumableName: '',
        amount: 1,
        total: '',
        operator: '',
        isAddOn: false,
      },
      input: [
        {
          id_goods: '',
          packaging: '',
          quantity: '',
          price: '',
        },
      ],
      bhpEdit: false,
      // bhpEditIndex: null,
      actionEdit: false,
      itemEditIndex: null,
      // totalCost: 0,
      consumable: {
        headers: [
          { text: 'Tanggal', value: 'displayDate', width: '12%' },
          { text: 'Nama Tindakan/BHP', value: 'name', width: '40%' },
          {
            text: 'Jumlah',
            value: 'quantity',
            class: 'text-center',
            sortable: false,
          },
          { text: 'Subtotal Biaya', value: 'total_price', sortable: false },
          { text: 'Operator Utama', value: 'staff' },
          { text: 'Operator Sekunder', value: 'operatorList' },
          { text: '', value: 'action' },
        ],
        item: [],
      },
      rules: {
        action: [v => !!v || 'Masukkan Tindakan'],
        actionAmount: [
          v => !!v || 'Masukkan Jumlah',
          v => v > 0 || 'Jumlah Harus Lebih dari 0',
        ],
        consumableAmount: [
          v => !!v || 'Masukkan Jumlah',
          v => v > 0 || 'Jumlah Harus Lebih dari 0',
        ],
        consumable: [v => !!v || 'Masukkan BHP/Alkes'],
        operator: [v => !!v || 'Masukkan Operator'],
      },
    };
  },
  computed: {
    isBpjs() {
      return this.patientData.meta.guarantorType.toLowerCase() === 'bpjs';
    },
    totalCost() {
      return this.consumable.item.reduce((sum, item) => {
        return sum + item.price;
      }, 0);
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.inputService.operator = {
        id: this.$store.getters.userLoggedIn.id,
        name: this.$store.getters.userLoggedIn.name,
      };
      this.getStaff();
      this.getService();
      this.getConsumable();
      this.showAllConsumable();
    });
  },
  methods: {
    addData() {
      const payload = this.consumable.item
        .filter(v => v.isNew === true)
        .map(item => {
          return {
            ...item,
            secondaryStaff: _.get(item, 'operatorList', [])
              .filter(f => f.operator !== '')
              .map(operator => operator.operator.id),
          };
        });
      axios
        .post(
          Constant.apiUrl.concat(
            `/patient/intercons/${this.patientData.id_registration}`,
          ),
          {
            item: payload,
            created_by: this.$store.getters.userLoggedIn.id,
          },
        )
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Data Tindakan berhasil disimpan!', '', 'success');
            this.close();
            this.showAllConsumable();
            this.consumable.item = '';
          }
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    close() {
      this.$emit('close-form');
    },
    getConsumable() {
      this.loading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/service/consumable/${this.patientData.id_registration}`,
            ),
          )
          .then(response => {
            let data = response.data.data;
            this.bhp = data.map(bhp => {
              return {
                id: {
                  id_drugs: bhp.id_drugs,
                  id_goods: bhp.id_goods,
                },
                name: bhp.name,
                hpp: bhp.hpp,
                packaging: bhp.packaging,
              };
            });
            this.loading = false;
          });
      });
    },
    getStaff() {
      this.loading = true;
      axios.get(Constant.apiUrl.concat('/master/staff')).then(response => {
        let data = response.data.data;
        this.staff = data
          .filter(x => x.role.status)
          .map(staff => {
            return {
              id: staff._id.toString(),
              name: staff.detail.name,
              disabled: false,
            };
          });
        this.primaryStaff = [...this.staff];
        this.updateSelectedList();
        this.loading = false;
      });
    },
    getService() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/service?page=1&itemCount=&sort=&search=&category=${this.patientData.meta.guarantorType.toLowerCase()}`,
          ),
        )
        .then(response => {
          let data = response.data.data;
          this.service = data.map(service => {
            return {
              _id: service._id.toString(),
              name: service.name,
              total: _.get(service, 'cost.total', 0),
            };
          });
          this.loading = false;
        });
    },
    addService() {
      if (this.$refs.action.validate()) {
        const arrayPreInput = {
          date: moment().format(),
          displayDate: moment().format('DD/MM/YYYY'),
          displayTime: moment().format('HH:mm'),
          id_service: this.inputService.serviceName._id,
          name: this.inputService.serviceName.name,
          quantity: this.inputService.amount,
          price: this.inputService.serviceName.total,
          total_price: this.inputService.total,
          staff: this.inputService.operator.name,
          id_staff: this.inputService.operator.id,
          isNew: true,
          isAddOn: this.inputService.isAddOn,
          operatorList: this.operatorList.filter(f => f.operator !== ''),
        };

        if (this.itemEditIndex !== null) {
          this.consumable.item.splice(this.itemEditIndex, 1, arrayPreInput);
        } else {
          this.consumable.item.push(arrayPreInput);
        }

        this.resetServiceForm();
        this.$refs.action.resetValidation();
      }
    },
    resetServiceForm() {
      this.inputService = {
        isAddOn: null,
        serviceName: '',
        amount: 1,
        total: '',
        operator: {
          id: this.$store.getters.userLoggedIn.id,
          name: this.$store.getters.userLoggedIn.name,
        },
      };
      this.operatorList = [{ operator: '' }];
      this.actionEdit = false;
      this.itemEditIndex = null;
      this.$refs.action.resetValidation();
      this.updateSelectedList();
    },
    resetBhpForm() {
      this.inputConsumable = {
        consumableName: '',
        amount: 1,
        total: '',
        operator: '',
        isAddOn: false,
      };
      this.bhpEdit = false;
      this.itemEditIndex = null;
      this.$refs.consumableform.resetValidation();
    },
    editItem(item, index) {
      if (item.id_goods || item.id_drugs) {
        this.bhpEdit = true;
        this.itemEditIndex = index;
        this.inputConsumable = {
          consumableName: {
            id: {
              id_drugs: item?.id_drugs,
              id_goods: item?.id_goods,
            },
            name: item.name,
            hpp: item.price,
          },
          amount: item.quantity,
          total: item.total_price,
          isAddOn: item.isAddOn,
        };
      } else {
        this.actionEdit = true;
        this.itemEditIndex = index;
        this.inputService = {
          serviceName: {
            _id: item.id_service,
            name: item.name,
            total: item.price,
          },
          operator: {
            name: item.staff,
            id: item.id_staff,
          },
          amount: item.quantity,
          total: item.total_price,
          isAddOn: item.isAddOn,
        };
        this.operatorList = item.operatorList;
        this.updateSelectedList();
      }
    },
    addBHP() {
      if (this.$refs.consumableform.validate()) {
        let data = {
          date: moment().format(),
          displayDate: moment().format('DD/MM/YYYY'),
          displayTime: moment().format('HH:mm'),
          name: this.inputConsumable.consumableName.name,
          quantity: +this.inputConsumable.amount,
          price: this.inputConsumable.consumableName.hpp,
          total_price: this.inputConsumable.total,
          staff: this.$store.getters.userLoggedIn.name,
          id_staff: this.$store.getters.userLoggedIn.id,
          isNew: true,
          isAddOn: this.inputConsumable.isAddOn,
        };

        if (this.inputConsumable.consumableName.id?.id_goods) {
          data.id_goods = this.inputConsumable.consumableName.id.id_goods;
        } else {
          data.id_drugs = this.inputConsumable.consumableName.id?.id_drugs;
        }

        if (this.itemEditIndex !== null) {
          this.consumable.item.splice(this.itemEditIndex, 1, data);
        } else {
          this.consumable.item.push(data);
        }

        this.resetBhpForm();
        this.$refs.consumableform.resetValidation();
      }
    },
    showAllConsumable() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/intercons/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.consumable.item = data.map(consume => {
            return {
              ...consume,
              date: consume.date,
              displayDate:
                moment(consume.date).format('DD/MM/YYYY') == 'Invalid date'
                  ? moment(consume.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
                  : moment(consume.date).format('DD/MM/YYYY'),
              displayTime:
                moment(consume.date).format('DD/MM/YYYY') == 'Invalid date'
                  ? moment(consume.date, 'DD/MM/YYYY').format('HH:mm')
                  : moment(consume.date).format('HH:mm'),
              price: consume.price,
              staff: consume.staff,
              isNew: false,
              isAddOn: consume.isAddOn,
              operatorList: consume.secondaryStaff
                ? consume.secondaryStaff.map(item => {
                    return { operator: { name: item } };
                  })
                : undefined,
            };
          });
          this.totalCost = this.consumable.item.reduce(
            (a, { total_price }) => a + total_price,
            0,
          );
          this.loading = false;
        });
    },
    deleteItem(item) {
      const index = this.consumable.item.indexOf(item);
      this.consumable.item.splice(index, 1);
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },

    addOperator() {
      this.operatorList.push({ operator: '' });
    },

    removeOperator(index) {
      if (this.operatorList.length > 1) {
        this.operatorList.splice(index, 1);
      } else {
        this.operatorList[index].operator = '';
      }
      this.updateSelectedList();
    },

    updateSelectedList() {
      let selectedItem = this.operatorList.map(item => item.operator);
      if (this.inputService.operator !== '') {
        selectedItem.push({ ...this.inputService.operator, disabled: true });
      }

      this.staff = this.staff.map(item => {
        const found = selectedItem.find(f => f.id === item.id);
        if (found) {
          return {
            ...item,
            disabled: true,
          };
        }
        return { ...item, disabled: false };
      });
    },

    onPrimaryOperatorChange(val) {
      const newValue = val;
      const oldValue = { ...this.inputService.operator };
      const foundDisabled = this.staff.find(
        f => f.id === newValue.id && f.disabled,
      );

      if (foundDisabled) {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>${foundDisabled.name} sudah dipilih sebagai operator sekunder</p></div>`,
          iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
          html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Apakah anda yakin ingin menjadikannya sebagai operator utama? ${foundDisabled.name} akan dihapus dari operator sekunder</p></div>`,
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'button-confirm',
            cancelButton: 'button-error-blue',
          },
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batal',
        }).then(async result => {
          if (!result.isConfirmed) {
            this.inputService.operator = oldValue;
          } else {
            this.operatorList = this.operatorList.map(item => {
              const { operator } = item;
              if (foundDisabled.id === operator.id) {
                return { operator: '' };
              }
              return { operator: operator };
            });
            this.inputService.operator = newValue;
            this.updateSelectedList();
          }
        });
      } else {
        this.inputService.operator = newValue;
        this.updateSelectedList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// v-form {
//   all: unset;
// }
// .custom-form {
//   all: unset;
// }
.operator {
  display: flex;
  align-items: baseline;
  justify-items: baseline;
  gap: 15px;
}
.main-card {
  overflow-x: hidden;
}
.title-head {
  font-size: 14px;
  span {
    color: #404040;
    margin-right: 22px;
  }
  label {
    color: #9e9e9e;
    margin-right: 8px;
  }
}
.grid-input {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  // grid-template-rows: auto auto;
  padding: 0 48px;
  align-items: start;
}

.data-head {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}
.head-table {
  border-top: 1px solid #f4f5f5;
  border-bottom: 1px solid #f4f5f5;
  text-align: left;
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
}

.body-table {
  &__data {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
  }
}

.form-body {
  &__title {
    font-size: 14px;
    font-weight: 500;
    color: black;
    text-align: left;
  }
}

.row-scroll {
  overflow-y: scroll;
  height: 440px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.margin-error {
  ::v-deep .v-input__slot {
    margin-bottom: 3px !important;
  }
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep {
  .v-data-table .v-data-table-header {
    background-color: #f4f5f5;

    span {
      color: #9e9e9e;
    }
  }

  .total-footer {
    background-color: #f4f5f5 !important;
    color: #404040 !important;
  }
}

.addon-chip {
  background-color: #fffbea;
  padding: 5px;
  color: #bb4802;
}

.price-box {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 8px;
  font-size: 0.9rem;
  color: #616161;

  .price-text {
    font-weight: 500;
    color: #1565c0;
  }
}

.add-on-info {
  text-align: left;
  max-width: 200px; /* Adjust width to control line wrapping */
  white-space: normal; /* Allow text to wrap */
}

.dropdown {
  min-height: 3rem;
  text-align: left;
}
.dropdown:hover {
  background-color: #e6f6fe;
  cursor: pointer;
}
</style>
