<template>
  <v-container fluid>
    <table class="datatable-style">
      <thead>
        <tr>
          <th
            v-for="(item, index) in table.headers"
            :key="index"
            :class="`text-${item.align}`"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <div
          v-for="(item, index) in historyData"
          :key="index"
          style=" border-bottom: 1px solid #e0e0e0;"
        >
          <tr>
            <td>{{ item.receiveTime }}</td>
            <td>{{ item.invoiceNumber }}</td>
            <td class="text-truncate" style="width: 100%;">
              {{ item.items[0].itemName }}
            </td>
            <td>
              {{ item.items[0].receiveAmount }} {{ item.items[0].package }}
            </td>
            <td>
              {{ item.items[0].manufacturers[0].manufacturerName }}
            </td>
            <td>
              {{ item.items[0].manufacturers[0].batch[0].batchNumber }}
            </td>
            <td>
              {{ item.items[0].manufacturers[0].batch[0].expiryDate }}
            </td>
            <td>
              {{ item.items[0].manufacturers[0].batch[0].receivedQuantity }}
              {{ item.items[0].package }}
            </td>
            <td>
              {{ item.items[0].manufacturers[0].batch[0].bonusQuantity }}
              {{ item.items[0].package }}
            </td>
            <td class="text-center">
              {{ item.items[0].status ? 'Selesai' : 'Belum Selesai' }}
            </td>
            <td>{{ item.staffName }}</td>
          </tr>
          <div v-for="(item2, index2) in item.items" :key="index2">
            <tr v-if="item.items.length > 1 && index2 !== 0">
              <td></td>
              <td></td>
              <td
                style="border-top: 1px solid #E0E0E0; width: 100%;"
                class="text-truncate"
              >
                {{ item2.itemName }}
              </td>
              <td style="border-top: 1px solid #E0E0E0;">
                {{ item2.receiveAmount }} {{ item2.package }}
              </td>
              <td style="border-top: 1px solid #E0E0E0;">
                {{ item2.manufacturers[0].manufacturerName }}
              </td>
              <td style="border-top: 1px solid #E0E0E0;">
                {{ item2.manufacturers[0].batch[0].batchNumber }}
              </td>
              <td style="border-top: 1px solid #E0E0E0;">
                {{ item2.manufacturers[0].batch[0].expiryDate }}
              </td>
              <td style="border-top: 1px solid #E0E0E0;">
                {{ item2.manufacturers[0].batch[0].receivedQuantity }}
                {{ item2.package }}
              </td>
              <td style="border-top: 1px solid #E0E0E0;">
                {{ item2.manufacturers[0].batch[0].bonusQuantity }}
                {{ item2.package }}
              </td>
              <td class="text-center" style="border-top: 1px solid #E0E0E0;">
                {{ item.items[0].status ? 'Selesai' : 'Belum Selesai' }}
              </td>
              <td style="border-top: 1px solid #E0E0E0;"></td>
            </tr>
            <div
              v-for="(manufacture, index3) in item2.manufacturers"
              :key="index3"
            >
              <tr v-if="item2.manufacturers.length > 1 && index3 !== 0">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{ manufacture.manufacturerName }}
                </td>
                <td>
                  {{ manufacture.batch[0].batchNumber }}
                </td>
                <td>
                  {{ manufacture.batch[0].expiryDate }}
                </td>
                <td>
                  {{ manufacture.batch[0].receivedQuantity }}
                  {{ item2.package }}
                </td>
                <td>
                  {{ manufacture.batch[0].bonusQuantity }}
                  {{ item2.package }}
                </td>
              </tr>
              <div
                v-for="(itemBatch, index4) in manufacture.batch"
                :key="index4"
              >
                <tr v-if="manufacture.batch.length > 1 && index4 !== 0">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {{ itemBatch.batchNumber }}
                  </td>
                  <td>
                    {{ itemBatch.expiryDate }}
                  </td>
                  <td>
                    {{ itemBatch.receivedQuantity }}
                    {{ item2.package }}
                  </td>
                  <td>
                    {{ itemBatch.bonusQuantity }}
                    {{ item2.package }}
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </div>
      </tbody>
    </table>

    <v-row no-gutters class="text-left py-5">
      <label style="color: #404040; font-size: 16px; font-weight: 600;">
        Riwayat File Bukti Faktur
      </label>
    </v-row>

    <table class="datatableHistory-style">
      <thead>
        <tr>
          <th>Tgl Penerimaan</th>
          <th>Nomor Faktur</th>
          <th>Nama Staff</th>
          <th>File Pendukung</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in historyTable.items" :key="index">
          <td style="border-bottom: 1px solid #E0E0E0 !important;">
            {{ item.receiveDate }}
          </td>
          <td style="border-bottom: 1px solid #E0E0E0 !important;">
            {{ item.invoiceNumber }}
          </td>
          <td style="border-bottom: 1px solid #E0E0E0 !important;">
            {{ item.staffName }}
          </td>
          <td style="border-bottom: 1px solid #E0E0E0 !important;">
            <div
              style="
                width: 10vw !important;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              "
              :key="'files-' + index"
              @click="openFile(item.path)"
              class="text-none text-truncate text-start"
            >
              <div
                style="
                display: flex;
                align-items: center;
                gap: 0.5rem;
                color: #404040;
                font-weight: 400;
                font-size: 12px;
              "
              >
                <v-icon>mdi-file-document</v-icon>
                <span
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: block;"
                >
                  {{ item.files }}
                </span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
export default {
  name: 'HistoryDetail',
  props: {
    historyData: {
      type: Array,
      default: null,
    },
    allStaff: {
      type: Array,
      default: null,
    },
    files: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dummyData: [
        {
          receiveTime: '10/02/2023 12.00',
          invoiceNumber: 'F00001',
          staffName: 'Aurelie Morales',
          items: [
            {
              itemName: 'Paracetamol 5 mg - Kaplet (SANBE)',
              receiveAmount: 40,
              package: 'Box',
              status: true,
              manufacturers: [
                {
                  manufacturerName: 'SANBE',
                  packageType: 'Box',
                  batch: [
                    {
                      batchNumber: '1',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 10,
                      bonusQuantity: 2,
                    },
                    {
                      batchNumber: '2',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 20,
                      bonusQuantity: 1,
                    },
                  ],
                },
                {
                  manufacturerName: 'Hexamorp',
                  packageType: 'Box',
                  batch: [
                    {
                      batchNumber: '3',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 10,
                      bonusQuantity: 2,
                    },
                    {
                      batchNumber: '4',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 20,
                      bonusQuantity: 1,
                    },
                  ],
                },
              ],
            },
            {
              itemName: 'Prednison 10 mg - Kaplet (HEXAPHARM)',
              receiveAmount: 40,
              package: 'Box',
              status: false,
              manufacturers: [
                {
                  manufacturerName: 'Hexamorp',
                  packageType: 'Box',
                  batch: [
                    {
                      batchNumber: '5',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 10,
                      bonusQuantity: 2,
                    },
                    {
                      batchNumber: '6',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 20,
                      bonusQuantity: 1,
                    },
                  ],
                },
                {
                  manufacturerName: 'SANBE',
                  packageType: 'Box',
                  batch: [
                    {
                      batchNumber: '7',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 10,
                      bonusQuantity: 2,
                    },
                    {
                      batchNumber: '8',
                      expiryDate: '15-02-2025',
                      receivedQuantity: 20,
                      bonusQuantity: 1,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      search: '',
      table: {
        headers: [
          { text: 'Tgl Penerimaan', align: 'start' },
          { text: 'Nomor Faktur', align: 'start' },
          { text: 'Nama Barang', align: 'start' },
          { text: 'Jml Total Diterima', align: 'start' },
          { text: 'Pabrikan', align: 'start' },
          { text: 'No Batch', align: 'start' },
          { text: 'Tgl Expired', align: 'start' },
          { text: 'Jml Diterima PerBatch', align: 'start' },
          { text: 'Jml Hibah', align: 'start' },
          { text: 'Status', align: 'center' },
          { text: 'Nama Petugas', align: 'start' },
        ],
        items: [],
      },
      historyTable: {
        headers: [
          {
            text: 'Tanggal Penerimaan',
            value: 'receiveDate',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Nomor Faktur',
            value: 'invoiceNumber',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Nama Staff',
            value: 'staffName',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'File Pendukung',
            value: 'files',
            width: '60%',
            sortable: false,
            class: 'black--text',
          },
        ],
        items: [
          {
            receiveDate: '10/12/2020 14:00 WIB',
            invoiceNumber: 'FA1234',
            staffName: 'Anggraeta Pramudita, S.Farm',
            files: ['IMG-201212000.jpg', 'Invoice FA12345.pdf'],
          },
        ],
      },
    };
  },
  watch: {
    historyData(val) {
      if (val) {
        this.mapData();
      }
    },
  },
  mounted() {
    this.mapData();
  },
  methods: {
    mapData() {
      this.table.items = this.historyData;
      this.historyTable.items = this.files.map(file => {
        return {
          staffName: file.created_by,
          invoiceNumber: this.historyData[0]?.invoiceNumber,
          receiveDate: `${moment(file.created_at).format(
            'DD/MM/YYYY HH:mm',
          )} WIB`,
          files: file.original_file_name,
          path: file.path,
        };
      });
    },
    openFile(path) {
      window.open(Constant.apiUrl.concat(`/${path}`));
    },
  },
};
</script>

<style lang="scss" scoped>
.datatableHistory-style {
  width: 50vw;
  border-spacing: 0;
  thead {
    background-color: #f5f5f5;
    tr {
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      th {
        text-align: start;
        padding: 0.75rem 0.5rem;
        color: #9e9e9e;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  tbody {
    tr {
      width: 100%;
      border-bottom: 1px solid #e0e0e0;
      td {
        text-align: start;
        padding: 0.75rem 0.5rem;
        color: #404040;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}

.datatable-style {
  width: 100%;
  border-spacing: 0;
  thead {
    background-color: #f5f5f5;
    tr {
      display: grid;
      grid-template-columns: 9.88fr 7.32fr 12.52fr 5.28fr 9.95fr 11.16fr 10.56fr 10.56fr 7.09fr 7.01fr 8.67fr;
      grid-template-rows: auto;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      th {
        text-align: start;
        padding: 0.75rem 0.5rem;
        color: #9e9e9e;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  tbody {
    tr {
      display: grid;
      grid-template-columns: 9.88fr 7.32fr 12.52fr 5.28fr 9.95fr 11.16fr 10.56fr 10.56fr 7.09fr 7.01fr 8.67fr;
      grid-template-rows: auto;
      width: 100%;
      td {
        text-align: start;
        padding: 0.75rem 0.5rem;
        color: #404040;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
</style>
