import { render, staticRenderFns } from "./SettingCategoryMarginAndPriceDialog.vue?vue&type=template&id=f38e4a2e&scoped=true"
import script from "./SettingCategoryMarginAndPriceDialog.vue?vue&type=script&lang=js"
export * from "./SettingCategoryMarginAndPriceDialog.vue?vue&type=script&lang=js"
import style0 from "./SettingCategoryMarginAndPriceDialog.vue?vue&type=style&index=0&id=f38e4a2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f38e4a2e",
  null
  
)

export default component.exports