<template>
  <v-card class="pa-5 main-card">
    <div class="d-flex justify-space-between mb-2">
      <h2>Penjualan Obat Bebas</h2>
      <v-btn text fab width="30" height="30" @click="$emit('close-form')">
        <v-icon size="18" color="#757575">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <div>
      <v-tabs
        v-model="tab"
        color="#1976D2"
        grow
        slider-color="primary"
        class="tab-font"
      >
        <v-tab class="text-capitalize" style="letter-spacing:0.1px">
          Penjualan Obat Non Racik
        </v-tab>
        <v-tab class="text-capitalize" style="letter-spacing:0.1px">
          Penjualan Obat Racik
        </v-tab>
        <v-tab class="text-capitalize" style="letter-spacing:0.1px">
          Penjualan Alkes & BHP
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="input-container">
            <v-row class="mb-5">
              <v-col cols="6" class="text-left pt-7 pr-10">
                <h4>
                  Data Informasi Pembeli
                </h4>
                <div class="pt-3 input-wrapper">
                  <div class="input-box">
                    <label class="font-weight-medium text-left">
                      Nomor Registrasi
                    </label>
                    <label class="text-left">{{ registrationCode }}</label>
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">Nama</label>
                    <v-text-field
                      dense
                      single-line
                      hide-details
                      :readonly="isDetail"
                      v-model="input.name"
                    />
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left required">
                      Jenis Kelamin
                    </label>
                    <div class="d-flex">
                      <v-chip
                        depressed
                        class="mr-2"
                        style="color: white; font-size: 12px; font-weight: 600;"
                        @click="onClickGender(true, 'nonmix')"
                        :outlined="!input.gender"
                        label
                        color="#1976D2"
                      >
                        LAKI-LAKI
                      </v-chip>
                      <v-chip
                        depressed
                        style=" font-size: 12px; font-weight: 600;"
                        label
                        color="#1976D2"
                        :outlined="input.gender"
                        @click="onClickGender(false, 'nonmix')"
                      >
                        PEREMPUAN
                      </v-chip>
                    </div>
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">Usia</label>
                    <v-text-field
                      suffix="Tahun"
                      dense
                      :readonly="isDetail"
                      hide-details
                      single-line
                      v-model="input.age"
                      v-on:keypress="isNumber($event)"
                    />
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">
                      Nama Dokter
                    </label>
                    <v-text-field
                      dense
                      single-line
                      :readonly="isDetail"
                      hide-details
                      v-model="input.doctor"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="6" class="text-left pt-7 pl-10">
                <h4>Pemesanan</h4>
                <v-form ref="nonmix">
                  <div class="input-wrapper pt-3">
                    <div class="input-box">
                      <label class="font-weight-medium text-left required">
                        Nama Obat
                      </label>
                      <v-combobox
                        append-icon="mdi-chevron-down"
                        single-line
                        dense
                        auto-select-first
                        item-value="id_stock"
                        :filter="comboboxFilter"
                        :rules="isDetail ? [] : rules.nonmix.drug"
                        :readonly="isDetail"
                        :search-input.sync="searchDrug"
                        :items="resource.drugs"
                        v-model="input.nonmix.drug"
                        :loading="isLoading.nonmix"
                        validate-on-blur
                      >
                        <template v-slot:item="data">
                          <v-list-item-content class="dropdown-item">
                            <v-list-item-title>
                              <v-layout>
                                <v-flex class="d-flex justify-start">
                                  <span class="text-left">
                                    {{ `${data.item.text}` }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-layout>
                                <span class="mr-4">{{
                                  `Exp: ${data.item.formattedExpiryDate || '-'}`
                                }}</span>
                                <span class="mr-4">{{
                                  `No Batch: ${data.item.batch || '-'}`
                                }}</span>
                                <span class="mr-4">{{
                                  `Sisa Stok: ${data.item.quantity} ${data.item.packaging}`
                                }}</span>
                                <span>{{
                                  currency(data.item.price || 0)
                                }}</span>
                              </v-layout>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template></v-combobox
                      >
                    </div>
                    <div class="input-box">
                      <label class="font-weight-medium text-left">
                        Jenis Sediaan
                      </label>
                      <label class="text-left"
                        >{{ input.nonmix.drug.preparation }}
                      </label>
                    </div>
                    <div class="input-box">
                      <label class="font-weight-medium text-left">Dosis</label>
                      <label class="text-left"
                        >{{
                          `${input.nonmix.drug.strength || ''} ${input.nonmix
                            .drug.strength_unit || ''}`
                        }}
                      </label>
                    </div>
                    <div class="input-box">
                      <label class="font-weight-medium text-left">Jumlah</label>
                      <v-text-field
                        dense
                        type="number"
                        :readonly="isDetail"
                        single-line
                        v-model="input.nonmix.amount"
                        hide-details
                        v-on:keypress="isNumber($event)"
                      />
                    </div>
                  </div>
                </v-form>
                <v-row>
                  <v-col cols="12" class="text-right pt-8">
                    <v-btn
                      color="#1976D2"
                      class="font-weight-medium caption px-2"
                      outlined
                      height="30"
                      @click="addToListTable('nonmix')"
                      v-if="!isDetail"
                      :loading="isLoadingGetMargin"
                    >
                      <v-icon small class="font-weight-bold">mdi-plus</v-icon>
                      TAMBAHKAN KE DAFTAR
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div style="overflow-x: auto !important;">
                  <v-form ref="nonmixTable">
                    <v-data-table
                      hide-default-footer
                      :loading="isLoading.table"
                      :headers="table.nonmix.headers"
                      :items="table.nonmix.items"
                      :items-per-page="table.nonmix.items.length"
                      class="table-style"
                      style="min-width: 1300; display: block;"
                    >
                      <template slot="no-data">
                        <span
                          style="color: #757575; font-size: 14px; font-weight: 400;"
                        >
                          Belum ada data yang ditambahkan
                        </span>
                      </template>
                      <template v-slot:item="{ item, index }">
                        <tr>
                          <td class="text-left">{{ index + 1 }}</td>
                          <td class="text-left">
                            <span
                              class="text-truncate"
                              style="max-width: 16vw !important; display: inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                            >
                              {{ item.drugName }}
                            </span>
                          </td>
                          <td class="text-left">
                            <div style="min-width: 78px;">
                              {{ item.preparation }}
                            </div>
                          </td>
                          <td class="text-center">{{ item.dosage }}</td>
                          <td class="text-center">
                            <div style="min-width: 78px;">
                              <v-text-field
                                dense
                                single-line
                                placeholder="0"
                                v-if="!isDetail"
                                v-model="item.amount"
                                :rules="[
                                  v => !!v || 'Jumlah Obat belum diisi',
                                  v => +v > 0 || 'Jumlah harus lebih dari 0',
                                ]"
                                validate-on-blur
                                v-on:keypress="isNumber($event)"
                              />
                              <label v-else>{{ item.amount }}</label>
                            </div>
                          </td>
                          <td class="text-left">{{ item.unit }}</td>
                          <td class="text-right">
                            <v-combobox
                              v-if="!isDetail"
                              placeholder="0"
                              class="input-end"
                              append-icon="mdi-chevron-down"
                              dense
                              single-line
                              :items="getDrugsList(item.id)"
                              prefix="Rp"
                              item-value="value"
                              return-object
                              item-text="displayValue"
                              :value="formattedValue(item)"
                              @input="val => handleInput(val, item)"
                              v-on:keypress="isNumber($event)"
                              :rules="[
                                v =>
                                  (!!v &&
                                    v !== 0 &&
                                    v !== '0' &&
                                    v !== '0,00') ||
                                  'Harga harus lebih dari Rp 0',
                              ]"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content class="dropdown-item">
                                  <v-list-item-title>
                                    <v-layout>
                                      <v-flex class="d-flex justify-start">
                                        <span
                                          class="text-left"
                                          style="font-size: 14px; font-weight: 400;"
                                        >
                                          {{ currency(data.item.value || 0) }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <v-layout>
                                      <span
                                        style="font-size: 12px; font-weight: 400;"
                                      >
                                        {{ data.item.name }}
                                      </span>
                                    </v-layout>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-combobox>
                            <span v-else>
                              {{ currency(handlePriceType(item.price)) }}
                            </span>
                          </td>
                          <td class="text-right">
                            {{
                              currency(
                                handlePriceType(item.price) * item.amount || 0,
                              )
                            }}
                          </td>
                          <td class="text-right">
                            <div style="min-width: 60px;">
                              <v-text-field
                                placeholder="0"
                                class="input-end"
                                dense
                                single-line
                                v-if="!isDetail"
                                v-model="item.discount"
                                suffix="%"
                                :rules="
                                  item.discount
                                    ? [
                                        v => v <= 100 || 'Diskon melebihi 100%',
                                        v => v >= 0 || 'Diskon kurang dari 0%',
                                      ]
                                    : []
                                "
                                validate-on-blur
                                v-on:keypress="isNumber($event)"
                              />
                              <span v-else> {{ item.discount }} % </span>
                            </div>
                          </td>
                          <td class="text-right">
                            <div style="min-width: 60px;">
                              {{
                                item.discount
                                  ? currency(
                                      (handlePriceType(item.price) *
                                        item.amount || 0) -
                                        calculateTotalDiscount(item, 'nonmix'),
                                    )
                                  : currency(0)
                              }}
                            </div>
                          </td>
                          <td class="text-right">
                            {{
                              currency(calculateTotalDiscount(item, 'nonmix'))
                            }}
                          </td>
                          <td class="text-center">
                            <v-icon
                              @click="deleteItem(item, 'nonmix')"
                              color="red"
                              v-if="!isDetail"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-form>
                </div>
                <div class="d-flex justify-end pt-5">
                  <div
                    style="width: 20rem; display: flex; flex-direction: column; gap: 1rem;"
                  >
                    <div class="subtotal">
                      <span>
                        Total Obat Non Racik
                      </span>
                      <span>
                        {{ currency(totalNonMix) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Total Obat Racik
                      </span>
                      <span>
                        {{ currency(totalMix) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Total Alkes & BHP
                      </span>
                      <span>
                        {{ currency(totalMedtool) }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal">
                      <span>
                        Total Keseluruhan
                      </span>
                      <span>
                        {{ currency(total) }}
                      </span>
                    </div>
                    <div
                      class="subtotal"
                      style="display: flex; align-items: center; margin-top: -0.75rem;"
                    >
                      <span>
                        Diskon Tambahan
                      </span>
                      <div style="width: 50px;">
                        <v-text-field
                          placeholder="0"
                          class="input-center"
                          dense
                          single-line
                          :readonly="isDetail"
                          v-model="overallDiscount"
                          suffix="%"
                          :rules="[
                            v => v <= 100 || 'Diskon melebihi 100%',
                            v => v >= 0 || 'Diskon kurang dari 0%',
                          ]"
                          validate-on-blur
                          v-on:keypress="isNumber($event)"
                        />
                      </div>
                      <span>
                        {{
                          total && overallDiscount
                            ? currency(total * (overallDiscount / 100))
                            : currency(0)
                        }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal">
                      <span>
                        Total Akhir
                      </span>
                      <span>
                        {{ currency(total - total * (overallDiscount / 100)) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Dana Kebajikan
                      </span>
                      <span>
                        {{
                          currency(
                            roundTotal(total - total * (overallDiscount / 100)),
                          )
                        }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal font-weight-bold primary--text">
                      <span>
                        Sisa Tagihan
                      </span>
                      <span>
                        {{
                          currency(
                            roundTotal(
                              total - total * (overallDiscount / 100),
                            ) +
                              total -
                              total * (overallDiscount / 100),
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="input-container">
            <v-row class="mb-5">
              <v-col cols="6" class="text-left pt-7 pr-10">
                <h4>
                  Data Informasi Pembeli
                </h4>
                <div class="input-wrapper pt-3">
                  <div class="input-box">
                    <label class="font-weight-medium text-left">
                      Nomor Registrasi
                    </label>
                    <label class="text-left">{{ registrationCode }}</label>
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">Nama</label>
                    <v-text-field
                      dense
                      :readonly="isDetail"
                      single-line
                      hide-details
                      v-model="input.name"
                    />
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left required">
                      Jenis Kelamin
                    </label>
                    <div class="d-flex">
                      <v-chip
                        style="cursor: pointer;"
                        class="mr-2 font-weight-medium caption"
                        :outlined="!input.gender"
                        label
                        @click="onClickGender(true, 'mix')"
                        color="primary"
                      >
                        LAKI-LAKI
                      </v-chip>
                      <v-chip
                        style="cursor: pointer;"
                        @click="onClickGender(false, 'mix')"
                        :outlined="input.gender"
                        class="font-weight-medium caption"
                        color="primary"
                        label
                      >
                        PEREMPUAN
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="6" class="pl-10">
                <div class="input-wrapper pt-12">
                  <div class="input-box">
                    <label class="font-weight-medium text-left">Usia</label>
                    <v-text-field
                      suffix="Tahun"
                      type="number"
                      dense
                      hide-details
                      :readonly="isDetail"
                      single-line
                      v-model="input.age"
                      v-on:keypress="isNumber($event)"
                    />
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">
                      Nama Dokter
                    </label>
                    <v-text-field
                      dense
                      single-line
                      hide-details
                      :readonly="isDetail"
                      v-model="input.doctor"
                    />
                  </div>
                </div>
                <v-row>
                  <v-col cols="12" class="text-right pt-8">
                    <v-btn
                      color="primary"
                      class="font-weight-medium caption"
                      outlined
                      height="30"
                      v-if="!isDetail"
                      width="130"
                      @click="openDialog()"
                    >
                      BUAT OBAT RACIK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div style="overflow-x: auto !important;">
                  <v-form ref="mixTable">
                    <v-data-table
                      style="min-width: 1958px; display: block;"
                      hide-default-footer
                      :loading="isLoading.table"
                      :headers="table.mix.headers"
                      :items="table.mix.items"
                      :items-per-page="table.mix.items.length"
                      class="table-style"
                    >
                      <template v-slot:item="{ item, index }">
                        <tr>
                          <td>{{ index + 1 }}</td>
                          <td class="text-left">{{ item.mixDrugName }}</td>
                          <td class="text-center">{{ item.amount }}</td>
                          <td class="text-center">{{ item.unit }}</td>
                          <td class="text-left py-1">
                            <p
                              class="text-truncate text-left py-3"
                              style="max-width: 14vw !important;"
                              :style="
                                item.drugs.length <= 1 ? { margin: 'auto' } : {}
                              "
                              v-for="(drug, index) in item.drugs"
                              :key="`drug-${index}`"
                            >
                              {{ drug.name }}
                            </p>
                          </td>
                          <td class="text-center py-1">
                            <p
                              class="py-3"
                              v-for="(drug, index) in item.drugs"
                              :key="`drug-${index}`"
                              :style="
                                item.drugs.length <= 1 ? { margin: 'auto' } : {}
                              "
                            >
                              {{ drug.preparation }}
                            </p>
                          </td>
                          <td class="text-center py-1 mx-0 px-0">
                            <p
                              class="py-3"
                              v-for="(drug, index) in item.drugs"
                              :key="`drug-${index}`"
                              :style="
                                item.drugs.length <= 1 ? { margin: 'auto' } : {}
                              "
                            >
                              {{ drug.dosage }}
                            </p>
                          </td>
                          <td class="text-center py-1">
                            <div
                              :class="isDetail ? 'py-3' : 'py-2'"
                              v-for="(drug, index) in item.drugs"
                              :key="`drug-${index}`"
                            >
                              <v-text-field
                                single-line
                                dense
                                v-if="!isDetail"
                                class="ma-0 pa-0"
                                v-model="drug.amount"
                                :rules="[
                                  v => !!v || 'Jumlah Obat belum diisi',
                                  ,
                                  v => +v > 0 || 'Jumlah harus lebih dari 0',
                                ]"
                                validate-on-blur
                                v-on:keypress="isNumber($event)"
                              >
                              </v-text-field>
                              <p
                                v-else
                                :style="
                                  item.drugs.length <= 1
                                    ? `margin-bottom: 0 !important;`
                                    : `margin-bottom: 16px !important;`
                                "
                              >
                                {{ drug.amount }}
                              </p>
                            </div>
                          </td>
                          <td class="text-end py-1">
                            <div
                              :class="isDetail ? 'py-3' : 'py-2'"
                              v-for="(drug, index) in item.drugs"
                              :key="`drug-${index}`"
                            >
                              <v-combobox
                                v-if="!isDetail"
                                placeholder="0"
                                class="input-end"
                                append-icon="mdi-chevron-down"
                                dense
                                single-line
                                :items="getDrugsList(drug.id)"
                                prefix="Rp"
                                item-value="value"
                                return-object
                                item-text="displayValue"
                                :value="formattedValue(drug)"
                                @input="val => handleInput(val, drug)"
                                v-on:keypress="isNumber($event)"
                                :rules="[
                                  v =>
                                    (!!v &&
                                      v !== 0 &&
                                      v !== '0' &&
                                      v !== '0,00') ||
                                    'Harga harus lebih dari Rp 0',
                                ]"
                              >
                                <template v-slot:item="data">
                                  <v-list-item-content class="dropdown-item">
                                    <v-list-item-title>
                                      <v-layout>
                                        <v-flex class="d-flex justify-start">
                                          <span
                                            class="text-left"
                                            style="font-size: 14px; font-weight: 400;"
                                          >
                                            {{ currency(data.item.value || 0) }}
                                          </span>
                                        </v-flex>
                                      </v-layout>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <v-layout>
                                        <span
                                          style="font-size: 12px; font-weight: 400;"
                                        >
                                          {{ data.item.name }}
                                        </span>
                                      </v-layout>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-combobox>
                              <p
                                v-else
                                :style="
                                  item.drugs.length <= 1
                                    ? `margin-bottom: 0 !important;`
                                    : `margin-bottom: 16px !important;`
                                "
                              >
                                {{ currency(drug.price) }}
                              </p>
                            </div>
                          </td>
                          <td class="text-right">
                            {{ currency(calculateSubTotalMix(item)) }}
                          </td>
                          <td class="text-right">
                            <v-currency-field
                              single-line
                              dense
                              v-if="!isDetail"
                              class="ma-0 pa-0 input-end"
                              prefix="Rp "
                              auto-decimal-mode
                              decimal-length="0"
                              v-model="item.tuslah"
                              v-on:keypress="isNumber($event)"
                            />
                            <span v-else>
                              {{ currency(item.tuslah) }}
                            </span>
                          </td>
                          <td>
                            {{
                              currency(
                                calculateSubTotalMix(item) +
                                  Number(extractNumbers(item.tuslah)),
                              )
                            }}
                          </td>
                          <td class="text-right">
                            <v-text-field
                              placeholder="0"
                              single-line
                              dense
                              v-if="!isDetail"
                              class="ma-0 pa-0 input-end"
                              v-model="item.discount"
                              :rules="
                                item.discount
                                  ? [
                                      v => v <= 100 || 'Diskon melebihi 100%',
                                      v => v >= 0 || 'Diskon kurang dari 0%',
                                    ]
                                  : []
                              "
                              validate-on-blur
                              suffix="%"
                              v-on:keypress="isNumber($event)"
                            />
                            <span v-else> {{ item.discount }} % </span>
                          </td>
                          <td class="text-right">
                            {{
                              currency(
                                calculateSubTotalMix(item) *
                                  ((item.discount || 0) / 100),
                              )
                            }}
                          </td>
                          <td class="text-right">
                            {{ currency(calculateTotalMix(item)) }}
                          </td>
                          <td class="text-center">
                            <v-icon
                              v-if="!isDetail"
                              @click="deleteItem(item, 'mix')"
                              color="red"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-form>
                </div>
                <div class="d-flex justify-end pt-5">
                  <div
                    style="width: 20rem; display: flex; flex-direction: column; gap: 1rem;"
                  >
                    <div class="subtotal">
                      <span>
                        Total Obat Non Racik
                      </span>
                      <span>
                        {{ currency(totalNonMix) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Total Obat Racik
                      </span>
                      <span>
                        {{ currency(totalMix) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Total Alkes & BHP
                      </span>
                      <span>
                        {{ currency(totalMedtool) }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal">
                      <span>
                        Total Keseluruhan
                      </span>
                      <span>
                        {{ currency(total) }}
                      </span>
                    </div>
                    <div
                      class="subtotal"
                      style="display: flex; align-items: center; margin-top: -0.75rem;"
                    >
                      <span>
                        Diskon Tambahan
                      </span>
                      <div style="width: 50px;">
                        <v-text-field
                          placeholder="0"
                          class="input-center"
                          dense
                          single-line
                          :readonly="isDetail"
                          v-model="overallDiscount"
                          suffix="%"
                          :rules="[
                            v => v <= 100 || 'Diskon melebihi 100%',
                            v => v >= 0 || 'Diskon kurang dari 0%',
                          ]"
                          validate-on-blur
                          v-on:keypress="isNumber($event)"
                        />
                      </div>
                      <span>
                        {{
                          total && overallDiscount
                            ? currency(total * (overallDiscount / 100))
                            : currency(0)
                        }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal">
                      <span>
                        Total Akhir
                      </span>
                      <span>
                        {{ currency(total - total * (overallDiscount / 100)) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Dana Kebajikan
                      </span>
                      <span>
                        {{
                          currency(
                            roundTotal(total - total * (overallDiscount / 100)),
                          )
                        }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal font-weight-bold primary--text">
                      <span>
                        Sisa Tagihan
                      </span>
                      <span>
                        {{
                          currency(
                            roundTotal(
                              total - total * (overallDiscount / 100),
                            ) +
                              total -
                              total * (overallDiscount / 100),
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="input-container">
            <v-row class="mb-2">
              <v-col cols="6" class="text-left pt-7 pr-10">
                <h4>
                  Data Informasi Pembeli
                </h4>
                <div class="input-wrapper pt-3">
                  <div class="input-box">
                    <label class="font-weight-medium text-left">
                      Nomor Registrasi
                    </label>
                    <label class="text-left">{{ registrationCode }}</label>
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">Nama</label>
                    <v-text-field
                      dense
                      :readonly="isDetail"
                      single-line
                      hide-details
                      v-model="input.name"
                    />
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left required">
                      Jenis Kelamin
                    </label>
                    <div class="d-flex">
                      <v-chip
                        class="mr-2 font-weight-medium caption"
                        label
                        @click="onClickGender(true, 'medtool')"
                        :outlined="!input.gender"
                        color="primary"
                        >LAKI-LAKI</v-chip
                      >
                      <v-chip
                        class="font-weight-medium caption"
                        label
                        @click="onClickGender(false, 'medtool')"
                        color="primary"
                        :outlined="input.gender"
                        >PEREMPUAN</v-chip
                      >
                    </div>
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">Usia</label>
                    <v-text-field
                      suffix="Tahun"
                      dense
                      hide-details
                      type="number"
                      :readonly="isDetail"
                      single-line
                      v-model="input.age"
                      v-on:keypress="isNumber($event)"
                    />
                  </div>
                  <div class="input-box">
                    <label class="font-weight-medium text-left">
                      Nama Dokter
                    </label>
                    <v-text-field
                      dense
                      single-line
                      hide-details
                      :readonly="isDetail"
                      v-model="input.doctor"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="6" class="text-left pt-7 pl-10">
                <h4>Pemesanan</h4>
                <v-form ref="medtool">
                  <div class="input-wrapper pt-3">
                    <div class="input-box">
                      <label class="font-weight-medium text-left required">
                        Nama Alkes atau BHP
                      </label>
                      <v-combobox
                        append-icon="mdi-chevron-down"
                        :rules="isDetail ? [] : rules.medtool.name"
                        :readonly="isDetail"
                        :items="resource.medtoolAndBHP"
                        :loading="isLoading.medtoolAndBHP"
                        :search-input.sync="searchMedtoolAndBHP"
                        item-value="id_stock"
                        single-line
                        dense
                        v-model="input.medtoolAndBHP.item"
                        validate-on-blur
                      >
                        <template v-slot:item="data">
                          <v-list-item-content class="dropdown-item">
                            <v-list-item-title>
                              <v-layout>
                                <v-flex class="d-flex justify-start">
                                  <span class="text-left">
                                    {{ `${data.item.text}` }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-layout>
                                <span class="mr-4">{{
                                  `Exp: ${data.item.formattedExpiryDate || '-'}`
                                }}</span>
                                <span class="mr-4">{{
                                  `No Batch: ${data.item.batch || '-'}`
                                }}</span>
                                <span class="mr-4">{{
                                  `Sisa Stok : ${data.item.quantity} ${data.item.packaging}`
                                }}</span>
                                <span>{{
                                  currency(data.item.price || 0)
                                }}</span>
                              </v-layout>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template></v-combobox
                      >
                    </div>
                    <div class="input-box">
                      <label class="font-weight-medium text-left">Jumlah</label>
                      <v-text-field
                        dense
                        type="number"
                        :readonly="isDetail"
                        single-line
                        :suffix="input.medtoolAndBHP.unit"
                        v-model="input.medtoolAndBHP.amount"
                        hide-details
                        validate-on-blur
                        v-on:keypress="isNumber($event)"
                      />
                    </div>
                  </div>
                </v-form>
                <v-row>
                  <v-col cols="12" class="text-right pt-8">
                    <v-btn
                      color="primary"
                      class="font-weight-medium caption"
                      outlined
                      @click="addToListTable('medtool')"
                      height="30"
                      v-if="!isDetail"
                      width="190"
                      ><v-icon small class="font-weight-bold">mdi-plus</v-icon
                      >TAMBAHKAN KE DAFTAR</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div style="overflow-x: auto !important;">
                  <v-form ref="medtoolTable">
                    <v-data-table
                      hide-default-footer
                      :loading="isLoading.table"
                      :headers="table.medtoolAndBHP.headers"
                      :items="table.medtoolAndBHP.items"
                      :items-per-page="table.medtoolAndBHP.items.length"
                      class="table-style"
                      style="min-width: 1300; display: block;"
                    >
                      <template slot="no-data">
                        <span
                          style="color: #757575; font-size: 14px; font-weight: 400;"
                        >
                          Belum ada data yang ditambahkan
                        </span>
                      </template>
                      <template v-slot:item="{ item, index }">
                        <tr>
                          <td class="text-left">{{ index + 1 }}</td>
                          <td class="text-left">
                            <span
                              class="text-truncate"
                              style="max-width: 16vw !important; display: inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                            >
                              {{ item.name }}
                            </span>
                          </td>
                          <td class="text-left">
                            <div style="min-width: 58px;">
                              <v-text-field
                                placeholder="0"
                                single-line
                                dense
                                v-if="!isDetail"
                                :rules="[
                                  v => !!v || 'Jumlah alkes belum diisi',
                                  v => +v > 0 || 'Jumlah harus lebih dari 0',
                                ]"
                                v-model="item.amount"
                                validate-on-blur
                                v-on:keypress="isNumber($event)"
                              />
                              <label v-else>{{ item.amount }}</label>
                            </div>
                          </td>
                          <td class="text-left">{{ item.unit }}</td>
                          <td class="text-right">
                            <v-combobox
                              v-if="!isDetail"
                              placeholder="0"
                              class="input-end"
                              append-icon="mdi-chevron-down"
                              dense
                              single-line
                              :items="getDrugsList(item.id_drugs)"
                              prefix="Rp"
                              item-value="value"
                              return-object
                              item-text="displayValue"
                              :value="formattedValue(item)"
                              @input="val => handleInput(val, item)"
                              v-on:keypress="isNumber($event)"
                              :rules="[
                                v =>
                                  (!!v &&
                                    v !== 0 &&
                                    v !== '0' &&
                                    v !== '0,00') ||
                                  'Harga harus lebih dari Rp 0',
                              ]"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content class="dropdown-item">
                                  <v-list-item-title>
                                    <v-layout>
                                      <v-flex class="d-flex justify-start">
                                        <span
                                          class="text-left"
                                          style="font-size: 14px; font-weight: 400;"
                                        >
                                          {{ currency(data.item.value || 0) }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <v-layout>
                                      <span
                                        style="font-size: 12px; font-weight: 400;"
                                      >
                                        {{ data.item.name }}
                                      </span>
                                    </v-layout>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-combobox>
                            <span v-else>
                              {{ currency(handlePriceType(item.price)) }}
                            </span>
                          </td>
                          <td class="text-right">
                            {{
                              currency(
                                handlePriceType(item.price) * item.amount || 0,
                              )
                            }}
                          </td>
                          <td class="text-right">
                            <div style="min-width: 60px;">
                              <v-text-field
                                class="input-end"
                                dense
                                single-line
                                v-if="!isDetail"
                                v-model="item.discount"
                                placeholder="0"
                                suffix="%"
                                :rules="
                                  item.discount
                                    ? [
                                        v => v <= 100 || 'Diskon melebihi 100%',
                                        v => v >= 0 || 'Diskon kurang dari 0%',
                                      ]
                                    : []
                                "
                                validate-on-blur
                                v-on:keypress="isNumber($event)"
                              />
                              <span v-else> {{ item.discount }} % </span>
                            </div>
                          </td>
                          <td class="text-right">
                            <div style="min-width: 60px;">
                              {{
                                item.discount
                                  ? currency(
                                      (handlePriceType(item.price) *
                                        item.amount || 0) -
                                        calculateTotalDiscount(
                                          item,
                                          'medtoolAndBHP',
                                        ),
                                    )
                                  : currency(0)
                              }}
                            </div>
                          </td>
                          <td class="text-right">
                            {{
                              currency(
                                calculateTotalDiscount(item, 'medtoolAndBHP'),
                              )
                            }}
                          </td>
                          <td class="text-center">
                            <v-icon
                              @click="deleteItem(item, 'medtool')"
                              color="red"
                              v-if="!isDetail"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-form>
                </div>
                <div class="d-flex justify-end pt-5">
                  <div
                    style="width: 20rem; display: flex; flex-direction: column; gap: 1rem;"
                  >
                    <div class="subtotal">
                      <span>
                        Total Obat Non Racik
                      </span>
                      <span>
                        {{ currency(totalNonMix) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Total Obat Racik
                      </span>
                      <span>
                        {{ currency(totalMix) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Total Alkes & BHP
                      </span>
                      <span>
                        {{ currency(totalMedtool) }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal">
                      <span>
                        Total Keseluruhan
                      </span>
                      <span>
                        {{ currency(total) }}
                      </span>
                    </div>
                    <div
                      class="subtotal"
                      style="display: flex; align-items: center; margin-top: -0.75rem;"
                    >
                      <span>
                        Diskon Tambahan
                      </span>
                      <div style="width: 50px;">
                        <v-text-field
                          placeholder="0"
                          class="input-center"
                          dense
                          single-line
                          :readonly="isDetail"
                          v-model="overallDiscount"
                          suffix="%"
                          :rules="[
                            v => v <= 100 || 'Diskon melebihi 100%',
                            v => v >= 0 || 'Diskon kurang dari 0%',
                          ]"
                          validate-on-blur
                          v-on:keypress="isNumber($event)"
                        />
                      </div>
                      <span>
                        {{
                          total && overallDiscount
                            ? currency(total * (overallDiscount / 100))
                            : currency(0)
                        }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal">
                      <span>
                        Total Akhir
                      </span>
                      <span>
                        {{ currency(total - total * (overallDiscount / 100)) }}
                      </span>
                    </div>
                    <div class="subtotal">
                      <span>
                        Dana Kebajikan
                      </span>
                      <span>
                        {{
                          currency(
                            roundTotal(total - total * (overallDiscount / 100)),
                          )
                        }}
                      </span>
                    </div>
                    <v-divider />
                    <div class="subtotal font-weight-bold primary--text">
                      <span>
                        Sisa Tagihan
                      </span>
                      <span>
                        {{
                          currency(
                            roundTotal(
                              total - total * (overallDiscount / 100),
                            ) +
                              total -
                              total * (overallDiscount / 100),
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <v-footer class="d-flex justify-end pt-7 px-0" color="white">
        <div class="d-flex">
          <v-btn
            style="letter-spacing:0.5px !important; min-width: fit-content !important;"
            class="mr-3 text-none"
            color="#1976D2"
            outlined
            depressed
            @click="$emit('close-form')"
          >
            Kembali
          </v-btn>
          <v-btn
            style="color: white !important;letter-spacing:0.5px !important; min-width: fit-content !important;"
            class="text-none"
            depressed
            color="#1976D2"
            @click="save()"
            :loading="isLoading.save"
            v-if="!isDetail"
          >
            Simpan
          </v-btn>
        </div>
      </v-footer>
    </div>
    <v-dialog persistent v-model="dialog">
      <MixDrugManufacturing
        v-if="dialog"
        @save="addMixDrug"
        @close-dialog="closeDialog()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import formatMixin from '@/mixin/formatMixin';
import MixDrugManufacturing from './MixDrugManufacturing.vue';
// api
import { getDrugsStock } from '@/fetchApi/Stock';
import {
  addDrugSales,
  getDrugSalesById,
  getDrugSalesRegistrationCode,
  getDrugMarginById,
} from '@/fetchApi/DrugSales';
// utils
const moment = require('moment-timezone');
const _ = require('lodash');

export default {
  name: 'inputOTC',
  mixins: [formatMixin],
  components: { MixDrugManufacturing },
  props: {
    isDetail: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tab: null,
      dialog: false,
      isLoading: {
        nonmix: false,
        medtoolAndBHP: false,
        save: false,
        table: false,
      },
      searchDrug: '',
      searchMedtoolAndBHP: '',
      registrationCode: '',
      overallDiscount: null,
      isLoadingGetMargin: false,
      resource: {
        drugs: [],
        medtoolAndBHP: [],
        drugsMargin: [],
      },
      input: {
        name: '',
        age: null,
        gender: true,
        doctor: '',
        nonmix: {
          drugName: '',
          preparation: '',
          dosage: '',
          amount: '',
          drug: '',
        },
        medtoolAndBHP: {
          item: '',
          amount: '',
          unit: '',
        },
      },
      rules: {
        nonmix: {
          drug: [v => !!v || 'Masukkan nama obat'],
          amount: [v => !!v || 'Masukkan jumlah obat'],
        },
        medtool: {
          amount: [v => !!v || 'Masukkan jumlah alkes atau BHP'],
          nodecimal: [v => v % 1 === 0 || 'Jumlah tidak boleh desimal'],
          name: [v => !!v || 'Masukkan nama alkes atau BHP'],
        },
      },
      table: {
        nonmix: {
          headers: [
            {
              text: 'No',
              value: 'no',
              width: '2%',
              class: 'text-sm-left',
              sortable: false,
              // 2.3%
            },
            {
              text: 'Nama Obat',
              value: 'drugName',
              width: '20%',
              class: 'text-sm-left',
              sortable: false,
              // 20.7%
            },
            {
              text: 'Jenis Sediaan',
              value: 'preparation',
              width: '10%',
              class: 'text-sm-left',
              sortable: false,
              // 7.4%
            },
            {
              text: 'Dosis',
              value: 'dosage',
              class: 'text-sm-left',
              width: '5%',
              sortable: false,
              // 4.95%
            },
            {
              text: 'Jumlah',
              value: 'amount',
              class: 'text-sm-left',
              width: '7%',
              sortable: false,
              // 7.4%
            },
            {
              text: 'Satuan',
              value: 'unit',
              width: '6%',
              class: 'text-sm-left',
              sortable: false,
              // 6.3%
            },
            {
              text: 'Harga',
              value: 'price',
              width: '12%',
              sortable: false,
              class: 'text-sm-right',
              // 12%
            },
            {
              text: 'Sub Total',
              value: 'subTotal',
              width: '9%',
              sortable: false,
              class: 'text-sm-right',
              // 9.5%
            },
            {
              text: 'Diskon (%)',
              value: 'discount',
              width: '9%',
              sortable: false,
              class: 'text-sm-right',
              // 7.12%
            },
            {
              text: 'Diskon (Rp)',
              value: 'discountPrice',
              width: '9%',
              sortable: false,
              class: 'text-sm-right',
              // 8.9%
            },
            {
              text: 'Total',
              value: 'total',
              width: '9%',
              sortable: false,
              class: 'text-sm-right',
              // 8.9%
            },
            {
              text: 'Action',
              value: 'action',
              width: '4%',
              class: 'text-sm-center',
              sortable: false,
              // 4.18
            },
          ],
          items: [],
        },
        mix: {
          headers: [
            {
              text: 'No',
              value: 'no',
              width: '1.5%',
              sortable: false,
            },
            {
              text: 'Nama Obat Racik',
              value: 'mixDrugName',
              width: '7%',
              sortable: false,
            },
            {
              text: 'Jumlah',
              value: 'amount',
              class: 'text-sm-center',
              width: '4%',
              sortable: false,
            },
            {
              text: 'Satuan',
              value: 'unit',
              width: '5%',
              class: 'text-sm-center',
              sortable: false,
            },
            {
              text: 'Nama Obat',
              value: 'drugName',
              width: '11%',
              class: 'text-sm-left',
              sortable: false,
            },
            {
              text: 'Jenis Sediaan',
              value: 'preparation',
              width: '5%',
              class: 'text-sm-center px-0 mx-0',
              sortable: false,
            },
            {
              text: 'Dosis',
              value: 'dosage',
              class: 'text-sm-center',
              width: '5%',
              sortable: false,
            },
            {
              text: 'Jumlah Obat Diambil',
              value: 'amount',
              width: '7%',
              class: 'text-sm-center mx-0 px-0',
              sortable: false,
            },
            {
              text: 'Harga',
              value: 'price',
              width: '7%',
              sortable: false,
              class: 'text-sm-right',
            },
            {
              text: 'Sub Total',
              value: 'price',
              width: '7%',
              sortable: false,
              class: 'text-sm-right',
            },
            {
              text: 'Biaya Tuslah',
              value: 'price',
              width: '7%',
              sortable: false,
              class: 'text-sm-right',
            },
            {
              text: 'Harga Setelah Tuslah',
              value: 'price',
              width: '8%',
              sortable: false,
              class: 'text-sm-right',
            },
            {
              text: 'Diskon(%)',
              value: 'price',
              width: '6%',
              sortable: false,
              class: 'text-sm-right',
            },
            {
              text: 'Diskon(Rp)',
              value: 'price',
              width: '7%',
              sortable: false,
              class: 'text-sm-right',
            },
            {
              text: 'Total',
              value: 'price',
              width: '7%',
              sortable: false,
              class: 'text-sm-right',
            },
            {
              text: 'Action',
              value: 'action',
              width: '2.7%',
              class: 'text-sm-center',
              sortable: false,
            },
          ],
          items: [],
        },
        medtoolAndBHP: {
          headers: [
            {
              text: 'No',
              value: 'no',
              sortable: false,
              width: '3%',
              class: 'text-sm-left',
            },
            {
              text: 'Nama Alkes atau BHP',
              value: 'name',
              sortable: false,
              width: '19%',
              class: 'text-sm-left',
            },
            {
              text: 'Jumlah',
              value: 'amount',
              sortable: false,
              width: '7%',
              class: 'text-sm-left',
            },
            {
              text: 'Satuan',
              value: 'unit',
              sortable: false,
              width: '6%',
              class: 'text-sm-left',
            },
            {
              text: 'Harga',
              value: 'price',
              sortable: false,
              width: '13%',
              class: 'text-sm-right',
            },
            {
              text: 'Sub Total',
              value: 'subTotal',
              sortable: false,
              width: '13%',
              class: 'text-sm-right',
            },
            {
              text: 'Diskon (%)',
              value: 'discount',
              sortable: false,
              width: '8%',
              class: 'text-sm-right',
            },
            {
              text: 'Diskon (Rp)',
              value: 'discountPrice',
              sortable: false,
              width: '13%',
              class: 'text-sm-right',
            },
            {
              text: 'Total',
              value: 'total',
              sortable: false,
              width: '13%',
              class: 'text-sm-right',
            },
            {
              text: 'Action',
              value: 'action',
              sortable: false,
              width: '4%',
              class: 'text-sm-center',
            },
          ],
          items: [],
        },
      },
      cachedTotalNonMix: 0,
      cachedTotalMix: 0,
      cachedTotalMedtool: 0,
      cachedTotal: 0,
    };
  },
  watch: {
    searchDrug(value) {
      if (value) {
        this.searchData(this);
      }
    },
    searchMedtoolAndBHP(value) {
      if (value) {
        this.getMedtoolAndBHP(value);
      }
    },
    'table.nonmix.items': {
      handler(newItems) {
        this.cachedTotalNonMix = newItems.reduce(
          (sum, item) =>
            sum + item.price * item.amount * (1 - (item.discount || 0) / 100),
          0,
        );
        this.updateTotal();
      },
      deep: true,
    },
    'table.mix.items': {
      handler(newItems) {
        this.cachedTotalMix = newItems.reduce(
          (sum, item) => sum + item.price * +item.amount,
          0,
        );
        this.updateTotal();
      },
      deep: true,
    },
    'table.medtoolAndBHP.items': {
      handler(newItems) {
        this.cachedTotalMedtool = newItems.reduce(
          (sum, item) => sum + item.total,
          0,
        );
        this.updateTotal();
      },
      deep: true,
    },
  },
  computed: {
    totalNonMix() {
      return this.cachedTotalNonMix;
    },
    totalMix() {
      return this.table.mix.items.reduce(
        (sum, item) => sum + this.calculateTotalMix(item),
        0,
      );
    },
    totalMedtool() {
      return this.cachedTotalMedtool;
    },
    total() {
      return this.cachedTotal;
    },
  },
  mounted() {
    if (this.isDetail) {
      this.getDataById();
    } else {
      this.getRegistrationCode();
      this.getDrugs();
      this.getMedtoolAndBHP();
    }
  },
  methods: {
    searchData: _.debounce(v => v.getDrugs(v.searchDrug), 650),
    roundTotal(total) {
      if (!total) return 0;
      let mod = total % 1000;
      let add = mod >= 500 ? 1000 - mod : 500 - mod;
      if (mod == 0 || mod === 500) add = 0;
      return add;
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        return true;
      } else if (char === '.' || char === ',') {
        e.preventDefault();
        return false;
      } else {
        e.preventDefault();
      }
    },
    updateTotal() {
      this.cachedTotal =
        this.cachedTotalNonMix + this.totalMix + this.cachedTotalMedtool;
    },
    getDrugsList(id) {
      return this.resource.drugsMargin.length !== 0 &&
        this.resource.drugsMargin.find(arr => arr.id === id)
        ? this.resource.drugsMargin.find(arr => arr.id === id).list
        : [];
    },
    formattedTuslahValue(price) {
      return new Intl.NumberFormat('id-ID', {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(price);
    },
    formattedValue(item) {
      if (!item.price) return '';
      return new Intl.NumberFormat('id-ID', {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item.price);
    },
    handleInput(value, item) {
      const dataVal = () => {
        if (typeof value === 'object' && value !== null) {
          return value.value !== null && value.value !== undefined
            ? value.value.toString()
            : '0';
        }
        if (value === undefined || value === null) {
          return '0';
        } else {
          return value.toString();
        }
      };
      const rawValue = dataVal().replace(/[^0-9]/g, '');
      // eslint-disable-next-line no-param-reassign
      item.price = rawValue;
    },
    handlePriceType(val) {
      switch (typeof val) {
        case 'object':
          return Number(val.value);
        case 'string':
          return parseInt(val.replace(/\./g, '').replace(',00', ''), 10);
        default:
          return Number(val);
      }
    },
    async handleGetDrugMargin(id) {
      this.isLoadingGetMargin = true;
      if (this.resource.drugsMargin.find(arr => arr.id === id)) {
        this.isLoadingGetMargin = false;
        return;
      }
      try {
        const response = await getDrugMarginById(id).finally(() => {
          this.isLoadingGetMargin = false;
        });

        this.resource.drugsMargin.push({
          id: id,
          list: response.data.data.margin.map(arr => ({
            name: arr.name,
            value: arr.price,
            displayValue: this.currencyWithoutSymbol(arr.price),
          })),
        });
      } catch (error) {
        return error;
      }
    },
    comboboxFilter(_, __, itemText) {
      return itemText;
    },
    calculateTotalDiscount(params, type) {
      const discount = params.discount || 0;
      const price =
        this.handlePriceType(params.price) *
        params.amount *
        (1 - discount / 100);
      const total = Math.max(price, 0);
      const item =
        type !== 'medtoolAndBHP'
          ? this.table[type].items.find(i => i?.id === params?.id)
          : this.table[type].items.find(i => i?.id_drugs === params?.id_drugs);

      if (item) {
        item.total = total;
      }
      return total;
    },
    calculateTotalMix(params) {
      const discount = (params.discount || 0) / 100;
      const subTotal = this.calculateSubTotalMix(params);
      const totalDiscount =
        discount * (subTotal + this.extractNumbers(params.tuslah) || 0);
      const total =
        (subTotal + this.extractNumbers(params.tuslah) || 0) - totalDiscount;

      const item = this.table.mix.items.find(i =>
        _.isEqual(i.drugs, params.drugs),
      );
      if (item) {
        item.total = total;
      }
      return total;
    },
    calculateSubTotalMix(params) {
      return Number(params.drugs.reduce((a, b) => a + +b.price * +b.amount, 0));
    },
    // get recipe data by id
    async getDataById() {
      this.isLoading.table = true;
      try {
        const response = await getDrugSalesById(this.id);
        const { data } = response.data;
        this.registrationCode = data.transaction_code;
        this.input = {
          ...this.input,
          name: data.patient_name || '',
          age: data.patient_age || '',
          gender: data.patient_gender,
          doctor: data.doctor_name || '',
        };
        this.overallDiscount = data?.additional_discount || 0;
        // map non mix data to table
        this.table.nonmix.items = data.non_mixed_medicine.map(drug => {
          return {
            drugName: `${drug.id_drugs?.name} ${drug.id_drugs?.strength} ${drug.id_drugs?.strength_unit} (${drug.id_drugs?.factory})`,
            amount: drug.amount || '-',
            unit:
              drug.id_drugs?.packaging?.filter(i => i.isDefault)[0]
                ?.package_unit || '-',
            price: drug.price || '-',
            preparation: drug.id_drugs?.preparation,
            dosage: drug.id_drugs?.strength,
            discount: drug.discount || 0,
            total: this.calculateTotalDiscount(drug, 'nonmix'),
          };
        });
        this.table.mix.items = data.mixed_medicine.map(mix => {
          return {
            mixDrugName: mix.name || '-',
            amount: mix.amount || '-',
            unit: mix.amount_unit || '-',
            price: mix.price || '-',
            tuslah: mix.tuslah_price,
            total: this.calculateTotalMix({
              ...mix,
              drugs: mix.drugs.map(arr => ({ ...arr, price: arr.drug_price })),
              tuslah: mix.tuslah,
            }),
            discount: mix.discount || 0,
            drugs: mix.drugs.map(i => {
              return {
                name: `${i.id_drugs?.name} ${i.id_drugs?.strength} ${i.id_drugs?.strength_unit} (${i.id_drugs?.factory})`,
                preparation: i.id_drugs?.preparation || '-',
                dosage: i.id_drugs?.strength,
                dosage_unit: i.id_drugs?.strength_unit,
                amount: i.amount,
                unit: i.id_drugs?.strength_unit,
                package:
                  i.id_drugs?.packaging?.filter(x => x.isDefault)[0]
                    ?.package_unit || '-',
                price: i.drug_price,
              };
            }),
          };
        });
        // map medtool data to table
        this.table.medtoolAndBHP.items = data.medical_goods.map(tool => {
          return {
            id_drugs: tool._id,
            name: `${tool.id_drugs?.name} ${tool.id_drugs?.strength} ${tool.id_drugs?.strength_unit} (${tool.id_drugs?.factory})`,
            amount: tool.amount,
            unit:
              tool.id_drugs?.packaging?.filter(x => x.isDefault)[0]
                ?.package_unit || '-',
            price: tool.price || '-',
            discount: tool.discount || 0,
            total: this.calculateTotalDiscount(tool, 'medtoolAndBHP'),
          };
        });
      } catch (error) {
        // eslint-disable-next-line
        if (error) {
          this.table.medtoolAndBHP.items = [];
          this.table.mix.items = [];
          this.table.nonmix.items = [];
        }
      } finally {
        this.isLoading.table = false;
      }
    },
    extractNumbers(str) {
      return typeof str === 'string'
        ? parseFloat(str?.replace(/\./g, '').replace(',', '.'))
        : str;
    },
    // save OTC data to dashboard
    async save() {
      try {
        this.isLoading.save = true;
        if (
          this.table.nonmix.items.length === 0 &&
          this.table.mix.items.length === 0 &&
          this.table.medtoolAndBHP.items.length === 0
        ) {
          Swal.fire({
            icon: 'warning',
            title: 'Tidak dapat menyimpan',
            text: 'Tidak ada data obat, alkes, atau BHP yang disimpan',
          });
          this.isLoading.save = false;
          return;
        }
        const nonmixValid =
          this.table.nonmix.items.length === 0 ||
          this.$refs.nonmixTable.validate();
        const mixValid =
          this.table.mix.items.length === 0 || this.$refs.mixTable.validate();
        const medtoolValid =
          this.table.medtoolAndBHP.items.length === 0 ||
          this.$refs.medtoolTable.validate();

        if (!nonmixValid || !medtoolValid || !mixValid) {
          this.isLoading.save = false;
          return;
        }
        const payload = {
          transaction_code: this.registrationCode,
          patient_name: this.input.name,
          patient_age: this.input.age || null,
          patient_gender: this.input.gender,
          doctor_name: this.input.doctor,
          additional_discount: Number(this.overallDiscount),
          total_additional_discount: Number(
            this.total * (this.overallDiscount / 100),
          ),
          non_mixed_medicine: this.table.nonmix.items.map(item => {
            return {
              discount: item.discount ? Number(item.discount) : 0,
              price: Number(item.price),
              id_drugs: item.id,
              id_stock: item.id_stock,
              name: item.drugName,
              text: item.name,
              dosage: item.dosage,
              dosage_unit: item.dosage_unit,
              unit: item.unit,
              amount: item.amount,
            };
          }),
          medical_goods: this.table.medtoolAndBHP.items.map(item => {
            return {
              discount: item.discount ? Number(item.discount) : 0,
              price: Number(item.price),
              id_drugs: item.id_drugs,
              id_goods: item.id_goods,
              id_stock: item.id_stock,
              name: item.name,
              amount: item.amount,
            };
          }),
          mixed_medicine: this.table.mix.items.map(item => {
            return {
              name: item.mixDrugName,
              amount: item.amount,
              amount_unit: item.unit,
              tuslah_price: Number(this.extractNumbers(item.tuslah || 0)),
              discount: item.discount ? Number(item.discount) : 0,
              drugs: item.drugs.map(arr => {
                return {
                  price: Number(arr.price),
                  id_drugs: arr.id,
                  id_stock: arr.id_stock,
                  name: arr.name,
                  dosage: arr.dosage,
                  dosage_unit: arr.dosage_unit,
                  amount: arr.amount,
                };
              }),
            };
          }),
          created_by: this.$store.getters.userLoggedIn.id,
        };
        await addDrugSales(payload);
        Swal.fire(
          'Data Penjualan Obat Bebas berhasil disimpan!',
          '',
          'success',
        );
        this.$emit('close-form');
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Gagal Menyimpan data penjualan obat bebas',
          });
        }
      } finally {
        this.isLoading.save = false;
      }
    },
    // change between gender
    onClickGender(gender, type) {
      if (!this.isDetail) {
        if (type === 'nonmix') this.input.gender = gender;
        if (type === 'mix') this.input.gender = gender;
        if (type === 'medtool') this.input.gender = gender;
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    // function to save mix drugs from MixDrugManufacturing component
    addMixDrug(drug) {
      this.resource.drugsMargin.push(...drug.drugsMargin);
      this.table.mix.items.push({
        mixDrugName: drug.mixDrugName,
        tuslah: this.formattedCurrency(drug.tuslah.price),
        amount: drug.amount,
        unit: drug.unit,
        price: drug.table.reduce((a, b) => +a + b.price, 0),
        total: drug.table.reduce(
          (sum, item) =>
            sum + (Number(item.price) || 0) * (Number(item.amount) || 0),
          0,
        ),
        drugs: drug.table.map(item => {
          return {
            name: item.drugName,
            preparation: item.preparation,
            dosage: item.dosage,
            dosage_unit: item.strength_unit,
            amount: item.amount,
            unit: item.strength_unit,
            id: item.id,
            id_stock: item.id_stock,
            package: item.package,
            price: item.price,
            quantity: item.quantity,
            expiry: item.expiry,
          };
        }),
      });
    },
    // delete per item at nonmix table
    deleteItem(item, type) {
      if (type === 'nonmix') {
        const index = this.table.nonmix.items.indexOf(item);
        this.table.nonmix.items.splice(index, 1);
      }
      if (type === 'mix') {
        const index = this.table.mix.items.indexOf(item);
        this.table.mix.items.splice(index, 1);
      }
      if (type === 'medtool') {
        const index = this.table.medtoolAndBHP.items.indexOf(item);
        this.table.medtoolAndBHP.items.splice(index, 1);
      }
    },
    addToListTable(type) {
      if (type === 'nonmix') {
        if (!this.input.nonmix.drug?.id) return;
        if (this.$refs.nonmix.validate()) {
          if (this.input.nonmix.amount <= this.input.nonmix.drug.quantity) {
            this.handleGetDrugMargin(this.input.nonmix.drug.id);
            this.table.nonmix.items.push({
              drugName: this.input.nonmix.drug.displayName,
              expiry: this.input.nonmix.drug.expiry,
              id: this.input.nonmix.drug.id,
              preparation: this.input.nonmix.drug.preparation,
              dosage: this.input.nonmix.drug.strength,
              dosage_unit: this.input.nonmix.drug.strength_unit,
              amount: this.input.nonmix.amount,
              unit: this.input.nonmix.drug.packaging,
              price: this.handlePriceType(this.input.nonmix.drug.price),
              id_stock: this.input.nonmix.drug.id_stock,
              name: this.input.nonmix.drug.name,
              quantity: this.input.nonmix.drug.quantity,
              total: 0,
            });
            this.input.nonmix.drug = '';
            this.input.nonmix.amount = '';
            this.$refs.nonmix.resetValidation();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: `Stok Obat ${this.input.nonmix.drug.displayName} tidak mencukupi, tersisa ${this.input.nonmix.drug.quantity}`,
            });
          }
        }
      }
      if (type === 'medtool') {
        if (!this.input.medtoolAndBHP?.item?.id_stock) return;
        if (this.$refs.medtool.validate()) {
          if (
            this.input.medtoolAndBHP.amount <=
            this.input.medtoolAndBHP.item.quantity
          ) {
            this.handleGetDrugMargin(this.input.medtoolAndBHP.item.id_drugs);
            this.table.medtoolAndBHP.items.push({
              name: this.input.medtoolAndBHP.item.displayName,
              amount: this.input.medtoolAndBHP.amount,
              unit: this.input.medtoolAndBHP.item.packaging,
              price: this.handlePriceType(this.input.medtoolAndBHP.item.price),
              expiry: this.input.medtoolAndBHP.item.expiry,
              id_drugs: this.input.medtoolAndBHP.item.id_drugs,
              id_goods: this.input.medtoolAndBHP.item.id_goods,
              id_stock: this.input.medtoolAndBHP.item.id_stock,
              strength: this.input.medtoolAndBHP.item.strength,
              strength_unit: this.input.medtoolAndBHP.item.strength_unit,
              quantity: this.input.medtoolAndBHP.item.quantity,
              total: 0,
            });
            this.input.medtoolAndBHP.item = '';
            this.input.medtoolAndBHP.amount = '';
            this.$refs.medtool.resetValidation();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: `Stok Alkes/BHP ${this.input.medtoolAndBHP.item.displayName} tidak mencukupi, tersisa ${this.input.medtoolAndBHP.item.quantity}`,
            });
          }
        }
      }
    },
    async getRegistrationCode() {
      try {
        const response = await getDrugSalesRegistrationCode();

        this.registrationCode = response.data.data;
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Gagal mendapatkan kode registrasi',
          });
        }
      }
    },
    async getDrugs(keyword) {
      this.isLoading.nonmix = true;
      try {
        const params = {
          page: 1,
          itemCount: 20,
          category: 'Obat',
          name: keyword || '',
        };

        const response = await getDrugsStock(params);

        this.resource.drugs = response.data.data.map(arr => {
          return {
            id: arr.id_drugs,
            batch: arr.batch_number,
            id_stock: arr._id,
            name: arr.drugs.detail.name,
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs.detail.strength_unit,
            strength: arr.drugs.detail.strength,
            preparation: arr.drugs.detail.preparation,
            packaging: arr.package,
            price: arr.price,
            quantity: arr.quantity,
            expiry: arr.expiry_date,
            formattedExpiryDate: moment(arr.expiry_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY',
            ),
          };
        });
      } catch (error) {
        if (error) {
          this.resource.drugs = [];
        }
      } finally {
        this.isLoading.nonmix = false;
      }
    },
    async getMedtoolAndBHP(keyword) {
      try {
        this.isLoading.medtoolAndBHP = true;

        const params = {
          page: 1,
          itemCount: 20,
          category: 'alkesbhp',
          name: keyword || '',
        };

        const response = await getDrugsStock(params);

        this.resource.medtoolAndBHP = response.data.data.map(arr => {
          return {
            id_drugs: arr.id_drugs,
            batch: arr.batch_number,
            id_goods: arr.id_goods,
            id_stock: arr._id,
            name: arr.drugs.detail.name,
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs.detail.strength_unit,
            strength: arr.drugs.detail.strength,
            preparation: arr.drugs.detail.preparation,
            packaging: arr.package,
            price: arr.price,
            quantity: arr.quantity,
            expiry: arr.expiry_date,
            formattedExpiryDate: moment(arr.expiry_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY',
            ),
          };
        });
      } catch (error) {
        if (error) {
          this.resource.medtoolAndBHP = [];
        }
      } finally {
        this.isLoading.medtoolAndBHP = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtotal {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  color: #616161;
}
.input-container {
  padding-right: 1rem;
  max-height: 67vh;
  height: 67vh;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: black;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #1976d2;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: #404040;
  }
}
.main-card {
  height: 90vh !important;
  h2 {
    font-weight: 700;
    font-size: 24px;
    color: #404040;
  }
}
.tab-font {
  font-weight: 500;
  font-size: 14px;
}
.data-input {
  display: grid;
  grid-template-columns: 40% 60%;
  row-gap: 10px;
  font-size: 0.9rem;
}
.price-box {
  width: 95%;
  display: flex;
  justify-content: space-between;
  background-color: #f4faff;
  border-radius: 4px;
  border: 0.2px solid #bdbdbd;
  padding: 8px;
  font-size: 0.9rem;
}
.input-wrapper {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .input-box {
    display: grid;
    grid-template-columns: 30% 70%;
    row-gap: 10px;
    font-size: 0.9rem;
    width: 100%;
    align-items: center;
  }
}
.required:after {
  content: ' *';
  color: red;
}

.table-style {
  ::v-deep .v-data-table-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    span {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #9e9e9e !important;
    }
    tr {
      th {
        height: 38px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #404040 !important;
        line-height: 16.8px !important;
      }
    }
  }
}
.input-end :deep(input) {
  text-align: end;
}
.input-center :deep(input) {
  text-align: center;
}
.dropdown-item:hover {
  color: #1976d2 !important;
}
</style>
