<template>
  <v-card class="py-5 main-card">
    <div class="d-flex justify-space-between mb-4 pl-4">
      <h2>Buat Obat Racik Penjualan Bebas</h2>
      <v-btn text fab width="35" height="35" @click="$emit('close-dialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-row class="px-4">
      <v-col
        cols="4"
        class="text-left"
        style="display: flex; flex-direction: column; gap: 1rem;"
      >
        <v-form ref="generalData">
          <h4>
            Pembuatan Obat Racik
          </h4>
          <div class="input-wrapper pt-3">
            <div class="input-box">
              <label class="required">
                Nama Obat Racik
              </label>
              <v-text-field
                single-line
                :rules="[v => !!v || 'Belum diisi']"
                dense
                v-model="input.mixDrugName"
              />
            </div>
            <div class="input-box">
              <label class="required">
                Jumlah
              </label>
              <v-text-field
                single-line
                type="number"
                :min="0"
                :rules="[v => !!v || 'Belum diisi']"
                dense
                v-model="input.amount"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="input-box">
              <label class="required">
                Satuan
              </label>
              <v-autocomplete
                append-icon="mdi-chevron-down"
                auto-select-first
                single-line
                dense
                :rules="[v => !!v || 'Belum diisi']"
                item-text="name"
                :items="resource.unit"
                v-model="input.unit"
              />
            </div>
          </div>
        </v-form>

        <v-form ref="tuslahData" lazy-validation>
          <div class="container-card">
            <div>
              <v-checkbox class="mt-0 pt-0" v-model="isActiveTuslah" />
            </div>
            <div style="width: 100%">
              <h4
                style="color: #404040; font-weight: 500; font-size: 14px; padding-top: 0.1rem;"
                class="mb-4"
              >
                Biaya Tuslah
              </h4>
              <div class="input-wrapper">
                <div
                  class="input-box"
                  style="grid-template-columns: 35% 65% !important;"
                >
                  <label style="font-weight: 400;">
                    Nama Layanan
                  </label>
                  <v-autocomplete
                    :disabled="!isActiveTuslah"
                    :search-input.sync="searchTuslah"
                    append-icon="mdi-chevron-down"
                    single-line
                    dense
                    item-text="name"
                    return-object
                    :items="resource.tuslahList"
                    v-model="tuslah"
                    :loading="isLoadingTuslah"
                    :rules="isActiveTuslah ? [v => !!v || 'Belum diisi'] : []"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-layout>
                            <v-flex class="d-flex justify-start">
                              <span
                                class="text-left"
                                style="color: #404040; font-weight: 400; font-size: 14px;"
                              >
                                {{ `${data.item.name || ''}` }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-layout>
                            <span
                              class="mr-4"
                              style="color: #9E9E9E; font-weight: 400; font-size: 12px;"
                            >
                              {{ currency(data.item.cost.total) }}
                            </span>
                          </v-layout>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </div>
                <div
                  class="input-box"
                  style="grid-template-columns: 35% 65% !important;"
                >
                  <label style="font-weight: 400;">
                    Biaya
                  </label>
                  <label style="font-weight: 400;">
                    {{
                      tuslah?.cost?.total ? currency(tuslah?.cost?.total) : ''
                    }}
                  </label>
                </div>
                <div
                  class="input-box"
                  style="grid-template-columns: 35% 65% !important;"
                >
                  <label style="font-weight: 400;">
                    Total Biaya
                  </label>
                  <label style="font-weight: 400;">
                    {{
                      input.amount && tuslah?.cost?.total
                        ? currency(tuslah?.cost?.total * input.amount)
                        : ''
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </v-form>

        <v-divider />

        <div class="container-card" style="display: block;">
          <v-form ref="mixdata">
            <h4>
              Tambah Bahan Obat Racik
            </h4>
            <div class="input-wrapper pt-3">
              <div class="input-box">
                <label>Nama Obat</label>
                <v-combobox
                  append-icon="mdi-chevron-down"
                  item-value="id_stock"
                  :filter="comboboxFilter"
                  single-line
                  :rules="[v => !!v || 'Belum diisi']"
                  dense
                  auto-select-first
                  :search-input.sync="searchDrug"
                  :items="resource.drugs"
                  v-model="input.material.drugName"
                  :loading="isLoading"
                >
                  <!-- :rules="rules.drug" -->
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-layout>
                          <v-flex class="d-flex justify-start">
                            <span class="text-left">
                              {{ `${data.item.text || ''}` }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-layout>
                          <span class="mr-4">{{
                            `Exp: ${data.item.formattedExpiryDate || '-'}`
                          }}</span>
                          <span class="mr-4">{{
                            `No Batch: ${data.item.batch || '-'}`
                          }}</span>
                          <span class="mr-4">{{
                            `Sisa Stok : ${data.item.quantity || ''} ${data.item
                              .packaging || ''}`
                          }}</span>
                          <span>{{ currency(data.item.price || 0) }}</span>
                        </v-layout>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </div>
              <div class="input-box">
                <label>Jenis Sediaan</label>
                <label>{{ input.material.drugName.preparation }}</label>
              </div>
              <div class="input-box">
                <label>Dosis</label>
                <v-text-field
                  :suffix="input.material.drugName.strength_unit"
                  v-model="input.material.drugName.strength"
                  hide-details
                  single-line
                  dense
                  v-on:keypress="isNumber($event)"
                />
              </div>
              <div class="input-box">
                <label>Jumlah Obat Diambil</label>
                <v-text-field
                  v-model="input.material.amount"
                  single-line
                  type="number"
                  :rules="[v => +v > 0 || 'Jumlah harus lebih dari 0']"
                  dense
                  v-on:keypress="isNumber($event)"
                />
              </div>
            </div>
          </v-form>
          <v-row>
            <v-col cols="12" class="text-right pt-8">
              <v-btn
                color="primary"
                class="font-weight-medium caption"
                outlined
                @click="addToList()"
                height="30"
                width="200"
                style="background-color: white !important"
                :loading="isLoadingGetMargin"
                ><v-icon small class="font-weight-bold">mdi-plus</v-icon>TAMBAH
                BAHAN OBAT RACIK
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="8">
        <v-form ref="tableData" lazy-validation>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            :items-per-page="table.items.length"
            height="450"
            hide-default-footer
            class="table-style"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-center">{{ index + 1 }}</td>
                <td
                  class="text-truncate text-left"
                  style="max-width: 6vw !important; "
                >
                  {{ item.drugName }}
                </td>
                <td class="text-center">{{ item.preparation }}</td>
                <td class="text-center">{{ item.dosage }}</td>
                <td class="text-center">
                  <v-text-field
                    v-model="item.amount"
                    placeholder="0"
                    single-line
                    dense
                    :rules="[
                      v => !!v || 'Belum diisi',
                      v => +v > 0 || 'Jumlah harus lebih dari 0',
                    ]"
                    validate-on-blur
                    v-on:keypress="isNumber($event)"
                  />
                </td>
                <td class="text-right">
                  <v-combobox
                    placeholder="0"
                    class="input-end"
                    append-icon="mdi-chevron-down"
                    dense
                    single-line
                    :items="
                      resource.drugsMargin.length !== 0
                        ? resource.drugsMargin.find(arr => arr.id === item.id)
                            .list
                        : []
                    "
                    prefix="Rp"
                    item-value="value"
                    return-object
                    item-text="displayValue"
                    :value="formattedValue(item)"
                    @input="val => handleInput(val, item)"
                    v-on:keypress="isNumber($event)"
                    :rules="[
                      v =>
                        (!!v && v !== 0 && v !== '0' && v !== '0,00') ||
                        'Harga harus lebih dari Rp 0',
                    ]"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-layout>
                            <v-flex class="d-flex justify-start">
                              <span
                                class="text-left"
                                style="font-size: 14px; font-weight: 400;"
                              >
                                {{ currency(data.item.value || 0) }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-layout>
                            <span style="font-size: 12px; font-weight: 400;">
                              {{ data.item.name }}
                            </span>
                          </v-layout>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </td>
                <td class="text-right">
                  {{ item.amount ? currency(item.price * item.amount) : '' }}
                </td>
                <td class="text-center">
                  <v-icon color="red" @click="deleteFromList(item)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>
    <v-footer class="d-flex justify-end" color="white">
      <div class="d-flex" style="margin-top: -30px">
        <v-btn
          style="letter-spacing:0.5px !important; min-width: fit-content !important;"
          class="mr-3 text-none"
          color="#1976D2"
          outlined
          depressed
          @click="$emit('close-dialog')"
        >
          Kembali
        </v-btn>
        <v-btn
          @click="save()"
          style="color: white !important;letter-spacing:0.5px !important; min-width: fit-content !important;"
          class="text-none"
          depressed
          color="#1976D2"
        >
          Simpan
        </v-btn>
      </div>
    </v-footer>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import formatMixin from '@/mixin/formatMixin';

// api
import { getDrugsStock } from '@/fetchApi/Stock';
import { getTuslah } from '@/fetchApi/MasterData/Service';
import { getDrugMarginById } from '@/fetchApi/DrugSales';

//utils
const moment = require('moment-timezone');
const _ = require('lodash');

export default {
  name: 'MixDrugManufacturing',
  mixins: [formatMixin],
  data() {
    return {
      isLoading: false,
      isLoadingTuslah: false,
      searchDrug: '',
      searchTuslah: '',
      input: {
        mixDrugName: '',
        amount: '',
        unit: '',
        material: {
          drugName: '',
          amount: '',
        },
      },
      isActiveTuslah: false,
      tuslah: {},
      isLoadingGetMargin: false,
      resource: {
        unit: ['Kapsul', 'Bungkus', 'Sirup', 'Salep'],
        drugs: [],
        tuslahList: [],
        drugsMargin: [],
      },
      rules: {
        drug: [v => !!v || 'Masukkan nama obat'],
        amount: [v => !!v || 'Masukkan jumlah obat'],
        mixDrugName: [v => !!v || 'Masukkan nama obat racik'],
        amountGeneral: [v => !!v || 'Masukkan jumlah obat racik'],
        unit: [v => !!v || 'Masukkan satuan obat racik'],
      },
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            sortable: false,
            width: '3%',
            class: 'text-sm-left',
          },
          {
            text: 'Nama Obat',
            value: 'drugName',
            sortable: false,
            width: '23%',
            class: 'text-sm-left',
          },
          {
            text: 'Jenis Sediaan',
            value: 'preparation',
            sortable: false,
            width: '14%',
            class: 'text-sm-center',
          },
          {
            text: 'Dosis',
            value: 'dosage',
            sortable: false,
            class: 'text-sm-center',
            width: '8%',
          },
          {
            text: 'Jumlah Obat Diambil',
            value: 'amount',
            class: 'text-sm-center',
            sortable: false,
            width: '18%',
          },
          {
            text: 'Harga',
            value: 'amount',
            class: 'text-sm-right',
            sortable: false,
            width: '17%',
          },
          {
            text: 'Sub Total',
            value: 'amount',
            class: 'text-sm-right',
            sortable: false,
            width: '15%',
          },
          {
            text: '',
            value: 'action',
            class: 'text-sm-center pr-2 px-0 mx-0',
            sortable: false,
            width: '6%',
          },
        ],
        items: [],
      },
    };
  },
  watch: {
    searchDrug(value) {
      if (value) {
        this.searchData(this);
      }
    },
    searchTuslah(value) {
      if (value) {
        this.searchDataTuslah(this);
      }
    },
  },
  mounted() {
    this.getDrugs('');
    this.getTuslahList('');
  },
  methods: {
    searchData: _.debounce(v => v.getDrugs(v.searchDrug), 650),
    searchDataTuslah: _.debounce(v => v.getTuslahList(v.searchTuslah), 650),
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        return true;
      } else if (char === '.' || char === ',') {
        e.preventDefault();
        return false;
      } else {
        e.preventDefault();
      }
    },
    formattedValue(item) {
      if (!this.table.items.find(arr => arr.id === item.id).price) return '';
      return new Intl.NumberFormat('id-ID', {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.table.items.find(arr => arr.id === item.id).price);
    },
    handleInput(value, item) {
      const dataVal = () => {
        if (typeof value === 'object' && value !== null) {
          return value.value !== null && value.value !== undefined
            ? value.value.toString()
            : '0';
        }
        if (value === undefined || value === null) {
          return '0';
        } else {
          return value.toString();
        }
      };
      const rawValue = dataVal().replace(/[^0-9]/g, '');
      this.table.items.find(arr => arr.id === item.id).price = rawValue;
    },
    handlePriceType(val) {
      switch (typeof val) {
        case 'object':
          return Number(val.value);
        case 'string':
          return parseInt(val.replace(/\./g, '').replace(',00', ''), 10);
        default:
          return Number(val);
      }
    },
    async handleGetDrugMargin(id) {
      this.isLoadingGetMargin = true;
      if (this.resource.drugsMargin.find(arr => arr.id === id)) {
        this.isLoadingGetMargin = false;
        return;
      }
      try {
        const response = await getDrugMarginById(id).finally(() => {
          this.isLoadingGetMargin = false;
        });

        this.resource.drugsMargin.push({
          id: id,
          list: response.data.data.margin.map(arr => ({
            name: arr.name,
            value: arr.price,
            displayValue: this.currencyWithoutSymbol(arr.price),
          })),
        });
      } catch (error) {
        return error;
      }
    },
    comboboxFilter(_, __, itemText) {
      return itemText;
    },
    save() {
      if (this.isActiveTuslah && !this.$refs.tuslahData.validate()) {
        return;
      }
      if (!this.$refs.tableData.validate()) {
        return;
      }
      if (this.$refs.generalData.validate()) {
        if (this.table.items.length === 0) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal',
            text: 'Tidak Ada Obat Yang Ditambahkan',
          });
        }
        if (this.table.items.length > 0) {
          const dataTable = {
            tuslah: {
              ...this.tuslah,
              isActive: this.isActiveTuslah,
              price: this.tuslah.cost?.total * Number(this.input.amount) || 0,
              priceService: this.tuslah.cost?.total,
            },
            mixDrugName: this.input.mixDrugName,
            amount: this.input.amount,
            unit: this.input.unit,
            table: this.table.items,
            drugsMargin: this.resource.drugsMargin,
          };

          this.$emit('save', dataTable);
          this.$emit('close-dialog');
        }
      }
    },
    addToList() {
      if (!this.input.material?.drugName?.id) return;
      if (this.$refs.mixdata.validate()) {
        if (
          this.input.material.amount <= this.input.material.drugName.quantity
        ) {
          this.handleGetDrugMargin(this.input.material.drugName.id);
          this.table.items.push({
            drugName: this.input.material.drugName.displayName,
            package: this.input.material.drugName.packaging,
            dosage: this.input.material.drugName.strength,
            amount: this.input.material.amount,
            id: this.input.material.drugName.id,
            id_stock: this.input.material.drugName.id_stock,
            name: this.input.material.drugName.name,
            strength_unit: this.input.material.drugName.strength_unit,
            price: +this.input.material.drugName.price,
            expiry: this.input.material.drugName.expiry,
            quantity: this.input.material.drugName.quantity,
            preparation: this.input.material.drugName.preparation,
          });
          this.input.material.drugName = '';
          this.input.material.amount = '';
          this.$refs.mixdata.resetValidation();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: `Stok Obat ${this.input.material.drugName.displayName} tidak mencukupi, tersisa ${this.input.material.drugName.quantity}`,
          });
        }
      }
    },
    deleteFromList(item) {
      const index = this.table.items.indexOf(item);
      this.table.items.splice(index, 1);
    },
    async getDrugs(keyword) {
      this.isLoading = true;
      try {
        const params = {
          page: 1,
          itemCount: 30,
          category: 'Obat',
          name: keyword || '',
        };

        const response = await getDrugsStock(params);

        this.resource.drugs = response.data.data.map(arr => {
          return {
            id: arr.id_drugs,
            batch: arr.batch_number,
            id_stock: arr._id,
            name: arr.drugs.detail?.name || '',
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs?.detail?.strength_unit || '',
            strength: arr.drugs.detail?.strength || '',
            preparation: arr.drugs.detail?.preparation || '',
            packaging: arr.package || '',
            price: arr.price || '',
            quantity: arr.quantity || '',
            expiry: arr.expiry_date || '',
            formattedExpiryDate: moment(arr.expiry_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY',
            ),
          };
        });
      } catch (error) {
        if (error) {
          this.resource.drugs = [];
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getTuslahList() {
      this.isLoadingTuslah = true;
      try {
        const response = await getTuslah({ keyword: '' });
        this.resource.tuslahList = response;
        this.isLoadingTuslah = false;
      } catch (error) {
        this.isLoadingTuslah = false;
        return error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  overflow: hidden;
  min-height: 600px;
  height: 100%;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #1976d2;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: #404040;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    color: #404040;
  }
}
.add-drug-material {
  background-color: #f4f5f5;
  padding: 15px;
  border-radius: 4px;
}
.data-input {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  row-gap: 5px;
  font-size: 0.9rem;
}

.container-card {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.input-wrapper {
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .input-box {
    display: grid;
    grid-template-columns: 40% 60%;
    row-gap: 10px;
    font-size: 0.9rem;
    width: 100%;
    align-items: center;
    height: 34px;
  }
}

.required:after {
  content: ' *';
  color: red;
}

.table-style {
  ::v-deep .v-data-table-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    span {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #9e9e9e !important;
    }
    tr {
      th {
        height: 38px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #404040 !important;
        line-height: 16.8px !important;
      }
    }
  }
}
</style>
