<template>
  <v-card class="pa-8 pt-5">
    <v-btn @click="closeDialog" text fab dense light small class="close-btn"
      ><v-icon color="#757575">mdi-close</v-icon></v-btn
    >
    <h2 class="mb-5">Tambah Data Pabrikan</h2>
    <v-form ref="form" class="grid">
      <label class="required">Nama Pabrikan</label>
      <v-text-field
        :rules="rules.required"
        v-model="input.name"
        placeholder="Masukkan nama"
        dense
        light
        single-line
        validate-on-blur
      />
      <label>Alamat</label>
      <v-textarea
        placeholder="Masukkan alamat"
        v-model="input.address"
        no-resize
        dense
        light
        outlined
        single-line
        height="100"
      />
      <label>Email</label>
      <v-text-field
        :rules="rules.email"
        v-model="input.email"
        placeholder="nama@gmail.com"
        dense
        light
        single-line
        validate-on-blur
      />
      <label>No Telp/HP</label>
      <v-text-field
        placeholder="000000000000"
        type="number"
        v-model="input.telp"
        dense
        light
        single-line
      />
    </v-form>
    <v-card-actions class="d-flex justify-end pa-0">
      <v-btn
        style="letter-spacing: 0.001px;"
        class="text-capitalize"
        color="error"
        outlined
        depressed
        light
        @click="closeDialog"
        >Batal</v-btn
      >
      <v-btn
        style="letter-spacing: 0.001px;"
        color="primary"
        depressed
        light
        class="text-capitalize"
        :loading="loading"
        @click="save"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import alertMixin from '@/mixin/alertMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapMutations } = createNamespacedHelpers('MasterDrugs');

export default {
  name: 'AddFactoryDialog',
  mixins: [alertMixin],
  data() {
    return {
      loading: false,
      input: {
        name: '',
        address: '',
        email: '',
        telp: '',
      },
      rules: {
        required: [v => !!v || 'Wajib diisi'],
        email: [v => this.isValidEmail(v) || 'Alamat email tidak valid'],
      },
    };
  },
  methods: {
    ...mapActions(['resolveAddFactory']),
    ...mapMutations(['setNewFactory']),
    closeDialog() {
      this.$emit('close-dialog');
    },
    isValidEmail(email) {
      if (!email) return true;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = {
          name: this.input.name,
          detail: {
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
            address: this.input.address,
            email: this.input.email,
            phone: this.input.telp,
          },
        };

        try {
          await this.resolveAddFactory(payload);
          this.setNewFactory(payload.name);
          this.$emit('close-dialog', 'save');
        } catch (error) {
          this.showErrorAxios(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  text-align: start;
  font-size: 0.8vw;
  column-gap: 2vw;
  margin-bottom: 1.5vw;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
