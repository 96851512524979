<template>
  <v-container class="px-4 pb-2 pt-8" fluid>
    <div class="mb-7 d-flex justify-space-between pr-8">
      <div class="d-flex text-start align-center">
        <h1>Margin dan Harga</h1>
        <span class="chip mx-3"
          >Global{{ isGlobalSpecific ? ' + Spesifik' : '' }} Setting</span
        >
        <span class="grey-light-text">
          Harga dan margin diatur
          {{
            isGlobalSpecific
              ? 'secara global untuk semua item atau disesuaikan spesifik untuk item tertentu'
              : 'untuk semua item sekaligus.'
          }}
        </span>
      </div>
      <v-btn
        color="primary"
        class="btn-edit text-capitalize"
        outlined
        v-if="isAdmin"
        depressed
        dense
        @click="openEditSetting"
      >
        Ubah Pengaturan <v-icon class="ml-2">mdi-pencil</v-icon>
      </v-btn>
    </div>
    <div class="display-price-container mb-8">
      <display-price-card :index="0" v-bind="{ ...displayPrice?.[0] }" />
      <display-price-card :index="1" v-bind="{ ...displayPrice?.[1] }" />
      <display-price-card :index="2" v-bind="{ ...displayPrice?.[2] }" />
    </div>
    <div class="d-flex justify-space-between">
      <span class="grey-dark-text">PREVIEW PERHITUNGAN</span>
      <span class="grey-light-text pr-6">
        Base Harga: <strong class="black--text">HNA</strong>
      </span>
    </div>
    <!-- TABLE -->
    <div
      :class="{ 'specific-grid': isGlobalSpecific }"
      class="grid-display py-3"
    >
      <span
        v-for="(item, index) in tableHeaders"
        :key="'header-' + index"
        :class="item.class + ' font-weight-medium'"
      >
        {{ item.text }}
      </span>
    </div>
    <v-progress-linear
      indeterminate
      v-if="getLoadingState.previewTable"
    ></v-progress-linear>
    <div class="data-table-container mb-4 py-2 px-3">
      <div
        v-for="(item, index) in table.data"
        :key="'data-' + index"
        class="grid-display row py-3"
        :class="{ 'specific-grid': isGlobalSpecific }"
      >
        <span class="text-start">{{ item.name }}</span>
        <v-tooltip v-if="isGlobalSpecific" bottom>
          <template #activator="{ on: tooltip }">
            <span
              v-on="{ ...tooltip }"
              class="text-start chip-setting"
              :class="{ specific: item.setting === 'Spesifik' }"
              >{{ item.setting }}</span
            >
          </template>
          <span>{{
            item.setting === 'Spesifik'
              ? 'Harga produk ini telah diatur secara spesifik'
              : 'Harga produk ini mengikuti aturan global yang diatur saat ini'
          }}</span>
        </v-tooltip>
        <span class="text-start">{{ item.category }}</span>
        <span class="text-end">{{ currency(item.hna) }}</span>
        <span class="text-end">{{ currency(item.hpp) }}</span>
        <span class="text-start">{{ item.margin }}%</span>
        <span class="text-start">{{ item.ppn }}%</span>
        <span class="text-end">{{ currency(item.price1) }}</span>
        <span class="text-end">{{ currency(item.price2) }}</span>
        <span class="text-end pr-3">{{ currency(item.price3) }}</span>
        <v-btn
          fab
          dense
          v-if="isGlobalSpecific && isAdmin"
          depressed
          small
          text
          @click="openEditSpecificDrug(item)"
          ><v-icon color="#757575" small>mdi-pencil</v-icon></v-btn
        >
        <v-btn
          fab
          dense
          v-if="isGlobalSpecific && !isAdmin"
          depressed
          small
          text
          @click="openEditSpecificDrug(item)"
          ><v-icon color="#757575">mdi-clipboard-text</v-icon></v-btn
        >
      </div>
      <div v-if="!table.data.length" class="grey--text">Tidak ada data</div>
    </div>
    <footer class="d-flex justify-start">
      <v-pagination
        light
        v-if="table.data.length"
        @input="getDataPreview"
        v-model="pagination.page"
        :length="pagination.pageLength"
        :total-visible="pagination.limit"
      ></v-pagination>
    </footer>
  </v-container>
</template>

<script>
import DisplayPriceCard from '../DisplayPriceCard.vue';

import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'GlobalSettingDashboard',
  mixins: [formatMixin],
  components: { DisplayPriceCard },
  data() {
    return {
      pagination: {
        page: 1,
        limit: 10,
        pageLength: 10,
      },
      table: {
        data: [],
      },
    };
  },
  mounted() {
    this.getDataPreview();
  },
  methods: {
    ...mapMutations([
      'setSelectedForm',
      'setDialog',
      'setSpecificDrug',
      'setIsEdit',
      'setEditedDataFromServer',
      'setPriceSettingDirectly',
      'setIsEditFromDashboard',
      'setSpecificDrugResult',
    ]),
    ...mapActions(['resolveGetPreviewCalculation']),
    openEditSpecificDrug(drug) {
      this.setIsEditFromDashboard(true);
      this.setSpecificDrug(drug);
      this.setDialog(true);
      this.setSelectedForm(5);
    },
    openEditSetting() {
      this.setPriceSettingDirectly(
        JSON.parse(JSON.stringify(this.getDataFromServer.nondrugs[0].margins)),
      );
      if (this.isGlobalSpecific) {
        this.setSpecificDrugResult({
          status: 'set',
          value: this.getDataFromServer.nondrugs
            .filter(item => item.id_drug)
            .map(item => {
              return {
                id: item.id_drug,
                hna: item.hna,
                margins: item.margins.map(v => ({
                  ...v,
                  sellingPrice: this.calculatePrice(item.hna, v),
                })),
              };
            }),
        });
      }
      this.setSelectedForm(this.isGlobalSpecific ? 4 : 1);
      this.setIsEdit(true);
      this.setDialog(true);
    },
    calculatePrice(hna, data) {
      if (!data?.isActive) return 0;
      const basePrice = hna;
      const marginAmount = (basePrice * +data.margin) / 100;
      const priceAfterMargin = basePrice + marginAmount;
      const taxAmount = (priceAfterMargin * +data.ppn) / 100;
      return priceAfterMargin + taxAmount;
    },
    async getDataPreview() {
      // try {
      const itemCount = 9;
      const params = {
        page: this.pagination.page,
        itemCount,
      };

      const { dataLength } = await this.resolveGetPreviewCalculation(params);
      this.pagination.pageLength = Math.ceil(dataLength / itemCount);

      const calculatePrice = (hna, index, specificIdx) => {
        if (
          !this.getDataFromServer.nondrugs[
            specificIdx === undefined ? 0 : specificIdx
          ].margins?.[index]?.isActive
        )
          return 0;
        const basePrice = hna;
        const marginAmount =
          (basePrice *
            this.getDataFromServer.nondrugs[
              specificIdx === undefined ? 0 : specificIdx
            ].margins?.[index]?.margin) /
          100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin *
            this.getDataFromServer.nondrugs[
              specificIdx === undefined ? 0 : specificIdx
            ].margins?.[index]?.ppn) /
          100;
        return priceAfterMargin + taxAmount;
      };

      this.table.data = this.getPreviewData.map(item => {
        const specificItemIndex = this.getDataFromServer.nondrugs.findIndex(
          i => i.id_drug === item.id,
        );
        if (specificItemIndex > -1) {
          return {
            id: item.id,
            name: item.name,
            category: item.category,
            setting: 'Spesifik',
            hna: item.hna,
            hpp: item.hpp,
            margin: this.getDataFromServer.nondrugs[specificItemIndex]
              .margins[0].margin,
            ppn: this.getDataFromServer.nondrugs[specificItemIndex].margins[0]
              .ppn,
            allMarginsData: this.getDataFromServer.nondrugs[specificItemIndex]
              .margins,
            price1: calculatePrice(item.hna, 0, specificItemIndex),
            price2: calculatePrice(item.hna, 1, specificItemIndex),
            price3: calculatePrice(item.hna, 2, specificItemIndex),
          };
        }
        return {
          id: item.id,
          name: item.name,
          setting: 'Global',
          category: item.category,
          hna: item.hna,
          hpp: item.hpp,
          margin: this.getDataFromServer.nondrugs[0].margins[0].margin,
          ppn: this.getDataFromServer.nondrugs[0].margins[0].ppn,
          price1: calculatePrice(item.hna, 0),
          price2: calculatePrice(item.hna, 1),
          price3: calculatePrice(item.hna, 2),
        };
      });
    },
  },
  computed: {
    ...mapGetters([
      'getPriceSetting',
      'getDataFromServer',
      'getSelectedSetting',
      'getPreviewData',
      'getLoadingState',
    ]),
    isAdmin: {
      get() {
        return this.$store.getters.userLoggedIn.role
          .map(role => role.toLowerCase())
          .some(role => ['administrator', 'kepala unit'].includes(role));
      },
    },
    isGlobalSpecific: {
      get() {
        return this.getSelectedSetting === 'global-specific-setting';
      },
    },
    tableHeaders: {
      get() {
        return this.isGlobalSpecific
          ? [
              {
                text: 'Nama Barang',
                class: 'text-start',
              },
              {
                text: 'Pengaturan Harga',
                class: 'text-start',
              },
              {
                text: 'Golongan',
                class: 'text-start',
              },
              {
                text: 'HNA',
                class: 'text-end',
              },
              {
                text: 'HPP',
                class: 'text-end',
              },
              {
                text: 'Margin',
                class: 'text-start',
              },
              {
                text: 'PPN',
                class: 'text-start',
              },
            ].concat(
              this.getDataFromServer.nondrugs[0].margins
                .map((_, index) => ({
                  text: `Harga ${index + 1}`,
                  class: `text-end ${index === 2 ? ' pr-4' : ''}`,
                }))
                .concat({
                  text: '',
                  class: '',
                }),
            )
          : [
              {
                text: 'Nama Barang',
                class: 'text-start',
              },
              {
                text: 'Golongan',
                class: 'text-start',
              },
              {
                text: 'HNA',
                class: 'text-end',
              },
              {
                text: 'HPP',
                class: 'text-end',
              },
              {
                text: 'Margin',
                class: 'text-start',
              },
              {
                text: 'PPN',
                class: 'text-start',
              },
            ].concat(
              this.getDataFromServer.nondrugs[0].margins.map((item, index) => ({
                text: item.description
                  ? item.description
                  : `Harga ${index + 1}`,
                class: `text-end ${index === 2 ? ' pr-4' : ''}`,
              })),
            );
      },
    },
    displayPrice: {
      get() {
        return this.getDataFromServer.nondrugs[0].margins;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  background-color: #f0f8fe;
  color: #1976d2;
  padding: 0.5rem 0.7rem;
  font-size: 0.8vw;
}

.chip-setting {
  background-color: #f0f8fe;
  height: fit-content;
  width: fit-content;
  padding: 0.2vw 0.6vw;
  color: #1976d2;
  display: flex;
  align-items: center;
  border-radius: 5px;

  &.specific {
    background-color: #e7f8ec;
    color: #2b9c4d;
  }
}

h1 {
  font-size: 1.4vw;
}

.btn-edit {
  border-width: 2px;
  letter-spacing: 0.001px;
  padding: 1.1rem 0.5rem !important;
  font-size: 0.9vw;
}

.display-price-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: 80%;
  column-gap: 3rem;
  align-items: end;
}

.grid-display {
  display: grid;
  grid-template-columns: 25% 12% 8% 8% 4% 4% repeat(3, calc(29% / 3));
  gap: 1rem;
  font-size: 1rem;
  color: #9e9e9e;
  border-bottom: 2px solid #e0e0e0;
  font-size: 0.9rem;
  align-items: center;

  &.specific-grid {
    grid-template-columns: 22% 9% 6% 8% 8% 4% 4% repeat(3, calc(22% / 3)) 3%;
  }

  &.row {
    border-bottom: 2px solid #ededed;
    color: #404040;
  }
}

.data-table-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 45vh;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}

.grey-dark-text {
  color: #616161;
}

.grey-light-text {
  color: #9e9e9e;
}
</style>
