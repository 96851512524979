var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-4 pb-10 pt-5",class:{ active: _vm.isActive },attrs:{"elevation":"0"}},[_c('v-form',{ref:"form"},[(_vm.isAdmin)?[(_vm.index === 0)?_c('p',{staticClass:"grey-light-text"},[_vm._v("HARGA DEFAULT")]):_c('v-checkbox',{staticClass:"mb-4 pa-0",attrs:{"label":'Harga ' + (_vm.index + 1),"hide-details":"","single-line":"","dense":"","value":true},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})]:[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('h4',[_c('strong',[_vm._v(_vm._s(_vm.index ? `Harga ${_vm.index + 1}` : 'Harga Default'))])]),_c('v-card',{class:`status-card ${_vm.isActive ? 'active' : ''}`,attrs:{"elevation":"0","rounded":"lg","color":_vm.isActive ? '#e7f8ec' : '#ffebee'}},[_c('span',[_vm._v(_vm._s(_vm.isActive ? 'Aktif' : 'Tidak Aktif'))]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":_vm.isActive ? '#2b9c4d' : '#d32f2f'}},[_vm._v(_vm._s(_vm.isActive ? 'mdi-check' : 'mdi-close'))])],1)],1)],_c('p',{staticClass:"grey-dark-text mb-0 font-weight-bold",class:{ 'disable-text': !_vm.isAdmin }},[_vm._v(" Base Harga ")]),_c('div',{staticClass:"d-flex align-baseline"},[_c('p',{staticClass:"grey-dark-text mr-3",class:{ 'disable-text': !_vm.isAdmin }},[_vm._v(" HNA ")]),_c('v-text-field',{attrs:{"single-line":"","dense":"","hide-details":"","value":_vm.hna,"disabled":""}})],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-10"},[_c('p',{class:{
            required: _vm.isActive && _vm.isAdmin,
            'disable-text': !_vm.isAdmin,
          }},[_vm._v(" Margin ")]),_c('v-text-field',{attrs:{"disabled":!_vm.isActive || !_vm.isAdmin,"rules":_vm.rulesByCondition
              ? [
                  _vm.rulesByCondition.noEmptyRule,
                  _vm.rulesByCondition.max,
                  _vm.rulesByCondition.noIdenticalMargin[_vm.index],
                ].concat(..._vm.additionalRules)
              : [],"placeholder":"-","single-line":"","type":"number","dense":"","suffix":"%"},model:{value:(_vm.margin),callback:function ($$v) {_vm.margin=$$v},expression:"margin"}})],1),_c('div',[_c('p',{class:{
            required: _vm.isActive && _vm.isAdmin,
            'disable-text': !_vm.isAdmin,
          }},[_vm._v(" PPN ")]),_c('v-text-field',{attrs:{"type":"number","disabled":!_vm.isActive || !_vm.isAdmin,"rules":_vm.rulesByCondition
              ? [_vm.rulesByCondition.noEmptyRule, _vm.rulesByCondition.max].concat(
                  ..._vm.additionalRules,
                )
              : [],"placeholder":"-","single-line":"","dense":"","suffix":"%"},model:{value:(_vm.ppn),callback:function ($$v) {_vm.ppn=$$v},expression:"ppn"}})],1)]),_c('p',{staticClass:"grey-dark-text font-weight-bold",class:{ 'disable-text': !_vm.isAdmin }},[_vm._v(" Harga Jual ")]),_c('v-text-field',{staticClass:"mb-5",attrs:{"single-line":"","dense":"","hide-details":"","value":_vm.sellingPrice,"disabled":""}}),_c('p',{staticClass:"grey-dark-text font-weight-bold",class:{ required: _vm.isActive && _vm.isAdmin, 'disable-text': !_vm.isAdmin }},[_vm._v(" Deskripsi Harga "+_vm._s(_vm.index === 0 ? 'Default' : _vm.index + 1)+" ")]),_c('v-text-field',{attrs:{"single-line":"","dense":"","rules":_vm.rulesByCondition ? [_vm.rulesByCondition.noEmptyRule] : [],"disabled":!_vm.isActive || !_vm.isAdmin,"placeholder":"contoh: Harga Retail"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }