<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="d-flex px-7 pt-6 justify-space-between align-baseline">
      <h2>Penjualan Obat Bebas</h2>
      <div class="d-flex justify-space-between align-center">
        <v-combobox
          append-icon="mdi-chevron-down"
          :items="resource.status"
          placeholder="Status Pembayaran"
          @change="
            () => {
              table.pagination.page = 1;
              getOtcData();
            }
          "
          hide-details
          single-line
          outlined
          class="input-filter mr-4"
          dense
          flat
          clearable
          return-object
          item-text="text"
          v-model="filter.status"
        >
        </v-combobox>
        <v-menu
          offset-y
          :close-on-content-click="false"
          v-model="datepicker"
          ref="menu"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-4 input-filter date"
              dense
              flat
              v-on="on"
              v-bind="attrs"
              readonly
              append-icon="mdi-chevron-down"
              placeholder="Rentang Tanggal"
              v-model="date.formatted"
              :value="date.formatted"
              single-line
              clearable
              @change="
                () => {
                  table.pagination.page = 1;
                  getOtcData();
                }
              "
              @click:clear="clearDate()"
              hide-details
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            :max="today"
            multiple
            v-model="selectedDate"
            @input="changedate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="datepicker = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(date);
                getOtcData();
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <router-link :to="routeBack" active>
          <font-awesome-icon icon="home" />
        </router-link>
        <v-icon class="ml-4" :class="{ refresh: isRefresh }" @click="refresh()"
          >mdi-autorenew</v-icon
        >
        <v-text-field
          background-color="grey lighten-3"
          placeholder="Cari Pasien..."
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </div>
    </v-row>
    <v-row class="py-0 px-4">
      <v-col class="text-left">
        <span class="font-weight-medium caption grey--text"
          >Dapat membeli resep obat racik dan non racik</span
        >
      </v-col>
    </v-row>
    <v-row class="px-4 mb-5 table-container">
      <v-col>
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :expanded="expanded"
          @click:row="clickedRow"
          single-expand
          class="table"
          item-key="no"
          :items-per-page="itemsPerPage"
          :loading="isLoading"
          hide-default-footer
        >
          <template v-slot:[`item.billing`]="{ item }">
            <tr class="text-left">
              <td>
                {{ currency(item.billing) }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="my-border px-0">
              <v-card flat elevation="0">
                <v-row no-gutters class="d-flex justify-space-between">
                  <div class="caption grey--text text-left pl-15">
                    {{ item.gender ? 'Laki-laki' : 'Perempuan' }} <br />
                    {{ item.age }} Tahun
                  </div>
                  <span class="caption grey--text pr-2">{{
                    `Dipesan pada ${item.date} Pukul ${item.time} WIB`
                  }}</span>
                </v-row>
              </v-card>
              <v-card
                color="grey lighten-4"
                class="text-right"
                elevation="0"
                flat
              >
                <v-container class="px-0 mx-0" style="overflow-x: hidden">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="onClickedButton(0, true, item.id)"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                          >
                            <font-awesome-icon icon="prescription-bottle" />
                          </v-btn>
                        </template>
                        <span>Detail Pesanan</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            @click="printInvoice(item)"
                          >
                            <font-awesome-icon icon="print" />
                          </v-btn>
                        </template>
                        <span>Print Nota</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-footer color="white" class="d-flex justify-center">
      <v-pagination
        light
        v-model="table.pagination.page"
        :length="pageLength"
        total-visible="5"
        @input="getOtcData()"
      >
      </v-pagination>
    </v-footer>
    <v-btn
      color="primary"
      @click="onClickedButton(0)"
      fab
      class="btn-add"
      width="45"
      height="45"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent>
      <Inputotc
        v-if="selectedForm === 0 && dialog"
        :isDetail="isDetail"
        :id="idotc"
        @close-form="closeForm()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Inputotc from './OverTheCounter/InputOTC.vue';
import Constant from '@/const';
import axios from 'axios';
import moment from 'moment-timezone';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import printMixin from '@/mixin/printMixin';
import formatMixin from '@/mixin/formatMixin';

const _ = require('lodash');

export default {
  name: 'OverTheCounter',
  mixins: [formatMixin, printMixin],
  components: { Inputotc },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      search: '',
      idotc: '',
      isLoading: false,
      isRefresh: false,
      today: moment().format('YYYY-MM-DD'),
      datepicker: false,
      tab: null,
      expanded: [],
      pageLength: 0,
      dialog: false,
      selectedForm: null,
      isDetail: false,
      selectedDate: [],
      resource: {
        status: [
          {
            text: 'Lunas',
            value: 1,
          },
          {
            text: 'Belum Lunas',
            value: 0,
          },
        ],
      },
      filter: { status: '' },
      date: {
        raw: [],
        formatted: '',
      },
      table: {
        pagination: {
          page: 1,
        },
        items: [],
        headers: [
          {
            text: 'No',
            value: 'no',
            width: '3%',
            sortable: false,
          },
          {
            text: 'Nama',
            value: 'name',
            width: '35%',
            sortable: false,
          },
          {
            text: 'Nomor Registrasi',
            value: 'registrationNumber',
            width: '15%',
            sortable: false,
          },
          {
            text: 'Jumlah Tagihan',
            value: 'billing',
            width: '16.5%',
            sortable: false,
          },
          {
            text: 'Nama Petugas',
            value: 'staff',
            width: '25.5%',
            sortable: false,
          },
        ],
      },
    };
  },
  watch: {
    search() {
      this.table.pagination.page = 1;
      this.searchData(this);
    },
    'filter.status'(value) {
      if (!value) {
        this.getOtcData();
      }
    },
    selectedDate() {
      this.getOtcData();
    },
    date() {
      this.getOtcData();
    },
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeBack() {
      return `/farmasi/${this.routeController}`;
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat inap';

        case 'rawat-jalan':
          return 'rawat jalan';

        case 'igd':
          return 'IGD';

        default:
          return '';
      }
    },
    itemsPerPage() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  mounted() {
    this.changedate([moment().format('YYYY-MM-DD')]);
    this.getOtcData();
    this.date;
  },
  methods: {
    printInvoice(item) {
      // try {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const leftMargin = 1.0;

      doc.setProperties({
        title: `Nota Obat ${item.name}`,
      });

      doc.setFontSize(12);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'Nota Penjualan Obat Bebas', 1.9);
      doc.autoTable({
        body: [
          [
            'Tanggal Transaksi',
            ':',
            `${moment(item.dateRaw).format('DD/MM/YYYY')}`,
          ],
          ['No Transaksi', ':', `${item.registrationNumber}`],
        ],
        startY: 2,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.4 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 3 },
        },
        margin: {
          left: leftMargin,
        },
        theme: 'plain',
      });

      doc.autoTable({
        body: [
          ['Nama', ':', `${item.name}`],
          ['Nama Petugas', ':', `${item.staff}`],
        ],
        startY: 2,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.3 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3.5,
        },
        theme: 'plain',
      });

      doc.autoTable({
        head: [['Nama', 'Jumlah', 'Harga', 'Diskon', 'Subtotal']],
        body: item.drugList,

        startY: 2.8,
        styles: {
          fontSize: 10,
          lineWidth: 0.001,
          lineColor: 'black',
          textColor: 'black',
        },
        columnStyles: {
          0: { cellWidth: 2 },
          1: { cellWidth: 0.7 },
          2: { cellWidth: 1.2 },
          3: { cellWidth: 1 },
          4: { cellWidth: 1.2 },
        },
        headStyles: {
          fillColor: '#f2f8fc',
          halign: 'center',
        },
        margin: {
          left: leftMargin,
          right: leftMargin,
        },
        theme: 'grid',
      });
      const lastTable = doc.autoTable.previous.finalY;
      doc.autoTable({
        body: [
          [
            `${
              this.$store.getters.userLoggedIn.clinic.clinicRegion
            }, ${moment().format('DD/MM/YYYY hh.mm')}`,
          ],
          ['Administrasi Billing'],
          [],
          [],
          [`${this.$store.getters.userLoggedIn.name}`],
        ],
        startY: lastTable + 0.2,
        styles: {
          halign: 'center',
          minCellHeight: 0.01,
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          ['Total', `${this.currency(item.billing)}`],
          ['Total Diskon', `${this.currency(item.totalDiscount)}`],
          [
            'Total Setelah Diskon',
            `${this.currency(item.billingData.total_bill)}`,
          ],
          [
            'Dana Kebajikan',
            `${this.currency(item.billingData.rounding_price)}`,
          ],
          [],
          ['Total Bayar', `${this.currency(item.billingData.total_paid)}`],
          ['Sisa Tagihan', `${this.currency(item.billingData.remaining_bill)}`],
          [
            'Pembayaran',
            `${this.currency(
              item.billingData.payment && item.billingData.payment.length > 0
                ? item.billingData.payment[item.billingData.payment.length - 1]
                    .amount
                : 0,
            )}`,
          ],
          ['Kembalian', `${this.currency(item.billingData.total_return)}`],
        ],
        startY: lastTable + 0.2,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.8 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          [
            'Total Tagihan',
            `${this.currency(
              item.billingData.rounding_price + item.billingData.total_bill,
            )}`,
          ],
        ],
        startY: lastTable + 1.45,
        styles: {
          align: 'left',
          fontSize: 11,
          fontStyle: 'bold',
        },
        columnStyles: {
          0: { cellWidth: 1.8 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          [
            'Status Tagihan',
            `${
              item.billingData.is_paid
                ? 'Lunas'.toUpperCase()
                : 'Belum Lunas'.toUpperCase()
            }`,
          ],
        ],
        startY: lastTable + 3,
        styles: {
          align: 'left',
          fontSize: 11,
          fontStyle: 'bold',
        },
        columnStyles: {
          0: { cellWidth: 1.8 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3,
        },
        theme: 'plain',
      });

      // Letter Head
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }

      // print action
      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    refresh() {
      this.isRefresh = true;
      this.getOtcData();
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getOtcData();
    }, 650),
    clearDate() {
      this.date.raw = [];
      this.selectedDate = [];
      this.getOtcData();
    },
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.date = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.datepicker = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.date = {
          raw: [data[0], data[0]],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
    },
    async getOtcData() {
      this.isLoading = true;
      let startDate = '';
      let endDate = '';
      if (this.date.raw.length > 0) {
        startDate = moment(this.date.raw[0]).format('DD-MM-YYYY');
        endDate =
          this.date.raw[1] == ''
            ? moment().format('DD-MM-YYYY')
            : moment(this.date.raw[1]).format('DD-MM-YYYY');
      }
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/drugssales?page=${this.table.pagination.page}&itemCount=${
              this.itemsPerPage
            }&search=${this.search}&isPaid=${
              this.filter?.status?.value === 0 ||
              this.filter?.status?.value === 1
                ? this.filter.status.value
                : ''
            }&startDate=${startDate}&endDate=${endDate}&type=${this.routeName}`,
          ),
        );
        const { data } = response.data;
        const { length: dataLength } = response.data;
        this.pageLength = Math.ceil(dataLength / this.itemsPerPage);
        this.table.items = data.map((item, index) => {
          let nonmix,
            mix,
            medtool,
            nonmixDiscount,
            mixDiscount,
            medtoolDiscount;
          nonmixDiscount = item.id_billing.detail.nonracik.map(v => v.discount);
          mixDiscount = item.id_billing.detail.racik.map(v => v.discount);
          medtoolDiscount = item.id_billing.detail.consumables.map(
            v => v.discount,
          );
          const totalDiscount = [
            ...nonmixDiscount,
            ...mixDiscount,
            ...medtoolDiscount,
          ];
          nonmix = item.id_billing.detail.nonracik.map(v => {
            return [
              v.id_drugs.detail[0].name,
              v.amount,
              this.currency(v.price),
              this.currency(v.discount),
              this.currency(v.price * v.amount - v.discount),
            ];
          });
          mix = item.id_billing.detail.racik.map(v => {
            return [
              v.name,
              v.amount,
              this.currency(v.price),
              this.currency(v.discount),
              this.currency(v.price * v.amount - v.discount),
            ];
          });
          medtool = item.id_billing.detail.consumables.map(v => {
            return [
              v.id_drugs.detail[0].name,
              v.amount,
              this.currency(v.price),
              this.currency(v.discount),
              this.currency(v.price * v.amount - v.discount),
            ];
          });
          return {
            billingData: item.id_billing,
            totalDiscount: totalDiscount.reduce((a, b) => +a + +b, 0),
            drugList: [...nonmix, ...mix, ...medtool],
            no:
              index +
              this.itemsPerPage * this.table.pagination.page -
              this.itemsPerPage +
              1,
            id: item._id,
            name: item.patient_name,
            registrationNumber: item.transaction_code,
            billing: item.total_price,
            staff: item.timestamps.created_by.detail[0].name,
            age: item.patient_age,
            gender: item.patient_gender,
            date: moment(item.timestamps.created_at)
              .locale('id')
              .format('DD MMMM YYYY'),
            dateRaw: item.timestamps.created_at,
            time: moment(item.timestamps.created_at).format('HH:mm'),
          };
        });
      } catch (error) {
        if (error) {
          this.table.items = [];
        }
      } finally {
        this.isLoading = false;
        this.isRefresh = false;
      }
    },
    clickedRow(value) {
      if (this.expanded.length && this.expanded[0].number == value.number) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    onClickedButton(value, isDetail = false, id) {
      this.changeForm(value);
      this.dialog = true;
      this.isDetail = isDetail;
      this.idotc = id;
    },
    closeForm() {
      this.dialog = false;
      this.isDetail = false;
      this.getOtcData();
    },
    changeForm(value) {
      this.selectedForm = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  min-height: 62vh; // force pagination stay at bottom
}

.input-filter {
  width: 200px;

  &.date {
    min-width: 0px;
    width: 250px;
  }
}

.table {
  overflow-x: hidden;
}

.my-border {
  background-color: white;
}

.btn-add {
  position: absolute;
  bottom: 6vh;
  right: 3vw;
}

.refresh {
  animation: rotation 0.23s infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::v-deep .v-data-table-header {
  background-color: #f4f5f5;
  border-bottom-color: #9ca2a5;

  span {
    color: rgb(56, 55, 55);
  }
}
</style>
