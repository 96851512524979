var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container text-start"},[(_vm.getLoadingState.dashboard)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{staticClass:"mt-5",attrs:{"indeterminate":"","size":"100"}})],1):[(
        (_vm.getSelectedSetting === 'global-setting' ||
          _vm.getSelectedSetting === 'global-specific-setting') &&
          _vm.isRenderDashboard
      )?_c('global-setting-dashboard'):_vm._e(),(
        (_vm.getSelectedSetting === 'global-category-setting' ||
          _vm.getSelectedSetting === 'category-setting') &&
          _vm.isRenderDashboard
      )?_c('category-setting-dashboard'):_vm._e(),(!_vm.isRenderDashboard)?[_c('h1',{staticClass:"pt-8"},[_vm._v("Margin dan Harga")]),_c('p',{staticClass:"pb-5"},[_vm._v(" Silakan pilih level setting dan harga terlebih dahulu ")]),_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"letter-spacing":"0.001px"},attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.openDialog(0)}}},[_vm._v(" Mulai "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-arrow-right")])],1)]:_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":_vm.dialogWidth[_vm.selectedForm] || '100vw',"transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog && _vm.selectedForm === 0)?_c('setting-option-dialog',{on:{"start-setting":_vm.startSetting}}):_vm._e(),(_vm.dialog && _vm.selectedForm === 1)?_c('setting-global-margin-and-price-dialog'):_vm._e(),(_vm.dialog && _vm.selectedForm === 2)?_c('global-margin-and-price-calculation-dialog',{on:{"finish-setting":_vm.finishSetting}}):_vm._e(),(_vm.dialog && _vm.selectedForm === 3)?_c('setting-category-margin-and-price-dialog',{on:{"finish-setting":_vm.finishSetting}}):_vm._e(),(_vm.dialog && _vm.selectedForm === 4)?_c('global-category-setting-dialog',{on:{"finish-setting":_vm.finishSetting}}):_vm._e(),(_vm.dialog && _vm.selectedForm === 5)?_c('specific-form',{on:{"finish-setting":_vm.finishSetting}}):_vm._e()],1),_c('v-snackbar',{attrs:{"timeout":4000,"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.snackbar.text))]),_c('v-icon',{staticClass:"ml-auto",attrs:{"dark":"","small":""},on:{"click":() => (_vm.snackbar.show = false)}},[_vm._v(" mdi-close ")])],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }