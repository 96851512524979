<template>
  <v-card>
    <v-container fluid>
      <div class="sticky">
        <div class="d-flex justify-space-between align-center px-4">
          <h2 class="heading_big mt-3">Pembuatan Obat dan Penyiapan Alkes</h2>
          <v-btn @click="$emit('close-form')" text fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <pharmacy-header />
      </div>

      <div class="after-sticky">
        <v-card flat class="body-container">
          <span class="title pb-2 center-width">Pembuatan Obat Non Racik</span>
          <skeleton :isLoading="isLoading">
            <v-data-table
              :headers="nonRacikTable.headers"
              :items="nonmix"
              :items-per-page="nonmix?.length"
              hide-default-footer
              class="center-width"
            >
              <template slot="no-data">
                <span style="color: black;">Tidak ada obat yang dipesan</span>
              </template>
              <template v-slot:[`item.no`]="{ item }">
                <tr class="text-left">
                  <td>
                    {{ nonmix.indexOf(item) + 1 }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.shownName`]="{ item }">
                <span>{{ item.drugs.name }}</span>
                <v-icon
                  small
                  color="red"
                  v-show="!item.isAvailable"
                  class="pl-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex align-center">
                  <v-simple-checkbox
                    v-model="item.status"
                    color="success"
                    class="d-inline"
                  >
                  </v-simple-checkbox>
                  <v-tooltip bottom>
                    <template #activator="{on: tooltip}">
                      <v-btn v-on="{ ...tooltip }" icon>
                        <v-icon @click="openDialog('nonmix', item)">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ubah obat</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </skeleton>

          <span class="title pb-2 center-width">Pembuatan Obat Racik</span>
          <skeleton :isLoading="isLoading">
            <v-data-table
              :headers="racikTable.headers"
              :items="mix"
              :items-per-page="mix?.length"
              hide-default-footer
              class="center-width"
              ><template slot="no-data">
                <span style="color: black;">Tidak ada obat yang dipesan</span>
              </template>
              <template v-slot:[`item.no`]="{ item }">
                <tr class="text-left">
                  <td>
                    {{ mix.indexOf(item) + 1 }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.drugs`]="{ item }">
                <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
                  <td class="py-1">{{ drug.id_drugs.name }}</td>
                  <v-icon
                    small
                    color="red"
                    v-show="!drug.available_stock"
                    class="pl-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                </tr>
              </template>
              <template v-slot:[`item.preparation`]="{ item }">
                <tr
                  v-for="(preparation, index) in item.drugs"
                  :key="'drug-' + index"
                >
                  <td class="py-1">{{ preparation.preparation }}</td>
                </tr>
              </template>
              <template v-slot:[`item.dosage`]="{ item }">
                <tr
                  v-for="(dosage, index) in item.drugs"
                  :key="'dosage-' + index"
                >
                  <td class="py-1">
                    {{ dosage.dosage_needed + ' ' + dosage.unit_dosage }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.requested_quantity`]="{ item }">
                <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
                  <td class="py-1">{{ drug.requested_quantity }}</td>
                </tr>
              </template>
              <template v-slot:[`item.drugQuantity`]="{ item }">
                <tr v-for="(drug, index) in item.drugs" :key="'drug-' + index">
                  <td class="py-1">{{ drug.quantity }}</td>
                </tr>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex align-center">
                  <v-simple-checkbox
                    v-model="item.status"
                    color="success"
                    class="d-inline"
                  >
                  </v-simple-checkbox>
                  <v-tooltip bottom>
                    <template #activator="{on: tooltip}">
                      <v-btn v-on="{ ...tooltip }" icon>
                        <v-icon @click="openDialog('mix', item)">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ubah obat</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </skeleton>
        </v-card>

        <v-card flat class="body-container before-sticky">
          <span class="title pb-2 center-width">Penyiapan Alkes/BHP</span>
          <skeleton :isLoading="isLoading">
            <v-data-table
              :headers="consumableTable.headers"
              :items="medtools"
              :items-per-page="medtools?.length"
              hide-default-footer
              class="center-width"
              ><template slot="no-data">
                <span style="color: black;"
                  >Tidak ada pesanan alat kesehatan pada pasien ini</span
                >
              </template>
              <template v-slot:[`item.no`]="{ item }">
                <tr class="text-left">
                  <td>
                    {{ medtools.indexOf(item) + 1 }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex align-center">
                  <v-simple-checkbox
                    v-model="item.status"
                    color="success"
                    class="d-inline"
                  >
                  </v-simple-checkbox>
                  <v-tooltip bottom>
                    <template #activator="{on: tooltip}">
                      <v-btn v-on="{ ...tooltip }" icon>
                        <v-icon @click="openDialog('medtool', item)">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ubah alkes</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </skeleton>
        </v-card>
      </div>

      <!-- <v-card flat class="body-container"> </v-card> -->
    </v-container>
    <v-card-actions class="action-layout pt-3 footer-sticky center-width">
      <v-btn
        depressed
        tile
        class="text-none"
        v-if="tab !== 2"
        color="primary"
        outlined
        @click="printTicket()"
        ><font-awesome-icon icon="print" />&nbsp;Cetak E-Ticket Obat
      </v-btn>
      <v-spacer />
      <div class="total-price">
        <label for="total-price" class="font-weight-bold subtitle-2 mr-lg-16"
          ><v-icon class="mr-2">mdi-shopping-outline</v-icon> Total Harga Obat
          dan Alkes</label
        >
        <v-spacer></v-spacer>
        <label for="total-price" class="font-weight-bold subtitle-2">{{
          currency(totalPrice || 0)
        }}</label>
      </div>
      <div class="action-footer">
        <v-btn
          @click="saveData()"
          :class="
            `text-capitalize ml-3 ${isSaveable ? 'save-btn' : 'disabled-btn'}`
          "
          depressed
          :loading="postLoading"
        >
          Selesaikan
        </v-btn>
      </div>
    </v-card-actions>
    <v-dialog persistent v-model="dialog" :max-width="dialogWidth" presistent>
      <DrugEdit
        v-if="dialogType === 'nonmix'"
        :id_pharmacy="patient.id"
        :id_recipe="patient.id_recipe"
        @close-form="closeDialog()"
        dialog-type="creation"
      />
      <DrugEditMix
        v-if="dialogType === 'mix'"
        :id_pharmacy="patient.id"
        :id_recipe="patient.id_recipe"
        @close-form="closeDialog()"
        dialog-type="creation"
      />
      <MedToolEdit
        v-if="dialogType === 'medtool'"
        :id_pharmacy="patient.id"
        :id_recipe="patient.id_recipe"
        :item="selectedDrug"
        @close-form="closeDialog()"
        dialog-type="creation"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import printMixin from '@/mixin/printMixin';
import formatMixin from '@/mixin/formatMixin';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';
import printDrugsEticket from '@/mixin/printDrugsEticket';
import Skeleton from '../../../SharedComponent/Skeleton.vue';
import PharmacyHeader from '../HeaderInfo.vue';
import {
  // getRecipeByIdAndCategory,
  // getPatientPharmacy,
  updatePatientPharmacy,
} from '@/fetchApi/Pharmacy';
const {
  mapActions: mapActionsHeader,
  mapMutations: mapMutationsHeader,
} = createNamespacedHelpers('HeaderPharmacyStore');
import DrugEdit from '../DrugEdit.vue';
import DrugEditMix from '../DrugEditMix.vue';
import MedToolEdit from '../MedToolEdit.vue';

// import { updatePatientPharmacy } from '@/fetchApi/Pharmacy';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  name: 'DrugManuEdit',
  mixins: [printMixin, formatMixin, printDrugsEticket, alertMixin],
  components: { Skeleton, PharmacyHeader, DrugEdit, DrugEditMix, MedToolEdit },
  props: {
    patient: Object,
  },
  data() {
    return {
      postLoading: false,
      dialog: false,
      dialogType: '',
      // totalPrice: 0,
      isLoading: false,
      drugList: [],
      patient_status: '',
      tab: null,
      show: false,
      selectedDrug: null,
      nonRacikTable: {
        headers: [
          { sortable: false, text: 'No', align: 'start', value: 'no' },
          { sortable: false, text: 'Nama Obat', value: 'shownName' },
          { sortable: false, text: 'Jenis Sediaan', value: 'preparation' },
          { sortable: false, text: 'Dosis', value: 'dosage' },
          { sortable: false, text: 'Jumlah', value: 'quantity' },
          { sortable: false, text: 'Satuan', value: 'preparation' },
          { sortable: false, text: 'Aturan Pakai', value: 'usage' },
          { sortable: false, text: 'Aturan Umum', value: 'general_rule' },
          {
            sortable: false,
            text: 'Jumlah Diberikan',
            value: 'quantity',
          },
          { sortable: false, text: 'Selesai', value: 'status', width: '6%' },
        ],
        item: [],
      },
      racikTable: {
        headers: [
          { sortable: false, text: 'No', align: 'start', value: 'no' },
          { sortable: false, text: 'Nama Obat Racik', value: 'name' },
          { sortable: false, text: 'Jumlah', value: 'quantity' },
          { sortable: false, text: 'Satuan', value: 'packaging' },
          { sortable: false, text: 'Aturan Pakai', value: 'usage' },
          { sortable: false, text: 'Nama Obat', value: 'drugs' },
          { sortable: false, text: 'Jenis Sediaan', value: 'preparation' },
          { sortable: false, text: 'Dosis', value: 'dosage' },
          {
            sortable: false,
            text: 'Jumlah Digunakan',
            value: 'requested_quantity',
          },
          {
            sortable: false,
            text: 'Jumlah Ditagihkan',
            value: 'drugQuantity',
          },
          { sortable: false, text: 'Selesai', value: 'status', width: '6%' },
        ],
        item: [],
      },
      consumableTable: {
        headers: [
          {
            sortable: false,
            text: 'No',
            align: 'start',
            value: 'no',
            width: '4%',
          },
          { sortable: false, text: 'Nama Alkes/BHP', value: 'name' },
          {
            sortable: false,
            text: 'Jumlah barang diberikan',
            value: 'amountGiven',
          },
          { sortable: false, text: 'Satuan', value: 'unit' },
          { sortable: false, text: 'Selesai', value: 'status', width: '6%' },
        ],
        item: [],
      },
      data: [],
      recipe_price: [],
      mix_recipe_price: [],
      medical_tools_price: [],
      menuSelected: 1,
    };
  },
  computed: {
    ...mapGetters([
      'getPatientData',
      'getRecipe',
      'getMedTools',
      'getTotalPrice',
    ]),
    totalPrice: {
      get() {
        return this.getTotalPrice;
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
    medtools: {
      get() {
        return this.getMedTools.item;
      },
    },
    nonmix: {
      get() {
        return this.getRecipe?.list?.filter(drug => !drug.isRacik);
      },
    },
    mix: {
      get() {
        return this.getRecipe?.list?.filter(drug => drug.isRacik);
      },
    },
    dialogWidth() {
      if (this.dialogType !== 'medtool') {
        return '60%';
      } else {
        return '30%';
      }
    },
    isDrugsReady() {
      return (
        this.mix.some(item => item.status) ||
        this.nonmix.some(item => item.status) ||
        this.medtools.some(item => item.status)
      );
    },
    isDrugsComplete() {
      return (
        this.mix.every(item => item.status) &&
        this.nonmix.every(item => item.status) &&
        this.medtools.every(item => item.status)
      );
    },
    allergyList() {
      let allergy = this.data.allergy ? this.data.allergy : 'Tidak ada alergi';
      return allergy;
    },
    isSaveable() {
      return (
        this.nonmix.find(i => i.status) ||
        this.mix.find(i => i.status) ||
        this.medtools.find(i => i.status)
      );
    },
  },
  beforeDestroy() {
    this.clearStateHeader();
  },
  methods: {
    ...mapMutations(['clearState', 'mapDrugDataToForm']),
    ...mapActions(['resolveGetPharmacyPatientData', 'resolveGetDrugs']),
    ...mapActionsHeader([
      'resolveGetAllergiesPatient',
      'resolveGetPatientData',
    ]),
    ...mapMutationsHeader(['clearStateHeader']),
    async getAllergyPatient() {
      try {
        await this.resolveGetAllergiesPatient(this.patient.id_emr);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async getHeaderInformation() {
      try {
        await this.resolveGetPatientData(this.patient.id_registration);
      } catch (error) {
        this.showErrorAxios(error);
      }
      this.clearState('recipe');
      this.clearState('form');
    },
    openDialog(type, item) {
      this.dialog = true;
      this.dialogType = type;
      if (type === 'medtool') {
        this.selectedDrug = item;
      } else {
        this.mapDrugDataToForm(item.idx);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.dialogType = '';
      this.getPatientPharmacyData();
    },
    async printTicket() {
      const data_patient = {
        name: this.patientData.name,
        rmNumber: this.patientData.rmNumber,
        birthDate: this.patientData.birthDate,
      };
      const doc = this.printEticket(data_patient, this.getRecipe.list);
      this.printDocument(doc, {
        docName: 'E-Tiket Obat',
        rmNumber: this.patient.rm_number,
      });
    },
    async getPatientPharmacyData() {
      this.isLoading = true;
      try {
        await this.resolveGetPharmacyPatientData(this.patient.id).finally(
          () => (this.isLoading = false),
        );
        await this.resolveGetDrugs('production');
      } catch (error) {
        this.isLoading = false;
        this.showErrorAxios(error);
      }
    },
    async saveData() {
      this.postLoading = true;
      if (!this.isSaveable) return;
      if (!this.isDrugsComplete) {
        Swal.fire({
          title:
            '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Checklist Obat Belum Lengkap</p></div>',
          iconHtml:
            '<span style="color: #f2994a;" class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%;text-transform: none"><p>Anda bisa menyimpan ini terlebih dahulu dan</p><p>menyelesaikannya nanti</p></div>',
          showCancelButton: true,
          customClass: {
            cancelButton: 'button-error',
          },
          cancelButtonText: 'Batal',
          confirmButtonText: 'Simpan Sebagai Draft',
          reverseButtons: true,
        }).then(result => {
          if (result.isConfirmed) {
            this.putDrugReview();
          } else {
            this.postLoading = false;
          }
        });
      } else {
        this.putDrugReview();
      }
    },
    async putDrugReview() {
      try {
        const recipe = this.nonmix.map(item => {
          return {
            _id: item.id,
            isApproved: true,
            isCreated: item.status,
            isDelivered: false,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });

        const mix_recipe = this.mix.map(item => {
          return {
            _id: item.id,
            name: item.name,
            isApproved: true,
            isCreated: item.status,
            isDelivered: false,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });

        const medical_tools = this.medtools.map(item => {
          return {
            name: item.name,
            _id: item?._id || item?.id,
            isApproved: true,
            isCreated: item.status,
            isDelivered: false,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });
        const payload = {
          status: {
            type: 'handover',
            id_staff: this.$store.getters.userLoggedIn.id,
          },
          recipe: recipe,
          mix_recipe: mix_recipe,
          medical_tools: medical_tools,
          patient_status: this.patient_status,
          id_staff: this.$store.getters.userLoggedIn.id,
        };
        const response = await updatePatientPharmacy({
          payload,
          id: this.patient.id,
        });
        if (response.status === 200 || response.status === 204) {
          this.$emit('close-form');
          this.$emit('show-snackbar', {
            show: true,
            text: this.isDrugsComplete
              ? 'Pembuatan obat berhasil diselesaikan'
              : 'Draft berhasil disimpan',
            color: '#31b057',
          });
        }
        this.postLoading = false;
      } catch (error) {
        this.postLoading = false;
        if (error?.response?.status === 400) {
          Swal.fire({
            icon: 'warning',
            title: 'Terdapat Obat yang tidak cukup atau habis',
            text: 'Silahkan cek kembali dan pilih obat lainnya',
            confirmButtonText: 'Kembali',
          });
          this.getPatientPharmacyData();
          return;
        }
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal',
            text: 'Pembuatan Obat belum berhasil tersimpan',
          });
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // this.getPatient();
      this.getAllergyPatient();
      this.getHeaderInformation();
      this.getPatientPharmacyData();
    });
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  width: 100%;
  background-color: #ffffff !important;
}

.footer-sticky {
  width: 100%;
  position: fixed;
  bottom: -30px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
}

.after-sticky {
  padding: 0 1vw;
}

.body-container {
  margin-top: 10px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .v-data-table-header {
    background-color: #f4f5f5;
  }
}

.total-price {
  padding: 0.8rem;
  height: 2vw;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  display: flex;
  align-items: center;
  background-color: #f0f9ff;
}

.title {
  text-align: left;
  font-size: 16px !important;
  color: #137bc0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.action-layout {
  display: flex;
  .action-footer {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: black;
}

.disabled-btn {
  background-color: #9e9e9e !important;
  color: white;
}

.save-btn {
  background-color: #137bc0 !important;
  color: white;
}
</style>
