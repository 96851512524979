<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      Detail Obat
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row no-gutters>
          <v-col cols="6" class="mt-4 px-8">
            <div class="detail-input detail-input__height">
              <div class="detail-input__label">
                <label> Jenis Obat</label>
              </div>
              <div class="detail-input__text-label">
                <label>Non Racik</label>
              </div>
            </div>

            <div class="detail-input">
              <div class="detail-input__label">
                <label class="required">Nama Obat</label>
              </div>
              <v-combobox
                ref="drugName"
                class="detail-input__input-text"
                dense
                single-line
                placeholder="Pilih Obat"
                return-object
                item-value="id_stock"
                :items="resource.drugs"
                v-model="drug.drug"
                @change="mapPackage()"
                :loading="loading.drug"
                :search-input.sync="search"
                :readonly="
                  !isEditable || ['creation', 'hand-over'].includes(dialogType)
                "
                :rules="
                  isEditable
                    ? [
                        v =>
                          (v?.stock >= Number(drug.amount_given) &&
                            v?.stock !== 0) ||
                          'Obat tidak cukup',
                      ]
                    : []
                "
              >
                <!-- :rules="[v => v.stock > drug.amount_given || 'Obat tidak cukup']" -->
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-layout>
                        <v-flex d-flex justify-start>
                          <span class="text-left">
                            {{ `${data.item.text}` }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-layout>
                        <span class="mr-4">
                          Exp:
                          {{ data.item.expiryDateFormatted }}
                        </span>
                        <span class="mr-4">
                          No Batch: {{ data.item.batchNumber }}
                        </span>
                        <span class="mr-4">{{
                          `Sisa Stok : ${data.item.stock} ${data.item.packaging}`
                        }}</span>
                        <span>{{ currency(data.item.price || 0) }}</span>
                      </v-layout>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </div>
            <div class="detail-input detail-input__height">
              <div class="detail-input__label">
                <label>Dosis</label>
              </div>
              <div class="detail-input__text-label">
                <label
                  >{{ drug.drug?.strength || '' }}
                  {{ drug.drug?.strength_unit || '' }} ml</label
                >
              </div>
            </div>
            <div class="detail-input detail-input__height">
              <div class="detail-input__label">
                <label>Jumlah Diminta</label>
              </div>
              <div class="detail-input__text-label">
                <label>{{ drug.amount_requested }}</label>
              </div>
            </div>
            <div class="detail-input detail-input__height">
              <div class="detail-input__label">
                <label class="required">Jumlah Diberikan</label>
              </div>
              <v-text-field
                dense
                type="number"
                v-model="drug.amount_given"
                :readonly="!isEditable"
                :rules="rules.required"
              />
            </div>
          </v-col>
          <v-col cols="6" class="px-8">
            <div class="detail-input">
              <div class="detail-input__label">
                <label class="required">Aturan Pakai</label>
              </div>
              <div class="detail-input__input-text">
                <v-row no-gutters>
                  <v-col cols="1">
                    <v-radio-group
                      v-model="drug.radio_usage"
                      :readonly="!isEditable"
                    >
                      <v-radio class="mb-5" :value="false"></v-radio>
                      <v-radio :value="true"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="11" style="margin-top: 12px;">
                    <div class="usage-rules">
                      <v-combobox
                        :readonly="!isEditable"
                        :disabled="drug.radio_usage"
                        v-model="drug.usage.dropdownInput.day"
                        class="pt-0 mt-0 mb-5 pt-2"
                        append-icon="mdi-chevron-down"
                        auto-select-first
                        dense
                        :items="[
                          '1',
                          '2',
                          '3',
                          '4',
                          '5',
                          '6',
                          '7',
                          '8',
                          '9',
                          '10',
                        ]"
                      ></v-combobox>
                      <span>x</span>
                      <v-combobox
                        :readonly="!isEditable"
                        v-model="drug.usage.dropdownInput.usage"
                        :disabled="drug.radio_usage"
                        append-icon="mdi-chevron-down"
                        class="pt-0 mt-0 mb-5 pt-2"
                        dense
                        auto-select-first
                        :items="[
                          '1/4',
                          '1/3',
                          '1/2',
                          '2/3',
                          '3/4',
                          '1',
                          '2',
                          '3',
                          '4',
                          '5',
                        ]"
                      ></v-combobox>
                      <v-combobox
                        :readonly="!isEditable"
                        v-model="drug.usage.dropdownInput.package"
                        :disabled="drug.radio_usage"
                        class="pt-0 mt-0 mb-5 pt-2"
                        dense
                        auto-select-first
                        append-icon="mdi-chevron-down"
                        clearable
                        :items="resource.packaging"
                      >
                      </v-combobox>
                    </div>
                    <div class="usage-rules2">
                      <v-text-field
                        :readonly="!isEditable"
                        :disabled="!drug.radio_usage"
                        class="pt-2 mt-0"
                        dense
                        v-model="drug.usage.manualInput.usage"
                      ></v-text-field>
                      <v-combobox
                        :readonly="!isEditable"
                        v-model="drug.usage.manualInput.package"
                        :disabled="!drug.radio_usage"
                        class="pt-0 mt-0 mb-5 pt-2"
                        dense
                        auto-select-first
                        append-icon="mdi-chevron-down"
                        clearable
                        :items="resource.packaging"
                      >
                      </v-combobox>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label>Aturan Umum</label>
              </div>
              <v-autocomplete
                :readonly="!isEditable"
                v-model="drug.general_rule"
                class="detail-input__input-text"
                dense
                single-line
                placeholder="Pilih Aturan Umum"
                :items="resource.general_rules"
              />
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label>Rute</label>
              </div>
              <v-autocomplete
                :readonly="!isEditable"
                v-model="drug.route"
                class="detail-input__input-text"
                dense
                single-line
                placeholder="Pilih Aturan Umum"
                :items="resource.route"
              />
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label>Aturan Lainnya</label>
              </div>
              <v-text-field
                :readonly="!isEditable"
                dense
                v-model="drug.description"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-row no-gutters class="d-flex justify-end mb-10">
        <div class="price-text">
          <label class="price-text__label">Harga Obat</label>
          <label class="price-text__price">{{ currency(price) }}</label>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="d-flex justify-space-between py-3">
        <v-btn
          class="text-none action-button action-button__back"
          text
          @click="close()"
        >
          Kembali
        </v-btn>

        <div v-if="isEditable === true">
          <v-btn
            class="text-none"
            color="error"
            @click="isEditable = false"
            outlined
          >
            Batal
          </v-btn>
          <v-btn
            class="text-none action-button"
            color="primary"
            @click="saveDrug()"
          >
            Simpan Perubahan
          </v-btn>
        </div>
        <v-btn
          v-else
          class="text-none action-button"
          color="primary"
          @click="isEditable = true"
        >
          Ubah
        </v-btn>
      </v-row>
    </v-card-text>
    <v-snackbar
      absolute
      timeout="4100"
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getDrugsStock } from '@/fetchApi/Stock';
import { updatePharmacyDrug } from '@/fetchApi/Pharmacy';
import resources from '@/helper/pharmacyResources.js';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';

const moment = require('moment-timezone');
const _ = require('lodash');
const { mapGetters } = createNamespacedHelpers('DrugRequestStore');

export default {
  mixins: [formatMixin],
  props: {
    id_pharmacy: {
      type: String,
      default: null,
    },
    id_recipe: {
      type: String,
      default: null,
    },
    dialogType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rules: {
        required: [v => !!v || 'Wajib diisi'],
      },
      snackbar: {
        show: false,
        color: '',
        text: '',
      },
      isEditable: false,
      search: '',
      loading: {
        drug: false,
      },
      resource: {
        drugs: [],
        packaging: resources.packaging.nonRacik,
        general_rules: resources.general_rules,
        route: resources.route,
      },
    };
  },
  mounted() {
    this.getDrugs();
  },
  computed: {
    ...mapGetters(['getForm']),
    drug: {
      get() {
        return this.getForm.nonMix;
      },
    },
    price: {
      get() {
        return this.drug.pricePerItem * this.drug.amount_given;
      },
    },
  },
  watch: {
    'drug.amount_given': {
      handler() {
        return this.$refs.drugName.validate();
      },
    },
    search() {
      this.searchDrug(this);
    },
  },
  methods: {
    searchDrug: _.debounce(v => v.getDrugs(v.search), 600),
    close() {
      this.$emit('close-form');
    },
    mapPackage() {
      this.drug.usage.dropdownInput.package = this.drug.drug?.preparation;
    },
    async getDrugs(keyword = '') {
      try {
        this.loading.drug = true;
        const params = {
          page: 1,
          itemCount: 25,
          category: 'Obat',
          name: keyword || '',
        };
        const response = await getDrugsStock(params);
        const { data } = response.data;
        this.resource.drugs = data.map(arr => {
          return {
            id: arr.id_drugs,
            batchNumber: arr.batch_number,
            id_stock: arr._id,
            name: arr.drugs.detail.name,
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs.detail.strength_unit,
            strength: arr.drugs.detail.strength,
            dosage_needed: arr.drugs.detail.strength,
            preparation: arr.drugs.detail.preparation,
            packaging: arr.package,
            price: arr.price,
            pricePerItem: arr.price,
            expiry: arr.expiry_date,
            expiryDateFormatted: moment(arr.expiry_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY',
            ),
            stock: arr.quantity,
          };
        });
      } catch (error) {
        this.resource.drugs = [];
      } finally {
        this.loading.drug = false;
      }
    },

    async saveDrug() {
      try {
        if (!this.$refs.form.validate()) return;

        const { drug } = this.drug;
        const payload = {
          id_recipe: this.id_recipe,
          type: 'nonmix',
          recipe_number: this.drug.recipe_number,
          name: drug.name,
          preparation: drug.preparation,
          dosage: drug.strength,
          unit_dosage: drug.strength_unit,
          requested_quantity: this.drug.amount_requested,
          quantity: this.drug.amount_given,
          packaging: drug.packaging,
          description: this.drug.description,
          usage: !this.drug.radio_usage
            ? `${this.drug.usage.dropdownInput.day} x ${this.drug.usage.dropdownInput.usage} ${this.drug.usage.dropdownInput.package}`
            : `${this.drug.usage.manualInput.usage} ${this.drug.usage.manualInput.package}`,
          general_rule: this.drug.general_rule,
          route: this.drug.route,
          id_drugs: drug.id,
          id_stock: drug.id_stock,
          expiry: drug.expiry_date,
          price: drug.pricePerItem,
          availbility_stock: drug.stock,
          available_stock: true,
        };
        await updatePharmacyDrug(this.id_pharmacy, payload);
        this.isEditable = false;
        await this.showSnackbar();
      } catch (error) {
        Swal.fire(`Terjadi kesalahan, gagal mengubah obat`, '', 'error');
      }
    },
    async showSnackbar(error = false) {
      this.snackbar = {
        text: !error
          ? 'Data obat non racik berhasil diubah'
          : 'Data obat non racik tidak berhasil diubah',
        show: true,
        color: !error ? 'success' : 'error',
      };
      setTimeout(() => {
        this.snackbar.show = false;
      }, 4000);
      return;
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  src="../../../sass/pharmacy/editPharmacy.scss"
></style>
