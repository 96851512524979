<template>
  <v-card>
    <v-card-title
      class="justify-space-between"
      style="padding: 1rem 1.25rem !important;"
    >
      <h3>Detail Penerimaan Barang Supplier</h3>
      <v-btn icon depressed>
        <v-icon @click="onCloseButtonClick()">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-container fluid class="container-layout">
      <v-row no-gutters class="mb-4">
        <v-col class="text-left" cols="4">
          <div class="detail-order">
            <label>Nomor Pemesanan</label>
            <span class="text-uppercase">{{ selectedData.orderNumber }}</span>

            <label>Nomor Tanda Terima</label>
            <span class="text-uppercase">{{ selectedData.receiptNumber }}</span>

            <label>Nomor Faktur</label>
            <span class="text-uppercase">{{ selectedData.invoiceNumber }}</span>

            <label>Nama Supplier</label>
            <span>{{ selectedData.supplierName }}</span>
          </div>
        </v-col>
        <v-col class="text-left" cols="4">
          <div class="detail-order">
            <label>Tanggal Pemesanan</label>
            <span>{{ selectedData.orderDate }}</span>

            <label>Tanggal Penerimaan</label>
            <span>{{ selectedData.receiptDate }}</span>

            <label>Tanggal Jatuh Tempo</label>
            <span>{{ selectedData.dueDateFormatedLong }}</span>

            <label>Metode Pembayaran</label>
            <span class="text-capitalize">{{
              selectedData.paymentMethod
            }}</span>
          </div>
        </v-col>
        <v-col class="text-left" cols="4">
          <div class="detail-order">
            <label>Biaya Ongkir</label>
            <span>Rp {{ formatNumber(selectedData.deliveryFee || 0) }} </span>

            <label>Biaya Materai</label>
            <span>Rp {{ formatNumber(selectedData.stampCost || 0) }} </span>

            <label>PPN</label>
            <span>Rp {{ formatNumber(selectedData.vat || 0) }} </span>

            <label>Total Akhir</label>
            <span>Rp {{ formatNumber(selectedData.total || 0) }}</span>
          </div>
        </v-col>
      </v-row>

      <v-tabs height="40" v-model="tabs" grow>
        <v-tab class="text-none">Detail Penerimaan</v-tab>
        <v-tab class="text-none">Riwayat Penerimaan</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <ReceiveDetail
            :isLoading="isLoading"
            :allStaff="staffList"
            :items="data2"
            :fileIdentifier="
              `${selectedData.orderNumber}${selectedData.invoiceNumber}${selectedData.receiptNumber}`
            "
            @get-data="this.getDataById"
          />
        </v-tab-item>
        <v-tab-item>
          <HistoryDetail
            :allStaff="staffList"
            :files="files"
            :historyData="historyData"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-card>
</template>

<script>
import ReceiveDetail from './DetailComponent/ReceiveDetail.vue';
import HistoryDetail from './DetailComponent/HistoryDetail.vue';
import Constant from '@/const';
import moment from 'moment-timezone';

const axios = require('axios');

export default {
  name: 'DetailItemReceipt',
  props: {
    selectedData: {
      type: Object,
    },
  },
  components: {
    ReceiveDetail,
    HistoryDetail,
  },
  data() {
    return {
      data2: [],
      files: [],
      resource: {
        paymentMethod: [
          { text: 'Cash', value: 'cash' },
          { text: 'Tempo', value: 'tempo' },
          { text: 'Konsinyasi', value: 'konsinyasi' },
          { text: 'Hibah', value: 'hibah' },
        ],
        reason: [
          'Jumlah Barang Tidak Sesuai',
          'Penggantian Nomor Faktur',
          'Belum Ada Nomor Faktur',
        ],
      },
      tabs: null,
      data: [],
      historyData: [],
      staffList: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getDataById();
    // this.getStaff();
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    getStaff() {
      axios.get(Constant.apiUrl.concat(`/master/staff`)).then(response => {
        const { data } = response.data;
        this.staffList = data.map(staff => {
          return {
            name: staff.detail.name,
            id: staff._id,
          };
        });
      });
    },
    getDataById() {
      this.isLoading = true;
      this.historyData = [];
      try {
        axios
          .get(
            Constant.apiUrl
              .concat('/supply/itemlist/')
              .concat(this.selectedData.id),
          )
          .then(response => {
            const { data } = response.data;
            this.files = data[0].files.map(file => {
              return {
                ...file,
                invoiceNumber: this.selectedData.invoiceNumber,
              };
            });
            this.data2 = data[0].item.supply_item.map(item => ({
              itemName:
                item?.drugs[0]?.detail[0]?.name ||
                item?.goods[0]?.detail[0]?.name,
              receiveAmount: item.factory.reduce((total, item) => {
                return (
                  total +
                  item.batch.reduce(
                    (batchTotal, batch) => batchTotal + batch.received_amount,
                    0,
                  )
                );
              }, 0),
              unmatchAmount: item.amount.unfit_amount,
              orderQuantity: item.amount.ordered_amount,
              package: item.factory[0].package,
              manufacturers: item.factory.map(arr => ({
                manufacturerName: arr.factory,
                packageType: arr.package,
                batch: arr.batch.map(batch => ({
                  batchNumber: batch.batch_number,
                  expiryDate: moment(batch.expiry_date, 'YYYY-MM-DD').format(
                    'DD-MM-YYYY',
                  ),
                  receivedQuantity: batch.received_amount,
                  bonusQuantity: batch.hibah_amount,
                })),
                unitPrice: arr.price.price_per_unit,
                discount: arr.price.discount,
                subTotal: arr.price.sub_total,
                isDiscountPercentage: false,
              })),
            }));
            this.data = data[0].item.supply_item.map(item => {
              if (item.goods.length > 0) {
                return {
                  id: item._id,
                  history: item.history.map(hist => {
                    return {
                      ...hist,
                      created_at: moment(hist.created_at).format(
                        'DD/MM/YYYY HH:mm',
                      ),
                    };
                  }),
                  name: item.goods[0].detail[0]?.name,
                  package:
                    item.goods[0].detail[0]?.packaging.length > 0
                      ? item.goods[0].detail[0]?.packaging.filter(
                          i => i.isDefault,
                        )[0].package
                      : '',
                  orderAmount: item.amount.ordered_amount,
                  receiveAmount: item.amount.unfit_amount
                    ? Math.abs(
                        item.amount.ordered_amount - item.amount.unfit_amount,
                      )
                    : item.amount.ordered_amount,
                  unmatchAmount: item.amount.unfit_amount
                    ? item.amount.unfit_amount
                    : '-',
                  returnReason: item.return_reason,
                  // expiredDate: item?.stocks[0]?.expiry_date
                  //   ? moment(item?.stocks[0]?.expiry_date).format('DD/MM/YYYY')
                  //   : '-',
                  // batch: item?.stocks[0]?.batch_number,
                  // unitPrice: item.price.price_per_unit,
                  // discount: item.price.discount,
                  // subtotal: item.price.sub_total,
                  hibah: item.isHibah,
                  isHold: !item.isComplete,
                  orderDate: this.selectedData.orderDate,
                  invoiceNumber: this.selectedData.invoiceNumber,
                  receiptNumber: this.selectedData.receiptNumber,
                };
              }
              if (item.drugs.length > 0) {
                return {
                  id: item._id,
                  history: item.history.map(hist => {
                    return {
                      ...hist,
                      created_at: moment(hist.created_at).format(
                        'DD/MM/YYYY HH:mm',
                      ),
                    };
                  }),
                  name: item.drugs[0].detail[0]?.name,
                  package:
                    item.drugs[0].detail[0]?.packaging.length > 0
                      ? item.drugs[0].detail[0]?.packaging.filter(
                          i => i.isDefault,
                        )[0].package
                      : '',
                  orderAmount: item.amount.ordered_amount,
                  receiveAmount: item.amount.unfit_amount
                    ? Math.abs(
                        item.amount.ordered_amount - item.amount.unfit_amount,
                      )
                    : item.amount.ordered_amount,
                  unmatchAmount: item.amount.unfit_amount
                    ? item.amount.unfit_amount
                    : '-',
                  returnReason: item.return_reason,
                  // expiredDate: item?.stocks[0]?.expiry_date
                  //   ? moment(item?.stocks[0]?.expiry_date).format('DD/MM/YYYY')
                  //   : '-',
                  // batch: item?.stocks[0]?.batch_number,
                  // unitPrice: item.price.price_per_unit,
                  // discount: item.price.discount,
                  // subtotal: item.price.sub_total,
                  hibah: item.isHibah,
                  isHold: !item.isComplete,
                  orderDate: this.selectedData.orderDate,
                  invoiceNumber: this.selectedData.invoiceNumber,
                  receiptNumber: this.selectedData.receiptNumber,
                };
              }
            });

            // data[0].item.supply_item.forEach(item => {

            //   const history = item.history.map(i => {
            //     if (item.goods.length > 0) {
            //       return {
            //         receiveDate: moment(i.created_at).format('DD/MM/YYYY'),
            //         receiveTime: moment(i.created_at).format(
            //           'DD/MM/YYYY HH:mm',
            //         ),
            //         invoiceNumber: i.invoice_number,
            //         name: item.goods[0].detail[0]?.name,
            //         receiveAmount: Math.abs(i.received_amount),
            //         batch: i.batch_number,
            //         expiredDate: i.expiry_date
            //           ? moment(i.expiry_date).format('DD/MM/YYYY')
            //           : '-',
            //         staffName: i.created_by,
            //         status: !i.isComplete ? 'Belum Selesai' : 'Selesai',
            //         package: i.package ? i.package : '-',
            //       };
            //     }
            //     if (item.drugs.length > 0) {
            //       return {
            //         receiveDate: moment(i.created_at).format('DD/MM/YYYY'),
            //         receiveTime: moment(i.created_at).format(
            //           'DD/MM/YYYY HH:mm',
            //         ),
            //         invoiceNumber: i.invoice_number,
            //         name: item.drugs[0].detail[0]?.name,
            //         receiveAmount: Math.abs(i.received_amount),
            //         batch: i.batch_number,
            //         expiredDate: i.expiry_date
            //           ? moment(i.expiry_date).format('DD/MM/YYYY')
            //           : '-',
            //         staffName: i.created_by,
            //         status: !i.isComplete ? 'Belum Selesai' : 'Selesai',
            //         package: i.package ? i.package : '-',
            //       };
            //     }
            //   });
            //   this.historyData.push(...history);

            // });
            this.historyData = data.map(arr => ({
              receiveTime: moment(arr.timestamps.created_at).format(
                'DD/MM/YYYY HH:mm',
              ),
              invoiceNumber: arr.invoice_number,
              staffName: arr.item.supply_item[0].timestamps.created_by_name,
              items: arr.item.supply_item.map(supply => ({
                itemName:
                  supply?.drugs[0]?.detail[0]?.name ||
                  supply?.goods[0]?.detail[0]?.name,
                receiveAmount: supply.factory.reduce((total, item) => {
                  return (
                    total +
                    item.batch.reduce(
                      (batchTotal, batch) => batchTotal + batch.received_amount,
                      0,
                    )
                  );
                }, 0),
                package: supply.factory[0].package,
                status: supply.isComplete,
                manufacturers: supply.factory.map(manufacture => ({
                  manufacturerName: manufacture.factory,
                  packageType: manufacture.packageType,
                  batch: manufacture.batch.map(itemBatch => ({
                    batchNumber: itemBatch.batch_number,
                    expiryDate: moment(
                      itemBatch.expiry_date,
                      'YYYY-MM-DD',
                    ).format('DD-MM-YYYY'),
                    receivedQuantity: itemBatch.received_amount,
                    bonusQuantity: itemBatch.hibah_amount,
                  })),
                })),
              })),
            }));
          })
          .finally(() => {
            this.isLoading = false;
          });
      } catch (error) {
        return error;
        // this.historyData = [];
      }
    },
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',00';
    },
  },
};
</script>

<style lang="scss" scoped>
.container-layout {
  padding: 1rem 1.25rem !important;
  height: 82vh;
  overflow: auto;
}
.detail-order {
  display: grid;
  grid-template-columns: 13vw 13vw;
  grid-row-gap: 2.5vh;
  label {
    font-weight: 500;
    font-size: 14px;
    color: #404040;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #404040;
  }
}
</style>
