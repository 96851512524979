<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      Detail Obat
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6" class="mt-4 px-8">
          <div class="detail-input">
            <div class="detail-input__label">
              <label> Jenis Obat</label>
            </div>
            <div class="detail-input__text-label">
              <label>Racik</label>
            </div>
          </div>
          <div class="detail-input">
            <div class="detail-input__label">
              <label class="required">Nama Obat</label>
            </div>
            <v-text-field
              dense
              v-model="item.name"
              :rules="rules.required"
              :readonly="!isEditable"
            />
          </div>
          <div class="detail-input ingredient">
            <mix-ingredients-form :addColor="true" ref="mixIngredients" />
          </div>
        </v-col>
        <v-col cols="6" class="mt-4 px-8">
          <div class="detail-input detail-input__height">
            <div class="detail-input__label">
              <label>Jumlah Diminta</label>
            </div>
            <div class="detail-input__text-label">
              <label>{{ item.amount_requested }}</label>
            </div>
          </div>
          <div class="detail-input detail-input__height">
            <div class="detail-input__label">
              <label class="required">Jumlah Diberikan</label>
            </div>
            <v-text-field
              type="number"
              dense
              v-model="item.quantity"
              @keyup="
                updateMixDrugQuantity({
                  dosage: getForm.mix.drugs[page].dosage_needed,
                  index: undefined,
                })
              "
              :rules="rules.required"
              :readonly="!isEditable"
            />
          </div>
          <div class="detail-input">
            <div class="detail-input__label">
              <label class="required">Aturan Pakai</label>
            </div>
            <div class="detail-input__input-text">
              <v-row no-gutters>
                <v-col cols="12" style="margin-top: 12px;">
                  <div class="usage-rules">
                    <v-combobox
                      :readonly="!isEditable"
                      class="pt-0 mt-0 mb-5 pt-2"
                      append-icon="mdi-chevron-down"
                      auto-select-first
                      dense
                      v-model="item.usage.day"
                      :items="[
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                      ]"
                    ></v-combobox>
                    <span>x</span>
                    <v-combobox
                      :readonly="!isEditable"
                      append-icon="mdi-chevron-down"
                      class="pt-0 mt-0 mb-5 pt-2"
                      dense
                      auto-select-first
                      :items="[
                        '1/4',
                        '1/3',
                        '1/2',
                        '2/3',
                        '3/4',
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                      ]"
                      v-model="item.usage.usage"
                    ></v-combobox>
                    <v-combobox
                      :readonly="!isEditable"
                      class="pt-0 mt-0 mb-5 pt-2"
                      dense
                      auto-select-first
                      append-icon="mdi-chevron-down"
                      clearable
                      :items="resource.packaging"
                      v-model="item.usage.package"
                    >
                    </v-combobox>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="detail-input">
            <div class="detail-input__label">
              <label>Rute</label>
            </div>
            <v-autocomplete
              class="detail-input__input-text"
              dense
              single-line
              placeholder="Pilih Rute"
              :items="resource.route"
              v-model="item.route"
              :readonly="!isEditable"
            />
          </div>
          <div class="detail-input">
            <div class="detail-input__label">
              <label>Aturan Lainnya</label>
            </div>
            <v-text-field
              dense
              v-model="item.general_rules"
              :readonly="!isEditable"
            />
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="d-flex justify-end mb-10">
        <div class="price-text">
          <label class="price-text__label">Harga Obat</label>
          <label class="price-text__price">{{ currency(price) }}</label>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="d-flex justify-space-between py-3">
        <v-btn
          class="text-none action-button action-button__back"
          text
          @click="close()"
        >
          Kembali
        </v-btn>
        <div v-if="isEditable === true">
          <v-btn
            class="text-none"
            color="error"
            @click="isEditable = false"
            outlined
          >
            Batal
          </v-btn>
          <v-btn
            class="text-none action-button"
            color="primary"
            @click="saveDrug()"
          >
            Simpan Perubahan
          </v-btn>
        </div>
        <v-btn
          v-else
          class="text-none action-button"
          color="primary"
          @click="activeEdit()"
        >
          Ubah
        </v-btn>
      </v-row>
    </v-card-text>
    <v-snackbar
      absolute
      timeout="4100"
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import MixIngredientsForm from '../Submenu/DrugToolsRequest/component/DrugForm/MixIngredientsForm.vue';
import resources from '@/helper/pharmacyResources.js';
import formatMixin from '@/mixin/formatMixin';

import { updatePharmacyDrug } from '@/fetchApi/Pharmacy';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  components: { MixIngredientsForm },
  mixins: [formatMixin],
  props: {
    id_recipe: {
      type: String,
      default: null,
    },
    id_pharmacy: {
      type: String,
      default: null,
    },
    dialogType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rules: {
        required: [v => !!v || 'Wajib diisi'],
      },
      snackbar: {
        show: false,
        color: '',
        text: '',
      },
      isEditable: false,
      input: {
        name: '',
        radio_usage: 0,
        usage: {
          day: '',
          usage: '',
          package: '',
          manualUsage: '',
          manualPackage: '',
        },
        general_rules: '',
      },
      resource: {
        drugs: [],
        packaging: resources.packaging.nonRacik,
        route: resources.route,
      },
    };
  },
  computed: {
    ...mapGetters(['getForm', 'getDrugReview', 'getPage']),
    page: {
      get() {
        return this.getPage;
      },
    },
    item: {
      get() {
        return this.getForm.mix;
      },
    },
    price: {
      get() {
        return this.item.drugs.reduce((a, { detail }) => a + detail.price, 0);
      },
    },
    isSaved: {
      get() {
        return this.getDrugReview.isSaved;
      },
      set(value) {
        this.setDrugReview({ label: 'isSaved', value });
      },
    },
    formType: {
      get() {
        return this.getDrugReview.formType;
      },
      set(value) {
        this.setDrugReview({ label: 'formType', value });
      },
    },
    refs: {
      get() {
        return {
          ref: this.$refs,
        };
      },
    },
  },
  watch: {
    'item.quantity'() {
      for (let i of this.$refs.mixIngredients.$refs.formDrugs) {
        i.validate();
      }
    },
  },
  mounted() {
    this.isSaved = true;
    this.formType = this.dialogType;
  },
  methods: {
    ...mapMutations(['updateMixDrugQuantity', 'setDrugReview']),
    close() {
      this.$emit('close-form');
    },
    formValidation() {
      for (let i of this.$refs.mixIngredients.$refs.formDrugs) {
        if (i.validate() === false) return false;
      }
      return true;
    },
    activeEdit() {
      this.isEditable = true;
      this.isSaved = false;
    },
    async showSnackbar(error = false) {
      this.snackbar = {
        text: !error
          ? 'Data obat racik berhasil diubah'
          : 'Data obat racik tidak berhasil diubah',
        show: true,
        color: !error ? 'success' : 'error',
      };
      setTimeout(() => {
        this.snackbar.show = false;
      }, 4000);
      return;
    },
    async saveDrug() {
      try {
        if (!this.formValidation()) return;

        const payload = {
          drugs: this.getForm.mix.drugs.map(i => {
            return {
              available_stock: true,
              availability_stock: i.detail.quantity,
              preparation: i.detail.preparation,
              dosage: i.dosage_needed,
              dosage_needed: i.dosage_needed,
              name: i.detail.text,
              unit_dosage: i.detail.strength_unit,
              requested_quantity: i.amount_requested,
              quantity: i.amount_given,
              id_drugs: i.detail.id,
              id_stock: i.detail.id_stock,
              price: i.detail.pricePerItem,
            };
          }),
          id_recipe: this.id_recipe,
          type: 'mix',
          recipe_number: this.item.recipe_number,
          name: this.item.name,
          quantity: this.item.quantity,
          usage: `${this.item.usage.day} x ${this.item.usage.usage} ${this.item.usage.package}`,
          route: this.item.route,
          price: this.getForm.mix.drugs.reduce(
            (a, item) => a + item.detail.pricePerItem * item.amount_given,
            0,
          ),
        };

        await updatePharmacyDrug(this.id_pharmacy, payload);
        this.isEditable = false;
        this.isSaved = true;
        this.showSnackbar();
      } catch (error) {
        this.showSnackbar(true);
      }
    },
  },
};
</script>

<style scoped lang="scss" src="../../../sass/pharmacy/editPharmacy.scss">
.ingredient {
  color: blue;
}
</style>
