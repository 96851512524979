<template>
  <div>
    <div class="toolbar-container">
      <div class="d-flex flex-row label-container">
        <div class="label">
          Laporan berdasarkan Jumlah
          {{ isByCase ? 'Kasus' : 'Kunjungan Pasien' }}
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="chip fail" v-bind="attrs" v-on="on">
              <v-icon small class="pb-2 pl-2">mdi-information</v-icon>
            </div>
          </template>
          <div
            v-if="!isByCase"
            style="text-align: left;"
            v-html="
              'Semua kunjungan dihitung, termasuk kunjungan berulang<br> oleh pasien yang sama.'
            "
          ></div>
          <div
            v-if="isByCase"
            style="text-align: left;"
            v-html="
              'Pasien dihitung sekali per diagnosa dalam satu bulan. Jika <br> datang di bulan berbeda, dihitung ulang sebagai kasus baru.'
            "
          ></div>
        </v-tooltip>
      </div>

      <div class="d-flex flex-row">
        <v-autocomplete
          class="mx-2"
          dense
          outlined
          :items="resource.filterType"
          v-model="filter.type"
          :loading="isFetching"
          item-value="value"
          item-text="text"
          append-icon="mdi-chevron-down"
          placeholder="Pilih Filter"
          @change="
            resetFilter();
            handleFilter();
            pagination.page = 1;
          "
        >
        </v-autocomplete>
        <v-menu
          offset-y
          v-if="findFilterView(['Per Bulan', 'Rentang Bulan'])"
          :close-on-content-click="false"
          v-model="filter.startMonth.state"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              class="result-filter text-end"
              readonly
              v-model="filter.startMonth.text"
              v-on="on"
              placeholder="Bulan, tahun"
              outlined
              dense
              single-line
              required
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="filter.startMonth.value"
            @input="changeDate('startMonth')"
          ></v-date-picker>
        </v-menu>

        <div
          class="mx-2"
          v-if="findFilterView(['Per Tanggal', 'Rentang Tanggal'])"
        >
          <Datepicker
            :date="filter.startDate"
            showIconCalendar
            :clickCloseContent="false"
            :isIconDown="false"
            @date-change="handleDatePicker('start', $event)"
            :show="filter.startDateState"
          />
        </div>
        <span
          class="mt-2 mx-2"
          v-if="findFilterView(['Rentang Bulan', 'Rentang Tanggal'])"
          >s.d</span
        >
        <v-menu
          offset-y
          :close-on-content-click="false"
          v-model="filter.endMonth.state"
          v-if="findFilterView(['Rentang Bulan'])"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              class="result-filter text-end"
              readonly
              v-model="filter.endMonth.text"
              v-on="on"
              placeholder="Bulan, tahun"
              outlined
              dense
              single-line
              required
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="filter.endMonth.value"
            @input="changeDate('endMonth')"
          ></v-date-picker>
        </v-menu>
        <div class="mx-2" v-if="findFilterView(['Rentang Tanggal'])">
          <Datepicker
            :clickCloseContent="false"
            :date="filter.endDate"
            showIconCalendar
            :isIconDown="false"
            :show="filter.endDateState"
            @date-change="handleDatePicker('end', $event)"
          />
        </div>
        <v-select
          dense
          placeholder="Pilih Tahun"
          hide-details="false"
          single-line
          :items="resource.yearItems"
          v-model="filter.year.value"
          v-if="findFilterView(['Per Tahun'])"
          outlined
          @change="
            handleFilter();
            pagination.page = 1;
          "
        />
        <v-btn
          color="primary"
          class="mx-2 text-capitalize pa-0 px-3 h-8"
          light
          depressed
          outlined
          style="letter-spacing: 0.5px;height:40px;"
          prepend-icon="mdi-information"
          @click="exportData"
          :disabled="data.length <= 0"
        >
          <v-icon small class="mr-1">mdi-download</v-icon>
          Export Data</v-btn
        >
      </div>
    </div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th rowspan="2" class="report-head">No</th>
            <th rowspan="2" class="report-head">ICD-10</th>
            <th rowspan="2" class="report-head diagnose">Nama Penyakit</th>
            <th
              class="report-head range"
              v-for="(item, index) in resource.ageRangeHeader"
              colspan="2"
              :key="index"
            >
              {{ item }}
            </th>
            <th rowspan="2" class="report-head">Total</th>
          </tr>
          <tr>
            <th
              class="report-sub-head"
              v-for="index in resource.ageRangeHeader.length * 2"
              :key="index"
            >
              {{ index % 2 === 1 ? 'L' : 'P' }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td style="text-align: left; vertical-align: top;">
              {{ index + 1 + itemsPerRow * (pagination.page - 1) }}
            </td>
            <td style="text-align: left; vertical-align: top;">
              {{ item.icdx }}
            </td>
            <td style="text-align: left; vertical-align: top;">
              {{ item.name }}
            </td>
            <td
              v-for="(itemAgeGroup, indexAgeGroup) in item.ageGroup"
              :key="indexAgeGroup"
            >
              {{ itemAgeGroup }}
            </td>
          </tr>
          <tr v-if="data.length <= 0">
            <td
              style="text-align: center;"
              :colspan="4 + resource.ageRangeHeader.length * 2"
            >
              Data tidak ditemukan.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-progress-linear
      indeterminate
      height="3"
      v-if="isFetching"
      class="pa-0 ma-0"
    />
    <v-layout align-center>
      <v-pagination
        v-if="data.length > 0"
        v-model="pagination.page"
        :length="totalPage"
        :total-visible="10"
        class="mr-5"
        @input="handleFilter"
      ></v-pagination>
    </v-layout>
  </div>
</template>

<script>
import Constant from '@/const';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';
import moment from 'moment';
const _ = require('lodash');
const axios = require('axios');
export default {
  name: 'DetailTablePainData',
  props: {
    isByCase: {
      type: Boolean,
      default: false,
    },

    url: {
      type: String,
      default: '',
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      data: [],
      currentYear: new Date().getFullYear(),
      isFetching: false,
      filter: {
        startDate: null,
        startDateState: false,
        endDate: null,
        endDateState: false,
        type: 'Per Bulan',
        startMonth: {
          text: moment()
            .locale('id')
            .format('MMMM, YYYY'),
          value: moment().format('YYYY-MM'),
          state: false,
        },
        endMonth: {
          text: moment()
            .locale('id')
            .format('MMMM, YYYY'),
          value: moment().format('YYYY-MM'),
          state: false,
        },
        year: {
          text: '',
          value: new Date().getFullYear(),
          state: false,
        },
      },
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: null,
      },
      totalPage: 0,
      resource: {
        filterType: [
          {
            text: 'Semua Waktu',
            value: 'Semua Waktu',
          },
          {
            text: 'Per Tanggal',
            value: 'Per Tanggal',
          },
          {
            text: 'Rentang Tanggal',
            value: 'Rentang Tanggal',
          },
          {
            text: 'Per Bulan',
            value: 'Per Bulan',
          },
          {
            text: 'Rentang Bulan',
            value: 'Rentang Bulan',
          },
          {
            text: 'Per Tahun',
            value: 'Per Tahun',
          },
        ],
        yearItems: _.range(new Date().getFullYear(), 2019, -1),
        ageRangeHeader: [
          '0 - 7 Hr',
          '8 - 28 Hr',
          '1 Bln - < 1 Th',
          '1 - 4 Th',
          '5 - 9 Th',
          '10 - 14 Th',
          '15 - 19 Th',
          '20 - 44 Th',
          '45 - 54 Th',
          '55 - 59 Th',
          '60 - 69 Th',
          '>70 Th',
        ],
      },
    };
  },
  methods: {
    totalMale(...args) {
      return args.reduce((a, b) => a + b);
    },
    totalFemale(...args) {
      return args.reduce((a, b) => a + b);
    },
    total(...args) {
      return args.reduce((a, b) => a + b);
    },
    findFilterView(options) {
      return options.includes(this.filter.type);
    },

    handleDatePicker(opt, date) {
      this.pagination.page = 1;
      if (opt === 'start') {
        this.filter.startDate = date;
        this.filter.startDateState = false;
      }
      if (opt === 'end') {
        this.filter.endDate = date;
        this.filter.endDateState = false;
      }
      this.handleFilter();
    },

    changeDate(option) {
      this.pagination.page = 1;
      switch (option) {
        case 'startMonth':
          this.filter.startMonth.text = moment(
            this.filter.startMonth.value,
            'YYYY-MM',
          )
            .locale('id')
            .format('MMMM, YYYY');
          this.filter.startMonth.state = false;
          break;
        case 'endMonth':
          this.filter.endMonth.text = moment(
            this.filter.endMonth.value,
            'YYYY-MM',
          )
            .locale('id')
            .format('MMMM, YYYY');
          this.filter.endMonth.state = false;
          break;
        case 'year':
          this.filter.year.text = this.filter.value;
          this.filter.year.state = false;
          break;
      }
      this.handleFilter();
    },
    getOutputDate() {
      let sDate = '';
      let eDate = '';
      if (this.filter.type === 'Per Bulan') {
        sDate = moment(this.filter.startMonth.value, 'YYYY-MM')
          .startOf('month')
          .format('YYYY-MM-DD');
        eDate = moment(this.filter.startMonth.value, 'YYYY-MM')
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      if (this.filter.type === 'Rentang Bulan') {
        sDate = moment(this.filter.startMonth.value, 'YYYY-MM')
          .startOf('month')
          .format('YYYY-MM-DD');
        eDate = moment(this.filter.endMonth.value, 'YYYY-MM')
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      if (this.filter.type === 'Per Tanggal') {
        sDate = this.filter.startDate;
        eDate = this.filter.startDate;
      }
      if (this.filter.type === 'Rentang Tanggal') {
        sDate = this.filter.startDate;
        eDate = this.filter.endDate;
      }
      if (this.filter.type === 'Per Tahun') {
        sDate = moment(`${this.filter.year.value}-01-01`)
          .startOf('month')
          .format('YYYY-MM-DD');
        eDate = moment(`${this.filter.year.value}-12-31`)
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      return { sDate, eDate };
    },
    handleFilter() {
      let { sDate, eDate } = this.getOutputDate();
      this.getDisease({ sDate, eDate });
    },
    exportData() {
      let { sDate, eDate } = this.getOutputDate();
      this.$emit('exportReport', {
        startDate: sDate,
        endDate: eDate,
      });
    },

    getDisease(event) {
      let apiUrl = '';
      let sDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      let eDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD');
      // this.getPages();
      const itemCount = this.itemsPerRow;
      const halaman = this.pagination.page;
      this.isFetching = true;
      apiUrl = Constant.apiUrl
        .concat(this.url)
        .concat(
          `?page=${halaman}&itemCount=${itemCount}&start_date=${
            event === undefined ? sDate : event.sDate
          }&end_date=${event === undefined ? eDate : event.eDate}`,
        );
      axios
        .get(apiUrl)
        .then(response => {
          const result = response.data.data;

          this.data = result.map(item => {
            let flatArray = Object.values(item.ageGroup).flatMap(({ L, P }) => [
              L,
              P,
            ]);
            const totalLastValue = flatArray.pop() + flatArray.pop();
            flatArray.push(totalLastValue);
            return {
              ...item,
              ageGroup: flatArray,
            };
          });

          this.totalPage = Math.ceil(response.data.length / itemCount);
        })
        .catch(() => {
          this.data = [];
          this.pagination.totalItems = 0;
          this.totalPage = 0;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    refresh() {
      this.handleFilter();
    },
    resetFilter() {
      this.filter.startDate = null;
      this.filter.endDate = null;
      this.filter.startMonth = {
        text: moment()
          .locale('id')
          .format('MMMM, YYYY'),
        value: moment().format('YYYY-MM'),
        state: false,
      };
      this.filter.endMonth = {
        text: moment()
          .locale('id')
          .format('MMMM, YYYY'),
        value: moment().format('YYYY-MM'),
        state: false,
      };
      this.filter.year = {
        text: '',
        value: new Date().getFullYear(),
        state: false,
      };
    },
  },
  mounted() {
    this.handleFilter();
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 10;
      }
      return itemRow;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  // white-space: nowrap;
}

.graph-table {
  height: 50vh;
}

table thead {
  background: #ededed;
}

table th {
  font-weight: 500;
  font-size: 12px;
  padding: 0.5rem;
}

table .subheader {
  background: #f5f5f5;
  font-size: 9px;
}

table tbody td {
  border-right: 1px solid #ededed;
  border-left: 1px solid #ededed;
  // border-bottom: 1px solid #ededed;
  border-collapse: collapse;
  padding: 10px;
  font-size: 14px;
  &.text-left {
    text-align: left;
  }
  &.diagnose {
    max-width: 1250px;
    // white-space: collapse;
  }
  &.data {
    // width: 10%;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-container {
  width: 100%;
  margin-top: 10px;
}
.report-head {
  background: #deeef9;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  &.diagnose {
    width: 200px;
    max-width: 250px;
    min-width: 200px;
  }
  &.range {
    width: 200px;
    max-width: 250px;
    min-width: 100px;
  }
}

.report-sub-head {
  background: #f0f9ff;
  padding: 10px;
  text-align: center;

  span {
    font-size: 9px;
    color: #404040;
  }
}
.grid-display {
  display: grid;
  // grid-gap: 16px;
  // padding: 16px;

  &.report-header {
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    // grid-auto-flow: column;
    grid-auto-columns: minmax(1fr);
    span {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: #404040;
    }
    .report-head {
      background: #deeef9;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .report-sub-head {
      background: #f0f9ff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-align: center;

      span {
        font-size: 9px;
        color: #404040;
      }
    }
  }
  &.report-items {
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    grid-auto-flow: column;
    grid-auto-columns: minmax(1fr);
    grid-gap: 1px;
    span {
      padding: 0.5rem;
      font-size: 0.85714285714rem;
      line-height: 1.28571428571rem;
      font-weight: 400;
      color: #000000;
      white-space: pre-wrap;
      overflow-wrap: break-word !important;
      white-space: normal !important;
      text-align: left;
      box-sizing: border-box;
    }
  }
  .rowSpan23 {
    grid-row-end: 3;
    grid-row-start: 2;
  }
  .rowSpan12 {
    grid-row-end: 2;
    grid-row-start: 1;
  }
  .rowSpan13 {
    grid-row-end: 3;
    grid-row-start: 1;
  }
  .colSpan10 {
    grid-column-end: 4;
    grid-column-start: 2;
  }
  .colSpan13 {
    grid-column-end: 6;
    grid-column-start: 4;
  }
  .colSpan35 {
    grid-column-end: 8;
    grid-column-start: 6;
  }
  .colSpan57 {
    grid-column-end: 10;
    grid-column-start: 8;
  }
  .colSpan714 {
    grid-column-end: 12;
    grid-column-start: 10;
  }
  .colSpan816 {
    grid-column-end: 14;
    grid-column-start: 12;
  }
  .colSpan999 {
    grid-column-end: 16;
    grid-column-start: 14;
  }

  .colSpan1000 {
    grid-column-end: 18;
    grid-column-start: 16;
  }

  .colSpan1010 {
    grid-column-end: 20;
    grid-column-start: 18;
  }

  .colSpan1020 {
    grid-column-end: 22;
    grid-column-start: 20;
  }

  .colSpan1030 {
    grid-column-end: 24;
    grid-column-start: 22;
  }

  .colSpan1040 {
    grid-column-end: 26;
    grid-column-start: 24;
  }
}
.table-container {
  width: 100%;
  overflow-x: auto;
}
// .table-container {
//   position: relative;
//   height: 45vh;
//   .border-container {
//     z-index: 1;
//     position: absolute;
//     display: grid;
//     grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
//     left: 0fr;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     border-right: 1px solid #e0e0e0;
//     border-bottom: 1px solid #e0e0e0;
//     .border {
//       width: 1px;
//       background: #e0e0e0;
//     }
//   }
//   .item-container {
//     z-index: 5;
//     position: absolute;
//     background: transparent;
//     height: 44vh;
//     overflow-y: scroll;
//     overflow-x: hidden;
//     width: 100%;
//     scrollbar-color: black rgb(163, 163, 163);
//     scrollbar-width: thin;
//     &::-webkit-scrollbar {
//       width: 3px;
//       background-color: rgb(163, 163, 163);
//     }
//     &::-webkit-scrollbar-thumb {
//       width: 3px;
//       background-color: black;
//     }
//   }
// }

.input-graph {
  width: 80%;
  margin: auto;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 80%;

  .graph-img {
    height: 100px;
  }
}

.toolbar-container {
  display: flex;
  align-items: center;
  align-items: baseline;
  justify-content: space-between;

  .label {
    //styleName: Subtitle/medium (base);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .label-container {
    white-space: nowrap;
    margin-right: 10px;
  }
}

.table-container::-webkit-scrollbar {
  height: 6px; /* Horizontal scrollbar thickness */
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 100px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #8f8f8f; /* Scrollbar thumb color */
  border-radius: 100px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
