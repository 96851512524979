<template>
  <v-container fluid>
    <div class="toolbar-container">
      <div class="label1">Top 5/10 Diagnosa</div>
      <div class="input-toolbar">
        <v-radio-group
          v-model="filter.category"
          class="radio-group"
          row
          @change="handleFilter"
        >
          <div class="radio-container">
            <v-radio label="Top 5" outline value="5" />
          </div>
          <div class="radio-container">
            <v-radio label="Top 10" outline value="10" />
          </div>
        </v-radio-group>

        <div class="d-flex flex-row">
          <v-autocomplete
            class="mx-2"
            dense
            outlined
            :items="resource.filterType"
            v-model="filter.type"
            item-value="value"
            item-text="text"
            append-icon="mdi-chevron-down"
            placeholder="Pilih Filter"
            @change="
              handleFilter();
              resetFilter();
            "
          >
          </v-autocomplete>
          <v-menu
            offset-y
            v-if="findFilterView(['Per Bulan'])"
            :close-on-content-click="false"
            v-model="filter.startMonth.state"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="result-filter text-end"
                readonly
                v-model="filter.startMonth.text"
                v-on="on"
                placeholder="Bulan, tahun"
                outlined
                dense
                single-line
                required
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="filter.startMonth.value"
              @input="changeDate('startMonth')"
            ></v-date-picker>
          </v-menu>
          <div class="select-input">
            <v-select
              dense
              placeholder="Pilih Tahun"
              hide-details="false"
              single-line
              :items="resource.yearItems"
              v-model="filter.year.value"
              v-if="findFilterView(['Per Tahun'])"
              outlined
              @change="handleFilter"
            />
          </div>
        </div>
      </div>
    </div>
    <v-layout row wrap>
      <v-flex xs6 pa-2>
        <div class="graph-container">
          <span class="graph-header">Berdasarkan Jumlah Kunjungan Pasien</span>
          <apexcharts
            height="400"
            ref="diseaseTotalVisit"
            type="bar"
            :options="painGraph.byTotalVisit.chartOptions"
            :series="painGraph.byTotalVisit.series"
          >
          </apexcharts>

          <v-progress-circular
            v-if="isGraphFetching"
            class="graph-loader"
            indeterminate
            color="#30383A"
          ></v-progress-circular>
        </div>
      </v-flex>
      <v-flex xs6 pa-2>
        <div class="graph-container">
          <span class="graph-header">Berdasarkan Jumlah Kasus</span>
          <apexcharts
            height="400"
            ref="diseaseTotalCase"
            type="bar"
            :options="painGraph.byTotalCase.chartOptions"
            :series="painGraph.byTotalCase.series"
          >
          </apexcharts>
          <v-progress-circular
            class="graph-loader"
            v-if="isGraphFetching"
            indeterminate
            color="#30383A"
          ></v-progress-circular>
        </div>
      </v-flex>
    </v-layout>

    <v-layout row class="mt-8 annual-data-container">
      <div class="sub-label">
        Analisis Per Diagnosa pada tahun {{ filter.yearGraph }}
      </div>
      <div class="data">
        <div class="table-container">
          <v-text-field
            class="mb-4"
            v-model="filter.search"
            background-color="grey lighten-3"
            placeholder="Cari berdasarkan kode atau nama diagnosa"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="searchData"
          ></v-text-field>
          <div class="sub-label-italic mb-4">
            Klik salah satu diagnosa untuk menampilkan detail pada chart
          </div>
          <div>
            <div class="table-content">
              <table>
                <thead>
                  <tr>
                    <th v-for="(item, index) in resource.headers" :key="index">
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in resource.dataPain"
                    :key="index"
                    @click="setSelectedItem(item)"
                    :class="[
                      {
                        selected: item.id === annualTable.selectedItem.id,
                      },
                    ]"
                  >
                    <td class="name">
                      <!-- {{ item.name }} -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            {{ item.name }}
                          </div>
                        </template>
                        <span>{{ item.name }}</span>
                      </v-tooltip>
                    </td>
                    <td class="text-xs-right">
                      {{ item.totalCase }}
                    </td>
                    <td class="text-xs-right">{{ item.totalVisit }}</td>
                  </tr>
                  <tr v-if="resource.dataPain.length <= 0">
                    <td colspan="3" style="text-align: center;">
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="graph-container">
          <div class="content">
            <span class="graph-header">{{
              annualTable.selectedItem?.name
            }}</span>
            <div class="select-input">
              <v-select
                dense
                placeholder="Pilih Tahun"
                hide-details="false"
                single-line
                :items="resource.yearItems"
                v-model="filter.yearGraph"
                outlined
                @change="getDiseaseTable"
              />
            </div>
          </div>

          <apexcharts
            height="400"
            ref="diseaseResume"
            type="line"
            :options="painGraph.annualGraph.chartOptions"
            :series="painGraph.annualGraph.series"
          >
          </apexcharts>
          <v-progress-circular
            v-if="isAnnualGraphFetching"
            class="graph-loader"
            indeterminate
            color="#30383A"
          ></v-progress-circular>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import Constant from '@/const';
import moment from 'moment';
const axios = require('axios');
const _ = require('lodash');
export default {
  name: 'PainDataChart',
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      searchData: null,
      isGraphFetching: false,
      isAnnualGraphFetching: false,
      filter: {
        search: '',
        type: 'Per Bulan',
        category: '10',
        startMonth: {
          text: moment()
            .locale('id')
            .format('MMMM, YYYY'),
          value: moment().format('YYYY-MM'),
          state: false,
        },
        year: {
          text: '',
          value: new Date().getFullYear(),
          state: false,
        },
        yearGraph: new Date().getFullYear(),
      },
      resource: {
        filterType: [
          {
            text: 'Per Bulan',
            value: 'Per Bulan',
          },
          {
            text: 'Per Tahun',
            value: 'Per Tahun',
          },
        ],
        yearItems: _.range(new Date().getFullYear(), 2019, -1),
        headers: [
          {
            text: 'Nama Diagnosa',
            align: 'left',
            sortable: false,
            value: 'name',
          },
          { text: 'Jumlah Kasus', value: 'calories' },
          { text: 'Jml. Kunjungan Pasien', value: 'fat' },
        ],
        dataPain: [],
      },

      painGraph: {
        annualGraph: {
          series: [
            {
              data: [],
            },
          ],
          chartOptions: {
            chart: {
              type: 'line',
              toolbar: {
                show: false, // Hides the chart toolbar
              },
              width: '100%',
            },
            plotOptions: {
              bar: {
                borderRadius: 2,
                horizontal: true,
                barHeight: '65%',
                dataLabels: {
                  position: 'center',
                },
              },
            },
            markers: {
              size: 6, // Size of the dots
              shape: 'circle', // Circle shape
              strokeWidth: 2, // Border thickness
              strokeColors: '#ffffff', // White border
              fillOpacity: 1, // Fully visible
            },
            stroke: {
              curve: 'straight', // Keep the line straight
              width: 2, // Line thickness
            },
            colors: ['#1976D2', '#F9A825'],
            tooltip: {
              enabled: true,
              x: {
                formatter: (value, { dataPointIndex }) => {
                  const customLabels = [
                    'Januari',
                    'Febuari',
                    'Maret',
                    'April',
                    'Mei',
                    'Juni',
                    'Juli',
                    'Agustus',
                    'September',
                    'Oktober',
                    'November',
                    'Desember',
                  ];
                  return `${customLabels[dataPointIndex]} ${this.filter.yearGraph}`; // Show custom category in hover tooltip
                },
              },
            },
            xaxis: {
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'Mei',
                'Jun',
                'Jul',
                'Agu',
                'Sep',
                'Okt',
                'Nov',
                'Des',
              ],
            },
          },
        },
        byTotalCase: {
          series: [
            {
              data: [],
            },
          ],
          chartOptions: {
            chart: {
              type: 'bar',
              toolbar: {
                show: false, // Hides the chart toolbar
              },
              width: '100%',
            },
            plotOptions: {
              bar: {
                borderRadius: 2,
                borderRadiusApplication: 'end',
                horizontal: true,
                barHeight: '65%',
                dataLabels: {
                  position: 'center',
                },
              },
            },
            dataLabels: {
              enabled: true, // Enables numbers on bars
              style: {
                fontSize: '14px',
                colors: ['#fff'], // White text for better visibility
              },
              formatter: function(value) {
                return value; // Show the number inside the bar
              },
            },

            yaxis: {
              labels: {
                align: 'left', // Aligns category labels to the left
                style: {
                  fontSize: '14px',
                },
                // formatter: function(value) {
                //   return value.length > 10
                //     ? value.substring(0, 30) + '...'
                //     : value; // Truncate long labels
                // },
              },
            },
            colors: ['#F9A825'],
            xaxis: {
              categories: [],
            },
          },
        },
        byTotalVisit: {
          series: [
            {
              data: [],
            },
          ],
          chartOptions: {
            chart: {
              type: 'bar',
              toolbar: {
                show: false, // Hides the chart toolbar
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 2,
                borderRadiusApplication: 'end',
                horizontal: true,
                barHeight: '65%',
                dataLabels: {
                  position: 'center',
                },
              },
            },
            dataLabels: {
              enabled: true, // Enables numbers on bars
              style: {
                fontSize: '14px',
                colors: ['#fff'], // White text for better visibility
              },
              formatter: function(value) {
                return value; // Show the number inside the bar
              },
            },
            // tooltip: {
            //   enabled: true,
            //   custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            //     const fullLabel = w.globals.labels[dataPointIndex]; // Full category name
            //     const seriesName = w.config.series[seriesIndex].name; // Series name
            //     const value = series[seriesIndex][dataPointIndex]; // Data value

            //     return `
            //   <div style="
            //     background: #fff;
            //     color: #333;
            //     border-radius: 4px;
            //     font-size: 12px;
            //     box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
            //     border: 1px solid #ddd;
            //   ">
            //     <div style="
            //       font-weight: bold;
            //       margin-bottom: 4px;
            //       white-space: nowrap;
            //       background: #FAFAFA;
            //       padding:10px;
            //       border-radius: 6px 6px 0px 0px;
            //     ">${fullLabel}</div>
            //     <div style="display: flex; align-items: center; padding: 10px;">
            //       <span style="
            //         display: inline-block;
            //         width: 8px;
            //         height: 8px;
            //         background: ${w.config.colors[seriesIndex]};
            //         border-radius: 50%;
            //         margin-right: 6px;
            //       "></span>
            //       <span>${seriesName}: <strong>${value}</strong></span>
            //     </div>
            //   </div>`;
            //   },
            // },
            yaxis: {
              labels: {
                align: 'left', // Aligns category labels to the left
                style: {
                  fontSize: '14px',
                },
                // formatter: function(value) {
                //   return value.length > 10
                //     ? value.substring(0, 30) + '...'
                //     : value; // Truncate long labels
                // },
              },
            },
            colors: ['#1976D2'],
            xaxis: {
              categories: [],
            },
          },
        },
      },
      annualTable: {
        selectedItem: null,
      },
    };
  },

  methods: {
    findFilterView(options) {
      return options.includes(this.filter.type);
    },

    setSelectedItem(data) {
      this.annualTable.selectedItem = data;
      this.selectItem = data;
      this.$refs.diseaseResume.updateSeries([
        {
          name: 'Jumlah Kunjungan Pasien',
          data: data?.totalVisitGraphData ?? [],
        },
        { name: 'Jumlah Kasus', data: data?.totalCaseGraphData ?? [] },
      ]);
    },

    getDiseaseGraph(date) {
      this.isGraphFetching = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/sickness/graph?start_date=${date.sDate}&end_date=${date.eDate}`,
          ),
        )
        .then(response => {
          let totalChart = 10;
          let categories = response.data.data.map(item => {
            return `${item.icdx} - ${item.name}`;
          });

          let totalCase = response.data.data.map(item => {
            return item.totalCase;
          });

          let totalVisit = response.data.data.map(item => {
            return item.totalVisit;
          });

          if (this.filter.category === '5') {
            if (categories.length > 5) {
              categories = categories.slice(0, 5);
              totalCase = totalCase.slice(0, 5);
              totalVisit = totalVisit.slice(0, 5);
            }
            totalChart = 5;
          } else {
            if (categories.length > 10) {
              categories = categories.slice(0, 10);
              totalCase = totalCase.slice(0, 10);
              totalVisit = totalVisit.slice(0, 10);
            }
            totalChart = 10;
          }

          this.$refs.diseaseTotalVisit.updateOptions({
            xaxis: {
              categories: [
                ...categories,
                ...Array(totalChart - categories.length).fill('-'),
              ],
            },
          });

          this.$refs.diseaseTotalVisit.updateSeries([
            {
              name: 'Jumlah Kunjungan Pasien',
              data: [
                ...totalVisit,
                ...Array(totalChart - totalVisit.length).fill(0),
              ],
            },
          ]);

          this.$refs.diseaseTotalCase.updateOptions({
            xaxis: {
              categories: [
                ...categories,
                ...Array(totalChart - categories.length).fill('-'),
              ],
            },
          });

          this.$refs.diseaseTotalCase.updateSeries([
            {
              name: 'Jumlah Kasus',
              data: [
                ...totalCase,
                ...Array(totalChart - totalCase.length).fill(0),
              ],
            },
          ]);

          this.isGraphFetching = false;
        })
        .catch(() => {
          this.$refs.diseaseTotalVisit.updateOptions({
            xaxis: {
              categories: [],
            },
          });

          this.$refs.diseaseTotalVisit.updateSeries([
            {
              data: [],
            },
          ]);

          this.$refs.diseaseTotalCase.updateOptions({
            xaxis: {
              categories: [],
            },
          });

          this.$refs.diseaseTotalCase.updateSeries([
            {
              data: [],
            },
          ]);
          this.isGraphFetching = false;
        });
    },

    getDiseaseTable() {
      this.$refs.diseaseResume.updateSeries([
        {
          name: 'Jumlah Kunjungan Pasien',
          data: [],
        },
        { name: 'Jumlah Kasus', data: [] },
      ]);
      this.isAnnualGraphFetching = true;
      let sDate = moment(`${this.filter.yearGraph}-01-01`)
        .startOf('month')
        .format('YYYY-MM-DD');
      let eDate = moment(`${this.filter.yearGraph}-12-31`)
        .endOf('month')
        .format('YYYY-MM-DD');
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/sickness/graph-permonth?start_date=${sDate}&end_date=${eDate}`,
          ),
        )
        .then(response => {
          this.resource.dataPain = response.data.data.map(item => {
            return {
              id: item.icdx,
              name: `${item.icdx} - ${item.name}`,
              totalCase: Object.values(item.totalCase).reduce(
                (prev, next) => prev + next,
              ),
              totalVisit: Object.values(item.totalVisit).reduce(
                (prev, next) => prev + next,
              ),
              totalVisitGraphData: Object.values(item.totalVisit).map(
                item => item,
              ),
              totalCaseGraphData: Object.values(item.totalCase).map(
                item => item,
              ),
            };
          });
          if (this.filter.search.trim() !== '') {
            this.resource.dataPain = this.resource.dataPain.filter(f =>
              f.name.toLowerCase().includes(this.filter.search),
            );
          }
          this.setSelectedItem(this.resource.dataPain[0]);
          this.isAnnualGraphFetching = false;
        })
        .catch(() => {
          this.resource.dataPain = [];
          this.$refs.diseaseResume.updateSeries([
            {
              name: 'Jumlah Kunjungan Pasien',
              data: [],
            },
            { name: 'Jumlah Kasus', data: [] },
          ]);
          this.isAnnualGraphFetching = false;
          this.annualTable.selectedItem.name = '';
        });
    },
    changeDate(option) {
      switch (option) {
        case 'startMonth':
          this.filter.startMonth.text = moment(
            this.filter.startMonth.value,
            'YYYY-MM',
          )
            .locale('id')
            .format('MMMM, YYYY');
          this.filter.startMonth.state = false;
          break;
        case 'year':
          this.filter.year.text = this.filter.value;
          this.filter.year.state = false;
          break;
      }
      this.handleFilter();
    },

    resetFilter() {
      this.filter.startMonth = {
        text: moment()
          .locale('id')
          .format('MMMM, YYYY'),
        value: moment().format('YYYY-MM'),
        state: false,
      };
      this.filter.year = {
        text: '',
        value: new Date().getFullYear(),
        state: false,
      };
    },

    handleFilter() {
      let sDate = '';
      let eDate = '';
      if (this.filter.type === 'Per Bulan') {
        sDate = moment(this.filter.startMonth.value, 'YYYY-MM')
          .startOf('month')
          .format('YYYY-MM-DD');
        eDate = moment(this.filter.startMonth.value, 'YYYY-MM')
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        sDate = moment(`${this.filter.year.value}-01-01`)
          .startOf('month')
          .format('YYYY-MM-DD');
        eDate = moment(`${this.filter.year.value}-12-31`)
          .endOf('month')
          .format('YYYY-MM-DD');
      }

      this.$refs.diseaseTotalVisit.updateOptions({
        xaxis: {
          categories: [],
        },
      });

      this.$refs.diseaseTotalVisit.updateSeries([
        {
          data: [],
        },
      ]);

      this.$refs.diseaseTotalCase.updateOptions({
        xaxis: {
          categories: [],
        },
      });

      this.$refs.diseaseTotalCase.updateSeries([
        {
          data: [],
        },
      ]);
      this.getDiseaseGraph({ sDate, eDate });
    },

    refresh() {
      this.handleFilter();
      this.getDiseaseTable();
    },
  },
  mounted() {
    this.handleFilter();
    this.getDiseaseTable();

    this.searchData = _.debounce(() => {
      this.getDiseaseTable();
    }, 650);
  },
};
</script>

<style lang="scss" scoped>
.graph-container {
  background-color: #f0f8fe;
  padding: 16px;
  text-align: left;
  padding-top: 20px;
  width: 100%;
  height: fit-content;
  position: relative;
  min-height: 500px;
  & .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.graph-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.graph-container.table {
  max-width: 900px;
}

.graph-header {
  //styleName: Body/medium-prominent;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 9px;
  padding-top: 20px;
}

.label1 {
  //styleName: Subtitle/medium (base);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: nowrap;
}

.sub-label {
  //styleName: Body/large-prominent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 8px;
}

.sub-label-italic {
  //styleName: Body/small;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.toolbar-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.input-toolbar {
  display: flex;
  gap: 8px;
  //   align-items: flex-start;
  //   justify-content: center;
}

.label-container {
  white-space: nowrap;
  margin-right: 10px;
}
.radio-container {
  //   padding: 10px;
  border: 1px solid gray;
  padding: 6.5px;
  border-radius: 4px;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
::v-deep .v-input.radio-group .v-input--radio-group__input {
  display: flex;
  gap: 15px;
}

::v-deep .v-input.radio-group {
  margin-top: -3px;
}

.annual-data-container {
  display: block;
  .data {
    display: flex;
    width: 100%;
    gap: 20px;
  }
}

table {
  border-collapse: collapse;
}

.select-input {
  max-width: 100px;
}

.table-content {
  table thead {
    position: sticky;
    top: -0.5px;
    z-index: 999;
  }

  width: 400px;
  height: 620px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background-color: rgb(123, 121, 121);
  }
}
table {
  width: 100%;
  thead tr {
    border-top: solid 1px #f0f8fe;
    background: #f5f5f5;
    // border-left: #f5f5f5 4px solid;
    // z-index: 99;
    th {
      //styleName: Table/th;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.4px;
      letter-spacing: 0.005em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #9e9e9e;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
    th:not(:first-child) {
      text-align: right;
    }
  }

  tbody {
    tr:hover {
      cursor: pointer;
      background: #f7f7f7;
    }
    tr {
      border-top: solid 1px #ededed;
      border-bottom: solid 1px #ededed;
      &.selected {
        position: relative;
        box-shadow: inset 4px 0 0 #1976d2;
      }
      td {
        //styleName: Table/td-large (base);
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.005em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        &:not(:first-child) {
          text-align: right;
        }
        &.name div {
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          &:hover::after {
            content: attr(data-text); /* Show full text */
            position: absolute;
            left: 0;
            top: 100%;
            background: rgba(0, 0, 0, 0.75);
            color: #fff;
            padding: 5px;
            border-radius: 4px;
            white-space: nowrap;
            z-index: 10;
          }
        }
      }
    }
  }
}
</style>
