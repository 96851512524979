<template>
  <v-container fluid class="main">
    <v-row>
      <v-col cols="5" class="text-left pb-0">
        <h2 class="title">Laporan Pasien Rujukan BPJS</h2>
        <v-row class="pa-3 update pt-4">
          <p>
            {{ textDateType }}
          </p>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col class="icon-head pl-0" align-self="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey"
              v-bind="attrs"
              v-on="on"
              icon
              text
              @click="refresh()"
            >
              <v-icon :class="{ loadingrefresh: isLoading }"
                >mdi-autorenew
              </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-col>
      <v-col cols="3" class="pl-0" align-self="center">
        <v-text-field
          class="input"
          background-color="grey lighten-3"
          placeholder="Cari nama pasien"
          solo
          dense
          hide-details="false"
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col class="pt-4 pb-0" style="max-width: 100px">
        <v-btn
          color="#137BC0"
          class="white--text text-none"
          @click="exportBPJSPatient()"
          elevation="0"
          :loading="isLoadingExport"
          ><span class="text-none">Export</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0" align="center">
      <router-link :to="showData" active>
        <v-btn
          color="#137BC0"
          class="#137BC0--text text-none ml-2"
          elevation="0"
          outlined
        >
          <span style="letter-spacing: 0px !important;">
            <v-icon style="scale: 0.8; margin-top: -0.1rem;">
              mdi-arrow-left
            </v-icon>
            Dashboard
          </span>
        </v-btn>
      </router-link>
      <v-spacer />
      <v-col :cols="filter.dateType === 'range' ? '8' : '6'">
        <div class="container-filter">
          <div style="max-width: 210px;">
            <v-select
              v-model="filter.filterBy"
              :items="[
                { text: 'Tanggal Pembuatan Surat', value: 'created' },
                { text: 'Tanggal Rencana Berkunjung', value: 'plan' },
              ]"
              outlined
              append-icon="mdi-chevron-down"
              item-text="text"
              item-value="value"
              hide-details="false"
              dense
              @change="getBPJSPatient()"
            />
          </div>
          <div style="max-width: 210px;">
            <v-select
              v-model="filter.dateType"
              :items="[
                { text: 'Per Tanggal', value: 'perday' },
                { text: 'Per Bulan', value: 'permonth' },
                { text: 'Rentang Tanggal', value: 'range' },
              ]"
              outlined
              append-icon="mdi-chevron-down"
              item-text="text"
              item-value="value"
              hide-details="false"
              dense
            />
          </div>
          <div
            class="d-flex align-center justify-between"
            style="gap: 1rem;"
            v-if="filter.dateType === 'range'"
          >
            <Datepicker
              :clickCloseContent="false"
              :show="menu"
              :date="filter.startDate"
              outlined
              showIconCalendar
              :isIconDown="false"
              @date-change="handleStartDateChange"
              :max="filter.endDate"
              placeholder="Tanggal Awal"
            />
            <span>
              s.d
            </span>
            <Datepicker
              :clickCloseContent="false"
              :show="menu2"
              :date="filter.endDate"
              outlined
              showIconCalendar
              placeholder="Tanggal Akhir"
              :isIconDown="false"
              :min="filter.startDate"
              @date-change="handleEndDateChange"
            />
          </div>
          <div v-else-if="filter.dateType === 'perday'">
            <Datepicker
              v-show="filter.dateType === 'perday'"
              :clickCloseContent="false"
              :date="filter.singleDate"
              outlined
              showIconCalendar
              :isIconDown="false"
              @date-change="handleSingleDateChange"
            />
          </div>
          <Datepicker
            v-else-if="filter.dateType === 'permonth'"
            v-show="filter.dateType === 'permonth'"
            placeholder="Pilih Bulan"
            :clickCloseContent="false"
            :date="filter.month"
            outlined
            showIconCalendar
            :isIconDown="false"
            @date-change="handleMonthChange"
            typeDate="month"
          />
        </div>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="bpjs.headers"
      :items="bpjs.items"
      item-key="_id"
      :expanded="expanded"
      single-expand
      :search="search"
      :loading="isLoading"
      :items-per-page="itemsPerRow"
      :height="height"
      :page.sync="page"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:no-data>Tidak ada data yang ditemukan</template>
      <template v-slot:[`item.no`]="{ item }">
        <tr>
          <td class="text-center">
            {{ item.no }}
          </td>
        </tr>
      </template>
      <template v-slot:[`item.doctorName`]="{ item }">
        <tr>
          <td class="text-start text-truncate" style="max-width: 150px;">
            {{ item.doctorName }}
          </td>
        </tr>
      </template>
      <template v-slot:[`item.hospitalRef`]="{ item }">
        <tr>
          <td class="text-start text-truncate" style="max-width:10vw;">
            {{ item.hospitalRef }}
          </td>
        </tr>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <tr class="text-left">
          <td>
            <v-btn @click="print(item.idRegistration)" small fab text>
              <v-icon color="#848484">mdi-printer</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="expand px-0">
          <v-card flat color="grey lighten-4" class="pa-3">
            <v-row>
              <v-spacer />
              <v-col cols="3" class="text-left" align-self="center">
                <p class="expand__caption">No. BPJS : {{ item.bpjsNumber }}</p>
              </v-col>
              <v-col cols="3" class="text-left pl-0" align-self="center">
                <p class="expand__caption">
                  Tujuan Rujukan : {{ item.referralPoly }}
                </p>
              </v-col>
              <v-spacer />
            </v-row>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="300px">
      <print-BPJS-referral-dialog
        :id-registration="selectedPatientIdRegist"
        @close-form="dialog = false"
      />
    </v-dialog>
    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          snackbar.show = false;
        }
      "
    />
    <v-footer color="white d-flex justify-space-between pagination">
      <v-pagination
        v-if="lengthPage !== 0"
        v-model="page"
        :length="lengthPage"
        total-visible="5"
        @input="getBPJSPatient()"
      />
    </v-footer>
  </v-container>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import PrintBPJSReferralDialog from '../../SharedComponent/PrintBPJSReferralDialog.vue';
import BPJSPatientMapper from '@/mixin/MapperMixins/BPJSPatient';
import { Datepicker, Snackbar } from '@/components/SharedComponent/';
import { getReportBPJS } from '@/fetchApi/Report/BPJSReport';

const _ = require('lodash');

export default {
  name: 'BPJSPatient',
  mixins: [alertMixin, BPJSPatientMapper],
  components: {
    PrintBPJSReferralDialog,
    Datepicker,
    Snackbar,
  },
  data() {
    return {
      filter: {
        filterBy: 'created',
        dateType: 'perday',
        singleDate: moment().format('YYYY-MM-DD'),
        month: '',
        startDate: '',
        endDate: '',
      },
      snackbar: {
        show: false,
        color: 'success',
        text: '',
      },
      isLoadingExport: false,
      selectedPatientIdRegist: '',
      dialog: false,
      expanded: [],
      timestamp: moment().format('DD MMMM YYYY'),
      menu: false,
      menu2: false,
      dataLength: 0,
      bpjs: {
        headers: [
          { text: 'No.', value: 'no', sortable: false, width: '5.7%' },
          {
            text: 'Tgl Pembuatan Surat',
            value: 'createdAt',
            sortable: false,
            width: '10%',
          },
          {
            text: 'Tgl Rencana Berkunjung',
            value: 'referralPlanDate',
            sortable: false,
            width: '10%',
          },
          { text: 'No RM', value: 'noRM', sortable: false, width: '6.1%' },
          {
            text: 'Nama Pasien',
            value: 'patientName',
            sortable: false,
            width: '14.3%',
          },
          { text: 'Poliklinik', value: 'unit', sortable: false, width: '8%' },
          {
            text: 'Diagnosa Rujukan',
            value: 'diagnose',
            sortable: false,
            width: '14.3%',
          },
          {
            text: 'Nama Dokter',
            value: 'doctorName',
            sortable: false,
            width: '11.8%',
          },
          {
            text: 'Instansi Rujukan',
            value: 'hospitalRef',
            sortable: false,
            width: '11.8%',
          },
          {
            text: '',
            value: 'action',
            sortable: false,
            width: 'auto',
          },
        ],
        items: [],
      },
      search: '',
      page: 1,
      lengthPage: 0,
      isLoading: false,
    };
  },
  watch: {
    search() {
      this.searchData(this);
      this.page = 1;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getBPJSPatient();
    });
  },
  computed: {
    textDateType() {
      if (this.filter.singleDate !== '') {
        return `Tanggal ${moment(this.filter.singleDate).format(
          'DD MMMM, YYYY',
        )}`;
      } else if (this.filter.month !== '') {
        return `Bulan ${moment(this.filter.month).format('MMMM, YYYY')}`;
      } else if (this.filter.startDate !== '' && this.filter.endDate !== '') {
        return `Tanggal ${moment(this.filter.startDate).format(
          'DD-MM-YYYY',
        )} s.d ${moment(this.filter.endDate).format('DD-MM-YYYY')}`;
      } else {
        return '';
      }
    },
    showData() {
      return '/reporting/laporan-pelayanan/pasien-rujukan-bpjs';
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '51vh';
          break;
        case 'lg':
          height = '54vh';
          break;
        case 'xl':
          height = '61vh';
          break;
      }
      return height;
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    handleSingleDateChange(updatedDate) {
      this.filter.singleDate = '';
      if (this.filter.singleDate === '') {
        this.filter.singleDate = updatedDate;
      }
      this.filter.month = '';
      this.filter.endDate = '';
      this.filter.startDate = '';
      this.page = 1;
      this.getBPJSPatient();
    },
    handleMonthChange(updatedDate) {
      this.filter.month = '';
      if (this.filter.month === '') {
        this.filter.month = updatedDate;
      }
      this.filter.singleDate = '';
      this.filter.endDate = '';
      this.filter.startDate = '';
      this.page = 1;
      this.getBPJSPatient();
    },
    handleStartDateChange(updatedDate) {
      this.filter.startDate = updatedDate;
      if (this.filter.endDate !== '' && this.filter.startDate !== '') {
        this.filter.month = '';
        this.filter.singleDate = '';
        this.page = 1;
        this.getBPJSPatient();
      }
    },
    handleEndDateChange(updatedDate) {
      this.filter.endDate = updatedDate;
      if (this.filter.endDate !== '' && this.filter.startDate !== '') {
        this.filter.month = '';
        this.filter.singleDate = '';
        this.page = 1;
        this.getBPJSPatient();
      }
    },
    print(id_registration) {
      this.dialog = true;
      this.selectedPatientIdRegist = id_registration;
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.page = 1;
      v.getBPJSPatient();
    }, 650),
    refresh() {
      this.isRefresh = true;
      this.getBPJSPatient();
    },
    async exportBPJSPatient() {
      this.isLoadingExport = true;
      try {
        let params = {
          isExport: true,
          search: this.search,
          filterBy: this.filter.filterBy,
          startDate: this.filter.startDate,
          endDate: this.filter.endDate,
          dateType: this.filter.dateType,
        };
        if (this.filter.dateType === 'perday') {
          delete params.endDate;
          params.startDate = this.filter.singleDate;
        }
        if (this.filter.dateType === 'permonth') {
          params.startDate = moment(this.filter.month, 'YYYY-MM')
            .startOf('month')
            .format('YYYY-MM-DD');
          params.endDate = moment(this.filter.month, 'YYYY-MM')
            .endOf('month')
            .format('YYYY-MM-DD');
        }
        const res = await getReportBPJS(params);
        if (res.status === 200) {
          this.snackbar.show = true;
          this.snackbar.text = 'Export Berhasil';
          setTimeout(() => {
            this.snackbar.show = false;
          }, 4000);
          this.isLoadingExport = false;

          const { url } = res.config;
          window.open(
            `${Constant.apiUrl.concat(url)}?startDate=${params.startDate ||
              ''}&endDate=${params.endDate || ''}&search=${
              params.search
            }&filterBy=${params.filterBy}&dateType=${params.dateType}`,
            '_blank',
          );
        }
      } catch (error) {
        if (error.response.status === 404) {
          this.snackbar.show = true;
          this.snackbar.color = 'error';
          this.snackbar.text = 'Tidak ada data yang diexport';
          setTimeout(() => {
            this.snackbar.show = false;
          }, 4000);
          this.isLoadingExport = false;
        } else {
          this.snackbar.show = true;
          this.snackbar.color = 'error';
          this.snackbar.text =
            'Terjadi kesalahan, tidak dapat mengexport dokumen';
          setTimeout(() => {
            this.snackbar.show = false;
          }, 4000);
          this.isLoadingExport = false;
        }
      }
    },
    async getBPJSPatient() {
      this.isLoading = true;
      this.bpjs.items = [];
      const itemCount = this.itemsPerRow;
      try {
        let params = {
          isExport: false,
          search: this.search,
          page: this.page,
          filterBy: this.filter.filterBy,
          startDate: this.filter.startDate,
          endDate: this.filter.endDate,
          itemCount: itemCount,
        };
        if (this.filter.dateType === 'perday') {
          delete params.endDate;
          params.startDate = this.filter.singleDate;
        }
        if (this.filter.dateType === 'permonth') {
          params.startDate = moment(this.filter.month, 'YYYY-MM')
            .startOf('month')
            .format('YYYY-MM-DD');
          params.endDate = moment(this.filter.month, 'YYYY-MM')
            .endOf('month')
            .format('YYYY-MM-DD');
        }
        const res = await getReportBPJS(params);
        if (res.status === 200) {
          const data = res.data.data;
          this.dataLength = res.data.length;
          this.lengthPage = Math.ceil(this.dataLength / itemCount);
          this.bpjs.items = this.mapDetailBPJSPatient(
            data,
            itemCount,
            this.page,
          );
        }
      } catch (error) {
        this.bpjs.items = [];
        this.dataLength = 0;
        this.lengthPage = 0;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;

  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #404040;
  }
  .update {
    color: #31b057;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  ::v-deep .v-data-table-header {
    color: #9e9e9e;
    background-color: #f5f5f5;
  }

  ::v-deep {
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr.v-data-table__expanded__content {
      box-shadow: none;
    }

    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td {
      color: #404040;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr
      > th {
      color: #9e9e9e;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .container-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
  ::v-deep .v-select__selection--comma {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  ::v-deep .v-text-field input {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.table-data {
  max-height: 30vh;
}

.pagination {
  width: 100%;
}
.icon-head {
  max-width: 50px;
}
.btn-show {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.expand {
  background-color: #f4f5f5;
  &__caption {
    color: #828282;
    font-size: 12px;
  }
}

.loadingrefresh {
  -webkit-animation: fa-spin 0.2s infinite linear;
  -moz-animation: fa-spin 0.2s infinite linear;
  -o-animation: fa-spin 0.2s infinite linear;
  animation: fa-spin 0.2s infinite linear;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
6
