import Constant from '@/const';
import axios from 'axios';

const updateDrugSalesItemBilling = async ({ transaction_code, payload }) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/billing/drugssales/update/${transaction_code}`),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default updateDrugSalesItemBilling;
