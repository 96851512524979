<template>
  <section class="pl-15 pr-4">
    <div class="d-flex justify-end" style="height: 3vh;">
      <span v-if="loading" class="success--text loading-text"
        >Menyimpan<span>.</span><span>.</span><span>.</span></span
      >
      <span
        v-if="alertMessage.show"
        :class="alertMessage.isFail ? 'error--text' : 'success--text'"
      >
        Form {{ alertMessage.text.toLowerCase() }}
        {{ alertMessage.isFail ? 'gagal' : 'berhasil' }} disimpan
      </span>
    </div>
    <skeleton :isLoading="isLoading" class="mx-4">
      <v-tabs grow v-model="tabs" style="border-bottom: 2px solid #f5f5f5;">
        <v-tab
          v-for="(tab, index) in tabsResource"
          :key="`tab-${index}`"
          class="text-none"
          >{{ tab.text }}
          <template v-if="isEMR ? tab.icon === 'mdi-check-circle' : true">
            <v-icon
              v-if="tab.icon"
              :color="generateBadgeColor(tab.icon, index)"
              class="mb-4"
              small
              >{{ tab.icon }}
            </v-icon>
          </template>
        </v-tab>
      </v-tabs>
    </skeleton>
    <div class="content">
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <initial-assessment
            :isEMR="isEMR"
            :isLoading="isLoading"
            ref="initial_assessment"
          />
        </v-tab-item>
        <v-tab-item>
          <anamnesa :isEMR="isEMR" ref="anamnesis" />
        </v-tab-item>
        <v-tab-item>
          <objective-data :isEMR="isEMR" ref="objective" />
        </v-tab-item>
        <v-tab-item>
          <physical-examination :isEMR="isEMR" />
        </v-tab-item>
        <v-tab-item>
          <pain-assessment-inpatient :isEMR="isEMR" ref="pain_assessment" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-footer
      style="background-color: white; border-top: 1px solid #e0e0e0"
      class="d-flex pt-2 justify-space-between"
    >
      <div class="d-flex">
        <v-btn
          @click="navigate(tabs - 1)"
          :disabled="tabs === 0"
          color="primary"
          class="mr-3 nav-btn"
          depressed
          light
          outlined
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn
          class="nav-btn"
          :disabled="tabs === 4"
          @click="navigate(tabs + 1)"
          color="primary"
          depressed
          light
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div>
      <v-btn
        v-if="tabs === 4 && !isEMR"
        color="primary"
        @click="save"
        depressed
        light
        class="text-capitalize"
        >{{ getPainAssessment.isSaved ? 'Simpan Perubahan' : 'Simpan' }}</v-btn
      >
    </v-footer>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </section>
</template>

<script>
import Skeleton from '../../../../../SharedComponent/Skeleton.vue';
import InitialAssessment from './InitialAssessment.vue';
import Anamnesa from './Anamnesa.vue';
import ObjectiveData from './ObjectiveData.vue';
import PhysicalExamination from './PhysicalExamination.vue';
import PainAssessmentInpatient from './PainAssessmentInpatient.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'EarlyAssessmentInpatientStore',
);

export default {
  name: 'EarlyInpatientAssessment',
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      snackbar: {
        show: false,
        color: '',
        text: '',
      },
    };
  },
  components: {
    InitialAssessment,
    ObjectiveData,
    PhysicalExamination,
    PainAssessmentInpatient,
    Skeleton,
    Anamnesa,
  },
  watch: {
    tabs(destination, origin) {
      this.formValidation({ destination, origin });
      switch (origin) {
        case 0:
          if (this.getIsInitialAssessmentModified) {
            this.resolvePostEarlyAssessmentInpatient(origin);
          }
          break;
        case 1:
          if (this.getIsAnamnesisModified) {
            this.resolvePostEarlyAssessmentInpatient(origin);
          }
          break;
        case 2:
          if (this.getIsObjectiveModified) {
            this.resolvePostEarlyAssessmentInpatient(origin);
          }
          break;
        case 3:
          if (this.getIsPhysicalModified) {
            this.resolvePostEarlyAssessmentInpatient(origin);
          }
          break;
        case 4:
          if (this.getIsPainAssessmentModified) {
            this.resolvePostEarlyAssessmentInpatient(origin);
          }
          break;
      }
      this.resolveGetEarlyAssessmentInpatient({
        tab: destination,
        validate: this.validateForm,
      });
    },
  },
  mounted() {
    this.getAllData();
  },
  beforeDestroy() {
    // this.clearAllFormState();
  },
  computed: {
    ...mapGetters([
      'getTab',
      'getLoading',
      'getTabsResource',
      'getShowSuccessMessage',
      'getIsObjectiveModified',
      'getIsAnamnesisModified',
      'getIsPhysicalModified',
      'getIsPainAssessmentModified',
      'getIsInitialAssessmentModified',
      'getPainAssessment',
    ]),
    tabs: {
      get() {
        return this.getTab;
      },
      set(value) {
        this.setTab(value);
      },
    },
    tabsResource: {
      get() {
        return this.getTabsResource;
      },
    },
    alertMessage: {
      get() {
        return this.getShowSuccessMessage;
      },
    },
    loading: {
      get() {
        return this.getLoading.save;
      },
    },
  },
  methods: {
    ...mapMutations(['clearAllFormState', 'setTab']),
    ...mapActions([
      'resolvePostEarlyAssessmentInpatient',
      'resolveGetEarlyAssessmentInpatient',
      'formValidation',
    ]),
    async getAllData() {
      try {
        this.isLoading = true;
        for (let i = 0; i < 5; i++) {
          if (!i) {
            await this.resolveGetEarlyAssessmentInpatient({
              tab: i,
              validate: this.validateForm,
            });
          }
          this.resolveGetEarlyAssessmentInpatient({
            tab: i,
            validate: this.validateForm,
          });
        }
      } catch {
        this.snackbar = {
          show: true,
          text:
            'Terjadi Kesalahan, gagal mendapatkan data assesmen awal rawat inap',
          color: 'error',
        };
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        await this.resolvePostEarlyAssessmentInpatient(4);
        this.snackbar = {
          show: true,
          text: 'Formulir Assesmen Awal Berhasil Disimpan',
          color: 'success',
        };
      } catch {
        this.snackbar = {
          show: true,
          text: 'Terjadi Kesalahan, Formulir Assesmen Awal Gagal Disimpan',
          color: 'error',
        };
      } finally {
        await this.resolveGetEarlyAssessmentInpatient({
          tab: 4,
          validate: this.validateForm,
        });
      }
    },
    navigate(tab) {
      this.tabs = tab;
    },
    generateBadgeColor(icon, index) {
      let color;

      if (icon === 'mdi-alert-circle') {
        color = 'error';
      }
      if (icon === 'mdi-check-circle' && this.tabs === index) {
        color = 'success';
      }
      if (icon === 'mdi-check-circle' && this.tabs !== index) {
        color = '#9ca2a5';
      }

      return color || '';
    },
    validateForm(index) {
      this.$refs[this.tabsResource[index].type]?.$refs?.form?.validate();
    },
  },
};
</script>

<style scoped lang="scss">
.text-none {
  letter-spacing: 0.1px;
  font-size: 0.8vw;
}

.content {
  height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 5px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px !important;
    border-radius: 5px;
    background-color: rgb(163, 163, 163) !important;
  }
}
.v-tabs-items {
  overflow-y: auto;
  overflow-x: hidden;
}

.nav-btn {
  margin-top: 0.5vw;
  height: 3.5vh !important;
  padding: 0px;
}

.loading-text {
  font-size: 0.85vw;

  span:nth-child(1) {
    animation: fadeinout 1s infinite;
    animation-delay: 4s;
  }
  span:nth-child(2) {
    animation: fadeinout 1s infinite;
    animation-delay: 1.5s;
  }
  span:nth-child(3) {
    animation: fadeinout 1s infinite;
    animation-delay: 1s;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
