<template>
  <v-card class="main-card">
    <v-form ref="form">
      <v-card-title
        primary-title
        class="font-weight-bold py-0 d-flex justify-space-between mb-4"
      >
        Penjualan Obat Bebas
        <v-btn small text fab @click="$emit('close-form')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <div class="patient-data">
        <!-- first row -->
        <span class="label-text">No Transaksi</span>
        <span class="data-text">{{ patientData.registNumber }}</span>
        <span class="label-text">Nama Operator</span>
        <span class="data-text">{{ patientData.operatorName }}</span>
        <!-- second row -->
        <span class="label-text">Nama</span>
        <span class="data-text"
          >{{ patientData.name }} ({{ patientData.gender ? 'L' : 'P' }})</span
        >
        <span class="label-text">Usia</span>
        <span class="data-text">{{ patientData.age }} Tahun</span>
        <span class="label-text">Tanggal Pelayanan</span>
        <span class="data-text"
          >{{ patientData.date }} pukul {{ patientData.time }}</span
        >
      </div>
      <div class="content-container">
        <v-row class="mb-4">
          <v-col class="pa-9 pb-0">
            <table>
              <thead>
                <tr
                  :class="{ 'not-editable': isHasPayment }"
                  class="grid-table header"
                >
                  <th
                    v-for="(item, index) in tableHeaders"
                    :key="'header' + index"
                    :class="item.class"
                  >
                    {{ item.text }}
                  </th>
                </tr>
              </thead>
              <div class="table-body-container">
                <tr
                  :class="{ 'not-editable py-3': isHasPayment }"
                  class="grid-table"
                  v-for="(item, index) in table.items"
                  :key="'row-' + index"
                >
                  <td class="text-left">
                    {{ index + 1 }}
                  </td>
                  <td class="text-left">
                    {{ item.drug }}
                  </td>
                  <td
                    v-if="item.type !== 'mixed_medicine' && !isHasPayment"
                    class="text-left"
                  >
                    <v-text-field
                      v-model="item.amount"
                      @input="onChangeAmount(index)"
                      :rules="[rules.required, rules.noZeroRule]"
                      :disabled="!item.isEdited"
                      type="number"
                      single-line
                    />
                  </td>
                  <td v-else class="text-left">
                    {{ item.amount }}
                  </td>
                  <td class="text-left">
                    {{ item.unit || '-' }}
                  </td>
                  <td class="text-right">
                    {{ currency(item.pricePerUnit) }}
                  </td>
                  <td class="text-right pr-3">
                    {{ currency(item.subtotal) }}
                  </td>
                  <td
                    v-if="item.type === 'mixed_medicine' && !isHasPayment"
                    class="text-right"
                  >
                    <v-text-field
                      :disabled="!item.isEdited"
                      v-model="item.tuslah"
                      type="number"
                      single-line
                      placeholder="0"
                      prefix="Rp"
                    />
                  </td>
                  <td
                    v-else-if="item.type === 'mixed_medicine' && isHasPayment"
                    class="text-right"
                  >
                    {{ currency(item.tuslah) }}
                  </td>
                  <td v-else class="text-right">
                    Rp -
                  </td>
                  <td class="text-right pr-3">
                    {{ currency(item.subtotal + (+item.tuslah || 0)) }}
                  </td>
                  <td class="text-right">
                    <v-text-field
                      v-if="!isHasPayment"
                      v-model="item.percentDisc"
                      :rules="[rules.discount]"
                      type="number"
                      @input="onChangeDiscount(index)"
                      placeholder="0"
                      :disabled="!item.isEdited"
                      single-line
                      suffix="%"
                    />
                    <span v-else>{{ item.percentDisc }}</span>
                  </td>
                  <td class="text-right">
                    {{ currency(item.totalDisc) }}
                  </td>
                  <td class="text-right">
                    {{
                      currency(
                        item.subtotal + (+item.tuslah || 0) - +item.totalDisc,
                      )
                    }}
                  </td>
                  <td class="text-right" v-if="!isHasPayment">
                    <div class="d-flex align-center">
                      <v-tooltip v-if="!item.isEdited" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(null, index)"
                            :disabled="isEditing && !item.isEdited"
                            class="mr-2"
                            small
                            text
                            fab
                            dense
                            depressed
                            ><v-icon color="#757575" style="font-size: 1vw;"
                              >mdi-pencil</v-icon
                            ></v-btn
                          >
                        </template>
                        <span>Edit Obat</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(item, index)"
                            :disabled="isEditing && !item.isEdited"
                            style="max-width: 1.5vw !important; min-width: 0;"
                            class="mx-2"
                            color="primary"
                            small
                            outlined
                            dense
                            depressed
                            ><v-icon color="primary" style="font-size: 1vw;"
                              >mdi-check</v-icon
                            ></v-btn
                          >
                        </template>
                        <span>Simpan Perubahan</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            :disabled="table.items.length === 1 || isEditing"
                            v-on="on"
                            @click="deleteItem(item)"
                            small
                            text
                            fab
                            dense
                            depressed
                            ><v-icon color="#757575" style="font-size: 1vw;"
                              >mdi-delete</v-icon
                            ></v-btn
                          >
                        </template>
                        <span>Hapus Obat</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </div>
            </table>
          </v-col>
        </v-row>
        <v-form ref="payment-form">
          <v-row>
            <v-col cols="9"></v-col>
            <v-col class="pr-10">
              <div class="grid-payment">
                <p class="text-left">Total Keseluruhan</p>
                <p class="text-right">{{ currency(totalBilling) }}</p>
                <p class="text-left">Diskon Tambahan</p>
                <div class="mini-grid">
                  <v-text-field
                    single-line
                    v-if="!isHasPayment"
                    @blur="onUpdateDiscount"
                    :rules="[rules.discount]"
                    v-model="additionalDiscount"
                    class="pa-0 ma-0"
                    suffix="%"
                    placeholder="0"
                  />
                  <p v-else>{{ additionalDiscount }}%</p>
                  <p class="text-right my-3">
                    {{ currency(additionalDiscountNominal) }}
                  </p>
                </div>
              </div>
              <v-divider class="mt-2 mb-3" />
              <div class="grid-payment">
                <p class="text-left">Total Akhir</p>
                <p class="text-right">{{ currency(finalBilling) }}</p>
                <p class="text-left">Dana Kebajikan</p>
                <p class="text-right">{{ currency(roundTotal) }}</p>
              </div>
              <v-divider class="mt-2 mb-3" />
              <div class="grid-payment">
                <p class="text-left primary--text font-weight-bold">
                  Sisa Tagihan
                </p>
                <p class="text-right primary--text">
                  {{ currency(bill > 0 ? bill : 0) }}
                </p>
                <template v-if="!isPaid">
                  <p class="text-left font-weight-bold">Input Pembayaran</p>
                  <v-currency-field
                    single-line
                    auto-decimal-mode
                    :rules="[rules.payment]"
                    v-model="input.payment"
                    style="text-align: end !important;"
                    decimal-length="0"
                    class="pa-0 ma-0 mb-5 input-payment"
                    prefix="Rp"
                    placeholder="0,00"
                  />
                  <p class="text-left font-weight-bold">Kembalian</p>
                  <p class="text-right">{{ currency(totalChange) }}</p>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-btn
        class="btn-save text-capitalize"
        :loading="isLoadingPayment"
        v-if="!isPaid"
        color="primary"
        depressed
        @click="pay()"
        >Bayar</v-btn
      >
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.type === 'error' ? 'red' : 'success'"
        content-class="text-center font-weight-bold"
        height="20"
        :timeout="2000"
      >
        <div class="d-flex justify-space-between">
          <span class="mr-auto">{{ snackbar.message }}</span>
          <v-icon
            class="ml-auto mr-2"
            dark
            small
            @click="() => (snackbar.show = false)"
          >
            mdi-close
          </v-icon>
        </div>
      </v-snackbar>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';

import {
  updateDrugSalesItemBilling,
  deleteDrugSalesItemBilling,
  updateDiscountDrugSales,
  putPaymentDrugSales,
} from '@/fetchApi/DrugSales';
export default {
  name: 'OverCounterBilling',
  mixins: [formatMixin, AlertMixin],
  props: {
    idBilling: {
      type: String,
      default: '',
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingPayment: false,
      additionalDiscount: '',
      amountPaid: 0,
      snackbar: {
        type: '',
        show: false,
        message: '',
      },
      input: { payment: '' },
      patientData: {
        registNumber: '',
        name: '',
        operatorName: '',
        age: '',
        date: '',
        time: '',
        gender: '',
      },
      table: {
        items: [],
        itemCount: 5,
        page: 1,
      },
      rules: {
        required: value => !!value || 'Wajib diisi',
        noZeroRule: value => +value > 0 || 'Harus lebih dari 0',
        payment: value => (!!value && +value !== 0) || 'Pembayaran belum diisi',
        discount: value => +value <= 100 || 'Diskon melebihi 100%',
      },
    };
  },
  computed: {
    isLastItem() {
      const generateMultiplesOf = (n, length) => {
        const multiples = new Set();
        for (let i = n; i <= length; i += n) {
          multiples.add(i);
        }
        return multiples;
      };

      const itemCount = this.table.itemCount;
      const itemsLength = this.table.items.length;

      const multiplesOf5 = generateMultiplesOf(5, itemsLength);
      const multiplesOf10 = generateMultiplesOf(10, itemsLength);
      const multiplesOf15 = generateMultiplesOf(15, itemsLength);

      const lastItemIndex = itemsLength;

      return item => {
        const index = this.table.items.indexOf(item) + 1;
        if (index === lastItemIndex) return true;

        const isMultiple5 =
          itemCount === 5 && itemsLength > 5 && multiplesOf5.has(index);
        const isMultiple10 =
          itemCount === 10 && itemsLength > 10 && multiplesOf10.has(index);
        const isMultiple15 =
          itemCount === 15 && itemsLength > 15 && multiplesOf15.has(index);

        return isMultiple5 || isMultiple10 || isMultiple15;
      };
    },
    totalPrice() {
      const total = this.table.items.reduce(
        (a, b) => +a + +b.pricePerUnit * +b.amount,
        0,
      );
      return total;
    },
    totalDiscount() {
      const total = this.table.items.reduce((a, b) => +a + +b.totalDisc, 0);
      return total;
    },
    totalAll() {
      const total = this.table.items.reduce((a, b) => +a + +b.subtotal, 0);
      return total;
    },
    isEditing() {
      return this.table.items.some(item => item.isEdited);
    },
    totalBilling: {
      get() {
        return this.table.items.reduce(
          (a, b) => a + (b.subtotal + (+b.tuslah || 0) - +b.totalDisc),
          0,
        );
      },
    },
    additionalDiscountNominal: {
      get() {
        return this.additionalDiscount
          ? (this.totalBilling * this.additionalDiscount) / 100
          : 0;
      },
    },
    finalBilling: {
      get() {
        return this.totalBilling - this.additionalDiscountNominal;
      },
    },
    bill: {
      get() {
        return this.finalBilling + this.roundTotal - this.amountPaid;
      },
    },
    totalChange: {
      get() {
        return this.input.payment - this.bill < 0
          ? 0
          : this.input.payment - this.bill;
      },
    },
    roundTotal: {
      get() {
        let mod = this.finalBilling % 1000;
        let add = mod >= 500 ? 1000 - mod : 500 - mod;
        if (mod == 0 || mod === 500) add = 0;
        return add;
      },
    },
    isHasPayment: {
      get() {
        return this.amountPaid > 0;
      },
    },
    tableHeaders: {
      get() {
        return [
          {
            text: 'No',
            class: 'text-left',
          },
          {
            text: 'Item Pelayanan',
            class: 'text-left',
          },
          {
            text: 'Jumlah',
            class: 'text-left',
          },
          {
            text: 'Satuan',
            class: 'text-left',
          },
          {
            text: 'Harga',
            class: 'text-right',
          },
          {
            text: 'Sub Total',
            class: 'text-right pr-3',
          },
          {
            text: 'Biaya Tuslah',
            class: 'text-right',
          },
          {
            text: 'Harga Setelah Tuslah',
            class: 'text-right pr-3',
          },
          {
            text: 'Diskon (%)',
            class: 'text-right',
          },
          {
            text: 'Diskon (Rp)',
            class: 'text-right',
          },
          {
            text: 'Total',
            class: 'text-right',
          },
        ].concat(
          this.isHasPayment ? [] : [{ text: 'Action', class: 'text-right' }],
        );
      },
    },
  },
  mounted() {
    this.getBilling();
  },
  methods: {
    async editItem(item, index) {
      if (!this.$refs.form.validate()) return;
      try {
        if (this.isEditing) {
          await updateDrugSalesItemBilling({
            transaction_code: this.patientData.registNumber,
            payload: {
              id: item.id,
              amount: +item.amount,
              tuslah_price: +item.tuslah || 0,
              discount: +item.percentDisc || 0,
            },
          });
          this.snackbar = {
            show: true,
            message: 'Berhasil menyimpan penyesuaian',
            type: 'success',
          };
        }
        this.table.items = this.table.items.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              isEdited: !item.isEdited,
            };
          }
          return item;
        });
      } catch {
        this.snackbar = {
          show: true,
          message: 'Terjadi kesalahan, gagal menyimpan penyesuaian',
          type: 'error',
        };
      }
    },
    onChangeDiscount(index) {
      this.table.items = this.table.items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            totalDisc:
              (item.pricePerUnit * item.amount + (+item.tuslah || 0)) *
              (item.percentDisc / 100),
          };
        }
        return item;
      });
    },
    async onUpdateDiscount() {
      if (!this.additionalDiscount) return;
      try {
        const payload = {
          discount: +this.additionalDiscount,
        };
        await updateDiscountDrugSales({
          transaction_code: this.patientData.registNumber,
          payload,
        });
      } catch {
        this.snackbar = {
          show: true,
          message: 'Terjadi kesalahan, gagal menyimpan diskon',
          type: 'error',
        };
      }
    },
    onChangeAmount(index) {
      this.table.items = this.table.items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            subtotal: item.pricePerUnit * item.amount,
          };
        }
        return item;
      });
    },
    async pay() {
      if (this.isEditing) {
        this.snackbar = {
          show: true,
          message: 'Simpan edit terlebih dahulu',
          type: 'error',
        };
        return;
      }
      if (!this.$refs['payment-form'].validate()) return;
      this.isLoadingPayment = true;
      try {
        const payload = {
          amount: +this.input.payment,
        };
        await putPaymentDrugSales({
          transaction_code: this.patientData.registNumber,
          payload,
        });
        this.$emit('close-form', {
          show: true,
          text: 'Pembayaran Obat Berhasil',
          color: '#2b9c4d',
        });
      } catch (error) {
        this.snackbar = {
          show: true,
          message: error.response.data.message,
          type: 'error',
        };
      } finally {
        this.isLoadingPayment = false;
      }
    },
    async deleteItem(item) {
      Swal.fire({
        title: 'Peringatan',
        text: `Apakah Anda Yakin Akan Menghapus ${item.drug}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'red',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        reverseButtons: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await deleteDrugSalesItemBilling({
              transaction_code: this.patientData.registNumber,
              payload: {
                id: item.id,
              },
            });
            this.snackbar = {
              show: true,
              message: 'Berhasil menghapus item',
              type: 'success',
            };
            this.table.items = [];
            this.getBilling();
          } catch {
            this.snackbar = {
              show: true,
              message: 'Terjadi kesalahan, gagal menghapus item',
              type: 'error',
            };
          }
        }
      });
    },
    async getBilling() {
      this.isLoading = true;
      try {
        let mix, nonmix, medtool;
        const response = await axios.get(
          Constant.apiUrl.concat(`/billing/drugssales/${this.idBilling}`),
        );
        const { data } = response.data;
        this.additionalDiscount = data.additional_discount;
        this.amountPaid = data.total_paid;
        this.patientData = {
          registNumber: data.transaction_code,
          name: data.buyer_data.buyer_name,
          operatorName: data.timestamps.created_by?.name,
          age: data.buyer_data.buyer_age,
          gender: data.buyer_data.buyer_gender,
          date: moment(data.order_date).format('DD/MM/YYYY'),
          time: moment(data.order_date).format('HH:mm'),
          isMixed: false,
        };
        mix = data.detail.mixed_medicine.map(drug => {
          return {
            isMixed: true,
            drugs: drug.drugs.map(i => {
              return {
                id_drugs: i.id_drugs._id,
                id_stock: i.id_stock,
              };
            }),
            type: 'mixed_medicine',
            drug: drug?.name,
            amount: drug.amount,
            unit: drug.amount_unit,
            pricePerUnit: drug.price,
            id: drug._id,
            tuslah: drug.tuslah_price || '',
            totalPrice: drug.price * drug.amount,
            percentDisc: drug.discount_percentage || '',
            totalDisc: drug.discount_price,
            subtotal: drug.price * drug.amount,
            isEdited: false,
          };
        });
        nonmix = data.detail.non_mixed_medicine.map(drug => {
          return {
            isMixed: false,
            type: 'non_mixed_medicine',
            id_stock: drug.id_stock,
            drug: drug.id_drugs.detail?.name,
            amount: drug.amount,
            unit: drug.id_drugs.detail?.packaging?.filter(i => i.isDefault)[0]
              ?.package_unit,
            pricePerUnit: drug.price,
            id: drug._id,
            totalPrice: drug.price * drug.amount,
            percentDisc: drug.discount_percentage,
            totalDisc: drug.discount_price,
            subtotal: drug.price * drug.amount,
            idDrug: drug.id_drugs._id,
            isEdited: false,
          };
        });
        medtool = data.detail.medical_goods.map(drug => {
          return {
            drug: drug.id_drugs
              ? drug.id_drugs.detail?.name
              : drug.id_goods.detail?.name,
            type: 'medical_goods',
            id_stock: drug.id_stock,
            amount: drug.amount,
            unit: drug.id_drugs
              ? drug.id_drugs.detail?.packaging?.filter(i => i.isDefault)[0]
                  ?.package_unit
              : drug.id_goods.detail?.packaging?.filter(i => i.isDefault)[0]
                  ?.package_unit,
            pricePerUnit: drug.price,
            id: drug._id,
            totalPrice: drug.price * drug.amount,
            percentDisc: drug.discount_percentage,
            totalDisc: drug.discount_price,
            subtotal: drug.price * drug.amount,
            idDrug: drug.id_drugs ? drug.id_drugs._id : drug.id_goods._id,
            isEdited: false,
          };
        });
        this.table.items.push(...mix, ...nonmix, ...medtool);
      } catch (error) {
        this.table.items = [];
        this.showErrorAxios(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-payment {
  text-align: center;
}

.grid-payment {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;

  p {
    color: #616161;
  }

  .mini-grid {
    display: grid;
    align-items: baseline;
    grid-template-columns: 0.6fr auto;
  }

  .input-payment :deep(input) {
    text-align: end;
  }
}

.patient-data {
  padding: 0px 1.5rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr) 4fr;
  row-gap: 0.3rem;
}
.main-card {
  overflow-x: hidden;

  .label-text {
    font-size: 0.9vw;
    color: #404040;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: left;
  }

  .data-text {
    text-align: left;
    font-size: 0.9vw;
    color: #404040;
  }
}
.btn-save {
  position: absolute;
  bottom: 3vh;
  right: 2vw;
}

table {
  width: 100%;
  font-size: 0.85vw;

  .table-body-container {
    max-height: 45vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #757575;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 3% 17% 5% 3% 10% 12% 8% 12% 5% 9% 9% 5%;
    padding: 0 12px;
    align-items: center;
    color: #404040;

    &.not-editable {
      grid-template-columns: 3% 17% 5% 3% 10% 12% 10% 12% 5% 10% 11%;
    }

    &.header {
      color: #9e9e9e;
      padding: 12px;
      border-bottom: 1px solid #e0e0e0;
      background-color: #f5f5f5;
    }
  }
}

.content-container {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}
</style>
