<template>
  <div class="patientbilling">
    <!-- <v-toolbar flat min-height="110"> -->
    <div class="mt-2 px-6">
      <!-- <v-container fluid class="pa-0"> -->
      <v-row no-gutters align="center" class="d-flex justify-space-between">
        <!-- <div> -->
        <div class=" text-left">
          <label class="text-h5 font-weight-bold" for="">Tagihan Pasien</label>
          <p class="text-grey text-body-2 mt-1">
            {{ patientRegistered }} pasien terdaftar, {{ patientServed }} pasien
            terlayani, {{ paid.paid_off }} tagihan lunas,
            {{ paid.not_paid }} tagihan belum lunas
          </p>
        </div>
        <div class="d-flex flex-row align-center">
          <v-btn small text fab class="mx-3">
            <router-link :to="routeBack" active>
              <v-icon color="grey">mdi-home</v-icon>
            </router-link>
          </v-btn>
          <v-btn
            class="ma-1 mx-2"
            color="grey"
            icon
            text
            small
            @click="refresh()"
          >
            <v-icon :class="{ refresh: isRefresh }">mdi-refresh</v-icon>
          </v-btn>
          <v-text-field
            background-color="grey lighten-4"
            placeholder="Search"
            class="mx-4"
            style="width: 15vw;"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
          <div class="d-flex justify-center">
            <v-btn
              medium
              outlined
              color="primary"
              class="d-flex align-center px-2 text-none"
              @click="exportBilling()"
              :loading="isLoadingExport"
            >
              <v-icon medium class="mr-1 mt-1">mdi-download</v-icon>
              Export
            </v-btn>
          </div>
          <!-- <div class="ml-4">
            <div class="caption font-weight-medium text-right">
              {{ patientRegistered }} Pasien terdaftar,
              {{ patientServed }} Pasien terlayani
            </div>
            <div class="caption font-weight-medium text-right">
              {{ paid.paid_off }} Tagihan lunas, {{ paid.not_paid }} Tagihan
              belum lunas
            </div>
          </div> -->
        </div>
        <!-- </div> -->
      </v-row>
    </div>
    <v-divider></v-divider>
    <div
      class="mt-4 px-6 mb-6"
      :class="`filter-header__${assuranceType === 'bpjs' ? 'four' : 'three'}`"
    >
      <div>
        <v-autocomplete
          placeholder="Tipe Penjamin"
          v-model="assuranceType"
          append-icon="mdi-chevron-down"
          outlined
          :items="assurance"
          v-if="tabs !== 2"
          dense
          clearable
          hide-details="false"
          @change="
            resetPage();
            showAllBilling();
          "
        >
        </v-autocomplete>
      </div>
      <div>
        <v-autocomplete
          hide-details="false"
          dense
          outlined
          item-text="name"
          return-object
          placeholder="Pilih Unit"
          append-icon="mdi-chevron-down"
          v-model="unitType"
          :items="unit"
          v-if="tabs !== 2"
          @change="showAllBilling"
          clearable
        >
        </v-autocomplete>
        <v-autocomplete
          hide-details="false"
          dense
          outlined
          return-object
          placeholder="Status Pembayaran"
          v-model="status"
          :items="resourceStatus"
          append-icon="mdi-chevron-down"
          v-if="tabs === 2"
          @click:clear="showAllBilling()"
          @change="
            resetPage();
            showAllBilling();
          "
          clearable
        >
        </v-autocomplete>
      </div>
      <div class="d-flex flex-direction-column">
        <v-menu
          class="ma-0 pa-0"
          ref="menu"
          v-if="tabs !== 2"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              append-icon="mdi-chevron-down"
              v-model="dateRange.billing.formatted"
              :value="dateRange.billing.formatted"
              outlined
              placeholder="Rentang Tanggal"
              @click:clear="clearDate()"
              class="input text-left"
              clearable
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            :max="today"
            multiple
            v-model="selectedDateBilling"
            @input="changedate"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                menu2 = false;
                datepicker = false;
                menu2 = false;
              "
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                menu2 = false;
                resetPage();
                showAllBilling();
                getPaidOff();
                getServedCount();
                $refs.menu.save(date);
              "
            >
              OK
            </v-btn></v-date-picker
          >
        </v-menu>
        <v-menu
          v-if="tabs === 2"
          offset-y
          :close-on-content-click="false"
          v-model="menu2"
          ref="menu"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-4 input-filter date"
              dense
              flat
              v-on="on"
              v-bind="attrs"
              readonly
              append-icon="mdi-chevron-down"
              placeholder="Rentang Tanggal"
              v-model="dateRange.otc.formatted"
              :value="dateRange.otc.formatted"
              single-line
              clearable
              @change="getOtcData()"
              @click:clear="clearDate()"
              hide-details
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            multiple
            :max="today"
            v-model="selectedDate"
            @input="changedate"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                datepicker = false;
                menu2 = false;
              "
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                resetPage();
                menu2 = false;
                showAllBilling();
                $refs.menu.save(date);
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <!-- <div v-if="assuranceType === 'bpjs'"> -->
      <div
        v-if="assuranceType === 'bpjs'"
        class="d-flex flex-row align-center justify-center"
      >
        <label class="mr-3" color="#616161">Tagihan tambahan</label>
        <v-switch
          class="my-0"
          hide-details
          v-model="addOnBilling"
          inset
          @click="
            resetPage();
            showAllBilling();
          "
        ></v-switch>
      </div>
    </div>
    <!-- </v-container> -->
    <!-- </div> -->
    <!-- </v-toolbar> -->
    <v-main>
      <v-tabs v-model="tabs" center-active grow>
        <v-tab
          class="font-weight-bold text-capitalize border-bottom"
          style="letter-spacing: 0.1px;"
          @click="selectedTab = 'wadiah'"
          @change="showAllBilling()"
          >Wadiah</v-tab
        >
        <v-tab
          class="font-weight-bold text-capitalize border-bottom"
          style="letter-spacing: 0.1px;"
          @click="selectedTab = 'tagihan'"
          @change="showAllBilling()"
          >Tagihan Pasien</v-tab
        >
        <v-tab
          class="font-weight-bold text-capitalize border-bottom"
          style="letter-spacing: 0.1px;"
          @click="selectedTab = 'otc'"
          @change="showAllBilling()"
          >Penjualan Obat Bebas</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <!-- wadiah -->
        <v-tab-item>
          <template>
            <v-data-table
              :headers="wadiah.headers"
              :items="patientBill.item"
              :items-per-page="itemsPerRow"
              :expanded="expanded"
              single-expand
              item-key="registNumber"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              hide-default-footer
              :page.sync="page"
              :loading="isLoading"
              class="mt-1"
            >
              <template v-slot:[`item.no`]="{ item, index }">
                <tr class="text-left" :key="index">
                  <td>
                    {{ item.no + 1 }}
                  </td>
                </tr>
              </template>

              <template v-slot:[`item.assurance`]="{ item, index }">
                <tr class="d-flex align-center text-left" :key="index">
                  <td>{{ item.assurance.toUpperCase() }}</td>
                  <div class="addon-chip d-flex align-center">
                    <v-icon small :style="{ color: '#BB4802' }"
                      >mdi-cash</v-icon
                    >
                  </div>
                </tr>
              </template>

              <template v-slot:[`item.balance`]="{ item, index }">
                <tr class="text-left" :key="index">
                  <td>{{ currency(item.balance || 0) }}</td>
                </tr>
              </template>

              <template v-slot:[`item.isPaid`]="{ item, index }">
                <tr class="text-left" :key="index">
                  <td>
                    <v-chip
                      small
                      caption
                      class="text-sm-center status-chip"
                      :class="{
                        paid: item.isPaid === true,
                        unpaid: item.isPaid === false,
                      }"
                      append-icon="mdi-account"
                      >{{ item.isPaid ? 'Lunas' : 'Belum Lunas' }}
                      <v-tooltip
                        bottom
                        v-if="item.isPaid && item.isOverpayment"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            right
                            small
                            style="color:#268843"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span class="">Pasien memiliki sisa kembalian</span>
                      </v-tooltip>
                    </v-chip>
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="expand px-0">
                  <v-card flat>
                    <v-row no-gutters class="detail-info">
                      <div class="text-left pl-11 ml-6 grey--text caption">
                        <span
                          >Terdaftar {{ item.time }} WIB <br />
                          {{ item.date }}</span
                        >
                      </div>
                      <div class="text-left ml-4 grey--text caption">
                        <span>{{ item.gender }}</span>
                      </div>
                      <div class="text-left grey--text caption">
                        <span> • {{ item.age }} Tahun</span>
                      </div>
                    </v-row>
                  </v-card>

                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="changeForm(item, 0)"
                              >
                                <v-icon>mdi-account</v-icon>
                              </v-btn>
                            </template>
                            <span>Data Sosial</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="changeForm(item, 1)"
                              >
                                <v-icon>mdi-receipt</v-icon>
                              </v-btn>
                            </template>
                            <span>Tagihan Pasien</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
        <!-- tagihan pasien -->
        <v-tab-item>
          <v-data-table
            :headers="patientBill.headers"
            :items="patientBill.item"
            :items-per-page="itemsPerRow"
            hide-default-footer
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            item-key="registNumber"
            :expanded="expanded"
            single-expand
            :loading="isLoading"
            style="overflow: hidden"
            class="mt-1"
          >
            <template v-slot:[`item.no`]="{ item, index }">
              <tr class="text-left" :key="index">
                <td>
                  {{ item.no + 1 }}
                </td>
              </tr>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <tr class="text-left" :key="index">
                <td>{{ item.name }}</td>
                <v-tooltip
                  bottom
                  max-width="450"
                  v-if="
                    item.pcareBridgingStatus?.registration ||
                      item.pcareBridgingStatus?.service
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor: pointer;"
                      class="ml-2"
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <div class="text-left" v-if="item.pcareBridgingStatus.both">
                    <p class="ma-0 pa-0">
                      Data pendaftaran dan pelayanan gagal terkirim ke PCare.
                      Anda bisa mengirim ulang dengan cara sebagai berikut:
                    </p>
                    <ol>
                      <li>
                        Untuk data pendaftaran, silakan kirim ulang melalui
                        dashboard pendaftaran
                      </li>
                      <li>
                        Untuk data pelayanan, silakan cek kembali asesmen pasien
                        kemudian simpan kembali untuk mengirim ulang
                      </li>
                    </ol>
                  </div>
                  <div class="text-left" v-else>
                    <span>
                      {{
                        item.pcareBridgingStatus.registration ||
                          item.pcareBridgingStatus.service
                      }}
                    </span>
                  </div>
                </v-tooltip>
              </tr>
            </template>
            <template v-slot:[`item.assurance`]="{ item, index }">
              <tr class="d-flex align-center text-left" :key="index">
                <td>{{ item.assurance.toUpperCase() }}</td>
                <div
                  v-if="item.isIncludeAddOn"
                  class="addon-chip ml-1 d-flex flex-col align-center"
                >
                  <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                    >mdi-cash</v-icon
                  >
                </div>
              </tr>

              <!-- <tr class="text-left" :key="index">
                <td>
                  {{ item.assurance.toUpperCase() }}
                  <span
                    v-if="item.isGuarantorChanged"
                    class="caption grey--text"
                    >(Diubah)</span
                  >
                </td>
              </tr> -->
            </template>

            <template v-slot:[`item.bill`]="{ item }">
              <tr>
                <td>
                  {{
                    Math.sign(item.bill) === -1 ? 0 : currency(item.bill || 0)
                  }}
                </td>
              </tr>
            </template>
            <template v-slot:[`item.isPaid`]="{ item, index }">
              <tr class="text-left" :key="index">
                <td>
                  <v-chip
                    small
                    caption
                    class="text-sm-center status-chip"
                    :class="{
                      paid: item.isPaid === true,
                      unpaid: item.isPaid === false,
                    }"
                  >
                    {{ item.isPaid ? 'Lunas' : 'Belum Lunas' }}
                    <v-tooltip bottom v-if="item.isPaid && item.isOverpayment">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          right
                          small
                          style="color:#268843"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span class="">Pasien memiliki sisa kembalian</span>
                    </v-tooltip>
                  </v-chip>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="expand px-0">
                <v-card flat>
                  <v-row align="start" no-gutters>
                    <v-col
                      cols="2"
                      class="text-left pl-11 ml-6 grey--text caption"
                    >
                      <span
                        >Terdaftar {{ item.time }} WIB <br />
                        {{ item.date }}</span
                      >
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-left ml-3 pl-9 grey--text caption"
                    >
                      <span>{{ item.gender }} {{ item.age }} Tahun</span>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card flat color="grey lighten-4" class="text-right">
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              icon
                              class="mx-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="changeForm(item, 0)"
                            >
                              <v-icon>mdi-account</v-icon>
                            </v-btn>
                          </template>
                          <span>Data Sosial</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              icon
                              class="mx-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="changeForm(item, 1)"
                            >
                              <v-icon>mdi-receipt</v-icon>
                            </v-btn>
                          </template>
                          <span>Tagihan Pasien</span>
                        </v-tooltip>

                        <v-menu
                          left
                          :offset-x="true"
                          :close-on-content-click="true"
                          v-if="
                            !item.isBpjs && item.isOutpatient && !item.isPaid
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-btn icon>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      size="21"
                                      style="padding-top: 2px"
                                    >
                                      mdi-dots-vertical
                                    </v-icon>
                                  </template>
                                  <span>Lainnya</span>
                                </v-tooltip>
                              </v-btn>
                            </span>
                          </template>

                          <v-list style="padding: 0px; min-width: 12.5rem;">
                            <v-list-item
                              @click="changeForm(item, 3)"
                              class="dropdown"
                            >
                              <v-list-item-title>
                                <span class="dropdown-item">
                                  Ubah Tipe Penjamin
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <!-- penjualan obat bebas -->
        <v-tab-item>
          <template>
            <v-data-table
              :headers="otc.headers"
              :items="otc.items"
              :items-per-page="itemsPerRow"
              :expanded="expanded"
              single-expand
              item-key="registNumber"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              hide-default-footer
              :page.sync="page"
              :loading="isLoading"
              class="mt-1"
            >
              <template v-slot:item="{ item, expand, isExpanded }">
                <tr @click="expand(!isExpanded)">
                  <td>{{ item.no + 1 }}</td>
                  <td>{{ item.registNumber }}</td>
                  <td class="text-left">{{ item.name }}</td>
                  <td>{{ item.gender }}</td>
                  <td class="text-left">{{ item.age }} Tahun</td>
                  <td class="text-left">
                    {{
                      currency(item.amountBilling > 0 ? item.amountBilling : 0)
                    }}
                  </td>
                  <td>
                    <v-chip
                      small
                      caption
                      class="text-sm-center status-chip"
                      :class="{
                        unpaid: !item.isPaid,
                        paid: item.isPaid,
                      }"
                      append-icon="mdi-account"
                      >{{ item.isPaid ? 'Lunas' : 'Belum Lunas' }}</v-chip
                    >
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="expand px-0">
                  <v-card class="py-3" flat>
                    <v-row no-gutters>
                      <div class="text-left pl-11 ml-6 grey--text caption">
                        <span
                          >Terdaftar {{ item.date }} {{ item.time }} WIB</span
                        >
                      </div>
                    </v-row>
                  </v-card>

                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="changeForm(item, 2)"
                              >
                                <v-icon>mdi-receipt</v-icon>
                              </v-btn>
                            </template>
                            <span>Tagihan Pasien</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="printInvoice(item)"
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <font-awesome-icon icon="print" />
                              </v-btn>
                            </template>
                            <span>Print Nota</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
    <v-footer color="white">
      <div class="text-center">
        <v-pagination
          v-model="patientBill.pagination.page"
          :length="lengthPage"
          :total-visible="5"
          v-if="tabs !== 2"
          @input="showAllBilling()"
        ></v-pagination>
        <v-pagination
          v-else
          v-model="otc.pagination.page"
          :length="lengthPageOTC"
          :total-visible="10"
          @input="showAllBilling()"
        ></v-pagination>
      </div>
    </v-footer>
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="dialog"
      :fullscreen="selectedForm === 1 || selectedForm === 2"
      width="auto"
    >
      <social-data
        v-if="dialog && selectedForm === 0"
        :medicalRecord="selectedPatient.rm_number"
        @close-form="closeForm"
      />
      <dialog-billing
        v-if="dialog && selectedForm === 1"
        :billingData="selectedPatient"
        @close-form="closeForm()"
        @close-detail="closeForm()"
      />
      <over-counter-billing
        @close-form="closeForm"
        v-if="dialog && selectedForm === 2 && tabs === 2"
        :idBilling="selectedPatient.id"
        :isPaid="selectedPatient.isPaid"
      />
      <assurance-type-change
        :patient-data="selectedPatient"
        @close-form="closeForm"
        v-if="dialog && selectedForm === 3"
      />
    </v-dialog>
    <v-snackbar :color="snackbar.color" timeout="4000" v-model="snackbar.show">
      {{ snackbar.text }}
      <v-btn
        text
        fab
        dense
        depressed
        small
        class="ml-10"
        @click.native="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
// components
import DialogBilling from '../SharedComponent/Dialog/DialogBilling.vue';
import SocialData from '../SharedComponent/SocialData.vue';
import OverCounterBilling from './OverCounter/OverCounterBilling.vue';
import AssuranceTypeChange from './Billing/AssuranceTypeChange';

// utils
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import JsPDF from 'jspdf';

// mixins
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import printMixin from '@/mixin/printMixin';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'PatientBilling',
  mixins: [jwtMixin, alertMixin, formatMixin, printMixin],
  components: {
    DialogBilling,
    OverCounterBilling,
    SocialData,
    AssuranceTypeChange,
  },
  data() {
    return {
      isLoadingExport: false,
      isPersistent: true,
      selectedPatient: null,
      isRefresh: false,
      addOnBilling: false,
      expanded: [],
      menu2: false,
      dialog: false,
      tabs: null,
      paid: {
        paid_off: 0,
        not_paid: 0,
      },
      assuranceType: '',
      selectedForm: null,
      status: '',
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      resourceStatus: [
        {
          text: 'Lunas',
          value: 1,
        },
        {
          text: 'Belum Lunas',
          value: 0,
        },
      ],
      assurance: [
        { text: 'Umum', value: 'umum' },
        { text: 'BPJS', value: 'bpjs' },
        { text: 'Asuransi', value: 'asuransi' },
        { text: 'Semua Tipe Penjamin', value: '' },
      ],
      unitType: {
        name: '',
        id: '',
      },
      unit: [],
      patientBill: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'No. Registrasi', value: 'registNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'rm_number' },
          { text: 'Tipe Penjamin', value: 'assurance' },
          { text: 'Unit', value: 'unit' },
          { text: 'Tagihan', value: 'bill' },
          { text: 'Status', value: 'isPaid' },
        ],
        item: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      wadiah: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'No. Registrasi', value: 'registNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'rm_number' },
          { text: 'Tipe Penjamin', value: 'assurance' },
          { text: 'Unit', value: 'unit' },
          { text: 'Saldo', value: 'balance' },
          { text: 'Status', value: 'isPaid' },
        ],
        item: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      otc: {
        headers: [
          {
            text: 'No',
            align: 'center',
            sortable: false,
            value: 'no',
            width: '5%',
          },
          {
            text: 'No. Registrasi',
            value: 'registNumber',
            sortable: false,
            width: '10%',
          },
          {
            text: 'Nama',
            align: 'start',
            sortable: false,
            value: 'name',
            width: '30%',
          },
          {
            text: 'Jenis Kelamin',
            value: 'gender',
            sortable: false,
            align: 'center',
          },
          { text: 'Usia', sortable: false, value: 'age', align: 'start' },
          {
            text: 'Jumlah Tagihan',
            value: 'amountBilling',
            sortable: false,
            align: 'start',
          },
          {
            text: 'Status',
            value: 'isPaid',
            align: 'center',
            sortable: false,
          },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      lengthPage: 0,
      lengthPageOTC: 0,
      page: 1,
      selectedTab: 'tagihan',
      search: '',
      isLoading: false,
      patientRegistered: '',
      patientServed: '',
      dateRange: {
        otc: {
          raw: [moment().format('DD-MM-YYYY'), ''],
          formatted: moment().format('DD-MM-YYYY'),
        },
        billing: {
          raw: [moment().format('DD-MM-YYYY'), ''],
          formatted: moment().format('DD-MM-YYYY'),
        },
      },
      selectedDate: [],
      selectedDateBilling: [],
      isPaid: null,
    };
  },
  watch: {
    date() {
      this.showAllBilling();
      this.getPaidOff();
      this.getServedCount();
    },
    search() {
      this.resetPage();
      this.searchData(this);
    },
    status(value) {
      if (!value) {
        this.showAllBilling();
      }
    },
  },
  beforeMount() {
    this.tabs = 1;
  },
  mounted() {
    this.getPaidOff();
    this.getServedCount();
    this.getUnit();
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    routeBack() {
      return '/keuangan/billing';
    },
    today() {
      return moment().format();
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.showAllBilling();
    }, 650),
    refresh() {
      this.isRefresh = true;
      this.showAllBilling();
      this.getServedCount();
      this.getPaidOff();
    },
    clearDate() {
      this.dateRange.otc.raw = [];
      this.selectedDate = [];
      this.dateRange.billing.raw = [];
      this.selectedDateBilling = [];
      this.showAllBilling();
      this.getServedCount();
      this.getPaidOff();
    },
    resetPage() {
      this.patientBill.pagination.page = 1;
      this.otc.pagination.page = 1;
    },
    changeForm(data, value) {
      this.selectedPatient = data;
      this.selectedForm = value;
      this.dialog = true;
    },
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        if (this.tabs === 2) {
          this.selectedDate = slicedDate;
          this.dateRange.otc = {
            raw: [
              moment(slicedDate[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
              moment(slicedDate[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
            ],
            formatted: formateddate.join(' - '),
          };
        }
        if (this.tabs !== 2) {
          this.selectedDateBilling = slicedDate;
          this.dateRange.billing = {
            raw: [
              moment(slicedDate[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
              moment(slicedDate[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
            ],
            formatted: formateddate.join(' - '),
          };
        }
        // this.datepicker = false;
      }

      // if user selected 1 date
      if (this.tabs === 2) {
        if (data.length === 1) {
          this.dateRange.otc = {
            raw: [moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'), ''],
            formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          };
        }
      }
      if (this.tabs !== 2) {
        if (data.length === 1) {
          this.dateRange.billing = {
            raw: [moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'), ''],
            formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          };
        }
      }
    },
    async showAllOverCounterBilling(itemCount, page) {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/billing/drugssales?page=${page}&itemCount=${itemCount}&search=${this
              .search || ''}&isPaid=${
              this.status?.value === 0 || this.status?.value === 1
                ? this.status?.value
                : ''
            }&startDate=${this.dateRange.otc.raw[0] || ''}&endDate=${this
              .dateRange.otc.raw[1] || ''}`,
          ),
        );
        const { data } = response.data;
        const { length } = response.data;
        this.lengthPageOTC = Math.ceil(length / this.itemsPerRow);
        this.otc.items = data.map((bill, index) => {
          return {
            no: index + this.itemsPerRow * page - this.itemsPerRow,
            id: bill._id,
            name: _.get(bill, 'buyer_data.buyer_name', '-'),
            dateRaw: _.get(bill, 'timestamps.created_at', ''),
            staff: _.get(bill, 'created_by.detail[0].name', ''),
            gender: _.get(bill, `buyer_data.buyer_gender`, '-')
              ? 'Laki-laki'
              : 'Perempuan',
            age: _.get(bill, 'buyer_data.buyer_age', '-'),
            amountBilling: _.get(bill, 'remaining_bill', '-'),
            registNumber: _.get(bill, 'transaction_code', '-'),
            isPaid: _.get(bill, 'is_paid', '-'),
            date: moment(_.get(bill, 'order_date', '-')).format('DD/MM/YYYY'),
            time: moment(_.get(bill, 'order_date', '-')).format('HH:mm'),
            billingData: {
              billing: bill.total_price,
              totalDiscount: bill.total_discount,
              total_bill: bill.total_bill,
              rounding_price: bill.rounding_price,
              total_paid: bill.total_paid,
              remaining_bill: bill.remaining_bill,
              total_return: bill.total_return,
              payment: bill.payment,
            },
          };
        });
      } catch (error) {
        this.otc.items = [];
      } finally {
        this.isLoading = false;
        this.isRefresh = false;
      }
    },
    async printInvoice(item) {
      const response = await axios.get(
        Constant.apiUrl.concat(`/billing/drugssales/${item.id}`),
      );
      const { data } = response.data;
      let nonmix = data.detail.non_mixed_medicine.map(v => {
        return [
          v.name,
          v.amount,
          this.currency(v.price),
          this.currency(v.discount_price),
          this.currency(v.price * v.amount - v.discount_price),
        ];
      });
      let mix = data.detail.mixed_medicine.map(v => {
        return [
          v.name,
          v.amount,
          this.currency(v.price),
          this.currency(v.discount_price),
          this.currency(v.price * v.amount + v.tuslah_price - v.discount_price),
        ];
      });
      let medtool = data.detail.medical_goods.map(v => {
        return [
          v.name,
          v.amount,
          this.currency(v.price),
          this.currency(v.discount_price),
          this.currency(v.price * v.amount - v.discount_price),
        ];
      });
      const itemList = [...nonmix, ...mix, ...medtool];

      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const leftMargin = 1.0;

      doc.setProperties({
        title: `Nota Obat ${item.name}`,
      });

      doc.setFontSize(12);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'Nota Penjualan Obat Bebas', 1.9);
      doc.autoTable({
        body: [
          [
            'Tanggal Transaksi',
            ':',
            `${moment(item.dateRaw).format('DD/MM/YYYY')}`,
          ],
          ['No Transaksi', ':', `${item.registNumber}`],
        ],
        startY: 2,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.4 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 3 },
        },
        margin: {
          left: leftMargin,
        },
        theme: 'plain',
      });

      doc.autoTable({
        body: [
          ['Nama', ':', `${item.name}`],
          ['Nama Petugas', ':', `${item.staff}`],
        ],
        startY: 2,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.3 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3.5,
        },
        theme: 'plain',
      });

      doc.autoTable({
        head: [['Nama', 'Jumlah', 'Harga', 'Diskon', 'Subtotal']],
        body: itemList,

        startY: 2.8,
        styles: {
          fontSize: 10,
          lineWidth: 0.001,
          lineColor: 'black',
          textColor: 'black',
        },
        columnStyles: {
          0: { cellWidth: 2 },
          1: { cellWidth: 0.7 },
          2: { cellWidth: 1.2 },
          3: { cellWidth: 1 },
          4: { cellWidth: 1.2 },
        },
        headStyles: {
          fillColor: '#f2f8fc',
          halign: 'center',
        },
        margin: {
          left: leftMargin,
          right: leftMargin,
        },
        theme: 'grid',
      });
      const lastTable = doc.autoTable.previous.finalY;
      doc.autoTable({
        body: [
          [
            `${
              this.$store.getters.userLoggedIn.clinic.clinicRegion
            }, ${moment().format('DD/MM/YYYY hh.mm')}`,
          ],
          ['Administrasi Billing'],
          [],
          [],
          [`${this.$store.getters.userLoggedIn.name}`],
        ],
        startY: lastTable + 0.2,
        styles: {
          halign: 'center',
          minCellHeight: 0.01,
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          ['Total', `${this.currency(item.billingData.billing)}`],
          ['Total Diskon', `${this.currency(item.billingData.totalDiscount)}`],
          [
            'Total Setelah Diskon',
            `${this.currency(item.billingData.total_bill)}`,
          ],
          [
            'Dana Kebajikan',
            `${this.currency(item.billingData.rounding_price)}`,
          ],
          [],
          ['Total Bayar', `${this.currency(item.billingData.total_paid)}`],
          ['Sisa Tagihan', `${this.currency(item.billingData.remaining_bill)}`],
          [
            'Pembayaran',
            `${this.currency(
              item.billingData.payment && item.billingData.payment.length > 0
                ? item.billingData.payment[item.billingData.payment.length - 1]
                    .amount
                : 0,
            )}`,
          ],
          ['Kembalian', `${this.currency(item.billingData.total_return)}`],
        ],
        startY: lastTable + 0.2,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.8 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          [
            'Total Tagihan',
            `${this.currency(
              item.billingData.rounding_price + item.billingData.total_bill,
            )}`,
          ],
        ],
        startY: lastTable + 1.45,
        styles: {
          align: 'left',
          fontSize: 11,
          fontStyle: 'bold',
        },
        columnStyles: {
          0: { cellWidth: 1.8 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          [
            'Status Tagihan',
            `${
              item.billingData.is_paid
                ? 'Lunas'.toUpperCase()
                : 'Belum Lunas'.toUpperCase()
            }`,
          ],
        ],
        startY: lastTable + 3,
        styles: {
          align: 'left',
          fontSize: 11,
          fontStyle: 'bold',
        },
        columnStyles: {
          0: { cellWidth: 1.8 },
          2: { cellWidth: 2 },
        },
        margin: {
          left: leftMargin + 3,
        },
        theme: 'plain',
      });

      // Letter Head
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }

      // print action
      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    async exportBilling() {
      try {
        this.isLoadingExport = true;
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/billing/billing_component?startDate=${
              this.dateIsValid(this.dateRange.billing.raw[0])
                ? moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
                    'YYYY-MM-DD',
                  )
                : ''
            }&endDate=${
              this.dateIsValid(this.dateRange.billing.raw[1])
                ? moment(this.dateRange.billing.raw[1], 'DD-MM-YYYY').format(
                    'YYYY-MM-DD',
                  )
                : ''
            }`,
          ),
        );
        if (res.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = res.config;
          window.open(url, '_blank');
        }
        this.isLoadingExport = false;
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
        this.isLoadingExport = false;
      }
    },
    closeForm(snackbar) {
      if (snackbar) this.snackbar = snackbar;
      this.dialog = false;
      this.selectedForm = null;
      setTimeout(() => this.showAllBilling(), 500);
      this.getServedCount();
      this.getPaidOff();
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name&installation=Instalasi Rawat Jalan&installation=Instalasi Rawat Inap',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.unit = response.data.data.map(unit => {
              return { name: unit.name, id: unit._id };
            });
          }
        });
    },
    getServedCount() {
      let end;
      const start = this.dateRange?.billing?.raw[0]
        ? moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
            'YYYY-MM-DD',
          )
        : '';
      if (this.dateRange?.billing?.raw[0] && !this.dateRange?.billing?.raw[1]) {
        end = moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
          'YYYY-MM-DD',
        );
      } else {
        end = this.dateRange?.billing?.raw[1]
          ? moment(this.dateRange.billing.raw[1], 'DD-MM-YYYY').format(
              'YYYY-MM-DD',
            )
          : '';
      }
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/registration/count?startDate=${start}&endDate=${end}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.patientRegistered =
              data.servicedPatient + data.unservicedPatient;
            this.patientServed = data.servicedPatient;
          })
          .catch(() => {
            this.patientRegistered = 0;
            this.patientServed = 0;
          })
          .finally(() => {
            this.serveLoading = false;
          });
      });
    },
    getPaidOff() {
      let end;
      const start = this.dateRange?.billing?.raw[0]
        ? moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
            'YYYY-MM-DD',
          )
        : '';
      if (this.dateRange?.billing?.raw[0] && !this.dateRange?.billing?.raw[1]) {
        end = moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
          'YYYY-MM-DD',
        );
      } else {
        end = this.dateRange?.billing?.raw[1]
          ? moment(this.dateRange.billing.raw[1], 'DD-MM-YYYY').format(
              'YYYY-MM-DD',
            )
          : '';
      }
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/billing/paid-off?startDate=${start || ''}&endDate=${end || ''}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.paid = {
              paid_off: data.isPaid,
              not_paid: data.notPaid,
            };
          })
          .catch(() => {
            this.paid = {
              paid_off: 0,
              not_paid: 0,
            };
          });
      });
    },
    dateIsValid(date) {
      return moment(date, 'DD-MM-YYYY').isValid();
    },
    showAllBilling() {
      this.isLoading = true;
      const itemCount = this.itemsPerRow;
      const halaman = this.patientBill.pagination.page;
      const halamanotc = this.otc.pagination.page;
      if (this.selectedTab === 'otc') {
        this.showAllOverCounterBilling(itemCount, halamanotc); // get over counter billing
        return;
      }
      // const dateIsValid = date => {
      //   return moment(date, 'DD-MM-YYYY').isValid();
      // };
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/billing/all?page=${halaman}&itemCount=${itemCount}&search=${
                this.search
              }&startDate=${
                this.dateIsValid(this.dateRange.billing.raw[0])
                  ? moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }&endDate=${
                this.dateIsValid(this.dateRange.billing.raw[1])
                  ? moment(this.dateRange.billing.raw[1], 'DD-MM-YYYY').format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }&assurance=${this.assuranceType || ''}&unit_name=${this.unitType
                ?.name || ''}&add_on=${this.addOnBilling ? 1 : 0}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            const { length: dataLength } = response.data;
            this.lengthPage = Math.ceil(dataLength / itemCount);

            this.patientBill.item = data.map((bill, idx) => {
              return {
                no: idx + this.itemsPerRow * halaman - this.itemsPerRow,
                id: bill._id,
                name: _.get(bill, 'id_emr.social[0].name', '-'),
                registNumber: _.get(
                  bill,
                  'id_registration.registration_number',
                  '-',
                ),
                isGuarantorChanged: bill.isGuarantorChange,
                pcareBridgingStatus: {
                  // ATTENTION!! please dont change condition below
                  registration:
                    bill.pcare?.registration === false
                      ? 'Data pendaftaran gagal terkirim ke PCare. Anda dapat mengirim ulang melalui dashboard pendaftaran'
                      : null,
                  service:
                    bill.pcare?.service === false
                      ? 'Data pelayanan gagal terkirim ke PCare. Silakan cek kembali asesmen pasien kemudian simpan kembali untuk mengirim ulang'
                      : '',
                  both:
                    bill.pcare?.registration === false &&
                    bill.pcare?.service === false,
                },
                isBpjs: bill.isBpjs,
                rm_number: _.get(bill, 'id_emr.medical_record_number', '-'),
                assurance: _.get(bill, 'id_registration.assurance.type', '-'),
                assuranceData: {
                  name: bill.id_registration?.assurance?.name,
                  number: bill.id_registration?.assurance?.number,
                },
                balance: _.get(bill, 'total_wadiah', '-'),
                bill: _.get(bill, 'remaining_bill', '-'),
                unit: _.get(bill, 'id_registration.id_unit.name', '-'),
                status: bill.id_registration ? 'Pasien Terdaftar' : '',
                isOutpatient:
                  bill.id_registration?.status?.type === 'rawat jalan',
                isPaid: bill.isPaid,
                isIncludeAddOn: bill.isIncludeAddOn,
                isOverpayment: bill.isOverpayment,
                gender: _.get(bill, `id_emr.social[0].gender`, '-')
                  ? 'Laki-laki'
                  : 'Perempuan',
                age: _.get(bill, 'id_emr.social[0].age', '-'),
                date: bill.id_registration
                  ? moment(bill.id_registration.timestamps.created_at)
                      .locale('id')
                      .format('l')
                  : '-',
                time: bill.id_registration
                  ? moment(bill.id_registration.timestamps.created_at)
                      .locale('id')
                      .format('HH:mm')
                  : '-',
                id_registration: bill.id_registration._id,
                isWadiah: this.selectedTab === 'wadiah',
              };
            });
            this.isPaid = this.patientBill.item.filter(x => x.isPaid).length;
          })
          .catch(() => {
            this.lengthPage = 0;
            this.patientBill.item = [];
          })
          .finally(() => {
            this.isLoading = false;
            this.isRefresh = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.showAllBilling();
      this.getServedCount();
      this.getPaidOff();
      this.interval = setInterval(this.showAllBilling, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.refresh {
  animation: rotation 0.23s infinite;
}
.text-grey {
  color: #9e9e9e;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.detail-info {
  max-width: 400px;
  grid-template-columns: 0.7fr 0.3fr 0.4fr;
  display: grid;
}

// .patientbilling {
//   padding: 3px 24px;
// }

.status-chip {
  min-width: 60px;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 10px;
  justify-content: center;
  border-radius: 5px;

  &.paid {
    background-color: #e7f8ec !important;
    color: #268843 !important;
  }

  &.unpaid {
    background-color: #ffebee !important;
    color: #c62828 !important;
  }
}

.border-bottom {
  border-bottom: 2px solid #f5f5f5 !important;
}

::v-deep .v-data-table-header {
  background-color: #f4f5f5;
  border-bottom-color: #9ca2a5;

  span {
    color: black;
  }
}

.filter-header {
  margin: 0;
  // display: grid;
  // column-gap: 16px;
  &__three {
    align-items: center;
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &__four {
    align-items: center;
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 0.4fr;
  }
}

::v-deep {
  .v-toolbar__content {
    height: 140px !important;
    padding: 0px;
  }
}

::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: 2 px solid #ececec;
  }
}

::v-deep {
  .v-data-table__wrapper {
    overflow: hidden;
  }
}

::v-deep .v-dialog--active.dialogPrint {
  width: 450px;
  box-shadow: none !important;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

.addon-chip {
  // background-color: #fffbea;
  // padding: 5px;
  color: #bb4802;
}
.dropdown {
  min-height: 3rem;
  border: 1px solid black;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-align: left;
}
.dropdown:hover {
  background-color: #e6f6fe;
  cursor: pointer;
}
.dropdown-item {
  color: #404040;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw !important;
}
</style>
