import Constant from '@/const';
import axios from 'axios';

const updateDiscountDrugSales = async ({ transaction_code, payload }) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(
        `/billing/drugssales/discount/${transaction_code}`,
      ),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default updateDiscountDrugSales;
